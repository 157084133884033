import React from "react";

// Components
import { ModalPrompt } from "../../../components/Modals/ModalPrompt/ModalPrompt";

// Constants
const bodyText = "Do you really want to delete this contact?";
const headerText = "Are you sure?";

export const ContactDeleteModal = (props) => {
    const { handleDeleteContact, isDeleting, onHide, show } = props;

    const buttonInvisible = {
        onClick: onHide,
        text: "Cancel",
    };
    const buttonPrimary = {
        isDisabled: isDeleting,
        cypressTestId: "contact-delete-confirm-button",
        onClick: handleDeleteContact,
        text: isDeleting ? "Deleting..." : "Delete",
    };

    return (
        <ModalPrompt
            bodyText={bodyText}
            buttonInvisible={buttonInvisible}
            buttonPrimary={buttonPrimary}
            headerText={headerText}
            onHide={onHide}
            show={show}
        />
    );
};
