import { useState } from "react";

// Platforms
import { Intercom } from "platforms/intercom";

export const useCartDisplay = () => {
    /**
     * useState
     */

    const [shouldDisplayCart, setShouldDisplayCart] = useState(false);

    /**
     * End Hooks
     */

    function hideCart() {
        Intercom.showWidget();
        setShouldDisplayCart(false);
    }

    function showCart() {
        Intercom.hideWidget();
        setShouldDisplayCart(true);
    }

    return { hideCart, shouldDisplayCart, showCart };
};
