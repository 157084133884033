import { useEffect, useState } from "react";

export const useSignInWelcomeBackgroundFade = ({ images }) => {
    /**
     * useState
     */

    const [hasLoaded, setHasLoaded] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [nextImageIndex, setNextImageIndex] = useState(1);
    const [fade, setFade] = useState(false);

    /**
     * useEffect
     */

    useEffect(() => {
        if (hasLoaded) {
            const timeout = setTimeout(() => {
                fade
                    ? setCurrentImageIndex(
                          (currentImageIndex + 1) % images.length
                      )
                    : setNextImageIndex(
                          (currentImageIndex + 1) % images.length
                      );
                return () => clearTimeout(timeout);
            }, 1000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fade]);

    /**
     * End Hooks
     */

    const handleSwitchBackground = () => {
        fade
            ? setCurrentImageIndex((currentImageIndex + 1) % images.length)
            : setNextImageIndex((currentImageIndex + 1) % images.length);
        setTimeout(() => {
            !hasLoaded && setHasLoaded(true);
            setFade((prev) => !prev);
        }, 500);
    };

    return { currentImageIndex, fade, nextImageIndex, handleSwitchBackground };
};
