export function generateRecruitingStationText(currentDepLocation) {
    if (currentDepLocation?.locale && currentDepLocation?.office) {
        // If these two values are the same, just return one of them
        if (currentDepLocation.locale === currentDepLocation.office) {
            return currentDepLocation.locale;
        } else {
            return `${currentDepLocation.locale} • ${currentDepLocation.office}`;
        }
    } else {
        return "Select Recruiting Station";
    }
}

export function generateCurrentDateText() {
    const date = new Date();
    const dateObj = {
        day: date.getDate(),
        month: date.toLocaleString("default", { month: "long" }),
        year: date.getFullYear(),
    };
    return `${dateObj.month} ${dateObj.day}, ${dateObj.year}`.toUpperCase();
}

export function generateRecruiterText(recruits) {
    if (recruits === 0) {
        return "WELCOME";
    } else if (recruits === 1) {
        return `${recruits} RECRUIT`;
    } else {
        return `${recruits} RECRUITS`;
    }
}

export function generateShipDateText(daysUntilShip) {
    if (daysUntilShip === null) {
        return "UPCOMING";
    } else if (daysUntilShip === 0) {
        return "TODAY";
    } else if (daysUntilShip === 1) {
        return "TOMORROW";
    } else if (daysUntilShip < 0) {
        return "SHIPPED";
    } else {
        return daysUntilShip + " DAYS";
    }
}

export function generateShipDateSubText(
    currentShipDate,
    daysUntilShip,
    formattedShipDate
) {
    if (daysUntilShip === null || daysUntilShip === -1) {
        return "";
    } else {
        return `${formattedShipDate.month} ${formattedShipDate.day}, ${currentShipDate.year}`;
    }
}

export function generateFormattedShipDate(shipDate) {
    const { day, month } = shipDate;
    return {
        day: getDay(day),
        month: getMonth(month),
    };
}

function getDay(day) {
    switch (day) {
        case "01":
            return 1;
        case "02":
            return 2;
        case "03":
            return 3;
        case "04":
            return 4;
        case "05":
            return 5;
        case "06":
            return 6;
        case "07":
            return 7;
        case "08":
            return 8;
        case "09":
            return 9;
        default:
            return day;
    }
}

function getMonth(month) {
    switch (month) {
        case "01":
            return "January";
        case "02":
            return "February";
        case "03":
            return "March";
        case "04":
            return "April";
        case "05":
            return "May";
        case "06":
            return "June";
        case "07":
            return "July";
        case "08":
            return "August";
        case "09":
            return "September";
        case "10":
            return "October";
        case "11":
            return "November";
        case "12":
            return "December";
        default:
            return month;
    }
}
