import React from "react";
import { useHistory } from "react-router";
import cx from "classnames";

// Components
import { Banner } from "components/Banner/Banner";
import { ButtonSecondary } from "components/Buttons/Buttons";
import { Text } from "components/Text/Text";

// CSS
import styles from "./letters-compose-review-credits.module.scss";

// Localization
import { useTranslation } from "localization/localization";

export const LettersComposeReviewCredits = (props) => {
    const {
        credits,
        handlePurchaseModalToggle,
        hasCredits,
        recipient,
        supportSquad,
    } = props;

    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * Router Hooks
     */

    const history = useHistory();

    /**
     * End Hooks
     */

    function goToSupportSquads() {
        history.push("/support-squad");
    }

    function renderCreditsAmount({ amount }) {
        const creditsText =
            amount === 1
                ? t("letter_ip_singular", { ns: "common" })
                : t("letter_ip_plural", { ns: "common" });
        const squadText = recipient.isSquadRecipient
            ? t("squad_ip_singular", { ns: "common" })
            : "";
        if (amount >= 1) {
            return `1 of ${amount} ${squadText}${creditsText}`;
        }
        if (amount === 0) {
            return `0 ${t("letter_ip_plural", { ns: "common" })}`;
        }
    }

    function renderPersonalCredits() {
        return (
            <>
                <div className="letters-compose__review__section__header">
                    <span className="letters-compose__review__section__header__text">
                        {renderCreditsAmount({ amount: credits })}
                    </span>
                </div>
                {!hasCredits && (
                    <Banner
                        classes="letters-compose__review__purchase-credits-banner"
                        config={{
                            shouldShowChevron: true,
                            shouldShowIcon: true,
                        }}
                        onClick={handlePurchaseModalToggle}
                        theme="yellow"
                    >
                        <Text isBold>
                            {t("ready_to_send_letter", { ns: "letters" })}
                        </Text>
                        <Text>
                            {t("letter_purchase_required", { ns: "letters" })}
                        </Text>
                    </Banner>
                )}
                {hasCredits && (
                    <ButtonSecondary
                        classes="letters-compose__review__purchase-credits-button"
                        cypressTestId="letters-compose-review-add-tokens-button"
                        onClick={handlePurchaseModalToggle}
                    >
                        <Text>{t("purchase_letters", { ns: "common" })}</Text>
                    </ButtonSecondary>
                )}
            </>
        );
    }

    function renderSquadCredits() {
        return (
            <>
                <div className="letters-compose__review__section__header">
                    <span className="letters-compose__review__section__header__text">
                        {renderCreditsAmount({
                            amount: supportSquad.tokenCount,
                        })}
                    </span>
                    <span
                        className={cx(
                            "letters-compose__review__section__header__icon",
                            "letters-compose__review__section__header__icon--disabled",
                            "material-icons"
                        )}
                    >
                        add
                    </span>
                </div>
                {hasCredits && (
                    <Banner classes={styles.banner}>
                        <Text>
                            {t("squad_letter_applied", {
                                ns: "letters",
                                squadName: supportSquad?.squadName,
                            })}
                        </Text>
                    </Banner>
                )}
                {!hasCredits && (
                    <Banner
                        classes={styles.banner}
                        config={{ isActive: true, shouldShowChevron: true }}
                        onClick={goToSupportSquads}
                        theme="orange"
                    >
                        <span>
                            {t("squad_not_enough_letters", { ns: "letters" })}
                        </span>
                    </Banner>
                )}
                <ButtonSecondary
                    classes="letters-compose__review__purchase-credits-button"
                    cypressTestId="letters-compose-review-add-tokens-button"
                    onClick={handlePurchaseModalToggle}
                >
                    <Text>{t("purchase_letters", { ns: "common" })}</Text>
                </ButtonSecondary>
            </>
        );
    }

    return (
        <div className="letters-compose__review__section credits-section">
            {recipient.isSquadRecipient
                ? renderSquadCredits()
                : renderPersonalCredits()}
        </div>
    );
};
