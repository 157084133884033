// Constants
import { characterLimits } from "../constants/constants";

// Enums
import { NavigationEnum } from "../../../enums/NavigationEnum";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Utils
import { formatValueForCharacterLimit } from "utils/textUtils";

// Validation
import { Validation } from "../validation/validation";

export const useWalletGiftCardsPurchaseSender = (props) => {
    const { sender, setCurrentSection, setOrderProgress, setSender } = props;

    /**
     * This method acts as the event listener for changes to the component's
     * input fields. The input values go through the `Validation.isValidPrintedValue`
     * validation if they are provided via the "message" or "name" fields.
     *
     * If incoming input value changes do not pass validation, they should not
     * be reflected in state.
     *
     * @param {Event} e contains event information, including target (name, value)
     */
    function handleChange({ target }) {
        const { name, value } = target;
        if (name === "name" || name === "message") {
            if (Validation.isValidPrintedValue(value)) {
                const formattedValue = formatValueForCharacterLimit({
                    characterLimit: characterLimits[name],
                    currentValue: sender[name],
                    incomingValue: value,
                });
                setSender((prev) => ({ ...prev, [name]: formattedValue }));
            }
        } else {
            setSender((prev) => ({ ...prev, [name]: value }));
        }
    }

    function handleGoBack() {
        setOrderProgress((prev) => ({ ...prev, recipient: false }));
        setCurrentSection(NavigationEnum.RECIPIENT);
    }

    function handleSubmit() {
        if (Validation.canSubmit(sender)) {
            AnalyticsLogger.logGiftCardMessageSubmit({ sender });
            setOrderProgress((prev) => ({ ...prev, sender: true }));
            setCurrentSection(NavigationEnum.BILLING_DETAILS);
        }
    }

    return {
        handleChange,
        handleGoBack,
        handleSubmit,
    };
};
