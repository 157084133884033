import React from "react";

// Components
import { ButtonSecondary, ButtonInvisible } from "components/Buttons/Buttons";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Modal } from "components/Modals/Modal";
import { Text } from "components/Text/Text";

// CSS
import styles from "css/letters/components/letters-photo-delete-modal.module.scss";

// Localization
import { useTranslation } from "localization/localization";

export const LettersPhotoDeleteModal = ({
    handleImageDelete,
    message,
    previewImage,
    setShouldShowDeleteModal,
    setShouldShowPhotoManageModal,
    shouldShow,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * End Hooks
     */

    function handleCloseModal() {
        setShouldShowDeleteModal(false);
        setShouldShowPhotoManageModal(true);
    }

    function handleDeletePhoto() {
        handleImageDelete(
            message.customPhotoArray
                .map((image) => image.binary)
                .indexOf(previewImage)
        );
        handleCloseModal();
    }

    return (
        <Modal
            className={styles["letters-photo-delete-modal"]}
            cypressTestId="letters-photo-delete-modal"
            dialogClassName={styles.dialog}
            onClose={handleCloseModal}
            onHide={handleCloseModal}
            size="small"
            show={shouldShow}
            shouldHideHeader={true}
        >
            <div className={styles.content}>
                <DeleteOutlineIcon className={styles.icon} />
                <Text classes={styles.header} isBold={true} type="h4">
                    {t("delete_photo_modal_title", { ns: "letters" })}
                </Text>
                <Text classes={styles["text"]} type="p">
                    {t("delete_photo_modal_subtitle", { ns: "letters" })}
                </Text>
                <div className={styles.buttons}>
                    <ButtonSecondary
                        classes={styles["button-secondary"]}
                        onClick={handleDeletePhoto}
                    >
                        <Text>{t("delete_photo", { ns: "letters" })}</Text>
                    </ButtonSecondary>
                    <ButtonInvisible onClick={handleCloseModal}>
                        <Text>{t("cancel", { ns: "common" })}</Text>
                    </ButtonInvisible>
                </div>
            </div>
        </Modal>
    );
};
