import { useState, useEffect } from "react";

export function useDebounceValue(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [delay, value]);

    return debouncedValue;
}

// Source: https://dev.to/gabe_ragland/debouncing-with-react-hooks-jci
