import React from "react";
import cx from "classnames";

// Components
import { Avatar } from "components/Avatar/Avatar";
import { CalendarIcon } from "components/Icons/CalendarIcon";
import { LetterIcon } from "components/Icons/LetterIcon";
import { Modal } from "components/Modals/Modal";
import { PhoneIcon } from "components/Icons/PhoneIcon";
import { Text } from "components/Text/Text";

// CSS
import styles from "./muster-recruit-detail-modal.module.scss";

// Utils
import { generateShipDateObject } from "utils/userUtils";
import { generateShipDateText } from "./utils";

export const MusterRecruitDetailModal = (props) => {
    const { selectedRecruit, shouldShow, handleToggleRecruitDetailModal } =
        props;

    /**
     * Constants
     */

    const recruitData = selectedRecruit?.suggestion;
    const formattedShipDate =
        recruitData?.basicTrainingShipDate &&
        generateShipDateText(
            generateShipDateObject(recruitData?.basicTrainingShipDate)
        );

    /**
     * End Hooks & Constants
     */

    return (
        <Modal
            bodyClasses={styles.body}
            className={styles.musterRecruitDetailModal}
            centered={true}
            onHide={handleToggleRecruitDetailModal}
            show={shouldShow}
        >
            <div className={styles.header}>
                <Avatar additionalClasses={styles.avatar} user={recruitData} />
                <Text isBold={true} type="h3">
                    {recruitData?.fullName || ""}
                </Text>
            </div>
            <div className={styles.details}>
                <Text isBold={true} type="h4">
                    Recruit Details
                </Text>
                <div className={styles.lineItem}>
                    <Text classes={styles.label} type="h4">
                        <LetterIcon
                            className={cx(styles.icon, styles.letterIcon)}
                        />
                        Email
                    </Text>
                    <Text classes={styles.value} type="h4">
                        {recruitData?.email || "Unknown"}
                    </Text>
                </div>
                <div className={styles.lineItem}>
                    <Text classes={styles.label} type="h4">
                        <PhoneIcon className={styles.icon} />
                        Phone
                    </Text>
                    <Text classes={styles.value} type="h4">
                        {recruitData?.phoneNumber || "Unknown"}
                    </Text>
                </div>
                <div className={styles.lineItem}>
                    <Text classes={styles.label} type="h4">
                        <CalendarIcon className={styles.icon} />
                        Ship Date
                    </Text>
                    <Text classes={styles.value} type="h4">
                        {formattedShipDate || "Unknown"}
                    </Text>
                </div>
            </div>
        </Modal>
    );
};
