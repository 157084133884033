import React from "react";

// Components
import { ButtonPrimary, ButtonSecondary } from "components/Buttons/Buttons";
import { Slider } from "components/Slider/Slider";
import { Text } from "components/Text/Text";

// CSS
import styles from "./wallet-gift-cards-landing-lead.module.scss";

// Constants
import { sliderImages } from "./constants/constants";

// Enums
import { WalletNavigationEnum } from "scenes/Wallet/constants/constants";

// Hooks
import { useWalletGiftCardsLandingLead } from "./hooks/useWalletGiftCardsLandingLead";

// Localization
import { useTranslation } from "localization/localization";

export const WalletGiftCardsLandingLead = (props) => {
    const { setCurrentSection, setWalletCurrentSection } = props;

    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    const { handleGoToLetterCompose } = useWalletGiftCardsLandingLead({
        setCurrentSection,
    });

    /**
     * End Hooks
     */

    return (
        <section className={styles["wallet-gift-cards-landing-lead"]}>
            <header>
                <Text type="h1">
                    {t("give_the_gift_of_support", { ns: "wallet" })}
                </Text>
            </header>
            <Slider
                images={sliderImages}
                shouldShowText={true}
                text={[{ subtitle: t("slider_text", { ns: "wallet" }) }]}
            />
            <div className={styles.buttons}>
                <ButtonPrimary
                    cypressTestId="wallet-gift-cards-landing-purchase-button"
                    onClick={handleGoToLetterCompose}
                >
                    <Text>
                        {t("send_gift_card_with_letter", { ns: "wallet" })}
                    </Text>
                </ButtonPrimary>
                <ButtonSecondary
                    cypressTestId="wallet-gift-cards-landing-manage-button"
                    onClick={() =>
                        setWalletCurrentSection(WalletNavigationEnum.MANAGE)
                    }
                >
                    <Text>{t("manage_gift_cards", { ns: "wallet" })}</Text>
                </ButtonSecondary>
            </div>
        </section>
    );
};
