import { DEV } from "../utils/urlUtils";

export const configDefault = {
    apiKey: DEV
        ? process.env.REACT_APP_FIREBASE_API_KEY_DEV
        : process.env.REACT_APP_FIREBASE_API_KEY_PROD,
    authDomain: DEV
        ? process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_DEV
        : process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_PROD,
    databaseURL: DEV
        ? process.env.REACT_APP_FIREBASE_DATABASE_URL_DEV
        : process.env.REACT_APP_FIREBASE_DATABASE_URL_PROD,
    projectId: DEV
        ? process.env.REACT_APP_FIREBASE_PROJECT_ID_DEV
        : process.env.REACT_APP_FIREBASE_PROJECT_ID_PROD,
    storageBucket: DEV
        ? process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_DEV
        : process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_PROD,
    messagingSenderId: DEV
        ? process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID_DEV
        : process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID_PROD,
    appId: DEV
        ? process.env.REACT_APP_FIREBASE_APP_ID_DEV
        : process.env.REACT_APP_FIREBASE_APP_ID_PROD,
    measurementId: DEV
        ? process.env.REACT_APP_FIREBASE_MEASUREMENT_ID_DEV
        : process.env.REACT_APP_FIREBASE_MEASUREMENT_ID_PROD,
};

export const configNews = {
    apiKey: DEV
        ? process.env.REACT_APP_FIREBASE_API_KEY_NEWS_DEV
        : process.env.REACT_APP_FIREBASE_API_KEY_NEWS,
    authDomain: DEV
        ? process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_NEWS_DEV
        : process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_NEWS,
    databaseURL: DEV
        ? process.env.REACT_APP_FIREBASE_DATABASE_URL_NEWS_DEV
        : process.env.REACT_APP_FIREBASE_DATABASE_URL_NEWS,
    projectId: DEV
        ? process.env.REACT_APP_FIREBASE_PROJECT_ID_NEWS_DEV
        : process.env.REACT_APP_FIREBASE_PROJECT_ID_NEWS,
    storageBucket: DEV
        ? process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_NEWS_DEV
        : process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_NEWS,
    messagingSenderId: DEV
        ? process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID_NEWS_DEV
        : process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID_NEWS,
    appId: DEV
        ? process.env.REACT_APP_FIREBASE_APP_ID_NEWS_DEV
        : process.env.REACT_APP_FIREBASE_APP_ID_NEWS,
    measurementId: DEV
        ? process.env.REACT_APP_FIREBASE_MEASUREMENT_ID_NEWS_DEV
        : process.env.REACT_APP_FIREBASE_MEASUREMENT_ID_NEWS,
};
