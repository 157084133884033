import React, { useEffect } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// Components
import { ButtonPrimary } from "components/Buttons/Buttons";
import { Select } from "components/Inputs/Select/Select";
import { TextField } from "components/Inputs/TextField/TextField";
import { Text } from "components/Text/Text";
import { ToggleSwitch } from "components/ToggleSwitch/ToggleSwitch";

// CSS
import styles from "./contact-modal-name.module.scss";

// Enums
import { ContactModalNavigation } from "../../enums/ContactModalNavigation";

// Utils
import { titleCase } from "utils/textUtils";
import { generateFullName } from "utils/userUtils";

export const ContactModalName = ({
    contactStaging,
    handleChange,
    setCurrentSection,
    setHeaderText,
    shouldReverseFullName,
    titles,
    toggleShouldReverseFullName,
}) => {
    const { firstName, lastName } = contactStaging;

    /**
     * useEffect
     */

    useEffect(() => {
        setHeaderText("Who are you writing to?");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * End Hooks
     */

    function handleSubmit(e) {
        e?.preventDefault();
        setCurrentSection(ContactModalNavigation.ADDRESS_FORMATTER);
    }

    function renderPreview() {
        return (
            <div
                className={cx(styles.preview, {
                    [styles["preview--hidden"]]: !firstName || !lastName,
                })}
                data-cy="contact-modal-name-preview"
            >
                <Text>Letters will be addressed to:</Text>
                <Text isBold>
                    {generateFullName(contactStaging, shouldReverseFullName)}
                </Text>
            </div>
        );
    }

    function renderToggle() {
        return (
            <ToggleSwitch
                checked={shouldReverseFullName}
                classes={cx(styles.toggle, {
                    [styles["toggle--hidden"]]: !firstName || !lastName,
                })}
                cypressTestId="contact-modal-name-toggle"
                labelSwitchText="Reverse first and last name?"
                name="toggleReverseName"
                onChange={toggleShouldReverseFullName}
            />
        );
    }

    return (
        <div className={styles.contactModalName}>
            <form className={styles.form} onSubmit={handleSubmit}>
                <TextField
                    className={styles.input}
                    cypressTestId="contact-modal-name-first-name"
                    id="firstName"
                    label="First Name"
                    name="firstName"
                    onChange={handleChange}
                    required
                    value={firstName}
                />
                <TextField
                    className={styles.input}
                    cypressTestId="contact-modal-name-last-name"
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    onChange={handleChange}
                    required
                    value={lastName}
                />
                <div className={styles.rankDescription}>
                    <Text>
                        Do you know their rank? It's ok if you don't. You can
                        always change this later. This will appear on the
                        letter's envelope addressed to them.
                    </Text>
                </div>
                <Select
                    className={styles.input}
                    cypressTestId="contact-modal-name-title-select"
                    id="contact-modal-name-title-select"
                    label="Rank"
                    labelId="contact-modal-name-title-select"
                    name="title"
                    onChange={handleChange}
                >
                    <option value={null} />
                    {titles.map((option) => (
                        <option
                            key={`${option.title} ${option.branchId}`}
                            value={option.title}
                        >
                            {`${option.title} ${titleCase(option.branchId)}`}
                        </option>
                    ))}
                </Select>
                {renderPreview()}
                {renderToggle()}
                <div className={styles.buttons}>
                    <ButtonPrimary
                        cypressTestId="contact-modal-name-submit-button"
                        isDisabled={!firstName || !lastName}
                        onClick={handleSubmit}
                        text="Continue"
                        type="submit"
                    />
                </div>
            </form>
        </div>
    );
};

ContactModalName.propTypes = {
    contactStaging: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    setCurrentSection: PropTypes.func.isRequired,
    shouldReverseFullName: PropTypes.bool.isRequired,
    titles: PropTypes.array.isRequired,
    toggleShouldReverseFullName: PropTypes.func.isRequired,
};
