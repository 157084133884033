import React from "react";
import PropTypes from "prop-types";

// CSS
import styles from "./dashboard-messages-preview-card.module.scss";

// Components
import { PreviewCard } from "components/PreviewCard/PreviewCard";

// Hooks
import { useDashboardMessagesPreviewCard } from "./hooks/useDashboardMessagesPreviewCard";

export const DashboardMessagesPreviewCard = ({ isLoading, message }) => {
    /**
     * Custom Hooks
     */

    const { handleTrackIterableClickEvent, item } =
        useDashboardMessagesPreviewCard({ message });

    /**
     * End Hooks
     */

    return (
        <PreviewCard
            className={styles.dashboardMessagesPreviewCard}
            isLoading={isLoading}
            item={item}
            onCardClick={handleTrackIterableClickEvent}
        />
    );
};

DashboardMessagesPreviewCard.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    message: PropTypes.object,
};
