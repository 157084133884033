import React from "react";
import cx from "classnames";

// Components
import { Avatar } from "components/Avatar/Avatar";
import { ButtonPhotoUpload } from "components/Buttons/ButtonPhotoUpload/ButtonPhotoUpload";
import { ButtonSecondary } from "components/Buttons/Buttons";
import { Text } from "components/Text/Text";

// Hooks
import { useLettersComposeMessageContent } from "./hooks/useLettersComposeMessageContent";

// Localization
import { useTranslation } from "localization/localization";

// Utils
import { moveCaretToEnd } from "utils/accessibilityUtils";
import { calculateRemainingMessageCharacters } from "utils/lettersUtils";

export const LettersComposeMessageContent = ({
    config,
    handleInputChange,
    hasMultiPhoto,
    message,
    recipient,
    shouldRenderPhotosInline,
    setPreviewImage,
    setRecipient,
    setShouldShowPhotoManageModal,
    toggleContactModal,
}) => {
    const { isEmbedded, hideUserList } = config || {};
    const { content, customPhotoArray } = message;

    /**
     * Custom Hooks
     **/

    const { t } = useTranslation("letters");

    const {
        handleImagePreviewClick,
        handleMessageContentChange,
        handleToggleContactModal,
        textAreaRef,
        textAreaStyle,
        userList,
    } = useLettersComposeMessageContent({
        handleInputChange,
        message,
        recipient,
        setPreviewImage,
        setRecipient,
        setShouldShowPhotoManageModal,
        toggleContactModal,
    });

    function renderCharactersLeft() {
        const { remainingCharacters } = calculateRemainingMessageCharacters(
            message.content
        );
        return (
            <div
                className={cx("letters-compose__message__content__characters", {
                    "letters-compose__message__content__characters--embedded":
                        isEmbedded,
                    "letters-compose__message__content__characters--overLimit":
                        remainingCharacters < 0,
                })}
            >
                <span className="letters-compose__message__content__characters__text">
                    {t("characters_left", { ns: "letters" })}:{" "}
                    {remainingCharacters}
                </span>
            </div>
        );
    }

    function renderHeader() {
        const user = userList[0];
        return (
            <div className="letters-compose__message__content__recipient">
                <div className="details">
                    <Avatar additionalClasses="avatar" user={user} />
                    <div>
                        <Text
                            classes="full-name"
                            cyId="letters-compose-message-content-header-full-name"
                            isBold={true}
                            type="h2"
                        >
                            {user.fullName || ""}
                        </Text>
                        <Text classes="address-line1" type="h4">
                            {user.address.line1 || ""}
                        </Text>
                        <Text classes="address-line2" type="h4">
                            {user.address.line2 || ""}
                        </Text>
                        <div className="city-state-zip">
                            <Text type="h4">
                                {user.address.city || ""},&nbsp;
                            </Text>
                            <Text type="h4">
                                {user.address.state || ""}&nbsp;
                            </Text>
                            <Text type="h4">{user.address.zipcode || ""}</Text>
                            <Text type="h4">{user.address.zip || ""}</Text>
                        </div>
                    </div>
                </div>
                <ButtonSecondary
                    classes="edit-contact-button"
                    isDisabled={
                        !user.connected ||
                        user.recipientId ||
                        user.isSquadRecipient
                    }
                    onClick={handleToggleContactModal}
                >
                    <Text>{t("edit_recipient", { ns: "letters" })}</Text>
                </ButtonSecondary>
            </div>
        );
    }

    function renderImagePreview() {
        return (
            <div
                className={cx(
                    "letters-compose__message__content__panel__message-preview__preview-container",
                    {
                        "letters-compose__message__content__panel__message-preview__preview-container--inline":
                            shouldRenderPhotosInline,
                    }
                )}
            >
                {Array(4)
                    .fill(0)
                    .map((i, index) => {
                        return (
                            <ButtonPhotoUpload
                                classes="button-photo-upload"
                                cypressTestId={`letters-compose-message-content-button-photo-upload-${index}`}
                                isLocked={index === 0 ? false : !hasMultiPhoto}
                                key={index}
                                onClick={{
                                    locked: () =>
                                        handleImagePreviewClick(index),
                                    unlocked: () =>
                                        handleImagePreviewClick(index),
                                }}
                                photo={{
                                    url: customPhotoArray[index]?.binary,
                                    text: customPhotoArray[index]?.url,
                                }}
                                shouldShowBoxShadow={true}
                                text={{
                                    locked: t("unlock_photos", {
                                        ns: "letters",
                                    }),
                                    unlocked: hasMultiPhoto
                                        ? t("upload_photo", { ns: "letters" })
                                        : t("free_photo", { ns: "letters" }),
                                }}
                            />
                        );
                    })}
            </div>
        );
    }

    function renderMessagePreview() {
        const { remainingCharacters } = calculateRemainingMessageCharacters(
            message.content
        );
        return (
            <div className="letters-compose__message__content__panel__message-preview">
                {!isEmbedded && (
                    <Text
                        classes="letters-compose__message__content__panel__message-preview__header"
                        isBold={true}
                        type="h2"
                    >
                        {t("letter_compose", { ns: "letters" })}
                    </Text>
                )}
                <div
                    className={cx(
                        "letters-compose__message__content__panel__message-preview__body",
                        {
                            "letters-compose__message__content__panel__message-preview__body--overLimit":
                                remainingCharacters < 0,
                        }
                    )}
                >
                    <textarea
                        className={cx(
                            "letters-compose__message__content__panel__message-preview__textarea",
                            "textarea",
                            {
                                "letters-compose__message__content__panel__message-preview__textarea--overLimit":
                                    remainingCharacters < 0,
                            }
                        )}
                        data-cy="letters-compose-message-content-text-area"
                        id="letters-compose-message-content-textarea"
                        name="content"
                        onChange={handleMessageContentChange}
                        onFocus={() => moveCaretToEnd(textAreaRef)}
                        placeholder={t("start_typing_your_letter", {
                            ns: "letters",
                        })}
                        ref={textAreaRef}
                        style={textAreaStyle}
                        value={content}
                    />
                    {shouldRenderPhotosInline && renderImagePreview()}
                </div>
                {renderCharactersLeft()}
            </div>
        );
    }

    return (
        <div
            className={cx("letters-compose__message__content", {
                "letters-compose__message__content--embedded": isEmbedded,
            })}
        >
            {!hideUserList && userList.length !== 0 && renderHeader()}
            {!shouldRenderPhotosInline && renderImagePreview()}
            {renderMessagePreview()}
        </div>
    );
};
