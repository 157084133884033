import React, { createContext, useState } from "react";

export const HeaderBarContext = createContext({});

export const HeaderBarProvider = (props) => {
    const { children } = props;

    /**
     * useState
     **/

    const [headerBarContent, setHeaderBarContent] = useState({
        backPathname: "",
        onBackClick: null,
        text: "",
        textSecondary: "",
    });

    /**
     * End Hooks
     **/

    const headerBarContext = {
        headerBarContent,
        setHeaderBarContent,
    };

    return (
        <HeaderBarContext.Provider value={headerBarContext}>
            {children}
        </HeaderBarContext.Provider>
    );
};
