import { useContext, useMemo } from "react";

// Context
import { CartContext } from "context/Cart/cart";

export const useMemoryBookPromoBanner = () => {
    /**
     * useContext
     */

    const { cart } = useContext(CartContext);

    /**
     * useMemo
     */

    const memoryBookItem = useMemo(
        () =>
            cart?.items.find(
                (item) => item?.variant?.product?.handle === "memory-book"
            ),
        [cart?.items]
    );
    /**
     * Constants
     */

    const promoBannerText =
        memoryBookItem?.variant?.product.metadata?.promo_banner;

    /**
     * End Hooks
     */

    return { cart, promoBannerText };
};
