export function EditAvatarIcon({ className, height, width }) {
    return (
        <svg
            className={className}
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <ellipse cx="12.1407" cy="12" rx="11.8595" ry="12" fill="#345168" />
            <path
                d="M16.0901 6.16651C15.6271 6.16508 15.1827 6.34956 14.8558 6.67894L14.4004 7.13645L16.8825 9.59788L17.338 9.14037C17.8264 8.6358 17.9669 7.88666 17.6947 7.23815C17.4226 6.58964 16.7907 6.16761 16.0901 6.16651Z"
                fill="white"
            />
            <path
                d="M6.63471 17.8329H8.5094C8.63398 17.8336 8.75372 17.7847 8.84216 17.697L15.7739 10.7606L13.2383 8.2251L6.30195 15.1568C6.21422 15.2452 6.16531 15.365 6.16602 15.4896V17.3643C6.16602 17.6231 6.37586 17.8329 6.63471 17.8329Z"
                fill="white"
            />
        </svg>
    );
}

EditAvatarIcon.defaultProps = {
    className: "",
    height: "24px",
    width: "24px",
};
