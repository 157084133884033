import React from "react";
import ReactCrop from "react-image-crop";
import PropTypes from "prop-types";

// Components
import { ButtonPrimary } from "components/Buttons/Buttons";
import { Modal } from "components/Modals/Modal";
import { Text } from "components/Text/Text";

// CSS
import styles from "./image-crop-modal.module.scss";
import "react-image-crop/dist/ReactCrop.css";

// Hooks
import { useImageCropModal } from "./hooks/useImageCropModal";

// Localization
import { useTranslation } from "localization/localization";

// Validation
import { Validation } from "./validation/validation";

export const ImageCropModal = ({
    aspect,
    finalWidth,
    handleImageCancel,
    handleSaveCroppedImage,
    imagePreview,
    showImageCropModal,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    const {
        completedCrop,
        crop,
        handleSetInitialCrop,
        handleSubmit,
        imageRef,
        setCompletedCrop,
        setCrop,
    } = useImageCropModal({
        aspect,
        finalWidth,
        handleSaveCroppedImage,
    });

    /**
     * End Hooks
     */

    return (
        <Modal
            backdrop="static"
            bodyClasses={styles.body}
            centered="true"
            cypressTestId="letters-compose-image-crop-modal"
            dialogClassName={styles.imageCropModal}
            headerText={t("crop_photo", { ns: "common" })}
            isHeaderTextCentered
            onHide={handleImageCancel}
            show={showImageCropModal}
            size="lg"
        >
            <ReactCrop
                aspect={aspect}
                className={styles.cropper}
                crop={crop}
                onChange={setCrop}
                onComplete={setCompletedCrop}
            >
                <img
                    alt={t("alt_cropping_window", { ns: "components" })}
                    className={styles.image}
                    onLoad={(e) => handleSetInitialCrop(e, imageRef)}
                    ref={imageRef}
                    src={imagePreview.imagePreviewUrl}
                />
            </ReactCrop>
            <div className={styles.buttons}>
                <ButtonPrimary
                    classes={styles.button}
                    cypressTestId="letters-compose-image-crop-modal-button"
                    isDisabled={!Validation.isValidCrop(completedCrop)}
                    onClick={handleSubmit}
                >
                    <Text>{t("apply", { ns: "common" })}</Text>
                </ButtonPrimary>
            </div>
        </Modal>
    );
};

ImageCropModal.defaultProps = {
    aspect: 1,
};

ImageCropModal.propTypes = {
    aspect: PropTypes.number.isRequired,
    handleImageCancel: PropTypes.func.isRequired,
    handleSaveCroppedImage: PropTypes.func.isRequired,
    imagePreview: PropTypes.object.isRequired,
    showImageCropModal: PropTypes.bool.isRequired,
};
