import { useContext, useEffect } from "react";

// Context
import { HeaderBarContext } from "context/headerBar";
import { PurchaseContext } from "context/purchase";

// Hooks
import { useDocumentTitle } from "@uidotdev/usehooks";

// Localization
import { useTranslation } from "localization/localization";

export const useMemoryBook = () => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useContext
     */

    const { setHeaderBarContent } = useContext(HeaderBarContext);
    const { fetchSavedCards } = useContext(PurchaseContext);

    /**
     * useEffect
     */

    useEffect(() => {
        fetchSavedCards();
        setHeaderBarContent({
            backPathname: "/",
            onBackClick: null,
            text: t("memory_book_ip_singular", { ns: "common" }),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Custom Hooks
     */

    useDocumentTitle(t("document_title", { ns: "memory_book" }));

    /**
     * End Hooks
     */

    return {};
};
