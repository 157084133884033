export class InboxMessage {
    constructor(message) {
        this.html = message.html;
        this.id = message.id;
        this.isRead = message.isRead;
        this.subtitle = message.subtitle;
        this.timestamp = message.timestamp;
        this.title = message.title;
    }

    markAsRead() {
        this.isRead = true;
    }
}
