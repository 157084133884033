import React from "react";

// Components
import { ButtonPrimary } from "components/Buttons/Buttons";
import { HeaderBar } from "components/HeaderBar/HeaderBar";
import { MemoryBookDescription } from "./components/MemoryBookDescription/MemoryBookDescription";
import { MemoryBookImage } from "./components/MemoryBookImage/MemoryBookImage";
import { MemoryBookIneligible } from "./components/MemoryBookIneligible/MemoryBookIneligible";
import { MemoryBookItems } from "./components/MemoryBookItems/MemoryBookItems";
import { MemoryBookPromoBanner } from "./components/MemoryBookPromoBanner/MemoryBookPromoBanner";
import { MemoryBookQuantity } from "./components/MemoryBookQuantity/MemoryBookQuantity";
import { Panel } from "components/Panel/Panel";
import { Text } from "components/Text/Text";

// CSS
import styles from "./memory-book.module.scss";

// Hooks
import { useMemoryBook } from "./hooks/useMemoryBook";
import { useMemoryBookCart } from "./hooks/useMemoryBookCart";

// Localization
import { useTranslation } from "localization/localization";

export const MemoryBook = () => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    useMemoryBook();

    const {
        cart,
        handleGoHome,
        ineligibleErrorMessage,
        isIneligibleForMemoryBook,
        isUpdatingCart,
        showCart,
        updateCartBookQuantity,
    } = useMemoryBookCart();

    /**
     * End Hooks
     */

    function renderBody() {
        return isIneligibleForMemoryBook ? (
            <MemoryBookIneligible
                handleGoHome={handleGoHome}
                ineligibleErrorMessage={ineligibleErrorMessage}
            />
        ) : (
            renderMemoryBookCart()
        );
    }

    function renderMemoryBookCart() {
        return (
            <div className={styles.body}>
                <div className={styles.content}>
                    <MemoryBookDescription cart={cart} />
                    <MemoryBookPromoBanner />
                    <MemoryBookItems
                        cart={cart}
                        isUpdatingCart={isUpdatingCart}
                    />
                    <MemoryBookQuantity
                        cart={cart}
                        isUpdatingCart={isUpdatingCart}
                        updateCartBookQuantity={updateCartBookQuantity}
                    />
                </div>
                <div className={styles.buttons}>
                    <ButtonPrimary
                        classes={styles.button}
                        cypressTestId="memory-book-continue-to-checkout-button"
                        isDisabled={!cart || isUpdatingCart}
                        onClick={showCart}
                    >
                        <Text>
                            {t("continue_to_checkout", { ns: "common" })}
                        </Text>
                    </ButtonPrimary>
                    <Text classes={styles.disclaimer}>
                        {t("tax_shipping_calculated_checkout", {
                            ns: "common",
                        })}
                    </Text>
                </div>
            </div>
        );
    }

    return (
        <div className="page">
            <HeaderBar />
            <div className={styles.memoryBook}>
                <Panel classes={styles.panel}>
                    {renderBody()}
                    <MemoryBookImage />
                </Panel>
            </div>
        </div>
    );
};
