import React from "react";

// Components
import { Banner } from "components/Banner/Banner";
import { ButtonPhotoUpload } from "components/Buttons/ButtonPhotoUpload/ButtonPhotoUpload";
import { ButtonPrimary, ButtonSecondary } from "components/Buttons/Buttons";
import { LettersComposeReviewAddOnsPanel } from "./LettersComposeReviewAddOnsPanel";
import { LettersComposeReviewRecipientSender } from "./components/LettersComposeReviewRecipientSender/LettersComposeReviewRecipientSender";
import { LettersComposeMessageContent } from "../../../components/LettersComposeMessageContent/LettersComposeMessageContent";
import { LettersComposeReviewCredits } from "./LettersComposeReviewCredits/LettersComposeReviewCredits";
import { LoadingOverlay } from "components/Loading/Loading";
import { Text } from "components/Text/Text";

// CSS
import "css/letters/scenes/Compose/letters-compose-review.scss";

// Enums
import { AddOnsNavigation } from "../enums/enums";

// Localization
import { useTranslation } from "localization/localization";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Utils
import { canSubmitLetterWithAddOns } from "scenes/Letters/utils/addOnsUtils";
import { calculateRemainingMessageCharacters } from "utils/lettersUtils";
import { emptyFunction } from "utils/miscUtils";

// Validation
import { useLettersComposeReview } from "./hooks/useLettersComposeReview";

export const LettersComposeReview = (props) => {
    const {
        addOnsTotal,
        credits,
        giftCard,
        handleAddOnsModalToggle,
        handleLetterSend,
        handlePurchaseModalToggle,
        handleSubmitMessageForReview,
        hasAddOnsTotal,
        hasSelectedAddOns,
        hasSelectedCreditCard,
        hasSelectedGiftCard,
        hasAddedPostagePaidInSession,
        isEditing,
        isEligibleForPostagePaid,
        loading,
        message,
        recipient,
        sender,
        setPreviewImage,
        setRecipient,
        setShouldShowDeleteModal,
        setShouldShowPhotoManageModal,
        supportSquad,
        titles,
    } = props;

    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    const {
        brand,
        connected,
        creditCardIconSrc,
        editStatuses,
        generateEditInputs,
        handleNameReverseToggle,
        handleSaveMessage,
        handleToggleContactModal,
        hasCredits,
        last4,
        toggleEditPanel,
    } = useLettersComposeReview(props);

    /**
     * End Hooks & Constants
     */

    function renderHeader(column) {
        return column === "left" ? (
            <div className="letters-compose__review__header">
                <Text
                    classes="letters-compose__review__header__title"
                    isBold={true}
                    type="h2"
                >
                    {t("letter_details", { ns: "letters" })}
                </Text>
            </div>
        ) : (
            <div className="letters-compose__review__header">
                <Text
                    classes="letters-compose__review__header__title"
                    isBold={true}
                    type="h2"
                >
                    {t("payment_details", { ns: "common" })}
                </Text>
            </div>
        );
    }

    function renderLoading() {
        return (
            (loading.addOns || loading.updateUserAddress) && <LoadingOverlay />
        );
    }

    function renderMessagePreview() {
        const { content, customPhotoArray, imagePreviewUrl } = message;
        const { remainingCharacters } =
            calculateRemainingMessageCharacters(content);
        const hasImageClass = !!imagePreviewUrl
            ? "letters-compose__review__message-preview__body__text__wrapper--has-image"
            : "";
        const body = editStatuses.message ? (
            <>
                <LettersComposeMessageContent
                    {...props}
                    config={{ isEmbedded: true, hideUserList: true }}
                    handleSubmit={handleSubmitMessageForReview}
                    setPreviewImage={setPreviewImage}
                    setShouldShowDeleteModal={setShouldShowDeleteModal}
                    setShouldShowPhotoManageModal={
                        setShouldShowPhotoManageModal
                    }
                    shouldRenderPhotosInline={false}
                />
                <ButtonPrimary
                    classes="letters-compose__review__message-preview__save-message-button"
                    cypressTestId="letters-compose-review-save-message-button"
                    isDisabled={remainingCharacters < 0}
                    onClick={handleSaveMessage}
                >
                    <Text>{t("save_changes", { ns: "common" })}</Text>
                </ButtonPrimary>
            </>
        ) : (
            <div className="letters-compose__review__message-preview__body">
                {customPhotoArray.length > 0 && (
                    <div className="letters-compose__review__message-preview__body__images">
                        {customPhotoArray.map((i, index) => {
                            return (
                                <ButtonPhotoUpload
                                    classes="button-photo-upload"
                                    isLocked={false}
                                    key={index}
                                    onClick={{
                                        locked: () => {},
                                        unlocked: () => {},
                                    }}
                                    photo={{
                                        url: customPhotoArray[index].binary,
                                        text: customPhotoArray[index].url,
                                    }}
                                    shouldShowBoxShadow={false}
                                    text={{
                                        locked: t("sandboxx_plus", {
                                            ns: "common",
                                        }),
                                        unlocked: t("upload_photo", {
                                            ns: "letters",
                                        }),
                                    }}
                                />
                            );
                        })}
                    </div>
                )}
                <div
                    className={`letters-compose__review__message-preview__body__text__wrapper ${hasImageClass}`}
                >
                    <p
                        className="letters-compose__review__message-preview__body__text"
                        data-cy="letters-compose-review-message-preview"
                    >
                        {content}
                    </p>
                </div>
                <ButtonSecondary
                    classes="letters-compose__review__message-preview__edit-message-button"
                    cypressTestId="letters-compose-review-edit-message"
                    onClick={() => toggleEditPanel("message")}
                >
                    <Text>{t("edit_letter", { ns: "letters" })}</Text>
                </ButtonSecondary>
            </div>
        );
        return (
            <div className="letters-compose__review__message-preview letters-compose__review__section">
                {body}
            </div>
        );
    }

    function renderPaymentMethod() {
        const isActive = hasSelectedAddOns || hasSelectedGiftCard;
        const onClickEdit = () => {
            AnalyticsLogger.logEditPayment();
            handleAddOnsModalToggle(true, {
                section: AddOnsNavigation.SELECT_PAYMENT,
            });
        };

        return (
            isActive &&
            (!!addOnsTotal || !!giftCard.amount.total) && (
                <div className="letters-compose__review__section">
                    <div className="letters-compose__review__section__header">
                        <Text
                            className="letters-compose__review__section__header__text"
                            isBold={true}
                            type="h4"
                        >
                            {t("payment_method", { ns: "common" })}
                        </Text>
                    </div>
                    {hasSelectedCreditCard ? (
                        <div className="letters-compose__review__payment-method__card">
                            <div className="letters-compose__review__payment-method__card__icon">
                                <img
                                    alt={`${brand} icon`}
                                    src={creditCardIconSrc}
                                />
                            </div>
                            <div className="letters-compose__review__payment-method__card__digits">
                                <span>••••{last4}</span>
                            </div>
                        </div>
                    ) : (
                        <>
                            <Banner
                                classes="letters-compose__review__payment-method__banner"
                                theme="orange"
                            >
                                {t("please_select_payment_method", {
                                    ns: "letters",
                                })}
                            </Banner>
                            <ButtonSecondary
                                classes="letters-compose__review__purchase-add-ons-button"
                                cypressTestId="letters-compose-review-add-tokens-button"
                                onClick={onClickEdit}
                            >
                                <Text>
                                    {t("add_payment_method", { ns: "common" })}
                                </Text>
                            </ButtonSecondary>
                        </>
                    )}
                </div>
            )
        );
    }

    /**
     * * The submit button is enabled if the following conditions are met...
     * * -- The letter is not in the process of being sent
     * * -- The user is not editing the letter
     */
    function renderSubmitButton() {
        const canSubmit =
            !isEditing &&
            hasCredits &&
            canSubmitLetterWithAddOns(hasAddOnsTotal, hasSelectedCreditCard);
        const onSubmit = canSubmit ? handleLetterSend : emptyFunction;
        const style = { width: "100%" };
        return (
            <ButtonPrimary
                cypressTestId="letters-compose-review-submit-button"
                isDisabled={!canSubmit}
                isLoading={loading.sendingLetter}
                onClick={onSubmit}
                style={style}
            >
                <Text>{t("send_letter", { ns: "letters" })}</Text>
            </ButtonPrimary>
        );
    }

    function renderTotal() {
        const total =
            hasSelectedAddOns || hasSelectedGiftCard
                ? (
                      parseFloat(addOnsTotal) +
                      parseFloat(giftCard.amount.total)
                  ).toFixed(2)
                : "0.00";
        return (
            <div
                className="letters-compose__review__total"
                data-cy="letters-compose-review-total"
            >
                <div className="letters-compose__review__total__text">
                    <span>{t("total", { ns: "common" })}</span>
                </div>
                <div className="letters-compose__review__total__amount">
                    <span>${total}</span>
                </div>
            </div>
        );
    }

    function renderColumnLeft() {
        return (
            <div className="letters-compose__review__grid__column-one">
                <div className="panel">
                    {renderHeader("left")}
                    {renderMessagePreview()}
                    <div className="letters-compose__review__grid__column-one__recipient-sender-container">
                        {!editStatuses.recipient && (
                            <LettersComposeReviewRecipientSender
                                connected={connected}
                                editStatuses={editStatuses}
                                hasAddedPostagePaidInSession={
                                    hasAddedPostagePaidInSession
                                }
                                handleToggleContactModal={
                                    handleToggleContactModal
                                }
                                isEditing={isEditing}
                                isEligibleForPostagePaid={
                                    isEligibleForPostagePaid
                                }
                                generateEditInputs={generateEditInputs}
                                handleNameReverseToggle={
                                    handleNameReverseToggle
                                }
                                loading={loading}
                                message={message}
                                parentKey="sender"
                                recipient={recipient}
                                sender={sender}
                                setRecipient={setRecipient}
                                titles={titles}
                                toggleEditPanel={toggleEditPanel}
                            />
                        )}
                        {!editStatuses.sender && (
                            <LettersComposeReviewRecipientSender
                                connected={connected}
                                editStatuses={editStatuses}
                                hasAddedPostagePaidInSession={
                                    hasAddedPostagePaidInSession
                                }
                                handleToggleContactModal={
                                    handleToggleContactModal
                                }
                                isEditing={isEditing}
                                isEligibleForPostagePaid={
                                    isEligibleForPostagePaid
                                }
                                generateEditInputs={generateEditInputs}
                                handleNameReverseToggle={
                                    handleNameReverseToggle
                                }
                                loading={loading}
                                message={message}
                                parentKey="recipient"
                                recipient={recipient}
                                sender={sender}
                                setRecipient={setRecipient}
                                titles={titles}
                                toggleEditPanel={toggleEditPanel}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }

    function renderColumnRight() {
        return (
            <div className="letters-compose__review__grid__column-two">
                <div className="panel">
                    {renderHeader("right")}
                    {renderLoading()}
                    <LettersComposeReviewCredits
                        credits={credits}
                        hasCredits={hasCredits}
                        handlePurchaseModalToggle={handlePurchaseModalToggle}
                        recipient={recipient}
                        supportSquad={supportSquad}
                    />
                    <LettersComposeReviewAddOnsPanel {...props} />
                    {renderPaymentMethod()}
                </div>
                <div className="place-order">
                    {renderTotal()}
                    {renderSubmitButton()}
                </div>
            </div>
        );
    }

    return (
        <div className="letters-compose__review">
            <div className="letters-compose__review__grid">
                {renderColumnLeft()}
                {renderColumnRight()}
            </div>
        </div>
    );
};
