import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// Components
import { Text } from "components/Text/Text";

// CSS
import styles from "./quantity-selector.module.scss";

// Hooks
import { useQuantitySelector } from "./hooks/useQuantitySelector";

// Utils
import { emptyFunction } from "utils/miscUtils";

export function QuantitySelector({
    initialQuantity,
    maximumQuantity,
    minimumQuantity,
    onChange,
    onChangeFailure,
    onChangeSuccess,
}) {
    const {
        canDecrement,
        canIncrement,
        handleDecrement,
        handleIncrement,
        quantity,
    } = useQuantitySelector({
        initialQuantity,
        maximumQuantity,
        minimumQuantity,
        onChange,
        onChangeFailure,
        onChangeSuccess,
    });

    return (
        <div className={styles.quantitySelector}>
            <button
                className={cx(styles.button, {
                    [styles["button--disabled"]]: !canDecrement,
                })}
                onClick={handleDecrement}
            >
                <Text>-</Text>
            </button>
            <Text classes={styles.number}>{quantity}</Text>
            <button
                className={cx(styles.button, {
                    [styles["button--disabled"]]: !canIncrement,
                })}
                onClick={handleIncrement}
            >
                <Text>+</Text>
            </button>
        </div>
    );
}

QuantitySelector.defaultProps = {
    initialQuantity: 0,
    minimumQuantity: 0,
    onChange: emptyFunction,
    onChangeFailure: emptyFunction,
    onChangeSuccess: emptyFunction,
};

QuantitySelector.propTypes = {
    initialQuantity: PropTypes.number,
    maximumQuantity: PropTypes.number,
    minimumQuantity: PropTypes.number,
    onChange: PropTypes.func,
    onChangeFailure: PropTypes.func,
    onChangeSuccess: PropTypes.func,
};
