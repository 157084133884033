import React from "react";
import cx from "classnames";
import { Skeleton } from "@material-ui/lab";

// Components
import { AvatarEmptyIcon } from "components/Icons/AvatarEmptyIcon";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { DogTagIcon } from "components/Icons/DogTagIcon";
import { DropdownCountry } from "components/Dropdowns/DropdownCountry/DropdownCountry";
import { DropdownState } from "components/Dropdowns/DropdownState/DropdownState";
import { IdBadgeIcon } from "components/Icons/IdBadgeIcon";
import { LocationIcon } from "components/Icons/LocationIcon";
import { MilitaryBaseIcon } from "components/Icons/MilitaryBaseIcon";
import { PhoneIcon } from "components/Icons/PhoneIcon";
import { Select } from "components/Inputs/Select/Select";
import { TextField } from "components/Inputs/TextField/TextField";
import { TextFieldMasked } from "components/Inputs/TextFieldMasked/TextFieldMasked";

// Localization
import { useTranslation } from "localization/localization";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Utils
import { generateSelectDefaults } from "../../utils";
import { handleKeyDown } from "utils/eventUtils";
import {
    generateBranchPrettyName,
    generateIntentionPrettyName,
    generateMilitaryStatusPrettyName,
    generateRelationshipPrettyName,
} from "utils/userUtils";

export const ProfileFieldsEdit = ({
    branchPersona,
    depLocation,
    handleChange,
    handleChangeState,
    handleOpenPersonaSelectionModal,
    handleShowProfileDepsModal,
    handleShowShipDateModal,
    isBuildingCareer,
    isLetterWriter,
    isPreparingForBasic,
    isProfileLoaded,
    isRecruiter,
    profileInfo,
    rootPersona,
    setShowProfileDeleteAccountModal,
    toggleFormEditState,
    togglePhoneVerificationModal,
    userStaging,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * Constants
     */

    const defaults = generateSelectDefaults(userStaging);

    /**
     * End Hooks
     */

    function renderStateInput() {
        return !userStaging.address?.country ||
            userStaging.address?.country === "US" ? (
            <DropdownState
                className={cx("input--state")}
                cypressTestId="profile-fields-edit-state"
                label={t("state", { ns: "common" })}
                name="state"
                onChange={handleChangeState}
                required
                value={userStaging.address?.state}
            />
        ) : (
            <TextField
                className={cx("input--state")}
                cypressTestId="profile-fields-edit-state"
                label={t("state", { ns: "common" })}
                name="state"
                onChange={handleChangeState}
                required
                value={userStaging.address?.state}
            />
        );
    }

    return (
        <div className="profile__fields__edit">
            <form
                className="profile__fields__edit__form form"
                onSubmit={(e) =>
                    toggleFormEditState(e, { shouldValidate: true })
                }
            >
                <div className="profile__fields__header__left">
                    <button
                        className="panel__header__button"
                        data-cy="profile-fields-cancel-button"
                        onClick={(e) =>
                            toggleFormEditState(e, {
                                shouldValidate: false,
                            })
                        }
                        onKeyDown={(e) =>
                            handleKeyDown(e, () =>
                                toggleFormEditState(e, {
                                    shouldValidate: false,
                                })
                            )
                        }
                        tabIndex="0"
                        type="reset"
                    >
                        {t("cancel", { ns: "common" })}
                    </button>
                </div>
                <div className="profile__fields__header__right">
                    <button
                        className="panel__header__button"
                        data-cy="profile-fields-save-button"
                        onClick={(e) =>
                            toggleFormEditState(e, {
                                shouldValidate: true,
                            })
                        }
                        onKeyDown={(e) =>
                            handleKeyDown(e, () =>
                                toggleFormEditState(e, {
                                    shouldValidate: true,
                                })
                            )
                        }
                        tabIndex="0"
                        type="submit"
                    >
                        {t("save", { ns: "common" })}
                    </button>
                </div>
                <TextField
                    className={cx("input--firstName")}
                    cypressTestId="profile-fields-edit-first-name"
                    label={t("first_name", { ns: "common" })}
                    name="firstName"
                    onChange={handleChange}
                    required
                    value={userStaging.firstName}
                />
                <TextField
                    className={cx("input--lastName")}
                    cypressTestId="profile-fields-edit-last-name"
                    label={t("last_name", { ns: "common" })}
                    name="lastName"
                    onChange={handleChange}
                    required
                    value={userStaging.lastName}
                />
                <TextField
                    className={cx("input--line1")}
                    cypressTestId="profile-fields-edit-line-1"
                    label={t("address_line_1", { ns: "common" })}
                    name="line1"
                    onChange={handleChange}
                    required
                    value={userStaging.address?.line1}
                />
                <TextField
                    className={cx("input--line2")}
                    cypressTestId="profile-fields-edit-line-2"
                    label={t("address_line_2", { ns: "common" })}
                    name="line2"
                    onChange={handleChange}
                    value={userStaging.address?.line2}
                />
                <TextField
                    className={cx("input--city")}
                    cypressTestId="profile-fields-edit-city"
                    label={t("city", { ns: "common" })}
                    name="city"
                    onChange={handleChange}
                    required
                    value={userStaging.address?.city}
                />
                {renderStateInput()}
                <TextField
                    className={cx("input--zipcode")}
                    cypressTestId="profile-fields-edit-zipcode"
                    label={t("zip_code", { ns: "common" })}
                    name="zipcode"
                    onChange={handleChange}
                    required
                    value={userStaging.address?.zipcode}
                />
                <DropdownCountry
                    className={cx("input--country")}
                    cypressTestId="profile-fields-edit-country"
                    label={t("country", { ns: "common" })}
                    name="country"
                    onChange={handleChange}
                    required
                    value={userStaging.address?.country}
                />
                <Select
                    className={cx("input--gender")}
                    cypressTestId="profile-fields-edit-gender-select"
                    label={t("gender", { ns: "common" })}
                    name="gender"
                    onChange={handleChange}
                    value={userStaging.gender}
                >
                    <option disabled value={null} />
                    <option selected={defaults.gender.female} value="Female">
                        {t("female", { ns: "common" })}
                    </option>
                    <option selected={defaults.gender.male} value="Male">
                        {t("male", { ns: "common" })}
                    </option>
                </Select>
                <TextFieldMasked
                    className={cx("input--birthday")}
                    data-cy="profile-fields-edit-birthday"
                    label={t("date_of_birth_label", { ns: "common" })}
                    name="birthday"
                    onChange={handleChange}
                    value={userStaging.birthday}
                />
                <Select
                    className={cx("input--maritalStatus")}
                    cypressTestId="profile-fields-edit-marital-status-select"
                    label={t("marital_status", { ns: "common" })}
                    name="maritalStatus"
                    onChange={handleChange}
                    value={userStaging.maritalStatus}
                >
                    <option
                        disabled
                        selected={defaults.maritalStatus.empty}
                        value={null}
                    ></option>
                    <option
                        selected={defaults.maritalStatus.single}
                        value="Single"
                    >
                        {t("single", { ns: "common" })}
                    </option>
                    <option
                        selected={defaults.maritalStatus.married}
                        value="Married"
                    >
                        {t("married", { ns: "common" })}
                    </option>
                    <option
                        selected={defaults.maritalStatus.separated}
                        value="Separated"
                    >
                        {t("separated", { ns: "common" })}
                    </option>
                    <option
                        selected={defaults.maritalStatus.divorced}
                        value="Divorced"
                    >
                        {t("divorced", { ns: "common" })}
                    </option>
                </Select>
            </form>
            <div className="profile__fields__display__item">
                <div className="profile__fields__display__item__left">
                    <div className="profile__fields__display__item__icon">
                        <PhoneIcon height="20px" width="20px" />
                    </div>
                    <div className="profile__fields__display__item__title">
                        <span>{t("phone", { ns: "common" })}</span>
                    </div>
                </div>
                <div className="profile__fields__display__item__left">
                    <div className="profile__fields__display__item__content">
                        <span
                            className="link"
                            data-cy="profile-fields-display-phone-number-edit"
                            onClick={() =>
                                togglePhoneVerificationModal(true, "profile")
                            }
                            onKeyDown={(e) =>
                                handleKeyDown(e, () =>
                                    togglePhoneVerificationModal(
                                        true,
                                        "profile"
                                    )
                                )
                            }
                            tabIndex="0"
                        >
                            {profileInfo.phoneNumber ||
                                t("not_specified", { ns: "common" })}
                        </span>
                    </div>
                </div>
            </div>
            <div className="profile__fields__display__item">
                <div className="profile__fields__display__item__left">
                    <div className="profile__fields__display__item__icon">
                        <AvatarEmptyIcon />
                    </div>
                    <div className="profile__fields__display__item__title">
                        <span>{t("use_sandboxx_to", { ns: "common" })}</span>
                    </div>
                </div>
                <div className="profile__fields__display__item__left">
                    <div className="profile__fields__display__item__content">
                        <span
                            className="link"
                            data-cy="profile-fields-edit-role"
                            onClick={handleOpenPersonaSelectionModal}
                            onKeyDown={(e) =>
                                handleKeyDown(e, () =>
                                    handleOpenPersonaSelectionModal()
                                )
                            }
                            tabIndex="0"
                        >
                            {rootPersona
                                ? generateIntentionPrettyName(rootPersona.id)
                                : t("not_specified", { ns: "common" })}
                        </span>
                    </div>
                </div>
            </div>
            {isLetterWriter && (
                <div className="profile__fields__display__item">
                    <div className="profile__fields__display__item__left">
                        <div className="profile__fields__display__item__icon">
                            <IdBadgeIcon />
                        </div>
                        <div className="profile__fields__display__item__title">
                            <span>
                                {t("relationship_to_recruit", { ns: "common" })}
                            </span>
                        </div>
                    </div>
                    <div className="profile__fields__display__item__left">
                        <div className="profile__fields__display__item__content">
                            <span
                                className="link"
                                data-cy="profile-fields-edit-role"
                                onClick={handleOpenPersonaSelectionModal}
                                onKeyDown={(e) =>
                                    handleKeyDown(e, () =>
                                        handleOpenPersonaSelectionModal()
                                    )
                                }
                                tabIndex="0"
                            >
                                {profileInfo?.personas[1]
                                    ? generateRelationshipPrettyName(
                                          profileInfo.personas[1].id
                                      )
                                    : t("not_specified", { ns: "common" })}
                            </span>
                        </div>
                    </div>
                </div>
            )}
            {(isPreparingForBasic || isBuildingCareer || isRecruiter) && (
                <div className="profile__fields__display__item">
                    <div className="profile__fields__display__item__left">
                        <div className="profile__fields__display__item__icon">
                            <DogTagIcon />
                        </div>
                        <div className="profile__fields__display__item__title">
                            <span>
                                {t("military_status", { ns: "common" })}
                            </span>
                        </div>
                    </div>
                    <div className="profile__fields__display__item__left">
                        <div className="profile__fields__display__item__content">
                            <span
                                className="link"
                                data-cy="profile-fields-edit-role"
                                onClick={handleOpenPersonaSelectionModal}
                                onKeyDown={(e) =>
                                    handleKeyDown(e, () =>
                                        handleOpenPersonaSelectionModal()
                                    )
                                }
                                tabIndex="0"
                            >
                                {profileInfo?.personas[1]
                                    ? generateMilitaryStatusPrettyName(
                                          profileInfo.personas[1].id
                                      )
                                    : t("not_specified", { ns: "common" })}
                            </span>
                        </div>
                    </div>
                </div>
            )}
            {(isPreparingForBasic || isBuildingCareer || isRecruiter) && (
                <div className="profile__fields__display__item">
                    <div className="profile__fields__display__item__left">
                        <div className="profile__fields__display__item__icon">
                            <IdBadgeIcon />
                        </div>
                        <div className="profile__fields__display__item__title">
                            <span>
                                {t("branch_of_service", { ns: "common" })}
                            </span>
                        </div>
                    </div>
                    <div className="profile__fields__display__item__left">
                        <div className="profile__fields__display__item__content">
                            <span
                                className="link"
                                data-cy="profile-fields-edit-branch"
                                onClick={handleOpenPersonaSelectionModal}
                                onKeyDown={(e) =>
                                    handleKeyDown(e, () =>
                                        handleOpenPersonaSelectionModal()
                                    )
                                }
                                tabIndex="0"
                            >
                                {branchPersona
                                    ? generateBranchPrettyName(branchPersona.id)
                                    : t("not_specified", { ns: "common" })}
                            </span>
                        </div>
                    </div>
                </div>
            )}
            {isPreparingForBasic && branchPersona && (
                <div className="profile__fields__display__item">
                    <div className="profile__fields__display__item__left">
                        <div className="profile__fields__display__item__icon">
                            <LocationIcon />
                        </div>
                        <div className="profile__fields__display__item__title">
                            <span>
                                {t("recruiting_station", { ns: "common" })}
                            </span>
                        </div>
                    </div>
                    <div className="profile__fields__display__item__left">
                        <div className="profile__fields__display__item__content">
                            <span
                                className="link"
                                onClick={() => handleShowProfileDepsModal(true)}
                                onKeyDown={(e) =>
                                    handleKeyDown(e, () =>
                                        handleShowProfileDepsModal(true)
                                    )
                                }
                                tabIndex="0"
                            >
                                {depLocation
                                    ? `${depLocation.locale} • ${depLocation.office}`
                                    : t("not_specified", { ns: "common" })}
                            </span>
                        </div>
                    </div>
                </div>
            )}
            {isPreparingForBasic && (
                <div className="profile__fields__display__item">
                    <div className="profile__fields__display__item__left">
                        <div className="profile__fields__display__item__icon">
                            <MilitaryBaseIcon />
                        </div>
                        <div className="profile__fields__display__item__title">
                            <span>{t("ship_date", { ns: "common" })}</span>
                        </div>
                    </div>
                    <div className="profile__fields__display__item__left">
                        <div className="profile__fields__display__item__content">
                            <span
                                className="link"
                                onClick={() => handleShowShipDateModal(true)}
                                onKeyDown={(e) =>
                                    handleKeyDown(e, () =>
                                        handleShowShipDateModal(true)
                                    )
                                }
                                tabIndex="0"
                            >
                                {isProfileLoaded ? (
                                    <Skeleton height={16} width={150} />
                                ) : profileInfo?.basicTrainingShipDate ? (
                                    profileInfo.basicTrainingShipDate
                                ) : (
                                    t("not_specified", { ns: "common" })
                                )}
                            </span>
                        </div>
                    </div>
                </div>
            )}
            <div className="profile__fields__display__item">
                <button
                    className="profile__fields__display__item__left profile__fields__display__item__left--action"
                    data-cy="profile-fields-delete-account"
                    onClick={() => {
                        setShowProfileDeleteAccountModal(true);
                        AnalyticsLogger.logDeleteAccountProfileButtonClick();
                    }}
                >
                    <div className="profile__fields__display__item__icon profile__fields__display__item__icon--delete">
                        <DeleteOutlineIcon />
                    </div>
                    <div className="profile__fields__display__item__title profile__fields__display__item__title--delete">
                        <span>
                            {t("delete_account.delete_account", {
                                ns: "profile",
                            })}
                        </span>
                    </div>
                </button>
            </div>
        </div>
    );
};
