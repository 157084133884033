import { useCallback, useContext, useEffect, useState } from "react";

// Context
import { HeaderBarContext } from "context/headerBar";

// Interfaces
import IterableInboxManager from "interfaces/inbox/IterableInboxManager";

// Localization
import { useTranslation } from "localization/localization";

// Logging
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Platforms
import { Iterable } from "platforms/iterable/iterable";

export const useInbox = () => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useContext
     */

    const { setHeaderBarContent } = useContext(HeaderBarContext);

    /**
     * useState
     */

    const [inboxManager] = useState(IterableInboxManager);
    const [, refreshComponent] = useState();
    const [selectedMessage, setSelectedMessage] = useState(null);

    /**
     * useCallback
     */

    const refresh = useCallback(() => refreshComponent({}), []);

    /**
     * useEffect
     */

    useEffect(() => {
        AnalyticsLogger.logInboxViewLoaded();
        fetchMessages();
        setHeaderBarContent({ text: t("inbox", { ns: "common" }) });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * If the inboxManager's array of messages changes, refresh the component
     * and display the current collection of messages
     */
    useEffect(() => {
        if (inboxManager?.messages?.length) {
            refresh();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inboxManager.messages]);

    /**
     * End Hooks
     */

    function fetchMessages() {
        if (Iterable.hasInitialized) {
            inboxManager.fetchMessages({ onSuccess: refresh });
        } else {
            setTimeout(
                () => inboxManager.fetchMessages({ onSuccess: refresh }),
                3000
            );
        }
    }

    return { inboxManager, refresh, selectedMessage, setSelectedMessage };
};
