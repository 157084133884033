import { useState } from "react";

// Enums
import { NavigationEnum } from "scenes/SignIn/constants/constants";

// Localization
import { useTranslation } from "localization/localization";

// Loggers
import { Analytics } from "platforms/analytics";
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Platforms
import { Intercom } from "platforms/intercom";

// Utils
import { CurrentUser, SandboxxRestAPI } from "utils/sandboxx";
import { generateLandingPath } from "utils/navigationUtils";
import { getRecaptchaToken } from "utils/recaptchaUtils";

export const useSignUpConfirmEmail = ({
    handleChangeVerificationCode,
    handleLoadingToggle,
    history,
    runShouldShowNavBarCheck,
    setCurrentSection,
    showNotification,
    userInfo,
    verificationCode,
}) => {
    const { email } = userInfo;

    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useState
     */

    const [shouldShowErrorState, setShouldShowErrorState] = useState(false);

    /**
     * End Hooks
     */

    function handleChangeConfirmVerificationCodeInput(value) {
        setShouldShowErrorState(false);
        handleChangeVerificationCode(value);
    }

    function handleSubmit(e) {
        e && e.preventDefault();
        handleLoadingToggle("verify", true);
        getRecaptchaToken("authEmailVerify", (recaptchaToken) => {
            const { musterInviteCode } =
                JSON.parse(sessionStorage.getItem("musterInviteCode")) || "";
            const referralCode = sessionStorage.getItem("referralCode") || "";
            const userPayload = {
                emailAddress: email,
                installParams: {},
                musterInviteCode,
                platform: "WEB",
                recaptchaToken,
                referralCode,
                verificationCode,
            };
            SandboxxRestAPI.checkEmailVerificationCode(
                userPayload,
                onCheckEmailVerificationCodeSuccess,
                onCheckEmailVerificationCodeFailure,
                onCheckEmailVerificationCodeFailure
            );
        });
    }

    function onCheckEmailVerificationCodeSuccess(res) {
        const {
            auth,
            musterConnection,
            newAccount,
            onboarding,
            user,
            verification,
        } = res;
        sessionStorage.setItem(
            "hasMusterConnection",
            musterConnection || false
        );
        CurrentUser.storeUserSignIn(user, auth, verification, onboarding);
        if (newAccount) {
            AnalyticsLogger.logSignUpVerificationConfirmEmail();
            Analytics.signUp({
                onIterableIdentifySuccess: AnalyticsLogger.logSignUpEndIterable,
            });
            AnalyticsLogger.logSignUpEnd(user);
        } else {
            AnalyticsLogger.logLogInVerificationConfirmEmail();
        }
        Intercom.registerUser();
        if (newAccount) {
            setCurrentSection(NavigationEnum.SIGN_UP_NAME);
        } else {
            const { personas } = CurrentUser.getUser();
            const rootPersona = personas.filter((persona) => {
                return persona.type === "RootPersona";
            });
            const landingUrl = generateLandingPath(rootPersona[0], {
                forcePasswordReset: auth.force_password_reset,
            });
            history.push(landingUrl.path);
            runShouldShowNavBarCheck();
        }
        handleLoadingToggle("verify", false);
    }

    function onCheckEmailVerificationCodeFailure(err) {
        setShouldShowErrorState(true);
        handleLoadingToggle("verify", false);
        showNotification({
            text: err.message,
            type: "warning",
        });
    }

    function onResendVerificationCodeFailure(err) {
        showNotification({
            text:
                err?.message ??
                t("sign_up_confirm_email.resend_verification_code_error", {
                    ns: "onboarding",
                }),
            type: "warning",
        });
    }

    function onResendVerificationCodeSuccess() {
        showNotification({
            text: t("sign_up_confirm_email.resend_verification_code_success", {
                ns: "onboarding",
            }),
            type: "success",
        });
    }

    function resendVerificationCode() {
        return getRecaptchaToken(
            "authEmailGenerate",
            (recaptchaToken) => {
                return SandboxxRestAPI.resendEmailVerificationCode(
                    {
                        email: userInfo.email,
                        recaptchaToken,
                    },
                    onResendVerificationCodeSuccess,
                    onResendVerificationCodeFailure,
                    onResendVerificationCodeFailure
                );
            },
            onResendVerificationCodeFailure
        );
    }

    return {
        handleChangeConfirmVerificationCodeInput,
        handleSubmit,
        resendVerificationCode,
        shouldShowErrorState,
    };
};
