export function WarningCircleIcon({ className, height, width, color }) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 52 52"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M26 47.25C37.736 47.25 47.25 37.736 47.25 26C47.25 14.2639 37.736 4.75 26 4.75C14.2639 4.75 4.75 14.2639 4.75 26C4.75 37.736 14.2639 47.25 26 47.25Z"
                fill={color}
            />
            <path
                d="M28.0564 37.0063C28.0564 38.2371 27.0581 39.2346 25.8272 39.2346C24.5962 39.2346 23.5986 38.2371 23.5986 37.0063C23.5986 35.7743 24.5962 34.7771 25.8272 34.7771C27.0581 34.7774 28.0564 35.7746 28.0564 37.0063Z"
                fill="white"
            />
            <path
                d="M28.8897 16.2137C28.8346 15.5802 28.5906 14.9838 28.2187 14.4944C27.6502 13.7466 26.7818 13.2486 25.8279 13.25C24.9022 13.2488 24.0574 13.7174 23.4887 14.428C23.0874 14.9292 22.8235 15.5511 22.7657 16.2137C22.7504 16.3887 22.7521 16.5783 22.7657 16.7484L24.0335 31.2692C24.0719 31.7074 24.2642 32.0946 24.552 32.3827C24.8812 32.7122 25.335 32.9123 25.8279 32.9117C26.2713 32.9123 26.6833 32.75 27.0009 32.4777C27.3454 32.1827 27.5792 31.7571 27.622 31.2689L28.8897 16.7481C28.9036 16.5781 28.905 16.3887 28.8897 16.2137Z"
                fill="white"
            />
        </svg>
    );
}

WarningCircleIcon.defaultProps = {
    className: "",
    height: "25px",
    width: "25px",
    color: "#B90202",
};
