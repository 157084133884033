import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// Components
import { Text } from "components/Text/Text";

// CSS
import styles from "./list-icon.module.scss";

export const ListIcon = ({ classNames, items }) => {
    return (
        <div className={cx(styles.listIcon, classNames.container)}>
            {items.map((item, index) => (
                <div className={cx(styles.item, classNames.item)} key={index}>
                    {item.icon}
                    <div className={styles.text}>
                        {item.title && (
                            <Text classes={cx(styles.title, classNames.title)}>
                                {item.title}
                            </Text>
                        )}
                        {item.subtitle && (
                            <Text
                                classes={cx(
                                    styles.subtitle,
                                    classNames.subtitle
                                )}
                            >
                                {item.subtitle}
                            </Text>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};

ListIcon.defaultProps = {
    classNames: { container: "", item: "", subtitle: "", title: "" },
};

ListIcon.propTypes = {
    classNames: PropTypes.shape({
        container: PropTypes.string,
        item: PropTypes.string,
        subtitle: PropTypes.string,
        title: PropTypes.string,
    }),
    items: PropTypes.arrayOf(
        PropTypes.shape({
            icon: PropTypes.element,
            subtitle: PropTypes.string,
            title: PropTypes.string,
        })
    ),
};
