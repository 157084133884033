import React from "react";

// Components
import { ButtonPrimary, ButtonInvisible } from "components/Buttons/Buttons";
import { Modal } from "components/Modals/Modal";
import { Text } from "components/Text/Text";
import { WarningCircleIcon } from "components/Icons/WarningCircleIcon";

// Enums
import { NavigationEnum } from "../../../constants/constants";
import { SignInPlatform } from "../../SignIn/SignInMain/constants/constants";

// Styles
import styles from "./sign-up-email-in-use-modal.module.scss";

// Utils
import { CurrentUser } from "../../../../../utils/sandboxx";
import { SandboxxRestAPI } from "../../../../../utils/sandboxx";

export const SignUpEmailInUseModal = (props) => {
    const {
        shouldShow,
        setCurrentSection,
        setShouldShowEmailInUseModal,
        setSignInPlatform,
        setUserInfo,
        toggleSignInMode,
        userInfo,
    } = props;

    function handleOnClose() {
        setShouldShowEmailInUseModal(false);
    }

    function handleGoToLogin() {
        toggleSignInMode();
        setCurrentSection(NavigationEnum.SIGN_IN_MAIN);
        setSignInPlatform(SignInPlatform.EMAIL);
        setShouldShowEmailInUseModal(false);
        SandboxxRestAPI.logout();
        CurrentUser.clearUser();
        localStorage.removeItem("sandboxxMessageRecipient");
        setUserInfo({
            ...userInfo,
            password: "",
        });
    }

    return (
        <Modal
            centered={true}
            className={styles["sign-up-email-in-use-modal"]}
            cypressTestId="sign-up-email-in-use-modal"
            onHide={handleOnClose}
            onClose={handleOnClose}
            show={shouldShow}
            shouldHideHeader={true}
            shouldHideHeaderBorder={true}
            size="sm"
        >
            <div className={styles.body}>
                <WarningCircleIcon
                    className={styles["warning-icon"]}
                    height="45px"
                    width="45px"
                />
                <Text type="h3" classes={styles.headline} isBold={true}>
                    Email Already In Use
                </Text>
                <Text type="p" classes={styles.paragraph}>
                    This email has previously been used to create a Sandboxx
                    account. Would you like to log in using this email?
                </Text>
                <div className={styles["buttons-wrapper"]}>
                    <ButtonPrimary
                        onClick={handleGoToLogin}
                        text="Log In"
                        type="button"
                    />
                    <ButtonInvisible
                        onClick={handleOnClose}
                        text="Cancel"
                        type="button"
                    />
                </div>
            </div>
        </Modal>
    );
};
