// Platforms
import { Intercom } from "platforms/intercom";

// Utils
import { RedirectUtil } from "utils/RedirectUtil";

export function handleRouteSetUp(Component, props, isSignIn = false) {
    if (isValidNoAuthRoute(props)) {
        Intercom.boot();
        Intercom.update();
        return Component;
    }
    if (isSignInPage(props)) {
        Intercom.shutdown();
        return RedirectUtil.handleGeneralRedirect(Component, props, isSignIn);
    } else {
        Intercom.boot();
        Intercom.update();
        return RedirectUtil.handleGeneralRedirect(Component, props, isSignIn);
    }
}

export function isSignInPage(props) {
    return (
        props.location.pathname.includes("signin") ||
        props.location.pathname.includes("login") ||
        props.location.pathname.includes("forgot-password")
    );
}

/**
 * This method returns true if the processed route contains a string
 * that corresponds with a page that does not require the user to be
 * logged-into the app
 *
 * @returns {Boolean}
 */
export function isValidNoAuthRoute(props) {
    const pathnames = [];
    return pathnames.some((pathname) =>
        props.location.pathname.includes(pathname)
    );
}
