import React from "react";
import cx from "classnames";

// Components
import { ButtonSignIn } from "components/Buttons/Buttons";
import { LetterIcon } from "components/Icons/LetterIcon";
import { AppleLoginButton } from "components/Auth/AppleLoginButton/AppleLoginButton";
import { FacebookLoginButton } from "components/Auth/FacebookLoginButton/FacebookLoginButton";
import { GoogleLoginButton } from "components/Auth/GoogleLoginButton/GoogleLoginButton";

// CSS
import signInMainStyles from "../../sign-in-main.module.scss";

// Enums
import { SignInPlatform } from "../../constants/constants";

// Localization
import { useTranslation } from "localization/localization";

export const SignInMainButtons = ({
    handleMissingEmailOAuth,
    handleSelectSignInPlatform,
    isUsingEmail,
    isUsingEmailVerify,
    isInSignUpMode,
    onLoginSuccess,
    showNotification,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * Constants
     */

    const callbacks = {
        handleMissingEmail: handleMissingEmailOAuth,
        onLoginSuccess,
        showNotification,
    };

    /**
     * End Hooks & Constants
     */

    /**
     * If SignIn is in Sign Up mode, then this button does not need to be displayed
     * since the only non-social option available should be email verify.
     *
     * If SignIn is in Log In mode, this method should display the button for whichever
     * sign in platform is not active. So if the email-password form is visible, this
     * method should render the button for email-verify.
     */
    function renderPlatformButtonEmail() {
        if (!isInSignUpMode) {
            // If email-password is active, show the toggle button for email-verify
            if (isUsingEmail) {
                return (
                    <ButtonSignIn
                        classes={cx(signInMainStyles.button)}
                        cypressTestId="sign-up-email-phone-toggle"
                        icon={<LetterIcon />}
                        id="email-verify-signup-button"
                        onClick={() =>
                            handleSelectSignInPlatform(
                                SignInPlatform.EMAIL_VERIFY
                            )
                        }
                        text={t("continue_with_email", {
                            ns: "common",
                        })}
                        type="button"
                    />
                );
            }
            // If email-verify is active, show the toggle button for email-password
            if (isUsingEmailVerify) {
                return (
                    <ButtonSignIn
                        classes={cx(signInMainStyles.button)}
                        cypressTestId="sign-up-email-phone-toggle"
                        icon={<LetterIcon />}
                        id="email-signup-button"
                        onClick={() =>
                            handleSelectSignInPlatform(SignInPlatform.EMAIL)
                        }
                        text={t("continue_with_password", {
                            ns: "common",
                        })}
                        type="button"
                    />
                );
            }
        }
    }

    return (
        <div className={signInMainStyles["platform-buttons"]}>
            {renderPlatformButtonEmail()}
            <AppleLoginButton
                callbacks={callbacks}
                mode="login"
                text={t("continue_with_apple", { ns: "common" })}
            />
            <GoogleLoginButton
                callbacks={callbacks}
                classes={cx(signInMainStyles.button)}
                size="big"
                text={t("continue_with_google", { ns: "common" })}
            />
            <FacebookLoginButton
                callbacks={callbacks}
                classes={cx(signInMainStyles.button)}
                size="big"
                text={t("continue_with_facebook", { ns: "common" })}
            />
        </div>
    );
};
