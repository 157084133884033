export const SquadActivity = Object.freeze({
    ADDRESS_UPDATE: "ADDRESS_UPDATE",
    IMAGE_UPDATE: "IMAGE_UPDATE",
    NAME_UPDATE: "NAME_UPDATE",
    GIFTCARD: "GIFTCARD",
    GRADUATION_UPDATE: "GRADUATION_UPDATE",
    INVITE: "INVITE",
    LETTER_SENT: "LETTER_SENT",
    MEMBER_JOIN: "MEMBER_JOIN",
    MEMBER_LEAVE: "MEMBER_LEAVE",
    MEMBER_REMOVE: "MEMBER_REMOVE",
    NEWSLETTER_SUBSCRIPTION_CANCEL: "NEWSLETTER_SUBSCRIPTION_CANCEL",
    NEWSLETTER_SUBSCRIPTION_PURCHASE: "NEWSLETTER_SUBSCRIPTION_PURCHASE",
    ROLE_UPGRADE: "ROLE_UPGRADE",
    SQUAD_CREATED: "SQUAD_CREATED",
    TOKEN_DONATED: "TOKEN_DONATED",
});
