export function AvatarEmptyIcon({ height, width, color }) {
    return (
        <svg width={width} height={height} viewBox="0 0 20 20" version="1.1">
            <g
                id="Web"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g
                    id="Profile-+Address-book"
                    transform="translate(-318.000000, -565.000000)"
                    fill="#000000"
                    fillRule="nonzero"
                >
                    <g
                        id="noun_profile_1069814"
                        transform="translate(318.000000, 565.000000)"
                    >
                        <path
                            d="M10,0 C4.4771525,0 0,4.4771525 0,10 C0,15.5228475 4.4771525,20 10,20 C15.5228475,20 20,15.5228475 20,10 C20,7.3478351 18.9464316,4.80429597 17.0710678,2.92893219 C15.195704,1.0535684 12.6521649,0 10,0 Z M4.48888889,16.7911111 C5.90760186,15.2518194 7.90552663,14.3761579 9.99888889,14.3761579 C12.0922511,14.3761579 14.0901759,15.2518194 15.5088889,16.7911111 C12.3001195,19.4037712 7.69765828,19.4037712 4.48888889,16.7911111 L4.48888889,16.7911111 Z M16.4222222,15.9355556 C14.7673852,14.1444545 12.4396616,13.1259366 10.0011111,13.1259366 C7.56256062,13.1259366 5.23483698,14.1444545 3.58,15.9355556 C0.355606179,12.4533396 0.494415779,7.03567238 3.89290216,3.72314553 C7.29138854,0.410618678 12.7108337,0.410618678 16.1093201,3.72314553 C19.5078064,7.03567238 19.646616,12.4533396 16.4222222,15.9355556 L16.4222222,15.9355556 Z"
                            id="Shape"
                        ></path>
                        <path
                            d="M10.0000619,3.57142857 C8.26647978,3.57142857 6.70363829,4.61582999 6.04042864,6.21753553 C5.37721899,7.81924107 5.74428023,9.66274696 6.97041924,10.8882634 C8.19655825,12.1137798 10.0402501,12.4799046 11.6416184,11.8158813 C13.2429866,11.1518579 14.2865936,9.58848591 14.2857137,7.85490378 C14.2845115,5.48885371 12.366112,3.57142857 10.0000619,3.57142857 Z M10.0000619,10.9173274 C8.31021779,10.9173274 6.94021647,9.54764963 6.93981604,7.85780535 C6.93941579,6.16796106 8.30876797,4.79763477 9.99861191,4.79683331 C11.6884559,4.79603255 13.0591067,6.16505987 13.0603086,7.85490378 C13.05911,9.54488735 11.690044,10.9149272 10.0000619,10.9173274 L10.0000619,10.9173274 Z"
                            id="Shape"
                        ></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}

AvatarEmptyIcon.defaultProps = {
    height: "20px",
    width: "20px",
    color: "#212121",
};
