import { useContext, useState } from "react";

// Context
import { NotificationBarContext } from "context/notificationBar";

// Enums
import { NavigationEnum } from "scenes/SignIn/constants/constants";

// Localization
import { useTranslation } from "localization/localization";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Utils
import { SandboxxRestAPI } from "utils/sandboxx";
import { getRecaptchaToken } from "utils/recaptchaUtils";

export const useSignUpConfirmPhone = ({
    handleChangeVerificationCode,
    handleLoadingToggle,
    setConnections,
    setCurrentSection,
    storeUserAfterUpdate,
    userInfo,
    verificationCode,
}) => {
    const { phoneNumber, verificationType } = userInfo;

    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useContext
     */

    const { showNotification } = useContext(NotificationBarContext);

    /**
     * useState
     */

    const [shouldShowErrorState, setShouldShowErrorState] = useState(false);

    /**
     * End Hooks
     */

    function handleChangeConfirmVerificationCodeInput(value) {
        setShouldShowErrorState(false);
        handleChangeVerificationCode(value);
    }

    function handleSubmit(e) {
        e && e.preventDefault();
        handleLoadingToggle("verify", true);
        getRecaptchaToken("authPhoneVerify", (recaptchaToken) => {
            if (recaptchaToken) {
                const userPayload = {
                    phoneNumber: phoneNumber.baseNumber,
                    countryCode: phoneNumber.countryCode,
                    platform: "WEB",
                    recaptchaToken,
                    verificationCode,
                };
                SandboxxRestAPI.checkPhoneVerificationCode(
                    userPayload,
                    onCheckPhoneVerificationCodeSuccess,
                    onCheckVerificationCodeFailure,
                    onCheckVerificationCodeFailure
                );
            }
        });
    }

    function onCheckForOnboardingSkipSuccess(connections) {
        setConnections(connections);
    }

    function onCheckVerificationCodeFailure(err) {
        setShouldShowErrorState(true);
        handleLoadingToggle("verify", false);
        showNotification({
            text: err.message,
            type: "warning",
        });
    }

    function onCheckPhoneVerificationCodeSuccess(res) {
        if (res.success) {
            // Trigger check for onboarding skip
            SandboxxRestAPI.checkForOnboardingSkip(
                onCheckForOnboardingSkipSuccess
            );

            // Store updated user data
            storeUserAfterUpdate();

            // Analytics
            AnalyticsLogger.logVerificationConfirmPhone();

            // Update UI state
            setShouldShowErrorState(false);
            handleLoadingToggle("verify", false);
            setCurrentSection(NavigationEnum.SIGN_UP_PERSONA_ROOT);
        } else {
            // Update UI state
            handleLoadingToggle("verify", false);
            setShouldShowErrorState(true);
            showNotification({
                text: t(
                    "sign_up_confirm_phone.resend_verification_code_error",
                    {
                        ns: "onboarding",
                    }
                ),
                type: "warning",
            });
        }
    }

    function onResendVerificationCodeFailure({ message }) {
        showNotification({
            text: message,
            type: "warning",
        });
    }

    function onResendVerificationCodeSuccess() {
        showNotification({
            text: t("sign_up_confirm_phone.resend_verification_code_success", {
                ns: "onboarding",
                phoneNumber: phoneNumber.baseNumber,
            }),
            type: "success",
        });
    }

    function resendVerificationCode() {
        return SandboxxRestAPI.resendPhoneVerificationCode(
            { phoneNumber, verificationType },
            onResendVerificationCodeSuccess,
            onResendVerificationCodeFailure,
            onResendVerificationCodeFailure
        );
    }

    return {
        handleChangeConfirmVerificationCodeInput,
        handleSubmit,
        resendVerificationCode,
        shouldShowErrorState,
    };
};
