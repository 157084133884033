import React from "react";

// Components
import { Banner } from "components/Banner/Banner";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import { Text } from "components/Text/Text";

// CSS
import styles from "./memory-book-promo-banner.module.scss";

// Hooks
import { useMemoryBookPromoBanner } from "./hooks/useMemoryBookPromoBanner";

export const MemoryBookPromoBanner = () => {
    /**
     * Custom Hooks
     */

    const { cart, promoBannerText } = useMemoryBookPromoBanner();

    /**
     * End Hooks
     */

    if (cart && promoBannerText) {
        return (
            <div className={styles.memoryBookPromoBanner}>
                <Banner classes={styles.banner}>
                    <div className={styles.content}>
                        <SellOutlinedIcon className={styles.icon} />
                        <Text>{promoBannerText}</Text>
                    </div>
                </Banner>
            </div>
        );
    } else {
        return null;
    }
};
