import { useState, useMemo } from "react";

// Localization
import { useTranslation } from "localization/localization";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Utils
import { convertNumberedObjectToArray } from "utils/miscUtils";
import {
    determineIfPostagePaidRemoved,
    generateIsSelectedStatus,
} from "../utils/addOnsUtils";
import { generateRecipientIdPayload } from "../../../utils/lettersUtils";
import { SandboxxRestAPI } from "../../../utils/sandboxx";

export const useAddOns = (
    hasPurchasedGiftCard,
    hasSelectedGiftCard,
    giftCard,
    recipient,
    selectedCard,
    sentLetter,
    setDisplay,
    setGiftCard,
    setLoading,
    setNavigation,
    showNotification
) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useState
     *
     * addOns
     * -----------------------------------
     * An array of available add-ons, each of which contains an `isSelected` flag
     * that determines whether it is in the user's cart
     *
     * hasPostagePaidInCart
     * -----------------------------------
     * A boolean that corresponds with whether the Postage Paid add-on is present
     * in the user's card
     *
     * isEligibleForPostagePaid
     * -----------------------------------
     * A boolean that corresponds with whether Postage Paid is available
     * as an add-on option
     *
     * showInvalidPostagePaidAddressBanner
     * -----------------------------------
     * This should only be set to true if the user updates their address so
     * that it is invalid WHILE Postage Paid is in their cart. It should
     * be reset to false if (1) they validate their address during that same
     * compose session; or (2) they dismiss the invalid address banner
     * notification
     *
     * postagePaid
     * -----------------------------------
     * Populated with current add-on data for Postage Paid if it is present
     * in the fetchAddOns response. Otherwise, it remains null.
     */

    const [addOns, setAddOns] = useState(null);
    const [hasPostagePaidInCart, setHasPostagePaidInCart] = useState(false);
    const [hasAddedPostagePaidInSession, setHasAddedPostagePaidInSession] =
        useState(false);
    const [isEligibleForPostagePaid, setIsEligibleForPostagePaid] =
        useState(false);
    const [postagePaid, setPostagePaid] = useState(null);

    // Constants
    const hasAddOnsOptions = addOns?.length;
    const addOnsSelected = useMemo(
        () => hasAddOnsOptions && addOns.filter(({ isSelected }) => isSelected),
        [addOns, hasAddOnsOptions]
    );
    const addOnsTotal = useMemo(
        () =>
            addOnsSelected &&
            addOnsSelected.reduce((acc, addOn) => {
                const { price } = addOn.productDetails;
                const priceFormatted = parseFloat(price);
                return acc + priceFormatted;
            }, 0.0),
        [addOnsSelected]
    );

    const hasAddOnsTotal = addOnsTotal > 0;
    const hasSelectedAddOns = addOnsSelected?.length;
    const hasSelectedCreditCard = selectedCard?.card;

    /**
     * End Hooks
     */

    /**
     * Sets boolean that indicates whether Postage Paid is present in
     * the list of available add-ons. Also stores Postage Paid data in state
     * if present in fetchAddOns response.
     */
    function checkIfEligibleForPostagePaid() {
        const isEligible = addOns?.some((option) => {
            if (option.productType === "POSTAGE") {
                setPostagePaid(option);
                return true;
            }
            return false;
        });
        setIsEligibleForPostagePaid(isEligible);
    }

    /**
     * Resets `addOns` to original state
     */
    function clearAddOns() {
        setAddOns((prev) => null);
    }

    function fetchAddOns() {
        setLoading((prev) => ({
            ...prev,
            addOns: true,
        }));
        const payload = {
            ...generateRecipientIdPayload({ recipient }),
            firstName: recipient.firstName,
        };
        SandboxxRestAPI.getAddOns(
            payload,
            onFetchAddOnsSuccess,
            onFetchAddOnsFailure,
            onFetchAddOnsFailure
        );
    }

    function onFetchAddOnsFailure(err) {
        setLoading((prev) => ({
            ...prev,
            addOns: false,
        }));
    }

    function onFetchAddOnsSuccess(res) {
        setAddOnsAfterFetch(res);
        setLoading((prev) => ({
            ...prev,
            addOns: false,
        }));
    }

    /**
     * Generates object that indicates whether a user has selected any add-ons, and also
     * generates an array of those selected add-ons
     *
     * @return {Object}
     * - @property {Boolean} hasSelectedAddons - set to true if the user has select add-ons
     * - @property {Array} selectedAddOns - contains add-ons that the user has selected
     */
    function generateSelectedAddOns() {
        const selectedNewsletters = addOns
            ?.filter(
                ({ isSelected, productType }) =>
                    isSelected && productType === "NEWSLETTER"
            )
            .map((addOn) => addOn.productDetails);
        const selectedPostage = addOns
            ?.filter(
                ({ isSelected, productType }) =>
                    isSelected && productType === "POSTAGE"
            )
            .map((addOn) => addOn.productDetails);

        const hasSelectedAddOns =
            selectedNewsletters && !!selectedNewsletters.length;
        const hasSelectedPostage = selectedPostage && !!selectedPostage.length;
        return {
            hasSelectedAddOns,
            hasSelectedPostage,
            selectedAddOns: selectedNewsletters,
            selectedPostage,
        };
    }

    function handleAddOnsChangeSection(section) {
        setNavigation((prevNavigation) => ({
            ...prevNavigation,
            addOnsModal: section,
        }));
    }

    /**
     *
     * @param {boolean} showAddOnsModal
     * @param {Object} config
     *
     * * If the section value is present in the config object, this method
     * * will set the current section of the LettersComposeAddOnsModal to
     * * that first
     */
    function handleAddOnsModalToggle(showAddOnsModal, { section } = {}) {
        section &&
            setNavigation((prevNavigation) => ({
                ...prevNavigation,
                addOnsModal: section,
            }));
        setDisplay((prevDisplay) => ({
            ...prevDisplay,
            showAddOnsModal,
        }));
    }

    function handleAddOnsPurchase() {
        const {
            hasSelectedAddOns,
            hasSelectedPostage,
            selectedAddOns,
            selectedPostage,
        } = generateSelectedAddOns();

        const bools = {
            hasAddOnsTotal,
            hasPurchasedGiftCard,
            hasSelectedAddOns,
            hasSelectedPostage,
        };

        if (hasPurchasedGiftCard || hasSelectedAddOns || hasSelectedPostage) {
            SandboxxRestAPI.purchaseAddOns(
                {
                    bools,
                    giftCard,
                    newsletters: selectedAddOns,
                    postage: selectedPostage,
                    sentLetter,
                },
                onAddOnsPurchaseSuccess
            );
        }
    }

    function onAddOnsPurchaseSuccess(res) {
        const selectedAddOns = addOns.filter((addOn) => addOn.isSelected);
        const hasSelectedAddOns = selectedAddOns && !!selectedAddOns.length;
        if (hasSelectedGiftCard) {
            AnalyticsLogger.logPurchaseGiftCard(giftCard);
        }
        if (hasSelectedAddOns) {
            AnalyticsLogger.logPurchaseAddOns(selectedAddOns);
        }
    }

    function handleRemoveAddOn(item) {
        removeAddOn(item);
        if (item.productType === "POSTAGE") {
            setHasPostagePaidInCart(false);
        }
        AnalyticsLogger.logRemoveAddOnFromCart(item);
    }

    function handleSelectAddOn(item) {
        selectAddOn(item);
        AnalyticsLogger.logAddAddOnToCart(item);
    }

    /**
     * TODO: Extract Braintree info from Gift Card to level above GC and Add-Ons
     */
    function handleAddOnsSelectPayment(braintree) {
        setGiftCard((prev) => ({
            ...prev,
            braintree,
        }));
    }

    /**
     * Removes the selected add-on from cart
     *
     * @param {Object} addOn that corresponds with selected add-on
     */
    function removeAddOn(addOn) {
        const options = addOns?.map((option) => {
            if (option.id === addOn.id) {
                return { ...option, isSelected: false };
            }
            return option;
        });
        setAddOns((prev) => options);
    }

    /**
     * This method is only used when Postage Paid has to be removed from
     * the user's cart in response to updating their address to one that
     * is not eligible for Postage Paid
     */
    function removePostagePaid() {
        setHasPostagePaidInCart(false);
        setPostagePaid(null);
        showNotification({
            text: t("reply_postage_removed", { ns: "letters" }),
            type: "info",
        });
    }

    /**
     * Adds the selected add-on to cart
     *
     * @param {Object} addOn that corresponds with selected add-on
     */
    function selectAddOn(addOn) {
        const options = addOns?.map((option) => {
            if (option.id === addOn.id) {
                if (option.productType === "POSTAGE") {
                    setHasAddedPostagePaidInSession(true);
                    setHasPostagePaidInCart(true);
                }
                return { ...option, isSelected: true };
            }
            return option;
        });
        setAddOns((prev) => options);
    }

    /**
     * This method is triggered when the user clicks on the Postage Paid
     * Available banner in LettersComposeReviewAddOnsPanel
     */
    function selectPostagePaid() {
        if (isEligibleForPostagePaid) {
            const options = addOns?.map((option) => {
                if (option.productType === "POSTAGE") {
                    return { ...option, isSelected: true };
                }
                return option;
            });
            setAddOns((prev) => options);
            setHasAddedPostagePaidInSession(true);
            setHasPostagePaidInCart(true);
        }
    }

    /**
     * This method is called after add-ons have been successfully fetched. If
     * this is the first time add-ons have been fetched, then the response is
     * put in state as is. Otherwise, logic is triggered that makes sure that
     * the `isSelected` of previous add-ons that are still available is
     * preserved.
     *
     * This method also updates all state values relating to Postage Paid
     */
    function setAddOnsAfterFetch(res) {
        const options = convertNumberedObjectToArray(res).map((option) => {
            return {
                ...option,
                isSelected: generateIsSelectedStatus(option, addOns),
            };
        });
        if (determineIfPostagePaidRemoved(options, addOns)) {
            removePostagePaid();
        }
        setAddOns((prev) => options);
    }

    return {
        addOns,
        addOnsTotal,
        checkIfEligibleForPostagePaid,
        clearAddOns,
        fetchAddOns,
        generateSelectedAddOns,
        handleAddOnsChangeSection,
        handleAddOnsModalToggle,
        handleAddOnsPurchase,
        handleAddOnsSelectPayment,
        handleRemoveAddOn,
        handleSelectAddOn,
        hasAddOnsTotal,
        hasAddedPostagePaidInSession,
        hasPostagePaidInCart,
        hasSelectedAddOns,
        hasSelectedCreditCard,
        isEligibleForPostagePaid,
        postagePaid,
        removeAddOn,
        selectAddOn,
        selectPostagePaid,
        setAddOnsAfterFetch,
    };
};
