export const WalletIcon = ({ className, color }) => {
    return (
        <svg
            className={className}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M22 10H2M2 8.2L2 15.8C2 16.9201 2 17.4802 2.21799 17.908C2.40973 18.2843 2.71569 18.5903 3.09202 18.782C3.51984 19 4.07989 19 5.2 19L18.8 19C19.9201 19 20.4802 19 20.908 18.782C21.2843 18.5903 21.5903 18.2843 21.782 17.908C22 17.4802 22 16.9201 22 15.8V8.2C22 7.0799 22 6.51984 21.782 6.09202C21.5903 5.7157 21.2843 5.40974 20.908 5.21799C20.4802 5 19.9201 5 18.8 5L5.2 5C4.0799 5 3.51984 5 3.09202 5.21799C2.7157 5.40973 2.40973 5.71569 2.21799 6.09202C2 6.51984 2 7.07989 2 8.2Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export const WalletIconFilled = ({ className, color }) => {
    return (
        <svg
            className={className}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.16146 4L18.8385 4C19.3657 3.99999 19.8205 3.99997 20.195 4.03057C20.5904 4.06288 20.9836 4.13419 21.362 4.32699C21.9265 4.61461 22.3854 5.07355 22.673 5.63803C22.8658 6.01641 22.9371 6.40963 22.9694 6.80498C23 7.17954 23 7.6343 23 8.16144C23 8.62456 22.6246 9 22.1614 9H1.83859C1.37545 9 1 8.62455 1 8.16141C0.999985 7.6343 0.999971 7.17953 1.03057 6.80497C1.06287 6.40963 1.13419 6.01641 1.32698 5.63803C1.6146 5.07354 2.07355 4.6146 2.63803 4.32698C3.01641 4.13419 3.40963 4.06287 3.80498 4.03057C4.17953 3.99997 4.63434 3.99998 5.16146 4Z"
                fill={color}
            />
            <path
                d="M2.6 11C2.03995 11 1.75992 11 1.54601 11.109C1.35785 11.2049 1.20487 11.3578 1.10899 11.546C1 11.7599 1 12.0399 1 12.6V15.8385C0.999985 16.3657 0.999971 16.8205 1.03057 17.195C1.06287 17.5904 1.13419 17.9836 1.32698 18.362C1.6146 18.9265 2.07355 19.3854 2.63803 19.673C3.01641 19.8658 3.40963 19.9371 3.80497 19.9694C4.17951 20 4.63421 20 5.16129 20L18.8386 20C19.3656 20 19.8205 20 20.195 19.9694C20.5904 19.9371 20.9836 19.8658 21.362 19.673C21.9265 19.3854 22.3854 18.9265 22.673 18.362C22.8658 17.9836 22.9371 17.5904 22.9694 17.195C23 16.8205 23 16.3657 23 15.8386V12.6C23 12.0399 23 11.7599 22.891 11.546C22.7951 11.3578 22.6422 11.2049 22.454 11.109C22.2401 11 21.9601 11 21.4 11H2.6Z"
                fill={color}
            />
        </svg>
    );
};
