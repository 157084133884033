import React from "react";
import PropTypes from "prop-types";

// Components
import { Divider } from "components/Divider/Divider";
import { Skeleton } from "@mui/material";
import { Text } from "components/Text/Text";

// CSS
import styles from "./memory-book-description.module.scss";

// Localization
import { useTranslation } from "localization/localization";

export const MemoryBookDescription = ({ cart }) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * End Hooks
     */

    const memoryBookItem = cart?.items.find(
        (item) => item?.variant?.product?.handle === "memory-book"
    );

    return (
        <div className={styles.memoryBookDescription}>
            <div className={styles.title}>
                <Text classes={styles.title} type="h1">
                    {t("memory_book_ip_singular", { ns: "common" })}
                </Text>
            </div>
            <div className={styles.description}>
                {memoryBookItem ? (
                    <Text>{memoryBookItem?.variant?.product?.description}</Text>
                ) : (
                    <>
                        <Skeleton width="100%" />
                        <Skeleton width="100%" />
                        <Skeleton width="80%" />
                    </>
                )}
            </div>
            <Divider className={styles.divider} />
        </div>
    );
};

MemoryBookDescription.propTypes = {
    cart: PropTypes.object,
};
