import PropTypes from "prop-types";

export const SquadsIcon = ({ className, color, height, width }) => {
    return (
        <svg
            className={className}
            fill="none"
            height={height}
            viewBox="0 0 24 17"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
        >
            <path
                d="M22.25 16.12V14.44C22.25 12.8744 21.1792 11.5589 19.73 11.1859M16.79 1.24424C18.0214 1.74268 18.89 2.9499 18.89 4.36C18.89 5.7701 18.0214 6.97732 16.79 7.47576M18.05 16.12C18.05 14.5544 18.05 13.7717 17.7942 13.1542C17.4532 12.3309 16.7991 11.6768 15.9758 11.3358C15.3583 11.08 14.5756 11.08 13.01 11.08H10.49C8.92444 11.08 8.14165 11.08 7.52418 11.3358C6.70089 11.6768 6.04678 12.3309 5.70576 13.1542C5.45 13.7717 5.45 14.5544 5.45 16.12M1.25 16.12V14.44C1.25 12.8744 2.32081 11.5589 3.77 11.1859M6.71 1.24425C5.47863 1.74269 4.61 2.94991 4.61 4.36001C4.61 5.77011 5.47863 6.97733 6.71 7.47577M15.11 4.36C15.11 6.21568 13.6057 7.72 11.75 7.72C9.89432 7.72 8.39 6.21568 8.39 4.36C8.39 2.50432 9.89432 1 11.75 1C13.6057 1 15.11 2.50432 15.11 4.36Z"
                stroke={color}
                strokeWidth="1.68"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

SquadsIcon.defaultProps = {
    className: "",
    color: "#212121",
    height: "17px",
    width: "24px",
};

SquadsIcon.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
};

export const SquadsFilledIcon = ({ className, color, height, width }) => {
    return (
        <svg
            className={className}
            fill="none"
            height={height}
            viewBox="0 0 24 15"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_2027_1698)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.38551 9.24609C11.1554 9.24609 12.9346 9.24609 14.7045 9.24609C15.3037 9.24609 15.7186 9.24609 16.0689 9.3014C17.7835 9.56874 19.1202 10.7579 19.4244 12.279C19.4889 12.5924 19.4889 12.9611 19.4889 13.4958C19.4889 13.6156 19.4889 13.7263 19.4705 13.8461C19.3783 14.2978 18.9819 14.6573 18.4657 14.7403C18.3551 14.7587 18.2352 14.7587 18.1799 14.7587C14.0961 14.7403 10.0031 14.7403 5.91937 14.7587C5.86406 14.7587 5.74422 14.7587 5.6336 14.7403C5.11737 14.6573 4.72097 14.307 4.62879 13.8461C4.61035 13.7355 4.61035 13.6156 4.61035 13.4958C4.61035 12.9611 4.61035 12.6016 4.67488 12.279C4.97909 10.7579 6.31576 9.56874 8.03039 9.3014C8.38991 9.24609 8.79553 9.24609 9.39472 9.24609H9.38551Z"
                    fill={color}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.104 3.98237C8.104 1.78838 9.88316 0 12.0864 0C14.2896 0 16.0687 1.77916 16.0687 3.98237C16.0687 6.18558 14.2896 7.96474 12.0864 7.96474C9.88316 7.96474 8.104 6.18558 8.104 3.98237Z"
                    fill={color}
                />
                <path
                    d="M3.72535 11.938C4.07565 10.1865 5.61513 8.82216 7.5971 8.50873C7.62476 8.50873 7.66163 8.50873 7.68928 8.49951C6.65682 8.49951 5.62435 8.49951 4.59189 8.49951C4.048 8.49951 3.67926 8.49951 3.3474 8.5456C1.78948 8.78528 0.581864 9.86384 0.305311 11.2466C0.25 11.5324 0.25 11.8642 0.25 12.3436C0.25 12.445 0.25 12.5556 0.268437 12.657C0.351403 13.0719 0.710922 13.3945 1.18106 13.4683C1.28247 13.4867 1.39309 13.4867 1.4484 13.4867C2.18587 13.4867 2.93257 13.4867 3.67004 13.4867C3.67004 13.4314 3.67004 13.3853 3.67004 13.3392C3.67004 12.7216 3.67004 12.3067 3.74379 11.938H3.72535Z"
                    fill={color}
                />
                <path
                    d="M8.03034 0.589896C7.50489 0.285687 6.90569 0.101318 6.2604 0.101318C4.31531 0.101318 2.72974 1.68689 2.72974 3.63198C2.72974 5.57708 4.30609 7.16265 6.2604 7.16265C6.7582 7.16265 7.22834 7.06124 7.65239 6.87688C6.90569 6.10253 6.43555 5.06084 6.43555 3.89932C6.43555 2.56264 7.06241 1.38268 8.03034 0.589896Z"
                    fill={color}
                />
                <path
                    d="M23.7847 11.2189C23.5081 9.84532 22.2913 8.76676 20.7426 8.51786C20.42 8.46255 20.042 8.47177 19.4981 8.47177C18.521 8.47177 17.5438 8.47177 16.5667 8.47177C16.6773 8.47177 16.7787 8.4902 16.8801 8.50864C18.8528 8.81285 20.4015 10.1864 20.7518 11.9379C20.8256 12.3066 20.8256 12.7215 20.8256 13.3391C20.8256 13.3852 20.8256 13.4221 20.8256 13.4682C21.434 13.4682 22.0516 13.4682 22.66 13.4682C22.7154 13.4682 22.8168 13.4682 22.9274 13.4497C23.3975 13.376 23.757 13.0533 23.84 12.6385C23.8584 12.5371 23.8584 12.4265 23.8584 12.3251C23.8584 11.8457 23.8584 11.5139 23.8031 11.2281L23.7847 11.2189Z"
                    fill={color}
                />
                <path
                    d="M17.6729 0.0737305C17.046 0.0737305 16.4653 0.258099 15.9583 0.543871C16.9262 1.31822 17.5623 2.48896 17.5623 3.82564C17.5623 4.96872 17.1013 5.99197 16.3731 6.7571C16.7787 6.92303 17.2212 7.01522 17.6821 7.01522C19.5995 7.01522 21.1575 5.4573 21.1575 3.53987C21.1575 1.62243 19.5903 0.0737305 17.6729 0.0737305Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_2027_1698">
                    <rect
                        width="23.59"
                        height="14.7495"
                        fill="white"
                        transform="translate(0.25)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

SquadsFilledIcon.defaultProps = {
    className: "",
    color: "#212121",
    height: "15px",
    width: "24px",
};

SquadsFilledIcon.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
};
