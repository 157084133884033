import { useState } from "react";

// Enums
import { NavigationEnum } from "../constants/constants";

// Utils
import { generateSignUpStartingPoint } from "../utils/navigationUtils";

export const useSignInNavigation = ({
    goToOnboarding,
    goToPasswordReset,
    goToSignUp,
    goToVerify,
    history,
}) => {
    /**
     * useState
     */

    // This state value is used for determining if the user has completed the extra info part of sign up
    const [hasCompletedSignUpName, setHasCompletedSignUpName] = useState(
        localStorage.getItem("hasCompletedSignUpName")
    );
    const [currentSection, setCurrentSection] = useState(
        goToSignUp
            ? generateSignUpStartingPoint({
                  goToOnboarding,
                  goToPasswordReset,
                  hasCompletedSignUpName,
                  goToVerify,
              })
            : NavigationEnum.SIGN_IN_MAIN
    );
    const [needsNameForSSO, setNeedsNameForSSO] = useState(false);

    /**
     * End Hooks
     */

    function handleGoToForgotPassword() {
        setCurrentSection(NavigationEnum.SIGN_IN_FORGOT_PASSWORD);
    }

    function handleGoToNameEntry() {
        setCurrentSection(NavigationEnum.SIGN_UP_NAME);
        setNeedsNameForSSO(true);
    }

    function handleGoToOnboarding() {
        setCurrentSection(NavigationEnum.SIGN_UP_PERSONA_ROOT);
    }

    function handleGoToPhoneEntry() {
        setCurrentSection(NavigationEnum.SIGN_UP_PHONE);
    }

    function handleGoToPasswordReset() {
        history.push("/signin");
        setCurrentSection(NavigationEnum.SIGN_IN_PASSWORD_RESET);
    }

    return {
        currentSection,
        handleGoToForgotPassword,
        handleGoToNameEntry,
        handleGoToOnboarding,
        handleGoToPasswordReset,
        handleGoToPhoneEntry,
        hasCompletedSignUpName,
        needsNameForSSO,
        setCurrentSection,
        setHasCompletedSignUpName,
    };
};
