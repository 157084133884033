import React from "react";
import { IMaskInput } from "react-imask";
import PropTypes from "prop-types";
import TextFieldMUI from "@mui/material/TextField";
import { ThemeProvider } from "@mui/material/styles";

// Theme
import { theme } from "./theme";

const TextMask = React.forwardRef(function TextMask(props, ref) {
    const { onChange, ...other } = props;
    const handleAccept = (value, mask) => {
        if (onChange) {
            onChange({
                target: {
                    name: props.name,
                    value: value,
                },
            });
        }
    };

    return (
        <IMaskInput
            {...other}
            mask="00/00/0000"
            definitions={{
                0: /[0-9]/,
            }}
            inputRef={ref}
            onAccept={handleAccept}
            overwrite
        />
    );
});

export const TextFieldMasked = ({ inputRef, ...props }) => {
    const {
        className,
        cypressTestId,
        defaultValue,
        disabled,
        error,
        helperText,
        id,
        label,
        name,
        onChange,
        required,
        size,
        type,
        value,
    } = props;

    return (
        <ThemeProvider theme={theme}>
            <TextFieldMUI
                className={className}
                defaultValue={defaultValue}
                disabled={disabled}
                error={error}
                helperText={helperText}
                id={id}
                InputProps={{
                    inputComponent: TextMask,
                    inputProps: {
                        "data-cy": cypressTestId,
                    },
                }}
                inputRef={inputRef}
                label={label}
                name={name}
                onChange={onChange}
                required={required}
                size={size}
                type={type}
                variant="outlined"
                value={value}
            />
        </ThemeProvider>
    );
};

TextFieldMasked.defaultProps = {
    className: "",
    disabled: false,
    error: false,
    select: false,
    size: "normal",
    type: "text",
};

TextFieldMasked.propTypes = {
    className: PropTypes.string,
    cypressTestId: PropTypes.string,
    defaultValue: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string.isRequired,
    name: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    size: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
};
