export const useNavBarItem = ({ data, history }) => {
    const isSelected = data.keywords.some(
        (keyword) => history.location.pathname === keyword
    );

    const shouldShowSubnavigation = data?.subnavigation?.some((item) =>
        item.link !== "/"
            ? history.location.pathname.indexOf(item.link) > -1
            : history.location.pathname === "/"
    );

    return { isSelected, shouldShowSubnavigation };
};
