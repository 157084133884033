import React from "react";

// Components
import { Card } from "components/Card/Card";
import { ProgressBar } from "components/ProgressBar/ProgressBar/ProgressBar";
import { Text } from "components/Text/Text";

// CSS
import styles from "./muster-overview-document-carousel-card.module.scss";

// Utils
import { calculateCompletedDocuments } from "scenes/Muster/utils/utils";

export const MusterOverviewDocumentCarouselCard = ({
    collection,
    handleNavigate,
    id,
}) => {
    const { length } = collection.documents;
    const documentText = `${length} Document${length > 1 ? "s" : ""}`;

    return (
        <div className={styles.musterOverviewDocumentCarouselCard}>
            <Card
                cypressTestId={`muster-document-card-${id}`}
                onClick={() => handleNavigate(collection.id)}
            >
                <img
                    alt={collection.id}
                    className={styles.image}
                    src={collection.lightModeIcon}
                />
                <div>
                    <div>
                        <Text type="h4">{collection.name}</Text>
                    </div>
                    <ProgressBar
                        completed={calculateCompletedDocuments(
                            collection.documents
                        )}
                        text={documentText}
                        total={collection.documents.length}
                    />
                </div>
            </Card>
        </div>
    );
};
