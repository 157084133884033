import React from "react";
import { animated } from "react-spring";
import cx from "classnames";

// Components
import { ButtonSecondary, ButtonPrimary } from "components/Buttons/Buttons";
import { Fade } from "hooks/Fade";
import { Overlay } from "components/Overlay/Overlay";
import { Text } from "components/Text/Text";

// CSS
import styles from "./letters-add-ons-carousel-item.module.scss";

// Hooks
import { useLettersAddOnsCarouselItem } from "./hooks/useLettersAddOnsCarouselItem";

// Localization
import { useTranslation } from "localization/localization";

// Media
import infoIconSrc from "media/icons/info.svg";

// Utils
import { generateButtonText } from "./utils/utils";

export const LettersAddOnsCarouselItem = ({
    addOn,
    addOns,
    giftCard,
    handleAddOnsModalToggle,
    handleRemoveAddOn,
    handleSelectAddOn,
    handleGiftCardClear,
    isInModal,
    position,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    const {
        handleOptionOnClick,
        hasGiftCard,
        imageSrc,
        isGiftCard,
        itemLeft,
        itemRef,
        shouldDisableButton,
    } = useLettersAddOnsCarouselItem({
        addOn,
        addOns,
        giftCard,
        handleAddOnsModalToggle,
        handleRemoveAddOn,
        handleSelectAddOn,
        handleGiftCardClear,
        position,
    });

    /**
     * End Hooks
     */

    function renderButton() {
        const ButtonComponent =
            addOn.isSelected || (hasGiftCard && isGiftCard)
                ? ButtonPrimary
                : ButtonSecondary;
        return (
            <ButtonComponent
                classes={cx(styles.button, "button--size-short")}
                cypressTestId={`letters-add-ons-carousel-item-${addOn.title}-add-button`}
                isDisabled={shouldDisableButton}
                onClick={handleOptionOnClick}
            >
                <Text>
                    {generateButtonText(addOn, hasGiftCard, isGiftCard)}
                </Text>
            </ButtonComponent>
        );
    }

    function renderImage() {
        if (imageSrc) {
            return (
                <Fade show={imageSrc}>
                    <img alt={addOn.title} src={imageSrc} />
                </Fade>
            );
        }
    }

    return (
        <animated.div
            className={cx(styles.lettersAddOnsCarouselItem, {
                [styles["lettersAddOnsCarouselItem--inModal"]]: isInModal,
            })}
            ref={itemRef}
            style={{ left: itemLeft }}
        >
            <div className={styles.details}>
                <div className={styles.image}>
                    {renderImage()}
                    {renderButton()}
                </div>
                <div className={styles.info}>
                    <div className={styles.title}>
                        <Text classes={styles.text} isBold={true}>
                            {addOn.title}
                        </Text>
                        <div className={styles.icon}>
                            <Overlay
                                overlayText={addOn.infoToolTip}
                                placement="bottom"
                            >
                                <img
                                    alt={t("info_icon", { ns: "common" })}
                                    src={infoIconSrc}
                                    tabIndex="0"
                                />
                            </Overlay>
                        </div>
                    </div>
                    <Text classes={styles.subtitle}>{addOn.subtitle}</Text>
                    <Text classes={styles.description}>
                        {addOn.description}
                    </Text>
                </div>
            </div>
        </animated.div>
    );
};
