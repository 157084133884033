export function UploadIcon({
    className = "",
    height = "16px",
    width = "16px",
    color = "#212121",
}) {
    return (
        <svg
            className={className}
            width={width}
            height={height}
            viewBox="0 0 12 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_405_21090)">
                <path
                    d="M3.15517 5.35764V4.81366C3.15517 3.31149 4.37292 2.09375 5.87508 2.09375C7.37725 2.09375 8.59499 3.31149 8.59499 4.81366V5.35764C9.79672 5.35764 10.7709 6.33183 10.7709 7.53357C10.7709 8.33897 10.3333 9.06127 9.68295 9.4375M3.15517 5.35764C1.95344 5.35764 0.979248 6.33183 0.979248 7.53357C0.979248 8.33897 1.41683 9.06127 2.06721 9.4375M3.15517 5.35764C3.39064 5.35764 3.61737 5.39504 3.82974 5.46422M5.87508 6.4456V11.3414M5.87508 6.4456L7.50703 8.07755M5.87508 6.4456L4.24314 8.07755"
                    stroke={color}
                    strokeWidth="0.815972"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_405_21090">
                    <rect
                        width="11.75"
                        height="11.75"
                        fill="white"
                        transform="translate(0 0.625)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}
