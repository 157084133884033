// CSS
import styles from "./banner.module.scss";

// Components
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { MaterialIcon } from "components/MaterialIcon/MaterialIcon";

export function generateBannerIcon(theme) {
    switch (theme) {
        case "orange":
            return (
                <MaterialIcon
                    className={styles.icon}
                    color="var(--orange-darkest)"
                    name="warning"
                />
            );
        case "blue":
        default:
            return (
                <InfoOutlinedIcon
                    className={styles.icon}
                    height="30px"
                    width="30px"
                />
            );
    }
}
