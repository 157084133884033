export function ErrorCircleIcon({
    height = "25px",
    width = "25px",
    color = "#B90202",
}) {
    return (
        <svg
            width={height}
            height={width}
            viewBox="0 0 52 52"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M26 47.25C37.736 47.25 47.25 37.736 47.25 26C47.25 14.2639 37.736 4.75 26 4.75C14.2639 4.75 4.75 14.2639 4.75 26C4.75 37.736 14.2639 47.25 26 47.25Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M35.9227 17.1399C36.5451 17.7623 36.5451 18.7714 35.9227 19.3938L28.7848 26.531L35.9227 33.6685C36.5451 34.2909 36.5451 35.3 35.9227 35.9224C35.3003 36.5448 34.2912 36.5448 33.6688 35.9224L26.5312 28.7845L19.394 35.9224C18.7716 36.5448 17.7625 36.5448 17.1401 35.9224C16.5177 35.3 16.5177 34.2909 17.1401 33.6685L24.2766 26.531L17.1401 19.3938C16.5177 18.7714 16.5177 17.7623 17.1401 17.1399C17.7625 16.5175 18.7716 16.5175 19.394 17.1399L26.5312 24.2763L33.6688 17.1399C34.2912 16.5175 35.3003 16.5175 35.9227 17.1399Z"
                fill="#F3F2EF"
            />
        </svg>
    );
}
