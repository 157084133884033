import React from "react";

// Components
import { ButtonPrimary, ButtonSecondary } from "components/Buttons/Buttons";
import { Divider } from "components/Divider/Divider";
import { Text } from "components/Text/Text";
import { WalletGiftCardsPurchaseRecipientContactsModal } from "./components/WalletGiftCardsPurchaseRecipientContactsModal/WalletGiftCardsPurchaseRecipientContactsModal";
import { WalletGiftCardsPurchaseRecipientNewRecipient } from "./components/WalletGiftCardsPurchaseRecipientNewRecipient/WalletGiftCardsPurchaseRecipientNewRecipient";
import { WalletGiftCardsPurchaseRecipientPreviousRecipients } from "./components/WalletGiftCardsPurchaseRecipientPreviousRecipients/WalletGiftCardsPurchaseRecipientPreviousRecipients";
import { WalletGiftCardsPurchaseRecipientContacts } from "./components/WalletGiftCardsPurchaseRecipientContacts/WalletGiftCardsPurchaseRecipientContacts";

// CSS
import styles from "./wallet-gift-cards-purchase-recipient.module.scss";

// Hooks
import { useWalletGiftCardsPurchaseRecipient } from "./hooks/useWalletGiftCardsPurchaseRecipient";

export const WalletGiftCardsPurchaseRecipient = (props) => {
    const { setRecipientSource } = props;

    /**
     * Custom Hooks
     */

    const {
        handleGoBack,
        handleSubmit,
        hasContacts,
        hasContactsOrSuggestedContacts,
        hasSuggestedContacts,
        isSubmitButtonDisabled,
        isSubmittingNewRecipient,
        newRecipient,
        setNewRecipient,
        setShouldShowContactsModal,
        shouldShowContactsModal,
    } = useWalletGiftCardsPurchaseRecipient(props);

    /**
     * Constants
     */

    const submitButtonText = isSubmittingNewRecipient
        ? "Submitting..."
        : "Continue";

    /**
     * End Hooks & Constants
     */

    function renderContacts() {
        if (hasContacts) {
            return (
                <WalletGiftCardsPurchaseRecipientContacts
                    {...props}
                    setShouldShowContactsModal={setShouldShowContactsModal}
                />
            );
        }
    }

    function renderDivider() {
        if (hasContactsOrSuggestedContacts) {
            return (
                <div className={styles.divider}>
                    <Divider text="OR" />
                </div>
            );
        }
    }

    function renderPreviousRecipients() {
        if (hasSuggestedContacts) {
            return (
                <WalletGiftCardsPurchaseRecipientPreviousRecipients
                    {...props}
                />
            );
        }
    }

    return (
        <div className={styles["wallet-gift-cards-purchase-recipient"]}>
            <WalletGiftCardsPurchaseRecipientContactsModal
                {...props}
                setShouldShowContactsModal={setShouldShowContactsModal}
                shouldShowContactsModal={shouldShowContactsModal}
            />
            <div className={styles.header}>
                <Text type="h2">Who's it for?</Text>
            </div>
            {renderPreviousRecipients()}
            {renderContacts()}
            {renderDivider()}
            <WalletGiftCardsPurchaseRecipientNewRecipient
                {...props}
                handleSubmit={handleSubmit}
                hasContactsOrSuggestedContacts={hasContactsOrSuggestedContacts}
                newRecipient={newRecipient}
                setNewRecipient={setNewRecipient}
                setRecipientSource={setRecipientSource}
            />
            <div className={styles.buttons}>
                <ButtonSecondary onClick={handleGoBack} text="Back" />
                <ButtonPrimary
                    cypressTestId="wallet-gift-cards-purchase-recipient-submit-button"
                    isDisabled={isSubmitButtonDisabled}
                    onClick={(e) => handleSubmit(newRecipient)}
                    text={submitButtonText}
                />
            </div>
        </div>
    );
};
