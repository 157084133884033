export function WalletGiftCardsLandingFundsNeverExpireIcon({
    height = "131px",
    width = "130px",
}) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 131 130"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M120.101 40.101L78.605 14.4434L7.44311 60.6236L7.21777 93.8646L47.6001 119.964L119.109 74.9323L120.101 40.101Z"
                fill="#F1F1ED"
            />
            <path
                d="M7.54355 62.253L36.3775 80.9643L46.9898 87.85C47.2758 88.0363 47.6052 88.1273 47.9345 88.1273C48.2638 88.1273 48.5888 88.0363 48.8792 87.85L119.114 42.2763C119.608 41.9557 119.902 41.4097 119.902 40.8203C119.902 40.231 119.603 39.685 119.114 39.3643L108.562 32.5177L90.2841 20.6573L79.6718 13.7717C79.0955 13.399 78.3588 13.399 77.7825 13.7717L67.1702 20.6573L7.54355 59.3453C7.04955 59.666 6.75488 60.212 6.75488 60.8013C6.75488 61.3906 7.05388 61.9366 7.54355 62.2573V62.253ZM22.0429 54.0673L68.1842 24.1283C71.2522 25.8313 74.9832 26.6937 78.7228 26.6937C82.4625 26.6937 86.1935 25.8357 89.2615 24.1283L104.614 34.0907C102.573 36.019 101.473 38.346 101.473 40.8203C101.473 43.2947 102.573 45.6217 104.614 47.55L58.4732 77.489C52.3372 74.083 43.5318 74.083 37.4002 77.489L22.0472 67.5266C24.0882 65.5983 25.1889 63.2713 25.1889 60.797C25.1889 58.3226 24.0882 55.9956 22.0472 54.0673H22.0429ZM47.9345 84.327L40.8105 79.7033C45.1698 77.97 50.7035 77.97 55.0628 79.7033L47.9388 84.327H47.9345ZM107.635 45.5913C105.888 44.2003 104.935 42.5363 104.935 40.8203C104.935 39.1043 105.888 37.4403 107.635 36.0537L114.984 40.8247L107.635 45.5957V45.5913ZM78.7185 17.2904L85.8425 21.914C81.4832 23.6473 75.9495 23.6473 71.5902 21.914L78.7142 17.2904H78.7185ZM19.0182 56.0303C20.7645 57.4213 21.7179 59.0853 21.7179 60.8013C21.7179 62.5173 20.7645 64.1813 19.0182 65.5723L11.6689 60.8013L19.0182 56.0303Z"
                fill="#C8C5B5"
            />
            <path
                d="M51.133 53.0749C54.3396 55.1549 58.296 55.0639 63.2013 52.7932L69.7446 57.0399C68.0676 57.5382 66.2606 57.6639 64.7266 57.3259C63.7906 57.1222 62.8676 57.7116 62.6639 58.6476C62.4603 59.5836 63.0496 60.5066 63.9856 60.7102C64.8089 60.8922 65.6799 60.9789 66.5639 60.9789C68.8476 60.9789 71.2439 60.3939 73.2763 59.3279L75.3043 60.6452C75.5946 60.8359 75.9239 60.9269 76.2446 60.9269C76.8123 60.9269 77.3669 60.6496 77.7006 60.1382C78.2206 59.3366 77.9909 58.2619 77.1893 57.7419L76.2359 57.1222C77.4189 55.8439 78.0646 54.3836 78.0646 52.9146C78.0646 51.1639 77.1633 49.6126 75.5209 48.5466C71.3999 45.8729 67.3006 46.9909 63.4439 48.8239L56.9093 44.5816C58.517 44.1049 60.259 43.9663 61.7453 44.2566C62.6813 44.4386 63.5956 43.8233 63.7733 42.8829C63.9553 41.9426 63.3399 41.0326 62.3996 40.8549C59.5223 40.3003 56.099 40.8636 53.3733 42.2936L51.3453 40.9806C50.5436 40.4606 49.469 40.6903 48.949 41.4919C48.429 42.2936 48.6586 43.3683 49.4603 43.8883L50.4136 44.5079C49.2306 45.7863 48.585 47.2466 48.585 48.7156C48.585 50.4662 49.4863 52.0176 51.1286 53.0836L51.133 53.0749ZM73.6359 51.4542C74.2773 51.8702 74.6023 52.3599 74.6023 52.9146C74.6023 53.6209 74.1039 54.4442 73.2589 55.1896L66.9496 51.0946C69.4629 50.1716 71.5473 50.0979 73.6403 51.4542H73.6359ZM59.648 50.4879C56.9136 51.3849 54.7296 51.2766 53.0223 50.1672C52.381 49.7512 52.056 49.2616 52.056 48.7069C52.056 48.0006 52.5543 47.1773 53.3993 46.4319L59.648 50.4836V50.4879Z"
                fill="#EE5928"
            />
            <path
                d="M117.225 47.5323L47.9346 92.4906L9.42867 67.509C8.627 66.989 7.55234 67.2143 7.03234 68.0203C6.51234 68.822 6.742 69.8966 7.54367 70.4166L46.99 96.0136C47.276 96.2 47.6053 96.291 47.9346 96.291C48.264 96.291 48.589 96.2 48.8793 96.0136L119.114 50.44C119.916 49.92 120.145 48.8453 119.625 48.0437C119.105 47.242 118.031 47.0123 117.229 47.5323H117.225Z"
                fill="#C8C5B5"
            />
            <path
                d="M117.225 55.6964L47.9346 100.655L9.42867 75.673C8.627 75.153 7.55234 75.3784 7.03234 76.1844C6.51234 76.986 6.742 78.0607 7.54367 78.5807L46.99 104.178C47.276 104.364 47.6053 104.455 47.9346 104.455C48.264 104.455 48.589 104.364 48.8793 104.178L119.114 58.6041C119.916 58.0841 120.145 57.0094 119.625 56.2077C119.105 55.4061 118.031 55.1764 117.229 55.6964H117.225Z"
                fill="#C8C5B5"
            />
            <path
                d="M117.225 63.8605L47.9346 108.819L9.42867 83.8371C8.627 83.3171 7.55234 83.5468 7.03234 84.3484C6.51234 85.1501 6.742 86.2248 7.54367 86.7448L46.99 112.342C47.276 112.528 47.6053 112.619 47.9346 112.619C48.264 112.619 48.589 112.528 48.8793 112.342L119.114 66.7681C119.916 66.2481 120.145 65.1735 119.625 64.3718C119.105 63.5701 118.031 63.3405 117.229 63.8605H117.225Z"
                fill="#C8C5B5"
            />
            <path
                d="M117.225 72.0245L47.9346 116.987L9.42867 92.0012C8.627 91.4812 7.55234 91.7065 7.03234 92.5125C6.51234 93.3142 6.742 94.3888 7.54367 94.9088L46.99 120.506C47.276 120.692 47.6053 120.783 47.9346 120.783C48.264 120.783 48.589 120.692 48.8793 120.506L119.114 74.9322C119.916 74.4122 120.145 73.3375 119.625 72.5359C119.105 71.7342 118.031 71.5045 117.229 72.0245H117.225Z"
                fill="#C8C5B5"
            />
            <path
                d="M47.9336 84.326L40.8096 79.7023C45.1689 77.969 50.7026 77.969 55.0619 79.7023L47.9379 84.326H47.9336Z"
                fill="#EE5928"
            />
            <path
                d="M107.634 45.5904C105.888 44.1994 104.935 42.5354 104.935 40.8194C104.935 39.1034 105.888 37.4394 107.634 36.0527L114.984 40.8237L107.634 45.5947V45.5904Z"
                fill="#EE5928"
            />
            <path
                d="M78.7182 17.2891L85.8422 21.9127C81.4828 23.6461 75.9492 23.6461 71.5898 21.9127L78.7138 17.2891H78.7182Z"
                fill="#EE5928"
            />
            <path
                d="M19.0173 56.0293C20.7636 57.4203 21.717 59.0843 21.717 60.8003C21.717 62.5163 20.7636 64.1803 19.0173 65.5713L11.668 60.8003L19.0173 56.0293Z"
                fill="#EE5928"
            />
        </svg>
    );
}
