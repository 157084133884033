export function formatSquadContact(contactStaging) {
    const supportSquadContact = {
        ...contactStaging,
        supporteeAddress: contactStaging.address,
        supporteeFirstName: contactStaging.firstName,
        supporteeFullName: contactStaging.fullName,
        supporteeLastName: contactStaging.lastName,
        supporteeTitle: contactStaging.title,
    };
    delete supportSquadContact.address;
    delete supportSquadContact.firstName;
    delete supportSquadContact.fullName;
    delete supportSquadContact.lastName;
    delete supportSquadContact.title;
    return supportSquadContact;
}
