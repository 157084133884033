import React from "react";
import cx from "classnames";

// Components
import { Avatar } from "../../../components/Avatar/Avatar";
import { Banner } from "components/Banner/Banner";
import { ButtonSecondary } from "../../../components/Buttons/Buttons";
import { ContactDeleteModal } from "../ContactDeleteModal/ContactDeleteModal";
import { HeaderBar } from "../../../components/HeaderBar/HeaderBar";
import { Panel } from "components/Panel/Panel";
import { Text } from "components/Text/Text";

// CSS
import styles from "./contact.module.scss";

// Hooks
import { useContact } from "./hooks/useContact";

// Utils
import { handleKeyDown } from "utils/eventUtils";
import { titleCase } from "../../../utils/textUtils";

export const Contact = ({ location }) => {
    /**
     * Custom Hooks
     */

    const {
        contact,
        handleDeleteContact,
        handleDuplicateConnection,
        handleGoToLetterCompose,
        handleToggleContactModal,
        isDeleting,
        setShowDeleteContactModal,
        showDeleteContactModal,
    } = useContact({ location });

    /**
     * Constants
     */

    const { firstName, isSandboxxUser, lastName, title } = contact || {};
    const hasAddress = contact?.address.line1 !== "";

    /**
     * End Hooks & Constants
     */

    function renderAddAsContactOption() {
        return (
            isSandboxxUser && (
                <ButtonSecondary
                    classes={styles.button}
                    onClick={handleDuplicateConnection}
                >
                    Add as a Contact
                </ButtonSecondary>
            )
        );
    }

    function renderDeleteContactOption() {
        return (
            !isSandboxxUser && (
                <div
                    className={cx(styles["item--action"], "red-highlight")}
                    onClick={() => setShowDeleteContactModal(true)}
                    onKeyDown={(e) =>
                        handleKeyDown(e, () => setShowDeleteContactModal(true))
                    }
                    tabIndex="0"
                >
                    <span className={cx(styles.icon, "material-icons")}>
                        delete_outline
                    </span>
                    <span
                        className={styles.text}
                        data-cy="contact-delete-button"
                    >
                        Delete Recipient
                    </span>
                </div>
            )
        );
    }

    function renderAddressItem(property) {
        const text = contact?.address[property] || titleCase(property);
        return (
            <div
                className={cx(styles.item, {
                    [styles["item--empty"]]: !contact?.address[property],
                })}
            >
                <Text cyId={`contact-${property}`}>{text}</Text>
            </div>
        );
    }

    function renderConnectionNote() {
        return (
            isSandboxxUser && (
                <Banner config={{ shouldShowIcon: true }}>
                    You and {firstName} {lastName} are connected. Being
                    connected means that you can see the other person's details,
                    like their address. In order to edit or update the address,
                    you will need to add your connection as a contact.
                </Banner>
            )
        );
    }

    function renderHeader() {
        return (
            <div className={styles.header}>
                <div className={styles.left}>
                    <div className={styles.avatarContainer}>
                        <Avatar
                            additionalClasses={styles.avatar}
                            user={contact}
                        />
                    </div>
                    <div className={styles.info}>
                        <div
                            className={styles.name}
                            data-cy="contact-header-name"
                        >
                            <Text type="h2">
                                {title} {firstName} {lastName}
                            </Text>
                        </div>
                        {hasAddress && (
                            <ButtonSecondary
                                classes="button--size-short"
                                onClick={handleGoToLetterCompose}
                                text="Send a Letter"
                                type="button"
                            />
                        )}
                    </div>
                </div>
                {!isSandboxxUser && (
                    <div className={styles.right}>
                        <div className={cx(styles.link, "panel__header__link")}>
                            <span
                                className="link"
                                data-cy="contact-header-panel-edit-button"
                                onClick={handleToggleContactModal}
                                onKeyDown={(e) =>
                                    handleKeyDown(e, handleToggleContactModal)
                                }
                                tabIndex="0"
                            >
                                Edit Recipient
                            </span>
                        </div>
                    </div>
                )}
            </div>
        );
    }

    return (
        <div className="page">
            <ContactDeleteModal
                contact={contact}
                handleDeleteContact={handleDeleteContact}
                isDeleting={isDeleting}
                onHide={() => setShowDeleteContactModal(false)}
                show={showDeleteContactModal}
            />
            <HeaderBar />
            <div className="page__body">
                <div className={styles.contact}>
                    <Panel>
                        {renderHeader()}
                        <div className={styles.address}>
                            <Text classes={styles.header} isBold type="h3">
                                Address
                            </Text>
                            {renderAddressItem("line1")}
                            {renderAddressItem("line2")}
                            {renderAddressItem("city")}
                            {renderAddressItem("state")}
                            {renderAddressItem("zipcode")}
                            {renderAddAsContactOption()}
                            {renderDeleteContactOption()}
                        </div>
                        {renderConnectionNote()}
                    </Panel>
                </div>
            </div>
        </div>
    );
};
