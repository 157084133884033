/**
 * Calculates the width of a set of DOM elements so that horizontal scroll behavior,
 * whether using the navigation arrows or dragging, can be properly handled
 *
 * @param {Object} options
 *  @param {Object} options.config
 *    @param {Boolean} options.config.isHTMLCollection
 *  @param {Number} options.gap
 *  @param {Array|HTMLCollection} options.items
 *  @param {Number} options.maxIndex
 * @returns {Number} totalWidth
 */
export function getElementsWidth({ config, gap, items, maxIndex }) {
    let totalWidth = 0;
    for (let i = 0; i < maxIndex; i++) {
        const item = config?.isHTMLCollection ? items.item(i) : items[i];
        totalWidth += item.offsetWidth + gap;
    }
    return totalWidth;
}
