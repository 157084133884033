import React from "react";
import PropTypes from "prop-types";

// Components
import { MenuItem } from "@mui/material";
import { Select } from "components/Inputs/Select/Select";
import { Skeleton } from "@mui/material";
import { Text } from "components/Text/Text";

// CSS
import styles from "./cart-payment-method-select.module.scss";

// Hooks
import { useCartPaymentMethodSelect } from "./hooks/useCartPaymentMethodSelect";

// Localization
import { useTranslation } from "localization/localization";

// Utils
import { generateCreditCardIconSrc } from "utils/purchaseUtils";

export const CartPaymentMethodSelect = ({
    handleSelectPaymentMethod,
    isSubmittingPaymentMethod,
    selectedPaymentMethod,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    const { isSubmittingCart, savedCards } = useCartPaymentMethodSelect();

    /**
     * End Hooks
     */

    function renderEmptyOption() {
        return (
            <MenuItem sx={{ pl: 0 }} value="">
                <div className={styles.option}>
                    <Text classes={styles.title}>
                        {t("cart.select_payment_method", { ns: "components" })}
                    </Text>
                </div>
            </MenuItem>
        );
    }

    function renderLoading() {
        return (
            <div className={styles.cartPaymentMethodSelect}>
                <Select
                    className={styles.select}
                    selectProps={{
                        disabled: true,
                        displayEmpty: true,
                        native: false,
                        renderValue: () => (
                            <div className={styles.option}>
                                <Skeleton
                                    className={styles.skeleton}
                                    variant="text"
                                    width="50%"
                                />
                            </div>
                        ),
                    }}
                />
            </div>
        );
    }

    function renderOption({ isSelected, key, option }) {
        return (
            <MenuItem
                key={key}
                sx={{ pl: isSelected ? 0 : "auto" }}
                value={option}
            >
                <div className={styles.option}>
                    <div className={styles.icon}>
                        <img
                            alt={t("credit_card", { ns: "common" })}
                            src={generateCreditCardIconSrc(option.brand)}
                        />
                    </div>
                    <Text classes={styles.number}>••••{option.last4}</Text>
                    <Text classes={styles.expirationDate}>
                        {option.expMonth}/{option.expYear}
                    </Text>
                </div>
            </MenuItem>
        );
    }

    function renderSelect() {
        return (
            <div className={styles.cartPaymentMethodSelect}>
                <Select
                    className={styles.select}
                    cypressTestId="cart-payment-select"
                    name="paymentMethod"
                    onChange={handleSelectPaymentMethod}
                    required
                    selectProps={{
                        "data-cy": "cart-payment-method-select-container",
                        disabled: isSubmittingPaymentMethod || isSubmittingCart,
                        displayEmpty: true,
                        native: false,
                        renderValue: () =>
                            selectedPaymentMethod
                                ? renderOption({
                                      option: selectedPaymentMethod,
                                      isSelected: true,
                                  })
                                : renderEmptyOption(),
                    }}
                    value={selectedPaymentMethod}
                >
                    <MenuItem disabled value="">
                        <div className={styles.option}>
                            <Text classes={styles.title}>
                                {t("cart.select_payment_method", {
                                    ns: "components",
                                })}
                            </Text>
                        </div>
                    </MenuItem>
                    {savedCards.map((savedCard, i) =>
                        renderOption({ key: i, option: savedCard })
                    )}
                </Select>
            </div>
        );
    }

    if (!savedCards) {
        return renderLoading();
    } else {
        return renderSelect();
    }
};

CartPaymentMethodSelect.propTypes = {
    handleSelectPaymentMethod: PropTypes.func,
    isSubmittingPaymentMethod: PropTypes.bool,
    selectedPaymentMethod: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
};
