import React from "react";
import { useHistory } from "react-router-dom";

// Components
import { ButtonPrimary, ButtonSecondary } from "components/Buttons/Buttons";
import { CheckmarkIcon } from "components/Icons/CheckmarkIcon";
import { Text } from "components/Text/Text";
import { WalletGiftCardsPurchaseOrderProgress } from "../WalletGiftCardsPurchaseOrderProgress/WalletGiftCardsPurchaseOrderProgress";

// CSS
import styles from "./wallet-gift-cards-purchase-confirmation.module.scss";

// Enums
import { NavigationEnum } from "../../enums/NavigationEnum";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Media
import infoIconSrc from "media/icons/info.svg";

// Utils
import { CurrentUser } from "utils/sandboxx";

export const WalletGiftCardsPurchaseConfirmation = (props) => {
    const { orderNumber, resetPurchaseState, setCurrentSection } = props;

    /**
     * Router Hooks
     */

    const history = useHistory();

    /**
     * End Hooks
     */

    function handleGoToDashboard() {
        history.push("/");
        resetPurchaseState();
    }

    function handleGoToPurchaseStart() {
        AnalyticsLogger.logGiftCardSendAnotherClicked();
        setCurrentSection(NavigationEnum.SELECT_OPTION);
        resetPurchaseState();
    }

    return (
        <div
            className={styles["wallet-gift-cards-purchase-confirmation"]}
            data-cy="wallet-gift-cards-purchase-confirmation"
        >
            <div className={styles.header}>
                <div className={styles.icon}>
                    <CheckmarkIcon color="#fff" />
                </div>
                <div className={styles.message}>
                    <Text type="h2">
                        Thanks, {CurrentUser.getUser().firstName}!
                    </Text>
                    <Text type="h4">
                        We're processing your order. Please check your email for
                        confirmation.
                    </Text>
                </div>
                <div className={styles["order-number"]}>
                    <img alt="Info icon" src={infoIconSrc} />
                    <Text type="p">Your order number is #{orderNumber}</Text>
                </div>
            </div>
            <div className={styles["order-progress"]}>
                <WalletGiftCardsPurchaseOrderProgress {...props} isFullWidth />
            </div>
            <div className={styles.buttons}>
                <ButtonPrimary
                    onClick={handleGoToPurchaseStart}
                    text="Send Another Gift"
                />
                <ButtonSecondary
                    onClick={handleGoToDashboard}
                    text="Back To Dashboard"
                />
            </div>
        </div>
    );
};
