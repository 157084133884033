import React from "react";

// Localization
import { useTranslation } from "localization/localization";

export const LettersComposeAddOnsConfirmation = ({
    handleCloseModal,
    handleLetterSend,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * End Hooks
     */

    function handleSubmit() {
        handleCloseModal(true);
        setTimeout(() => {
            handleLetterSend();
        }, 250);
    }

    return (
        <div className="letters-compose-purchase-confirmation-container modal__body">
            <div className="letters-compose-purchase-confirmation-checkmark" />
            <div className="letters-compose-purchase-confirmation-success-text">
                <span>
                    {t("thank_you_for_your_purchase", { ns: "common" })}
                </span>
            </div>
            <div className="letters-compose-purchase-confirmation-sent-text">
                <span>
                    {t("add_on_confirmation_subtitle", { ns: "letters" })}
                </span>
            </div>
            <button
                className="button button--primary button-small"
                onClick={handleSubmit}
            >
                <span>{t("send_letter", { ns: "common" })}</span>
            </button>
        </div>
    );
};
