export const ProfileIcon = ({ className, color }) => {
    return (
        <svg
            className={className}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M20 21C20 19.6044 20 18.9067 19.8278 18.3389C19.44 17.0605 18.4395 16.06 17.1611 15.6722C16.5933 15.5 15.8956 15.5 14.5 15.5H9.5C8.10444 15.5 7.40665 15.5 6.83886 15.6722C5.56045 16.06 4.56004 17.0605 4.17224 18.3389C4 18.9067 4 19.6044 4 21M16.5 7.5C16.5 9.98528 14.4853 12 12 12C9.51472 12 7.5 9.98528 7.5 7.5C7.5 5.01472 9.51472 3 12 3C14.4853 3 16.5 5.01472 16.5 7.5Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export const ProfileIconFilled = ({ className, color }) => {
    return (
        <svg
            className={className}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.78609 14.5C10.9287 14.5005 13.0714 14.5005 15.2141 14.5C15.9407 14.4998 16.4359 14.4997 16.8683 14.576C18.9384 14.941 20.5591 16.5617 20.9241 18.6318C21.0004 19.0642 21.0003 19.5593 21.0001 20.286C21.0001 20.4444 21.0049 20.6038 20.9773 20.7605C20.8678 21.3815 20.3816 21.8677 19.7606 21.9772C19.6244 22.0012 19.4809 22.0005 19.4096 22.0001C14.4704 21.9736 9.52974 21.9736 4.59059 22.0001C4.51923 22.0005 4.37578 22.0012 4.2396 21.9772C3.61859 21.8677 3.13237 21.3815 3.02287 20.7605C2.99524 20.6038 3.0001 20.4444 3.00006 20.286C2.9999 19.5593 2.99979 19.0642 3.07604 18.6318C3.44105 16.5617 5.06178 14.941 7.13183 14.576C7.56425 14.4997 8.05941 14.4998 8.78609 14.5Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.50008 7.5C6.50008 4.46243 8.96251 2 12.0001 2C15.0376 2 17.5001 4.46243 17.5001 7.5C17.5001 10.5376 15.0376 13 12.0001 13C8.96251 13 6.50008 10.5376 6.50008 7.5Z"
                fill={color}
            />
        </svg>
    );
};
