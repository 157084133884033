// Enums
import { NavigationEnum } from "../constants/constants";

export function generateSignUpStartingPoint({
    goToOnboarding,
    goToPasswordReset,
    hasCompletedSignUpName,
    goToVerify,
}) {
    if (goToVerify) {
        return NavigationEnum.SIGN_UP_MAIN;
    }
    if (!hasCompletedSignUpName) {
        return NavigationEnum.SIGN_UP_NAME;
    }
    if (goToOnboarding) {
        return NavigationEnum.SIGN_UP_PERSONA_ROOT;
    }
    if (goToPasswordReset) {
        return NavigationEnum.SIGN_IN_PASSWORD_RESET;
    }
    return NavigationEnum.SIGN_UP_NAME;
}
