import React from "react";

// Components
import { CartShippingOptionsDisplay } from "./components/CartShippingOptionsDisplay/CartShippingOptionsDisplay";
import { CartShippingOptionsSelect } from "./components/CartShippingOptionsSelect/CartShippingOptionsSelect";
import { Text } from "components/Text/Text";

// CSS
import styles from "./cart-shipping-options.module.scss";

// Enums
import { CartShippingOptionsNavigation } from "./enums/CartShippingOptionsNavigation";

// Hooks
import { useCartShippingOptions } from "./hooks/useCartShippingOptions";

// Localization
import { useTranslation } from "localization/localization";

export const CartShippingOptions = () => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    const { currentSection } = useCartShippingOptions();

    /**
     * End Hooks
     */

    function renderCurrentSection() {
        return {
            [CartShippingOptionsNavigation.DISPLAY]: (
                <CartShippingOptionsDisplay />
            ),
            [CartShippingOptionsNavigation.SELECT]: (
                <CartShippingOptionsSelect />
            ),
        }[currentSection];
    }

    return (
        <div className={styles.cartShippingOptions}>
            <div className={styles.header}>
                <Text>{t("cart.shipping_method", { ns: "components" })}</Text>
            </div>
            {renderCurrentSection()}
        </div>
    );
};
