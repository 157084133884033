export const InboxIcon = ({ className, color }) => {
    return (
        <svg
            className={className}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14.9997 19C14.9997 20.6569 13.6566 22 11.9997 22C10.3429 22 8.99972 20.6569 8.99972 19M13.7962 6.23856C14.2317 5.78864 14.4997 5.17562 14.4997 4.5C14.4997 3.11929 13.3804 2 11.9997 2C10.619 2 9.49972 3.11929 9.49972 4.5C9.49972 5.17562 9.76772 5.78864 10.2032 6.23856M17.9997 11.2C17.9997 9.82087 17.3676 8.49823 16.2424 7.52304C15.1171 6.54786 13.591 6 11.9997 6C10.4084 6 8.8823 6.54786 7.75708 7.52304C6.63186 8.49823 5.99972 9.82087 5.99972 11.2C5.99972 13.4818 5.43385 15.1506 4.72778 16.3447C3.92306 17.7056 3.5207 18.3861 3.53659 18.5486C3.55476 18.7346 3.58824 18.7933 3.73906 18.9036C3.87089 19 4.53323 19 5.85791 19H18.1415C19.4662 19 20.1286 19 20.2604 18.9036C20.4112 18.7933 20.4447 18.7346 20.4629 18.5486C20.4787 18.3861 20.0764 17.7056 19.2717 16.3447C18.5656 15.1506 17.9997 13.4818 17.9997 11.2Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export const InboxIconFilled = ({ className, color }) => {
    return (
        <svg
            className={className}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.71945 5.72148C8.57772 5.34107 8.50012 4.92938 8.50012 4.5C8.50012 2.567 10.0671 1 12.0001 1C13.9331 1 15.5001 2.567 15.5001 4.5C15.5001 4.92938 15.4225 5.34106 15.2808 5.72147C15.8667 5.99625 16.4122 6.34658 16.8977 6.76736C18.2223 7.91537 19.0001 9.50571 19.0001 11.2C19.0001 13.3077 19.5204 14.7999 20.1328 15.8357L20.1431 15.853C20.5368 16.5189 20.8444 17.0391 21.0507 17.4125C21.1538 17.5991 21.2445 17.7721 21.3105 17.9203C21.3432 17.9937 21.3793 18.0819 21.4074 18.1756C21.4284 18.2454 21.48 18.4265 21.4585 18.6459C21.4467 18.7664 21.4197 19.0057 21.2804 19.25C21.141 19.4942 20.9488 19.6393 20.8511 19.7108C20.6919 19.8272 20.5325 19.8772 20.4531 19.8991C20.3595 19.9251 20.2685 19.9404 20.193 19.9505C20.0421 19.9707 19.8664 19.981 19.6833 19.9873C19.3183 20 18.8079 20 18.1648 20H15.8741C15.4301 21.7252 13.864 23 12.0001 23C10.1363 23 8.57018 21.7252 8.12614 20H5.83563C5.19249 20 4.68197 20 4.31693 19.9873C4.13383 19.981 3.95814 19.9707 3.80726 19.9505C3.7318 19.9404 3.64079 19.9251 3.54712 19.8991C3.46776 19.8772 3.30841 19.8272 3.14917 19.7108C3.05145 19.6393 2.85922 19.4942 2.71988 19.25C2.58054 19.0057 2.55352 18.7664 2.54174 18.6459C2.52029 18.4265 2.57187 18.2454 2.59284 18.1756C2.62095 18.0819 2.65704 17.9937 2.68975 17.9203C2.75572 17.7721 2.84645 17.5991 2.94956 17.4125C3.15585 17.0391 3.4634 16.519 3.85719 15.853L3.86742 15.8357C4.47987 14.7999 5.00013 13.3077 5.00013 11.2C5.00013 9.50571 5.77792 7.91537 7.10256 6.76736C7.58807 6.34658 8.13355 5.99626 8.71945 5.72148ZM10.5001 4.5C10.5001 3.67157 11.1717 3 12.0001 3C12.8285 3 13.5001 3.67157 13.5001 4.5C13.5001 4.72108 13.4527 4.93048 13.3671 5.11911C12.919 5.04027 12.4612 5 12.0001 5C11.5391 5 11.0813 5.04027 10.6332 5.11912C10.5476 4.93048 10.5001 4.72109 10.5001 4.5ZM12.0001 21C12.7404 21 13.3867 20.5978 13.7326 20H10.2677C10.6135 20.5978 11.2598 21 12.0001 21Z"
                fill={color}
            />
        </svg>
    );
};
