import React, { useContext, useState } from "react";
import { Modal } from "components/Modals/Modal";
import { animated } from "react-spring";
import cx from "classnames";

// Components
import { ButtonPrimary } from "components/Buttons/Buttons";

// Context
import { InterstitialContext } from "context/interstitial";

// CSS
import styles from "./interstitial.module.scss";

// Hooks
import { useCrossFade } from "hooks/react-spring/useCrossFade";

// Utils
import { handleKeyDown } from "utils/eventUtils";

export const Interstitial = () => {
    const { interstitialContent, showInterstitial, toggleInterstitial } =
        useContext(InterstitialContext);

    const { callToActionText, messages, subtitle, title } = interstitialContent;

    /************************************************
     * useState
     ************************************************/

    const [carouselIndex, setCarouselIndex] = useState(0);

    /************************************************
     * React Spring
     ************************************************/

    const carouselFadeTransition = useCrossFade(messages[carouselIndex], {
        from: { position: "absolute" },
        enter: { position: "absolute" },
        leave: { position: "absolute" },
    });

    /************************************************
     * End Hooks
     ************************************************/

    function generateButtonText() {
        return carouselIndex === messages.length - 1
            ? callToActionText
            : "Next";
    }

    function handleButtonClick() {
        carouselIndex === messages.length - 1
            ? toggleInterstitial(false)
            : selectMarker(carouselIndex + 1);
    }

    function renderCarouselItem() {
        return carouselFadeTransition((style, item) => {
            return (
                <animated.div style={style}>
                    <div className={styles.item}>
                        <img
                            alt={item.header}
                            className={styles.image}
                            src={item.imageUrl}
                        />
                        <div className={styles.description}>{item.text}</div>
                    </div>
                </animated.div>
            );
        });
    }

    function renderCarouselMarkers() {
        const markers = messages.map((item, i) => {
            const isSelected = carouselIndex === i;
            return (
                <div
                    className={cx(styles.marker, {
                        [styles["marker--selected"]]: isSelected,
                    })}
                    key={i}
                    onClick={() => selectMarker(i)}
                    onKeyDown={
                        /* istanbul ignore next */ (e) =>
                            handleKeyDown(e, () => selectMarker(i))
                    }
                    tabIndex="0"
                />
            );
        });
        return <div className={styles.markers}>{markers}</div>;
    }

    function selectMarker(i) {
        setCarouselIndex(i);
    }

    return (
        <Modal
            centered="true"
            className={styles.interstitialModal}
            cypressTestId="interstitial"
            headerText={title}
            headerSubText={subtitle}
            onHide={() => toggleInterstitial(false)}
            show={showInterstitial}
            dialogClassName={styles.dialog}
        >
            <div className={styles.content}>
                <div className={styles.carousel}>
                    <div className={styles.container}>
                        {renderCarouselItem()}
                    </div>
                    {renderCarouselMarkers()}
                </div>

                <div className={styles.buttons}>
                    <ButtonPrimary
                        classes={styles.button}
                        isDisabled={null}
                        onClick={() => handleButtonClick()}
                        text={generateButtonText()}
                        type="button"
                    />
                </div>
            </div>
        </Modal>
    );
};
