import { useState } from "react";

export const useCarousel = ({ items, itemsInViewCount }) => {
    /************************************************
     * useState
     ************************************************/

    const [position, setPosition] = useState(0);

    /************************************************
     * Constants
     ************************************************/

    const hasItems = !!items?.length;
    const hasMultipleItems = hasItems && items.length > 1;
    const canGoLeft = hasItems && !!items[position - 1];
    const canGoRight = hasItems && !!items[position + itemsInViewCount];

    /************************************************
     * End Hooks
     ************************************************/

    function handleShiftCarousel(direction) {
        if (direction === "left" && canGoLeft) {
            setPosition((prevPosition) => prevPosition - itemsInViewCount);
        } else if (direction === "right" && canGoRight) {
            setPosition((prevPosition) => prevPosition + itemsInViewCount);
        }
    }

    return {
        canGoLeft,
        canGoRight,
        handleShiftCarousel,
        hasMultipleItems,
        position,
    };
};
