export function AppleIcon({
    height = "25px",
    width = "25px",
    color = "#212121",
}) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.26325 6.00018C10.2466 6.00018 11.4792 5.31498 12.2132 4.40138C12.878 3.57343 13.3627 2.41716 13.3627 1.26088C13.3627 1.10386 13.3488 0.946834 13.3211 0.818359C12.227 0.861184 10.9113 1.57493 10.1219 2.53136C9.49869 3.25938 8.93086 4.40138 8.93086 5.57193C8.93086 5.74323 8.95856 5.91453 8.97241 5.97163C9.04166 5.9859 9.15245 6.00018 9.26325 6.00018ZM5.80089 23.2729C7.14429 23.2729 7.73981 22.345 9.41559 22.345C11.1191 22.345 11.493 23.2444 12.9887 23.2444C14.4568 23.2444 15.4401 21.8454 16.368 20.475C17.4067 18.9048 17.836 17.3631 17.8637 17.2917C17.7668 17.2631 14.9554 16.0783 14.9554 12.7522C14.9554 9.8687 17.1713 8.56967 17.2959 8.46975C15.8279 6.29995 13.5981 6.24285 12.9887 6.24285C11.3407 6.24285 9.99727 7.27065 9.15245 7.27065C8.23839 7.27065 7.03349 6.29995 5.607 6.29995C2.89251 6.29995 0.136475 8.6125 0.136475 12.9806C0.136475 15.6929 1.16133 18.5622 2.42163 20.4179C3.50189 21.9882 4.44365 23.2729 5.80089 23.2729Z"
                fill={color}
            />
        </svg>
    );
}
