export function formatRecipientForResolution(recipient) {
    return {
        city: recipient.address.city,
        line1: recipient.address.line1,
        line2: recipient.address.line2,
        name: recipient.name,
        state: recipient.address.state,
        zipcode: recipient.address.zipcode,
    };
}

export function generateUpdateLetterAddressPayload({
    letter,
    recipientStaging,
}) {
    return {
        recipient: {
            address: {
                city: recipientStaging.city,
                line1: recipientStaging.line1,
                line2: recipientStaging.line2,
                state: recipientStaging.state,
                zipcode: recipientStaging.zipcode,
            },
        },
    };
}
