// Enums
import { RecipientSourceEnum } from "../../../enums/RecipientSourceEnum";

// Validation
import { Validation } from "../validation/validation";

/**
 * This method re-organizes the data that is collected by the new recipient form so that it
 * can be submitted as a new Address Book contact. This includes stripping any hyphens from
 * the zipcode.
 *
 * @param {Object} newRecipient contains a flat one-level object contaning new recipient data
 * @returns {Object} that contains recipient data that matches the Contact model structure
 */
export function formatNewRecipientForSubmit({
    city,
    firstName,
    lastName,
    line1,
    line2,
    state,
    zipcode,
}) {
    return {
        address: {
            city,
            line1,
            line2,
            state,
            zipcode: zipcode.replace(/-/g, ""),
        },
        firstName,
        lastName,
    };
}

/**
 *
 * @param {Object}
 *  @param {Object} newRecipient contains data for recipient built through new recipient form
 *  @param {Object || Null} recipient contains selected recipient data
 *  @param {String} recipientSource indicates which recipient select UI the user is interacting with
 * @returns {Boolean} indicating whether the page's submit button should be disabled
 */
export function generateIsSubmitButtonDisabled({
    isSubmittingNewRecipient,
    newRecipient,
    recipient,
    recipientSource,
}) {
    if (
        recipient &&
        (recipientSource === RecipientSourceEnum.CONTACTS ||
            recipientSource === RecipientSourceEnum.PREVIOUS_RECIPIENTS)
    ) {
        /**
         * If a recipient has been selected from the "Contacts" or "Previous Recipients"
         * UI (i.e., it's not null), then allow the user to interact with the submit button
         */
        return !(recipient !== null);
    } else if (recipientSource === RecipientSourceEnum.NEW_RECIPIENT) {
        /**
         * If the user is interacting with the new recipient form, and the `newRecipient`
         * data passes validations, then allow the user to interact with the submit button
         *
         * - OR -
         *
         * If `isSubmittingNewRecipient` is true (i.e., new recipient API calls are in progress),
         * then disable the button`
         */
        return (
            !Validation.isValidNewRecipient(newRecipient) ||
            isSubmittingNewRecipient
        );
    } else {
        /**
         * If neither newRecipient nor recipient are valid, return `true` so that the
         * submit button is disabled
         */
        return true;
    }
}
