import { useState } from "react";

// Constants
import { NavigationEnum } from "../constants/constants";
const { RECIPIENT_DETAILS } = NavigationEnum;

export const useWalletManagePurchaseCardModal = () => {
    /**
     * useState
     */

    const [currentSection, setCurrentSection] = useState(RECIPIENT_DETAILS);
    const [purchaseInfo, setPurchaseInfo] = useState({
        email: "",
        isForCurrentUser: false,
        provider: "",
    });

    /**
     * End Hooks
     */

    return {
        currentSection,
        purchaseInfo,
        setCurrentSection,
        setPurchaseInfo,
    };
};
