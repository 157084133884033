import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import IntlTelInput from "react-intl-tel-input";

// CSS
import styles from "./phone-number-input.module.scss";

export const PhoneNumberInput = (props) => {
    const {
        autoFocus,
        autoPlaceholder,
        containerClasses,
        countriesData,
        cypressTestId,
        displayName,
        excludeCountries,
        fieldName,
        format,
        inputClasses,
        onChange,
        onClear,
        phoneNumber,
        placeholder,
        preferredCountries,
        shouldHideLabel,
    } = props;

    return (
        <div
            className={cx(styles["phone-number-input"], containerClasses)}
            data-cy={cypressTestId}
        >
            <label
                className={cx(styles.label, {
                    [styles["label--hidden"]]: shouldHideLabel,
                })}
                htmlFor={fieldName}
            >
                {displayName}
            </label>
            <IntlTelInput
                autoFocus={autoFocus}
                autoPlaceholder={autoPlaceholder}
                countriesData={countriesData}
                defaultCountry="us"
                excludeCountries={excludeCountries}
                fieldName={fieldName}
                format={format}
                inputClassName={cx(styles.input, inputClasses)}
                onPhoneNumberChange={onChange}
                onSelectFlag={onClear}
                placeholder={placeholder}
                preferredCountries={preferredCountries}
                value={phoneNumber.baseNumber}
            />
        </div>
    );
};

PhoneNumberInput.defaultProps = {
    autoFocus: false,
    autoPlaceholder: true,
    containerClasses: "",
    countriesData: [["United States", "us", "1", 0]],
    cypressTestId: "",
    defaultCountry: "us",
    displayName: "Phone Number",
    excludeCountries: ["kp"],
    fieldName: "phoneNumber",
    format: true,
    inputClasses: "",
    placeholder: "Enter phone number",
    preferredCountries: ["us"],
    shouldHideLabel: false,
};

PhoneNumberInput.propTypes = {
    autoFocus: PropTypes.bool,
    autoPlaceholder: PropTypes.bool,
    containerClasses: PropTypes.string,
    countriesData: PropTypes.array,
    cypressTestId: PropTypes.string,
    defaultCountry: PropTypes.string,
    displayName: PropTypes.string,
    excludeCountries: PropTypes.arrayOf(PropTypes.string),
    fieldName: PropTypes.string,
    format: PropTypes.bool,
    inputClasses: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired,
    phoneNumber: PropTypes.object.isRequired,
    placeholder: PropTypes.string,
    preferredCountries: PropTypes.arrayOf(PropTypes.string),
    shouldHideLabel: PropTypes.bool,
};
