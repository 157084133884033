import React from "react";
import cx from "classnames";

// Components
import { ButtonInvisible, ButtonPrimary } from "components/Buttons/Buttons";
import { ConfirmVerificationCodeInput } from "components/Inputs/ConfirmVerificationCodeInput/ConfirmVerificationCodeInput";
import { Text } from "components/Text/Text";

// CSS
import signInStyles from "../../../sign-in.module.scss";
import signUpConfirmEmailStyles from "./sign-up-confirm-email.module.scss";

// Hooks
import { useSignUpConfirmEmail } from "./hooks/useSignUpConfirmEmail";

// Localization
import { useTranslation } from "localization/localization";

export const SignUpConfirmEmail = (props) => {
    const { loading, verificationCode } = props;

    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    const {
        handleChangeConfirmVerificationCodeInput,
        handleSubmit,
        resendVerificationCode,
        shouldShowErrorState,
    } = useSignUpConfirmEmail(props);

    /**
     * End Hooks
     */

    function renderForm() {
        return (
            <form
                className={cx(
                    signInStyles["form--verification-code"],
                    signUpConfirmEmailStyles.form,
                    "form"
                )}
                data-action="authEmailVerify"
                onSubmit={handleSubmit}
            >
                <ConfirmVerificationCodeInput
                    className={signUpConfirmEmailStyles.input}
                    label=""
                    length={6}
                    loading={loading.verify}
                    onChange={handleChangeConfirmVerificationCodeInput}
                    shouldShowErrorState={shouldShowErrorState}
                />
                <div className={signUpConfirmEmailStyles.buttons}>
                    <ButtonPrimary
                        classes={cx(
                            signInStyles.button,
                            signUpConfirmEmailStyles.button
                        )}
                        cypressTestId="sign-up-confirm-verify-button"
                        dataAttributes={{ "data-action": "authEmailVerify" }}
                        isDisabled={verificationCode.length < 6}
                        isLoading={loading.verify}
                        onClick={handleSubmit}
                        type="submit"
                    >
                        <Text>{t("continue", { ns: "common" })}</Text>
                    </ButtonPrimary>
                    <ButtonInvisible
                        classes={signUpConfirmEmailStyles.button}
                        cypressTestId="sign-up-confirm-resend-code-button"
                        onClick={resendVerificationCode}
                        text={t("resend_code", {
                            ns: "common",
                        })}
                    />
                </div>
            </form>
        );
    }

    return (
        <div
            className={cx(
                signInStyles.content,
                signUpConfirmEmailStyles["sign-up-confirm-email"]
            )}
        >
            <div className={signInStyles.top}>
                <div
                    className={cx(
                        signInStyles.header,
                        signUpConfirmEmailStyles.header
                    )}
                >
                    <span
                        className={signInStyles.text}
                        data-cy="sign-up-confirm-header-text"
                    >
                        {t("sign_up_confirm_email.title", {
                            ns: "onboarding",
                        })}
                    </span>
                    <span className={signInStyles["text-secondary"]}>
                        {t("sign_up_confirm_email.subtitle", {
                            ns: "onboarding",
                        })}
                    </span>
                </div>
                {renderForm()}
            </div>
        </div>
    );
};
