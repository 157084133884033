import React, { createContext, useEffect, useState } from "react";

// Platforms
import { firebase } from "../platforms/firebase";

// Constants
const imageCollectionPaths = {
    AIRFORCE: "airforce",
    AIRFORCE_GUARD: "airforce",
    AIRFORCE_RESERVE: "airforce",
    ARMY: "army",
    ARMY_GUARD: "army",
    ARMY_RESERVE: "army",
    COASTGUARD: "coastguard",
    COASTGUARD_RESERVE: "coastguard",
    MARINES: "usmc",
    MARINES_RESERVE: "usmc",
    NAVY: "navy",
    NAVY_RESERVE: "navy",
    SPACEFORCE: "spaceforce",
};
const rankCollectionPaths = {
    AIRFORCE: "AirForceRanks",
    AIRFORCE_GUARD: "AirNationalGuardRanks",
    AIRFORCE_RESERVE: "AirForceRanks",
    ARMY: "ArmyRanks",
    ARMY_GUARD: "ArmyNationalGuardRanks",
    ARMY_RESERVE: "ArmyRanks",
    COASTGUARD: "CoastGuardRanks",
    COASTGUARD_RESERVE: "CoastGuardRanks",
    MARINES: "MarineRanks",
    MARINES_RESERVE: "MarineRanks",
    NAVY: "NavyRanks",
    NAVY_RESERVE: "NavyRanks",
    SPACEFORCE: "SpaceForceRanks",
};

export const RanksContext = createContext({});

/**
 * * Context for rank data and images pulled from Firestore
 */
export const RanksProvider = (props) => {
    const { children } = props;

    /**
     * useState
     **/

    const [activeBranch, setActiveBranch] = useState(null);
    const [hasFetchedImages, setHasFetchedImages] = useState(false);
    const [ranks, setRanks] = useState(null);

    /**
     * useEffect
     **/

    useEffect(() => {
        !hasFetchedImages && ranks && fetchImages(ranks);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ranks]);

    /**
     * End Hooks
     **/

    async function fetchImages(ranks) {
        if (ranks) {
            const ranksWithImages = await Promise.all(
                ranks.map(async (rank) => {
                    const { image } = rank;
                    if (image) {
                        // eslint-disable-next-line
                        const imageName = image.replace(/^.*[\\\/]/, "");
                        const refUrl = `ranks/${imageCollectionPaths[activeBranch]}`;
                        const imageSrc = await firebase.getDownloadUrl(
                            refUrl,
                            imageName
                        );
                        return { ...rank, imageSrc };
                    } else {
                        return rank;
                    }
                })
            );
            setHasFetchedImages(true);
            setRanks(ranksWithImages);
        }
    }

    async function fetchRanks(branch) {
        if (branch) {
            const path = rankCollectionPaths[branch];
            const res = await firebase.getCollection(path);
            setActiveBranch(branch);
            setHasFetchedImages(false);
            setRanks(res);
        }
        return;
    }

    const ranksContext = { fetchRanks, ranks };
    return (
        <RanksContext.Provider value={ranksContext}>
            {children}
        </RanksContext.Provider>
    );
};
