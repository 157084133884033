import { SandboxxRestAPI } from "../../utils/sandboxx";
import { emptyFunction } from "../../utils/miscUtils";
import { DateTime } from "luxon";

// TODO: Eventually expand this class to LetterManager and maintain entire letter
// TODO: When server can handle it, incorporate storing recipient draft information
// TODO: Store draft everywhere as object, not three individual strings
export class DraftManager {
    createDraft(draftPayload, callback) {
        SandboxxRestAPI.createLetterDraft(draftPayload, (draft) => {
            this.draft = draft;
            callback();
        });
    }

    fetchDraft(callback = emptyFunction) {
        if (!this.hasRequestedDraft) {
            this.hasRequestedDraft = true;
            return SandboxxRestAPI.getLetterDraft((draftServer) => {
                this.draft = draftServer;
                callback();
            });
        }
    }

    generateRecipientPayload({ id, isSandboxxUser, isSquadRecipient }) {
        if (isSandboxxUser) {
            return { recipientId: id };
        } else if (isSquadRecipient) {
            return { squadId: id };
        } else {
            return { abContactId: id };
        }
    }

    saveDraft(
        { customMessage, customPhotoArray, recipient },
        callback = emptyFunction
    ) {
        const draftId = this.draft?.id || null;
        const recipientPayload = this.generateRecipientPayload(recipient);
        const timestamp = Math.floor(DateTime.local().ts / 1000);
        const draftPayload = {
            ...recipientPayload,
            customMessage,
            customPhotoArray,
            id: draftId,
            timestamp,
        };
        draftId
            ? this.updateDraft(draftPayload, callback)
            : this.createDraft(draftPayload, callback);
    }

    updateDraft(draftPayload, callback = emptyFunction) {
        const { customPhotoArray } = draftPayload;
        if (customPhotoArray !== null) {
            SandboxxRestAPI.updateLetterDraft(draftPayload, callback);
        }
    }
}
