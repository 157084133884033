import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export const Overlay = ({ children, overlayText, placement }) => {
    function renderTooltip() {
        return <Tooltip id="tooltip">{overlayText}</Tooltip>;
    }

    return (
        <OverlayTrigger
            overlay={renderTooltip()}
            placement={placement}
            trigger={["click", "focus", "hover"]}
        >
            {children}
        </OverlayTrigger>
    );
};
