import React, { createContext } from "react";

// Hooks
import { useCart } from "./hooks/useCart";
import { useCartDisplay } from "./hooks/useCartDisplay";
import { useCartShippingAddress } from "./hooks/useCartShippingAddress";
import { useCartShippingOptions } from "./hooks/useCartShippingOptions";

// Constants
export const CartContext = createContext({});

export const CartProvider = (props) => {
    const { children } = props;

    /**
     * Custom Hooks
     **/

    const {
        applyDiscountCode,
        cart,
        completeCartSession,
        completedOrder,
        getCartSession,
        hasShippingAddress,
        isSubmittingCart,
        isUpdatingCart,
        resetCartContext,
        setCart,
        setIsUpdatingCart,
        submitPaymentDetails,
        startCartSession,
        updateCartBookQuantity,
    } = useCart();

    const { hideCart, shouldDisplayCart, showCart } = useCartDisplay();

    const {
        resetCartShippingOptionsContext,
        submitShippingOption,
        shippingOptions,
    } = useCartShippingOptions({
        cart,
        setCart,
        setIsUpdatingCart,
    });

    const { submitShippingAddress } = useCartShippingAddress({
        cart,
        setCart,
        setIsUpdatingCart,
    });

    /**
     * End Hooks
     **/

    const cartContext = {
        applyDiscountCode,
        cart,
        completeCartSession,
        completedOrder,
        getCartSession,
        hasShippingAddress,
        hideCart,
        isSubmittingCart,
        isUpdatingCart,
        resetCartContext,
        resetCartShippingOptionsContext,
        setIsUpdatingCart,
        shippingOptions,
        shouldDisplayCart,
        showCart,
        startCartSession,
        submitPaymentDetails,
        submitShippingAddress,
        submitShippingOption,
        updateCartBookQuantity,
    };

    return (
        <CartContext.Provider value={cartContext}>
            {children}
        </CartContext.Provider>
    );
};
