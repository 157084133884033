import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// Enums
import { MaterialIconMode } from "./enums/MaterialIconMode";

// Utils
import { handleKeyDown } from "utils/eventUtils";
import { emptyFunction } from "utils/miscUtils";

export const MaterialIcon = ({
    className,
    color,
    onClick,
    mode,
    name,
    tabIndex,
}) => {
    const style = color ? { style: { color } } : {};

    function generateMaterialIconsClassName() {
        switch (mode) {
            case MaterialIconMode.MATERIAL_SYMBOLS_OUTLINED:
                return "material-symbols-outlined";
            case MaterialIconMode.MATERIAL_ICONS:
            default:
                return "material-icons";
        }
    }

    return (
        <span
            {...style}
            className={cx(generateMaterialIconsClassName(), className)}
            onClick={onClick}
            onKeyDown={(e) => handleKeyDown(e, onClick)}
            tabIndex={tabIndex}
        >
            {name}
        </span>
    );
};

MaterialIcon.defaultProps = {
    className: "",
    mode: MaterialIconMode.MATERIAL_SYMBOLS_OUTLINED,
    onClick: emptyFunction,
    tabIndex: null,
};

MaterialIcon.propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    tabIndex: PropTypes.string,
};
