// Enums
import { PurchaseModalNavigation } from "../enums/enums";

// Localization
import { t } from "localization/localization";

export function generateHeaderText(currentSection, purchaseMode) {
    switch (currentSection) {
        case PurchaseModalNavigation.PAYMENT:
            return {
                headerText: t("purchase_modal.enter_payment_information", {
                    ns: "components",
                }),
                headerSubText: "",
            };
        case PurchaseModalNavigation.REVIEW:
            return {
                headerText: t("purchase_modal.confirm_purchase", {
                    ns: "components",
                }),
                headerSubText: "",
            };
        default:
            return { headerText: "", headerSubText: "" };
    }
}
