import { useMemo } from "react";

// Enums
import { PersonaEnum } from "enums/PersonaEnum";

export const useMusterOverviewStoryly = (props) => {
    const { branchPersona, rootPersona } = props;

    /**
     * Constants
     */

    const isArmy =
        branchPersona.id === "ARMY" ||
        branchPersona.id === "ARMY_RESERVE" ||
        branchPersona.id === "ARMY_GUARD";
    const isRecruiter = rootPersona.id === PersonaEnum.RECRUITER;
    const isRecruit = rootPersona.id === PersonaEnum.RECRUIT;

    /**
     * useMemo
     */
    const segments = useMemo(
        () => [
            ...(isArmy ? ["army"] : []),
            ...(isRecruit ? ["recruit"] : []),
            ...(isRecruiter ? ["recruiter"] : []),
        ],
        [isArmy, isRecruit, isRecruiter]
    );

    /**
     * End Hooks & Constants
     **/

    return {
        isRecruit,
        segments,
    };
};
