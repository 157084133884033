import React, { useState } from "react";
import { animated } from "react-spring";

// CSS
import styles from "./sign-in.module.scss";

// Data
import { slideshowData } from "./data/slideshow";

// Hooks
import { useCrossFade } from "hooks/react-spring/useCrossFade";
import { useInterval } from "hooks/intervals/useInterval";

export const SignInSlideshow = (props) => {
    /**
     * useState
     */

    const [slideshowIndex, setSlideshowIndex] = useState(0);

    /**
     * Custom Hooks
     */

    useInterval(() => {
        const slideshowLength = slideshowData.length;
        const isAtEndOfSlideshow = slideshowIndex + 1 >= slideshowLength;
        const newSlideshowIndex = isAtEndOfSlideshow ? 0 : slideshowIndex + 1;
        setSlideshowIndex(newSlideshowIndex);
    }, 5000);

    /**
     * React Spring
     */

    const slideshowFadeTransition = useCrossFade(slideshowData[slideshowIndex]);

    /**
     * End Hooks
     */

    function renderImage() {
        return slideshowFadeTransition((style, item) => {
            return (
                <animated.div
                    className={styles.image}
                    style={{
                        ...style,
                        backgroundImage: `url(${item.imageSrc})`,
                    }}
                    key={item.id}
                />
            );
        });
    }

    return <div className={styles.slideshow}>{renderImage()}</div>;
};
