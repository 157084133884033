import React, { useState } from "react";
import cx from "classnames";

// Components
import { ButtonPrimary } from "components/Buttons/Buttons";
import { Text } from "components/Text/Text";

// CSS
import styles from "../../sign-in.module.scss";

// Enums
import { NavigationEnum } from "scenes/SignIn/constants/constants";

// Localization
import { useTranslation } from "localization/localization";

// Utils
import { SandboxxRestAPI } from "../../../../utils/sandboxx";

export const SignInForgotPassword = ({
    setCurrentSection,
    showNotification,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useState
     */

    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);

    /**
     * End Hooks
     */

    function handleChangeEmail({ target }) {
        const { value } = target;
        setEmail(value);
    }

    function handleGoBack() {
        setCurrentSection(NavigationEnum.SIGN_IN_MAIN);
    }

    function handleSubmit(e) {
        e.preventDefault();
        if (email) {
            setLoading(true);
            SandboxxRestAPI.requestPasswordResetEmail(
                email,
                onResetPasswordSuccess,
                onResetPasswordFailure,
                onResetPasswordFailure
            );
        } else {
            showNotification({
                text: t("sign_in_forgot_password.submit_email_missing_error", {
                    ns: "onboarding",
                }),
                type: "warning",
            });
        }
    }

    function onResetPasswordFailure({ message }) {
        showNotification({
            text: message,
            type: "warning",
        });
    }

    function onResetPasswordSuccess(res) {
        setLoading(false);
        showNotification(
            {
                text: t("sign_in_forgot_password.submit_success", {
                    ns: "onboarding",
                }),
                type: "success",
            },
            () => {
                setCurrentSection(NavigationEnum.SIGN_IN_MAIN);
            }
        );
    }

    return (
        <div className={styles.content}>
            <div className={styles.top}>
                <div className={styles.header}>
                    <span className={styles.text}>
                        {t("sign_in_forgot_password.title", {
                            ns: "onboarding",
                        })}
                    </span>
                    <span className={styles["text-secondary"]}>
                        {t("sign_in_forgot_password.subtitle", {
                            ns: "onboarding",
                        })}
                    </span>
                </div>
                <form
                    className={cx(styles.form, "form")}
                    onSubmit={handleSubmit}
                >
                    <span className={styles["input-header"]}>
                        {t("email", { ns: "common" })}
                    </span>
                    <input
                        className={cx(styles.input, "input")}
                        name="email"
                        type="text"
                        value={email}
                        onChange={handleChangeEmail}
                    />
                </form>
            </div>
            <div className={styles.bottom}>
                <div className={cx(styles.buttons, styles["buttons--single"])}>
                    <ButtonPrimary
                        classes={styles.button}
                        isLoading={loading}
                        onClick={handleSubmit}
                    >
                        <Text>{t("send", { ns: "common" })}</Text>
                    </ButtonPrimary>
                </div>
                <span className={styles.text}>
                    <span
                        className={cx(styles.link, "link")}
                        onClick={handleGoBack}
                    >
                        {t("go_back", { ns: "common" })}
                    </span>
                </span>
            </div>
        </div>
    );
};
