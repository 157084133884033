/**
 * This method converts a whole number without decimal places (e.g., 123456) and
 * converts it into a dollar-amount string (e.g., $1234.56).`
 *
 * @param {Number} number formatted as whole number with no decimal places
 * @returns {String} formatted as $###.##
 */
export function convertWholeNumberToDollars(number) {
    return (number / 100).toLocaleString("en-US", {
        currency: "USD",
        style: "currency",
    });
}
