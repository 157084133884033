import React from "react";
import PropTypes from "prop-types";

// Components
import { AddPaymentOption } from "components/PaymentOption/AddPaymentOption";
import { ButtonPrimary } from "components/Buttons/Buttons";
import { SavedPaymentOptions } from "components/PaymentOption/SavedPaymentOptions";
import { Text } from "components/Text/Text";

// Localization
import { useTranslation } from "localization/localization";

export const WalletManagePurchaseCardModalSelectPayment = ({
    loading,
    newCard,
    savedCards,
    selectedCard,
    setCurrentSection,
    setLoading,
    setNewCard,
    setSelectedCard,
    showNotification,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * Constants
     */

    const savedCardEndItem = { type: "select" };

    /**
     * End Hooks
     */

    function handleCardInputChange(e) {
        const { name, value } = e.target;
        setNewCard({
            ...newCard,
            [name]: value,
        });
    }

    function handleCardSelect(selectedCard, isSavedCard) {
        setSelectedCard((prev) => ({
            ...prev,
            card: selectedCard,
            isSavedCard,
        }));
    }

    function handleCardSetToken(token) {
        setNewCard({ ...newCard, token });
    }

    function handleSavedCardSelect(card, index, callback) {
        setSelectedCard((prev) => ({
            ...prev,
            card,
            index,
        }));
        callback(card);
    }

    function renderButtons(cardType, submitCallback) {
        return (
            <div className="button__container force-right">
                <ButtonPrimary
                    onClick={submitCallback}
                    onLoading={loading[cardType]}
                >
                    <Text>{t("next", { ns: "common" })}</Text>
                </ButtonPrimary>
            </div>
        );
    }

    return (
        <div
            className="letters-compose-add-ons__select-payment"
            data-cy="wallet-purchase-card-modal-select-payment"
        >
            <div className="letters-compose-add-ons-payment-container">
                <AddPaymentOption
                    changeCurrentSection={setCurrentSection}
                    context="wallet"
                    handleCardInputChange={handleCardInputChange}
                    handleCardSelect={handleCardSelect}
                    handleCardSetToken={handleCardSetToken}
                    hideBackButton={true}
                    loading={loading}
                    newCard={newCard}
                    renderButtons={renderButtons}
                    setLoading={setLoading}
                    showNotification={showNotification}
                />
                <div className="new-modal-text-divider horizontal">
                    {t("or", { ns: "common" })}
                </div>
                <SavedPaymentOptions
                    changeCurrentSection={setCurrentSection}
                    context="wallet"
                    endItem={savedCardEndItem}
                    handleCardSelect={handleCardSelect}
                    handleSavedCardSelect={handleSavedCardSelect}
                    renderButtons={renderButtons}
                    savedCards={savedCards}
                    selectedCard={selectedCard}
                />
            </div>
        </div>
    );
};

WalletManagePurchaseCardModalSelectPayment.propTypes = {
    handleClosePurchaseCardModal: PropTypes.func.isRequired,
    loading: PropTypes.object.isRequired,
    newCard: PropTypes.object.isRequired,
    savedCards: PropTypes.array,
    selectedCard: PropTypes.object,
    setCurrentSection: PropTypes.func.isRequired,
    setLoading: PropTypes.func.isRequired,
    setNewCard: PropTypes.func.isRequired,
    setSelectedCard: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
};
