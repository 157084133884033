import { useContext, useState } from "react";

// Context
import { NavBarContext } from "context/navbar";
import { NotificationBarContext } from "context/notificationBar";

// Localization
import { useTranslation } from "localization/localization";

// Platforms
import { Analytics } from "platforms/analytics";
import { Intercom } from "platforms/intercom";

// Utils
import { CurrentUser } from "utils/sandboxx";
import { generateLandingPath } from "utils/navigationUtils";
import { SandboxxRestAPI } from "utils/sandboxx";

export const useSignInMain = ({
    handleGoToNameEntry,
    handleGoToOnboarding,
    handleGoToPasswordReset,
    handleGoToPhoneEntry,
    history,
    setHasCompletedSignUpName,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useContext
     */

    const { runShouldShowNavBarCheck } = useContext(NavBarContext);
    const { showNotification } = useContext(NotificationBarContext);

    /**
     * useState
     */

    const [shouldShowSignInModalPrompt, setShouldShowSignInModalPrompt] =
        useState(false);

    /**
     * End Hooks
     */

    function handleMissingEmailOAuth() {
        showNotification({
            text: t("sign_in_main.submit_missing_email_oauth_error", {
                ns: "onboarding",
            }),
            type: "warning",
        });
    }

    /**
     * Callback method used when signing in using social buttons (e.g., GoogleLoginButton)
     */
    function onLoginSuccess(res) {
        const {
            auth,
            musterConnection,
            newAccount,
            onboarding,
            user,
            verification,
        } = res;
        sessionStorage.setItem(
            "hasMusterConnection",
            musterConnection || false
        );
        CurrentUser.storeUserSignIn(user, auth, verification, onboarding);
        const { firstName, lastName, personas } = CurrentUser.getUser();
        Analytics.login();
        Intercom.registerUser();
        const referralCode = sessionStorage.getItem("referralCode");
        if (referralCode) {
            SandboxxRestAPI.claimReferralCode(referralCode, () => {
                // Sucessfully redeemed, so clear the referralCode from session storage
                sessionStorage.setItem("referralCode", "");
            });
        }
        if (newAccount && (!firstName || !lastName)) {
            handleGoToNameEntry();
        } else if (newAccount) {
            localStorage.setItem("hasCompletedSignUpName", true);
            setHasCompletedSignUpName(true);
            handleGoToPhoneEntry();
        } else if (onboarding.required) {
            localStorage.setItem("hasCompletedSignUpName", true);
            setHasCompletedSignUpName(true);
            handleGoToOnboarding();
        } else if (auth.forcePasswordReset) {
            handleGoToPasswordReset();
        } else {
            const rootPersona = personas.filter((persona) => {
                return persona.type === "RootPersona";
            });
            const landingUrl = generateLandingPath(rootPersona[0]);
            history.push(landingUrl.path);
            runShouldShowNavBarCheck();
        }
    }

    return {
        handleMissingEmailOAuth,
        onLoginSuccess,
        setShouldShowSignInModalPrompt,
        shouldShowSignInModalPrompt,
    };
};
