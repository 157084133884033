import React from "react";
import cx from "classnames";

// Components
import { ButtonInvisible, ButtonPrimary } from "components/Buttons/Buttons";
import { ConfirmVerificationCodeInput } from "components/Inputs/ConfirmVerificationCodeInput/ConfirmVerificationCodeInput";
import { Text } from "components/Text/Text";

// CSS
import styles from "../profile-delete-account-modal.module.scss";

// Localization
import { useTranslation } from "localization/localization";

export const ProfileDeleteAccountModalPhoneCodeVerify = ({
    handleChangeConfirmVerificationCodeInput,
    handleSubmitConfirmVerifcationCode,
    loading,
    resendVerificationCode,
    shouldShowErrorState,
    verificationCode,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * End Hooks
     */

    function renderForm() {
        return (
            <form
                className={cx(styles.form)}
                data-action="signUpPhoneVerifySubmit"
                onSubmit={handleSubmitConfirmVerifcationCode}
            >
                <ConfirmVerificationCodeInput
                    label=""
                    length={6}
                    loading={loading}
                    onChange={handleChangeConfirmVerificationCodeInput}
                    shouldShowErrorState={shouldShowErrorState}
                />
            </form>
        );
    }

    return (
        <div className={cx(styles["phone-verify-code"])}>
            <div className={styles.top}>
                {renderForm()}
                <div className={styles.buttons}>
                    <ButtonPrimary
                        cypressTestId="sign-up-confirm-verify-button"
                        dataAttributes={{
                            "data-action": "signUpPhoneVerifySubmit",
                        }}
                        isDisabled={verificationCode.length < 6}
                        isLoading={loading}
                        onClick={handleSubmitConfirmVerifcationCode}
                    >
                        <Text>{t("continue", { ns: "common" })}</Text>
                    </ButtonPrimary>
                    <div className={styles["buttons-row"]}>
                        <ButtonInvisible
                            classes={styles.button}
                            onClick={resendVerificationCode}
                            text={t("resend_code", { ns: "common" })}
                            type="button"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
