import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

// Utils
import { isExternalURL, stripOriginFromURL } from "utils/urlUtils";

export const SmartLink = ({ children, className, config, to, ...props }) => {
    // Render either a Link component for internal links or an <a> tag for external links
    if (isExternalURL(to)) {
        return (
            <a
                {...props}
                {...(config.shouldOpenNewWindow
                    ? { rel: "", target: "_blank" }
                    : {})}
                className={className}
                href={to}
                onClick={(e) => e.stopPropagation()}
            >
                {children}
            </a>
        );
    } else {
        const formattedTo = stripOriginFromURL(to);
        return (
            <Link
                {...props}
                className={className}
                onClick={(e) => e.stopPropagation()}
                to={formattedTo}
            >
                {children}
            </Link>
        );
    }
};

SmartLink.defaultProps = {
    className: "",
    config: {
        shouldOpenNewWindow: false,
    },
};

SmartLink.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    config: PropTypes.shape({
        shouldOpenNewWindow: PropTypes.bool,
    }),
    to: PropTypes.string.isRequired,
};
