export function LetterFilledIcon({ className, height, width, color }) {
    return (
        <svg
            className={className}
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2.13352 8.18144C1.83359 7.9672 1.68363 7.86008 1.55288 7.84617C1.35735 7.82537 1.16139 7.92622 1.06467 8.09741C0.999992 8.21189 0.999995 8.39416 1 8.75869V15.2413C0.999988 16.0463 0.999978 16.7106 1.04419 17.2518C1.09012 17.8139 1.18868 18.3306 1.43598 18.816C1.81947 19.5686 2.43139 20.1805 3.18404 20.564C3.66937 20.8113 4.18608 20.9099 4.74818 20.9558C5.28937 21 5.95372 21 6.75868 21H17.2413C18.0463 21 18.7106 21 19.2518 20.9558C19.8139 20.9099 20.3306 20.8113 20.816 20.564C21.5686 20.1805 22.1805 19.5686 22.564 18.816C22.8113 18.3306 22.9099 17.8139 22.9558 17.2518C23 16.7106 23 16.0463 23 15.2413V8.75868C23 8.58001 23 8.49068 22.9836 8.42829C22.9096 8.14627 22.603 7.98561 22.329 8.08531C22.2684 8.10737 22.194 8.15886 22.0453 8.26184L14.3032 13.6219C13.7542 14.0032 13.2722 14.3379 12.7247 14.4706C12.2458 14.5867 11.7456 14.583 11.2685 14.4599C10.7229 14.3191 10.2459 13.9774 9.70265 13.5881L2.13352 8.18144Z"
                fill={color}
            />
            <path
                d="M22.1328 5.76872C22.3174 5.64092 22.4097 5.57702 22.4664 5.47424C22.5104 5.39443 22.5356 5.26804 22.5255 5.17745C22.5125 5.06079 22.463 4.98377 22.3638 4.82973C21.9839 4.23964 21.4373 3.75256 20.816 3.43598C20.3306 3.18868 19.8139 3.09012 19.2518 3.04419C18.7106 2.99998 18.0463 2.99999 17.2413 3H6.7587C5.95374 2.99999 5.28937 2.99998 4.74818 3.04419C4.18608 3.09012 3.66937 3.18868 3.18404 3.43598C2.62501 3.72082 2.1418 4.1326 1.77436 4.63335C1.65877 4.79089 1.60097 4.86966 1.58192 4.98921C1.56703 5.08269 1.58783 5.21257 1.63116 5.29674C1.68657 5.40436 1.78269 5.47302 1.97493 5.61033L10.75 11.8783C11.4773 12.3977 11.6316 12.4881 11.7681 12.5233C11.9272 12.5644 12.0939 12.5656 12.2535 12.5269C12.3906 12.4937 12.5463 12.4056 13.281 11.8969L22.1328 5.76872Z"
                fill={color}
            />
        </svg>
    );
}

LetterFilledIcon.defaultProps = {
    height: "25px",
    width: "25px",
    color: "#212121",
};
