import { DateTime } from "luxon";

/**
 * Calculates difference between the present and the given time in ISO format
 * @param    {String} iso  An ISO formatted time string
 * @param    {Array} units  An array containing a list of time units to break the duration into
 * @returns   {Object}
 * - @property {Number} units - A key value pair form each unit specified in the units param
 *
 */
/* istanbul ignore next */
export function calculateTimeUntil(iso, units) {
    const today = DateTime.local();
    const comparedDate = DateTime.fromISO(iso);
    return comparedDate.diff(today, units).toObject();
}

export function convertJSDateToObject(date) {
    const newDateObject = DateTime.fromJSDate(date).toObject();
    const dateObject = {
        day: generateDoubleDigitDateString(newDateObject?.day) || "",
        month: generateDoubleDigitDateString(newDateObject?.month) || "",
        year: newDateObject?.year?.toString() || "",
    };
    return dateObject;
}

export function convertISOToObject(date) {
    const newDateObject = DateTime.fromISO(date, { zone: "utc" }).toObject();
    const dateObject = {
        day: generateDoubleDigitDateString(newDateObject?.day) || "",
        month: generateDoubleDigitDateString(newDateObject?.month) || "",
        year: newDateObject?.year?.toString() || "",
    };
    return dateObject;
}

/**
 * This functions takes an ISO date and generates a string formatted
 * according to whatever Luxon DateTime format (e.g., DateTime.DATE_FULL)
 * is provided
 *
 * @param {String} iso
 * @returns {String}
 */
export function convertISOToLocaleString(iso, format = DateTime.DATE_FULL) {
    if (iso) {
        return DateTime.fromISO(iso).toLocaleString(format);
    }
}

/**
 * Calculates the hour, minute, and second difference between the present and the given time
 * @param    {String} timeUntil    An ISO formatted time string
 * @returns   {Object}
 * - @property {Number} hours - A number representing the number of hours between the present and the given time
 * - @property {Number} minutes - A number representing the number of minutes between the present and the given time
 * - @property {Number} seconds - A number representing the number of seconds between the present and the given time
 *
 */
export function findTimeDifference(timeUntil) {
    return DateTime.local()
        .until(DateTime.fromISO(timeUntil))
        .toDuration(["hours", "minutes", "seconds"])
        .toObject();
}

export function findTimeFrom(timeFrom) {
    return DateTime.fromISO(timeFrom, { zone: "utc" })
        .until(DateTime.local())
        .toDuration(["days", "hours", "minutes", "seconds"])
        .toObject();
}

export function generateDoubleDigitDateString(value) {
    if (value < 10) {
        return "0" + value.toString();
    }
    return value.toString();
}
