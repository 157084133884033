import { useContext, useEffect, useMemo, useRef, useState } from "react";

// Constants
import { Notifications } from "globals/notifications";

// Context
import { ContactsContext } from "context/contacts";
import { NotificationContext } from "context/notification/notification";

// Enums
import { ContactModalNavigation } from "scenes/Contacts/ContactModal/enums/ContactModalNavigation";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Models
import { Contact } from "models/contacts/Contact";
import { calculateRemainingMessageCharacters } from "utils/lettersUtils";

export const useLettersComposeMessageContent = ({
    handleInputChange,
    message,
    recipient,
    setPreviewImage,
    setRecipient,
    setShouldShowPhotoManageModal,
    toggleContactModal,
}) => {
    /**
     * useContext
     **/

    const {
        fetchContacts,
        fetchRecentContacts,
        setOnSubmitContactSuccessFinalCallback,
    } = useContext(ContactsContext);
    const { addNotification, removeNotification } =
        useContext(NotificationContext);

    /**
     * useRef
     **/

    let textAreaRef = useRef(null);

    /**
     * useState
     **/

    const [
        hasTriggeredCharacterLimitWarning,
        setHasTriggeredCharacterLimitWarning,
    ] = useState(false);
    const [textAreaStyle, setTextAreaStyle] = useState({});

    /**
     * useMemo
     */

    const isOverCharacterLimit = useMemo(() => {
        const { remainingCharacters } = calculateRemainingMessageCharacters(
            message.content
        );
        return remainingCharacters < 0;
    }, [message.content]);

    /**
     * useEffect
     */

    useEffect(() => {
        if (isOverCharacterLimit) {
            setHasTriggeredCharacterLimitWarning(true);
            addNotification(Notifications.letters.overCharacterLimit);
        } else if (hasTriggeredCharacterLimitWarning) {
            removeNotification(Notifications.letters.overCharacterLimit);
        }
        return () => {
            removeNotification(Notifications.letters.overCharacterLimit);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOverCharacterLimit]);

    useEffect(() => {
        expandTextArea();
    }, [textAreaRef]);

    /**
     * Constants
     **/

    const userList = [recipient];

    /**
     * End Hooks & Constants
     **/

    function expandTextArea() {
        const { scrollHeight } = textAreaRef.current;
        setTextAreaStyle({ height: `${scrollHeight}px` });
    }

    function handleImagePreviewClick(index) {
        setShouldShowPhotoManageModal(true);
        setPreviewImage(message.customPhotoArray[index]?.binary || null);
    }

    function handleMessageContentChange(e) {
        handleInputChange(e);
        expandTextArea();
    }

    function handleToggleContactModal() {
        AnalyticsLogger.logEditContactView();
        toggleContactModal({
            contactToEdit: recipient,
            isWritingLetter: true,
            shouldShow: true,
            targetScreen: ContactModalNavigation.FORM,
            type: "edit",
        });
        setOnSubmitContactSuccessFinalCallback(() => onEditContactSuccess);
    }

    function onEditContactSuccess(res, { contactStaging }) {
        // Fetch latest contacts and recent contacts lists
        fetchContacts();
        fetchRecentContacts();

        // Save new contact locally
        const contact = new Contact({
            ...contactStaging,
            abContactId: res.abContactId,
        });
        localStorage.setItem(
            "sandboxxMessageRecipient",
            JSON.stringify(contact.generateContactObject())
        );
        setRecipient(contact);
    }

    return {
        handleImagePreviewClick,
        handleMessageContentChange,
        handleToggleContactModal,
        textAreaRef,
        textAreaStyle,
        userList,
    };
};
