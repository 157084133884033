import React, { useRef } from "react";
import cx from "classnames";

// Components
import { InputValidatable } from "components/Inputs/InputValidatable/InputValidatable";

// CSS
import styles from "./wallet-gift-cards-purchase-recipient-new-recipient-input.module.scss";

// Hooks
import { useToggleOnFocus } from "hooks/forms/useToggleOnFocus";

// Validation
import { Validation } from "../../../../validation/validation";

export const WalletGiftCardsPurchaseRecipientNewRecipientInput = ({
    cypressTestId,
    labelText,
    name,
    onChange,
    value,
}) => {
    /**
     * useRef
     */

    const inputRef = useRef(null);

    /**
     * Custom Hooks
     */

    const [isFocusing, { onBlur, onFocus }] = useToggleOnFocus(false);

    /**
     * Constants
     */

    const isValid = Validation.isValidNewRecipientAttribute(name, value);
    const isError = value && !isFocusing && !isValid;

    /**
     * End Constants & Hooks
     */

    return (
        <label
            className={cx(
                styles[
                    "wallet-gift-cards-purchase-recipient-new-recipient-input"
                ],
                "label"
            )}
            htmlFor={name}
        >
            <span>{labelText}</span>
            <InputValidatable
                cypressTestId={cypressTestId}
                containerClasses={styles.input}
                isError={isError}
                isValid={isValid}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                onFocus={onFocus}
                setInputRef={(ref) => (inputRef.current = ref)}
                value={value}
            />
        </label>
    );
};
