import React, { useContext, useEffect } from "react";

// Components
import { HeaderBar } from "components/HeaderBar/HeaderBar";
import { MusterChecklistDeleteModal } from "./components/MusterChecklistDeleteModal/MusterChecklistDeleteModal";
import { MusterChecklistDocumentModal } from "./components/MusterChecklistDocumentModal/MusterChecklistDocumentModal";
import { MusterChecklistCollection } from "./components/MusterChecklistCollection/MusterChecklistCollection";
import { MusterChecklistSuccessModal } from "./components/MusterChecklistSuccessModal/MusterChecklistSuccessModal";
import { MusterHeaderBarContent } from "../MusterHeaderBarContent/MusterHeaderBarContent";

// Context
import { HeaderBarContext } from "context/headerBar";
import { MusterContext } from "context/muster";

// CSS
import styles from "./muster-checklist.module.scss";

// Hooks
import { useDocumentTitle } from "@uidotdev/usehooks";
import { useMusterChecklist } from "./hooks/useMusterChecklist";

export const MusterChecklist = ({ history, location }) => {
    /**
     * Constants
     */

    const activeAccordionId = location?.state?.activeAccordionId;

    /**
     * useContext
     */

    const { setHeaderBarContent } = useContext(HeaderBarContext);
    const { fetchRecruitDashboard, musterDocuments, setMusterDocuments } =
        useContext(MusterContext);

    /**
     * Custom Hooks
     */

    useDocumentTitle("Sandboxx - Waypoints Checklist");

    const {
        activeDocument,
        addMusterDocument,
        completeDocuments,
        deleteMusterDocument,
        incompleteDocuments,
        modalType,
        onToggleAddEditModal,
        onToggleDeleteModal,
        setActiveDocument,
        setShouldShowSuccessModal,
        shouldShowAddEditDocumentModal,
        shouldShowDeleteModal,
        shouldShowSuccessModal,
        updateMusterDocument,
    } = useMusterChecklist(
        fetchRecruitDashboard,
        musterDocuments,
        setMusterDocuments
    );

    /**
     * useEffect
     */

    useEffect(() => {
        // State calls
        setHeaderBarContent({
            onBackClick: () => history.push("/waypoints"),
            textSecondary:
                "To expedite your processing, please bring the below documents with you to your initial appointment",
            text: "Document Checklist",
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * End Hooks & Constants
     */

    function renderMusterChecklist() {
        return (
            <div className={styles.checklist}>
                <MusterChecklistCollection
                    collection={incompleteDocuments}
                    onToggleAddEditModal={onToggleAddEditModal}
                    onToggleDeleteModal={onToggleDeleteModal}
                    setActiveDocument={setActiveDocument}
                    activeAccordionId={activeAccordionId}
                    title="DOCUMENTS"
                    updateMusterDocument={updateMusterDocument}
                />
                <MusterChecklistCollection
                    collection={completeDocuments}
                    onToggleAddEditModal={onToggleAddEditModal}
                    onToggleDeleteModal={onToggleDeleteModal}
                    setActiveDocument={setActiveDocument}
                    activeAccordionId={activeAccordionId}
                    title="COLLECTED DOCUMENTS"
                    updateMusterDocument={updateMusterDocument}
                />
            </div>
        );
    }

    return (
        <div className="page">
            <HeaderBar>
                <MusterHeaderBarContent />
            </HeaderBar>
            <MusterChecklistDeleteModal
                activeDocument={activeDocument}
                deleteMusterDocument={deleteMusterDocument}
                onToggleDeleteModal={onToggleDeleteModal}
                shouldShowDeleteModal={shouldShowDeleteModal}
            />
            <MusterChecklistDocumentModal
                activeDocument={activeDocument}
                addMusterDocument={addMusterDocument}
                onToggleAddEditModal={onToggleAddEditModal}
                shouldShowAddEditDocumentModal={shouldShowAddEditDocumentModal}
                type={modalType}
                updateMusterDocument={updateMusterDocument}
            />
            <MusterChecklistSuccessModal
                setShouldShowSuccessModal={setShouldShowSuccessModal}
                shouldShowSuccessModal={shouldShowSuccessModal}
            />
            <div className={styles.musterChecklist}>
                {renderMusterChecklist()}
            </div>
        </div>
    );
};
