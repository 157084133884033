import React from "react";

// Components
import { HeaderBar } from "components/HeaderBar/HeaderBar";
import { WalletManageAddCardModal } from "./components/WalletManageAddCardModal/WalletManageAddCardModal";
import { WalletManageDetail } from "./components/WalletManageDetail/WalletManageDetail";
import { WalletManageList } from "./components/WalletManageList/WalletManageList";
import { WalletManagePurchaseCardModal } from "./components/WalletManagePurchaseCardModal/WalletManagePurchaseCardModal";

// CSS
import styles from "./wallet-manage.module.scss";

// Hooks
import { useWalletManage } from "./components/WalletManageList/hooks/useWalletManage";

export const WalletManage = (props) => {
    const { showNotification } = props;

    /**
     * Custom Hooks
     */

    const {
        addGiftCardToWalletWithBarcode,
        fetchPurchasedGiftCards,
        fetchSpendingGiftCards,
        handleSelectWalletListTab,
        hasPurchasedGiftCardsInWallet,
        hasSelectedGiftCard,
        hasSpendingGiftCardsInWallet,
        loading,
        purchasedGiftCards,
        selectedGiftCard,
        selectedWalletListTabId,
        setSelectedGiftCard,
        setShouldShowAddCardModal,
        setShouldShowPurchaseCardModal,
        shouldShowAddCardModal,
        shouldShowPurchaseCardModal,
        spendingGiftCards,
    } = useWalletManage(props);

    /**
     * End Hooks
     */

    return (
        <div className="page">
            <WalletManageAddCardModal
                {...props}
                addGiftCardToWalletWithBarcode={addGiftCardToWalletWithBarcode}
                loading={loading}
                setShouldShowAddCardModal={setShouldShowAddCardModal}
                shouldShowAddCardModal={shouldShowAddCardModal}
            />
            <WalletManagePurchaseCardModal
                {...props}
                fetchPurchasedGiftCards={fetchPurchasedGiftCards}
                fetchSpendingGiftCards={fetchSpendingGiftCards}
                loading={loading}
                setShouldShowPurchaseCardModal={setShouldShowPurchaseCardModal}
                shouldShowPurchaseCardModal={shouldShowPurchaseCardModal}
                showNotification={showNotification}
            />
            <HeaderBar />
            <div className="page__body">
                <div className={styles["wallet-manage"]}>
                    <WalletManageList
                        {...props}
                        handleSelectWalletListTab={handleSelectWalletListTab}
                        hasPurchasedGiftCardsInWallet={
                            hasPurchasedGiftCardsInWallet
                        }
                        hasSpendingGiftCardsInWallet={
                            hasSpendingGiftCardsInWallet
                        }
                        loading={loading}
                        purchasedGiftCards={purchasedGiftCards}
                        selectedGiftCard={selectedGiftCard}
                        selectedWalletListTabId={selectedWalletListTabId}
                        setSelectedGiftCard={setSelectedGiftCard}
                        setShouldShowAddCardModal={setShouldShowAddCardModal}
                        setShouldShowPurchaseCardModal={
                            setShouldShowPurchaseCardModal
                        }
                        spendingGiftCards={spendingGiftCards}
                    />
                    <WalletManageDetail
                        {...props}
                        loading={loading}
                        hasSelectedGiftCard={hasSelectedGiftCard}
                        selectedGiftCard={selectedGiftCard}
                        selectedWalletListTabId={selectedWalletListTabId}
                    />
                </div>
            </div>
        </div>
    );
};
