import React from "react";
import PropTypes from "prop-types";

// Components
import { Modal } from "components/Modals/Modal";

// CSS
import styles from "./wallet-gift-cards-purchase-select-option-delivery-info-modal.module.scss";

export const WalletGiftCardsPurchaseSelectOptionDeliveryInfoModal = (props) => {
    const { setShouldShowDeliveryInfoModal, shouldShowDeliveryInfoModal } =
        props;

    return (
        <Modal
            bodyClasses={
                styles[
                    "wallet-gift-cards-purchase-select-option-delivery-info-modal"
                ]
            }
            headerText="Delivery Information"
            headerSubText="Here’s what to expect after placing your order:"
            onHide={() => setShouldShowDeliveryInfoModal(false)}
            show={shouldShowDeliveryInfoModal}
        >
            <ul>
                <li>
                    A physical Sandboxx Gift Card will be printed for your
                    recruit
                </li>
                <li>Gift cards ship within 3 business days</li>
                <li>
                    Gift Cards are delivered to your recruit within 10-14
                    business days
                </li>
            </ul>
        </Modal>
    );
};

WalletGiftCardsPurchaseSelectOptionDeliveryInfoModal.propTypes = {
    setShouldShowDeliveryInfoModal: PropTypes.func.isRequired,
    shouldShowDeliveryInfoModal: PropTypes.bool.isRequired,
};
