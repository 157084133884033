import { useContext, useEffect, useRef, useState } from "react";

// API
import { SandboxxRestAPI } from "utils/sandboxx";

// Context
import { NotificationBarContext } from "context/notificationBar";

// Enums
import { CartNavigation } from "components/Cart/enums/CartNavigation";

// Platforms
import { Braintree } from "platforms/braintree";

export const useCartPaymentMethodForm = ({
    handleSelectPaymentMethodNew,
    setCartCurrentSection,
    setHeaderText,
}) => {
    /**
     * useRef
     */

    const dropinContainerRef = useRef(null);
    const dropinSubmitButtonRef = useRef(null);

    /**
     * useContext
     */

    const { showNotification } = useContext(NotificationBarContext);

    /**
     * useState
     */

    // TODO: Do we need this? Or can we use `isSubmittingPaymentMethod`?
    const [isSubmitting, setIsSubmitting] = useState(false);

    /**
     * useEffect
     */

    useEffect(() => {
        setHeaderText(() => "Add Payment Method");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const refs = {
            container: dropinContainerRef.current,
            submitButton: dropinSubmitButtonRef.current,
        };
        if (refs.container) {
            Braintree.createDropinForm(refs, (dropinInstance) => {
                setIsSubmitting(true);
                dropinInstance.requestPaymentMethod((err, card) => {
                    if (err) {
                        setIsSubmitting(false);
                        return;
                    }
                    handleSubmitPaymentMethod(card);
                });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dropinContainerRef]);

    /**
     * End Hooks
     */

    function handleGoBack() {
        setCartCurrentSection(CartNavigation.MAIN);
    }

    function handleSubmitPaymentMethod(card) {
        const { nonce } = card;
        SandboxxRestAPI.addBraintreeCard(
            { nonce },
            (res) => onAddBraintreeCardSuccess(res, card),
            onAddBraintreeCardFailure,
            onAddBraintreeCardFailure
        );
    }

    function onAddBraintreeCardFailure(err) {
        setIsSubmitting(false);
        showNotification({
            text: "There was an issue adding your credit card. Please confirm you have entered the correct card information and try again.",
            type: "warning",
        });
    }

    function onAddBraintreeCardSuccess({ message: token }, card) {
        handleSelectPaymentMethodNew(card, token);
        setIsSubmitting(false);
        setCartCurrentSection(CartNavigation.MAIN);
    }

    return {
        dropinContainerRef,
        dropinSubmitButtonRef,
        handleGoBack,
        isSubmitting,
    };
};
