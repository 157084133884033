import React, { forwardRef, useEffect, useRef, useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// Components
import CircularProgress from "@mui/material/CircularProgress";

// CSS
import styles from "./buttons.module.scss";

// Utils
import { emptyFunction } from "utils/miscUtils";
import { setMultipleRefs } from "utils/refUtils";

export const ButtonInvisible = (props) => {
    const {
        children,
        classes,
        config,
        cypressTestId,
        id,
        isDisabled,
        onClick,
        text,
        type,
    } = props;
    const { isDeemphasized } = config;

    const deemphasizedClass = isDeemphasized ? "button--deemphasized" : "";
    const disabledClass = isDisabled ? "disabled" : "";

    return (
        <button
            className={`button button--invisible button-small ${disabledClass} ${deemphasizedClass} ${classes}`}
            data-cy={cypressTestId}
            disabled={isDisabled}
            id={id}
            onClick={onClick}
            type={type}
        >
            {children ? children : <span>{text}</span>}
        </button>
    );
};

export const ButtonPrimary = forwardRef((props, ref) => {
    const {
        children,
        classes,
        config,
        cypressTestId,
        dataAttributes,
        id,
        isDisabled,
        isLoading,
        onClick,
        style,
        text,
        type,
    } = props;

    /**
     * useState
     */

    const [buttonHeight, setButtonHeight] = useState(null);
    const [buttonWidth, setButtonWidth] = useState(null);

    /**
     * useRef
     */

    const localRef = useRef(null);

    /**
     * useEffect
     */

    useEffect(() => {
        if (config.shouldMaintainDimensions && localRef.current) {
            setButtonHeight(localRef.current.offsetHeight);
            setButtonWidth(localRef.current.offsetWidth);
        }
    }, [config.shouldMaintainDimensions]);

    /**
     * End Hooks
     */

    function renderContent() {
        return children ? children : <span>{text}</span>;
    }

    function renderLoading() {
        return <CircularProgress color="inherit" size={24} />;
    }

    return (
        <button
            {...dataAttributes}
            className={cx(styles.button, styles.buttonPrimary, classes, {
                disabled: isDisabled,
                [styles["buttonPrimary--loading"]]: isLoading,
            })}
            data-cy={cypressTestId}
            disabled={isDisabled}
            id={id}
            onClick={onClick}
            ref={setMultipleRefs(ref, localRef)}
            style={{
                ...style,
                ...(buttonHeight && { height: buttonHeight }),
                ...(buttonWidth && { width: buttonWidth }),
            }}
            type={type}
        >
            {isLoading ? renderLoading() : renderContent()}
        </button>
    );
});

ButtonPrimary.defaultProps = {
    classes: "",
    config: { shouldMaintainDimensions: false },
    cypressTestId: "",
    isDisabled: false,
    onClick: emptyFunction,
    type: "button",
};

export const ButtonSecondary = (props) => {
    const {
        classes,
        children,
        config,
        cypressTestId,
        id,
        isDisabled,
        isFilled,
        isLoading,
        onClick,
        text,
        type,
    } = props;

    /**
     * useState
     */

    const [buttonHeight, setButtonHeight] = useState(null);
    const [buttonWidth, setButtonWidth] = useState(null);

    /**
     * useRef
     */

    const localRef = useRef(null);

    /**
     * useEffect
     */

    useEffect(() => {
        if (config.shouldMaintainDimensions && localRef.current) {
            setButtonHeight(localRef.current.offsetHeight);
            setButtonWidth(localRef.current.offsetWidth);
        }
    }, [config.shouldMaintainDimensions]);

    /**
     * End Hooks
     */

    function renderContent() {
        return children ? children : <span>{text}</span>;
    }

    function renderLoading() {
        return <CircularProgress color="inherit" size={24} />;
    }

    return (
        <button
            className={cx(styles.button, styles.buttonSecondary, classes, {
                disabled: isDisabled,
                [styles["buttonSecondary--filled"]]: isFilled,
                [styles["buttonSecondary--loading"]]: isLoading,
            })}
            data-cy={cypressTestId}
            disabled={isDisabled}
            id={id}
            onClick={onClick}
            ref={localRef}
            style={{
                ...(buttonHeight && { height: buttonHeight }),
                ...(buttonWidth && { width: buttonWidth }),
            }}
            type={type}
        >
            {isLoading ? renderLoading() : renderContent()}
        </button>
    );
};

ButtonSecondary.defaultProps = {
    classes: "",
    config: { shouldMaintainDimensions: false },
    cypressTestId: "",
    isDisabled: false,
    onClick: emptyFunction,
    type: "button",
};

ButtonSecondary.propTypes = {
    classes: PropTypes.string,
    config: PropTypes.shape({
        shouldMaintainDimensions: PropTypes.bool,
    }),
    cypressTestId: PropTypes.string,
    icon: PropTypes.element,
    id: PropTypes.string,
    isDisabled: PropTypes.bool,
    onClick: PropTypes.func,
    text: PropTypes.string,
};

/**
 * This component is used for the email and social platform login options
 * on the SignIn landing screen. Icons in these buttons are positioned
 * to the left of the button text.
 */
export const ButtonSignIn = (props) => {
    const {
        classes,
        cypressTestId,
        icon,
        id,
        isDisabled,
        onClick,
        text,
        type,
    } = props;

    return (
        <button
            className={cx(styles.button, styles["buttonSignIn"], classes, {
                disabled: isDisabled,
            })}
            data-cy={cypressTestId}
            disabled={isDisabled}
            id={id}
            onClick={onClick}
            type={type}
        >
            <div className={styles.icon}>{icon}</div>
            <span>{text}</span>
        </button>
    );
};

ButtonInvisible.defaultProps = {
    classes: "",
    config: {},
    cypressTestId: "",
    isDisabled: false,
    onClick: emptyFunction,
    type: "button",
};
