import React, { createContext, useState } from "react";

// Constants
export const NavBarContext = createContext({});

export const NavBarProvider = (props) => {
    const { children } = props;

    /**
     * useState
     */

    const [shouldShowNavBar, setShouldShowNavBar] = useState(
        !window.location.pathname.includes("signin")
    );

    /**
     * End Hooks
     */

    function runShouldShowNavBarCheck() {
        setShouldShowNavBar(!window.location.pathname.includes("signin"));
    }

    const navBarContext = {
        runShouldShowNavBarCheck,
        setShouldShowNavBar,
        shouldShowNavBar,
    };

    return (
        <NavBarContext.Provider value={navBarContext}>
            {children}
        </NavBarContext.Provider>
    );
};
