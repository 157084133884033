import React from "react";
import { Redirect } from "react-router-dom";

// Components
import { SignIn } from "../scenes/SignIn/SignIn";

// Utils
import { CurrentUser } from "./sandboxx";

/* istanbul ignore next */
export class RedirectUtil {
    static handleGeneralRedirect(Component, props = {}, isSignIn = false) {
        const user = CurrentUser.getUser();
        // If user has not signed in...
        if (!CurrentUser.isLoggedIn()) {
            return isSignIn ? (
                <SignIn {...props} />
            ) : (
                <Redirect key="signup" to="/signin" />
            );
        }
        // If user is fully logged-in but needs to reset their password
        if (CurrentUser.isLoggedIn() && user.forcePasswordReset) {
            return (
                <SignIn goToSignUp={true} goToPasswordReset={true} {...props} />
            );
        }
        // If user is logged in but has not selected a role during onboarding...
        if (CurrentUser.isLoggedIn() && CurrentUser.isOnboardingRequired()) {
            return isSignIn ? (
                <SignIn goToSignUp={true} goToOnboarding={true} {...props} />
            ) : (
                <Redirect key="onboarding" to="/signin" />
            );
        }

        if (CurrentUser.isLoggedIn()) {
            // If user is fully logged-in and trying to access sign in...
            return isSignIn ? (
                <Redirect key="letters" to="/letters" />
            ) : (
                Component
            );
        }
        // Otherwise...
        return Component;
    }

    static handleRedirectToHome(history) {
        history.push("/");
    }
}
