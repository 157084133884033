// Platforms
import { Auth } from "./auth";

export class Facebook {
    static login(callbacks, { mode = "login" }) {
        const FB = window.FB;
        try {
            FB.login(
                (res) => {
                    const { authResponse } = res;
                    if (mode === "login") {
                        Facebook.socialSignOn(authResponse, callbacks);
                    } else if (mode === "verify") {
                        Facebook.socialVerify(authResponse, callbacks);
                    }
                },
                { return_scopes: "true", scope: "email" }
            );
        } catch (err) {
            // eslint-disable-next-line
            const didNotLoad = err.message.includes(
                "TypeError: Cannot read properties of undefined (reading 'login')"
            );
            // If Facebook login fails, try reloading in two seconds
            if (didNotLoad) {
                setTimeout(() => {
                    if (FB) {
                        Facebook.login(callbacks, { mode: "login" });
                    }
                }, 2000);
            }
        }
    }

    static logout() {
        const FB = window.FB;
        FB.logout((res) => {});
    }

    static revokePermissions() {
        const FB = window.FB;
        FB.api("/me/permissions", "delete");
    }

    static socialVerify(authResponse, callbacks) {
        const FB = window.FB;
        const { onVerifySuccess, showNotification, toggleButtonLoading } =
            callbacks;
        if (authResponse) {
            const { accessToken, userID } = authResponse;
            FB.api(
                "/me",
                { fields: "email,first_name,last_name,picture" },
                () => {
                    const facebookUserData = {
                        accessToken,
                        userID,
                    };
                    localStorage.setItem("authProvider", "facebook");
                    onVerifySuccess(facebookUserData);
                    toggleButtonLoading(false);
                }
            );
        } else {
            showNotification({
                text: "Facebook Login failed. Please try again.",
                type: "warning",
            });
            toggleButtonLoading(false);
        }
    }

    static socialSignOn(authResponse, callbacks) {
        const FB = window.FB;
        const {
            handleMissingEmail,
            onLoginSuccess,
            showNotification,
            toggleButtonLoading,
        } = callbacks;
        if (authResponse) {
            const { accessToken, userID } = authResponse;
            FB.api(
                "/me",
                { fields: "email,first_name,last_name,picture" },
                (resApi) => {
                    const { email, first_name, last_name } = resApi;
                    const facebookUserData = {
                        accessToken,
                        email,
                        first_name,
                        last_name,
                        userID,
                    };
                    if (email) {
                        Auth.socialSignOn(
                            "facebook",
                            facebookUserData,
                            (res) => {
                                localStorage.setItem(
                                    "authProvider",
                                    "facebook"
                                );
                                onLoginSuccess(res, facebookUserData);
                            }
                        );
                    } else {
                        Facebook.revokePermissions();
                        handleMissingEmail();
                        toggleButtonLoading(false);
                    }
                }
            );
        } else {
            showNotification({
                text: "Facebook Login failed. Please try again.",
                type: "warning",
            });
            toggleButtonLoading(false);
        }
    }
}
