// Enums
import { ContactSourceEnum } from "enums/ContactSourceEnum";

export function determineIfIsSandboxxUser(contactData) {
    const { abContactId, id, recipientId, squadId } = contactData;
    if (!!abContactId || !!squadId) {
        return false;
    } else if (!!recipientId || !!id) {
        return true;
    }
}

export function generateFullName(contactData) {
    const { firstName, fullName, lastName, title } = contactData;
    if (fullName) {
        return fullName;
    } else {
        const titleText = title ? `${title} ` : "";
        return `${titleText}${firstName} ${lastName}`;
    }
}

/**
 * @param {*} contactData containing the data that needs to be formatted as a Contact
 * @returns {String} containing the contact's id
 *
 * Id sources
 * - abContactId: Address Book contact
 * - recipientId: Sandboxx user
 * - squadId: Support Squad recipient
 * - contactResource: data formatted using resource syntax (e.g., Wallet /suggested_contacts)
 */
export function generateId(contactData) {
    return (
        contactData.abContactId ||
        contactData.recipientId ||
        contactData.squadId ||
        contactData.id ||
        contactData.contactResource?.id
    );
}

/**
 * @param {*} contactData containing the data that needs to be formatted as a Contact
 * @returns {String} containing the contact source
 */
export function generateSource(contactData) {
    if (contactData.contactResource) {
        return contactData.contactResource.source;
    }
    if (contactData.abContactId) {
        return ContactSourceEnum.ADDRESS_BOOK_CONTACT;
    }
    if (contactData.recipientId) {
        return ContactSourceEnum.USER;
    }
    return null;
}
