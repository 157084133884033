import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { Skeleton } from "@mui/material";

// Components
import { Text } from "components/Text/Text";

// CSS
import styles from "../../memory-book.module.scss";

// Utils
import { convertWholeNumberToDollars } from "utils/cartUtils";

export const MemoryBookItems = ({ cart, isUpdatingCart }) => {
    function renderItem({ item }) {
        if (item) {
            const handle = item.variant.product.handle;
            const hasDiscount = item.discount_total > 0;
            const subtotalWithDiscount = item.subtotal - item.discount_total;
            return (
                <div
                    className={cx(styles.item, styles[`item--${handle}`])}
                    key={item.id}
                >
                    <div className={styles.label}>
                        <Text classes={styles.title}>
                            {item.variant.product.title}
                        </Text>
                        <Text classes={styles.subtitle}>
                            {item.variant.product.subtitle}
                        </Text>
                    </div>
                    <div className={cx(styles.value)}>
                        <Text
                            classes={cx(styles.subtotal, {
                                [styles["subtotal--hasDiscount"]]: hasDiscount,
                            })}
                        >
                            {convertWholeNumberToDollars(item.subtotal)}
                        </Text>
                        {hasDiscount && (
                            <Text classes={styles.total}>
                                {convertWholeNumberToDollars(
                                    subtotalWithDiscount
                                )}
                            </Text>
                        )}
                    </div>
                </div>
            );
        }
    }

    if (isUpdatingCart) {
        return (
            <div className={styles.items}>
                <div className={styles.item}>
                    <div className={styles.label}>
                        <Skeleton variant="text" width={200} />
                        <Skeleton variant="text" height={14} width={100} />
                    </div>
                    <div className={styles.value}>
                        <Skeleton variant="text" width={50} />
                    </div>
                </div>
                <div className={styles.item}>
                    <div className={styles.label}>
                        <Skeleton variant="text" width={200} />
                        <Skeleton variant="text" height={14} width={100} />
                    </div>
                    <div className={styles.value}>
                        <Skeleton variant="text" width={50} />
                    </div>
                </div>
            </div>
        );
    } else {
        const extraPagesItem = cart?.items.find(
            (item) => item?.variant?.product?.handle === "extra-pages"
        );
        const memoryBookItem = cart?.items.find(
            (item) => item?.variant?.product?.handle === "memory-book"
        );
        return (
            <div className={styles.items}>
                {renderItem({ item: memoryBookItem })}
                {renderItem({
                    item: extraPagesItem,
                })}
            </div>
        );
    }
};

MemoryBookItems.propTypes = {
    cart: PropTypes.object,
    isUpdatingCart: PropTypes.bool,
};
