import { useContext, useEffect, useState } from "react";

// Context
import { HeaderBarContext } from "context/headerBar";

// Enums
import { LetterType } from "enums/LetterType";

// Localization
import { useTranslation } from "localization/localization";

// Media
import emptyImageSrc from "media/icons/image-empty.svg";

// Utils
import { SandboxxRestAPI } from "utils/sandboxx";
import { scrollToTop } from "utils/scrollUtils";

export const useLettersSentLetter = ({ location }) => {
    const { letter, letterType } = location.state;
    const { recipient, recipientName } = letter;

    /**
     * Custom Hooks
     **/

    const { t } = useTranslation();

    /**
     * useContext
     **/

    const { setHeaderBarContent } = useContext(HeaderBarContext);

    /**
     * useState
     **/

    const [checkpoints, setCheckpoints] = useState([]);
    const [loading, setLoading] = useState({ tracking: true });
    const [imageSrc, setImageSrc] = useState([]);
    const [imageStyle, setImageStyle] = useState({
        backgroundImage: `url(${imageSrc})`,
    });
    const [, setMarkedAsDelivered] = useState(null);
    const [shouldShowResolutionModal, setShouldShowResolutionModal] =
        useState(false);
    const [replyLetter, setReplyLetter] = useState(null);
    const [resolutionModalSize, setResolutionModalSize] = useState("xl");

    const hasCheckpoints = !!checkpoints.length;

    /**
     * useEffect
     **/

    useEffect(() => {
        scrollToTop();
        fetchPhoto();
        fetchTracking();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setImageStyle((prev) => ({
            ...prev,
            backgroundImage: `url(${imageSrc})`,
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageSrc]);

    useEffect(() => {
        const name =
            letterType === LetterType.SENT ? recipient.name : recipientName;
        const text =
            letterType === LetterType.SENT
                ? t("sent_letter.letter_to", {
                      ns: "components",
                      name,
                  })
                : t("sent_letter.sent_you_a_letter", {
                      ns: "components",
                      name,
                  });
        setHeaderBarContent({ backPathname: "/letters/sent", text });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [letterType]);

    /**
     * End Hooks
     **/

    /**
     * Fetches URI for photo attached to a sent letter
     */
    function fetchPhoto() {
        const { customPhotoArray } = letter;

        if (customPhotoArray?.length === 0) {
            setImageSrc(emptyImageSrc);
        } else {
            customPhotoArray?.forEach((photo) => {
                SandboxxRestAPI.getImageCloudfront(photo, (res) => {
                    setImageSrc((prev) => [...prev, res]);
                });
            });
        }
    }

    /**
     * Fetches tracking info for a particular letter
     */
    async function fetchTracking() {
        if (letterType === LetterType.SENT) {
            fetchTrackingSent();
            fetchTrackingReplied({ isStaging: true });
        } else if (letterType === LetterType.REPLIED) {
            fetchTrackingReplied({ isStaging: false });
        }
    }

    async function fetchTrackingReplied(config) {
        const { id, mailboxxOrderId } = letter;
        return await SandboxxRestAPI.getLetterRepliedTracking(
            { id: id || mailboxxOrderId },
            (res) => onFetchTrackingRepliedSuccess(res, config),
            onFetchTrackingRepliedError,
            onFetchTrackingRepliedError
        );
    }

    async function fetchTrackingSent() {
        const { id } = letter;
        return await SandboxxRestAPI.getLetterSentTrackingV2(
            { id },
            onFetchTrackingSentSuccess,
            onFetchTrackingSentError,
            onFetchTrackingSentError
        );
    }

    function onFetchTrackingRepliedError(err) {
        setLoading((prev) => ({
            ...prev,
            tracking: false,
        }));
    }

    function onFetchTrackingRepliedSuccess(res, { isStaging = false }) {
        const { checkpoints, markedAsDeliveredByUser } = res;
        if (isStaging) {
            setReplyLetter(res);
        } else {
            setCheckpoints(checkpoints);
            setMarkedAsDelivered(markedAsDeliveredByUser);
            setLoading((prevLoading) => ({
                ...prevLoading,
                tracking: false,
            }));
        }
    }

    function onFetchTrackingSentError() {
        setLoading((prev) => ({
            ...prev,
            tracking: false,
        }));
    }

    function onFetchTrackingSentSuccess(res) {
        const { checkpoints } = res;
        setCheckpoints(checkpoints);
        setLoading(false);
        setLoading((prev) => ({
            ...prev,
            tracking: false,
        }));
    }

    return {
        checkpoints,
        hasCheckpoints,
        imageSrc,
        imageStyle,
        loading,
        replyLetter,
        resolutionModalSize,
        setShouldShowResolutionModal,
        setResolutionModalSize,
        shouldShowResolutionModal,
    };
};
