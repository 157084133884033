// Fade.js

import React, { useEffect, useState } from "react";

export const Fade = ({ children, show, style }) => {
    const [shouldRender, setShouldRender] = useState(show);

    useEffect(() => {
        if (show) setShouldRender(true);
    }, [show]);

    const onAnimationEnd = () => {
        if (!show) setShouldRender(false);
    };

    return (
        shouldRender && (
            <div
                style={{
                    ...style,
                    animation: `${show ? "fadeIn" : "fadeOut"} 1s`,
                }}
                onAnimationEnd={onAnimationEnd}
            >
                {children}
            </div>
        )
    );
};
