import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "var(--pacific-blue-100)",
                    },
                },
            },
        },
    },
    typography: {
        fontFamily: "'Rubik', sans-serif",
    },
});
