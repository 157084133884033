// Components
import { MaterialIcon } from "components/MaterialIcon/MaterialIcon";

// CSS
import styles from "../sign-up-success.module.scss";

// Enums
import { MaterialIconMode } from "components/MaterialIcon/enums/MaterialIconMode";
import { PersonaEnum } from "enums/PersonaEnum";

// Localization
import { t } from "localization/localization";

export function generatePersonaContent({ selectedRootPersona }) {
    switch (selectedRootPersona?.id) {
        case PersonaEnum.RECRUIT:
            return {
                items: [
                    {
                        icon: (
                            <MaterialIcon
                                className={styles.icon}
                                mode={
                                    MaterialIconMode.MATERIAL_SYMBOLS_OUTLINED
                                }
                                name="inventory"
                            />
                        ),
                        subtitle: t(
                            "sign_up_success.recruit.item_prepare_subtitle",
                            { ns: "onboarding" }
                        ),
                        title: t("sign_up_success.recruit.item_prepare_title", {
                            ns: "onboarding",
                        }),
                    },
                ],
                subtitle: t("sign_up_success.recruit.subtitle", {
                    ns: "onboarding",
                }),
                title: t("sign_up_success.title", { ns: "onboarding" }),
            };
        case PersonaEnum.RECRUITER_COMMAND:
        case PersonaEnum.RECRUITER:
            return {
                items: [
                    {
                        icon: (
                            <MaterialIcon
                                className={styles.icon}
                                mode={
                                    MaterialIconMode.MATERIAL_SYMBOLS_OUTLINED
                                }
                                name="group"
                            />
                        ),
                        subtitle: t(
                            "sign_up_success.recruiter.item_manage_prospects_subtitle",
                            { ns: "onboarding" }
                        ),
                        title: t(
                            "sign_up_success.recruiter.item_manage_prospects_title",
                            { ns: "onboarding" }
                        ),
                    },
                    {
                        icon: (
                            <MaterialIcon
                                className={styles.icon}
                                mode={
                                    MaterialIconMode.MATERIAL_SYMBOLS_OUTLINED
                                }
                                name="newsmode"
                            />
                        ),
                        subtitle: t(
                            "sign_up_success.recruiter.item_get_content_subtitle",
                            { ns: "onboarding" }
                        ),
                        title: t(
                            "sign_up_success.recruiter.item_get_content_title",
                            { ns: "onboarding" }
                        ),
                    },
                ],
                subtitle: t("sign_up_success.recruiter.subtitle", {
                    ns: "onboarding",
                }),
                title: t("sign_up_success.title", { ns: "onboarding" }),
            };
        case PersonaEnum.LETTER_WRITER:
        default:
            return {
                items: [
                    {
                        icon: (
                            <MaterialIcon
                                className={styles.icon}
                                mode={
                                    MaterialIconMode.MATERIAL_SYMBOLS_OUTLINED
                                }
                                name="mail"
                            />
                        ),
                        subtitle: t(
                            "sign_up_success.letter_writer.item_write_letter_subtitle",
                            { ns: "onboarding" }
                        ),
                        title: t(
                            "sign_up_success.letter_writer.item_write_letter_title",
                            { ns: "onboarding" }
                        ),
                    },
                    {
                        icon: (
                            <MaterialIcon
                                className={styles.icon}
                                mode={
                                    MaterialIconMode.MATERIAL_SYMBOLS_OUTLINED
                                }
                                name="imagesmode"
                            />
                        ),
                        subtitle: t(
                            "sign_up_success.letter_writer.item_motivate_photos_subtitle",
                            { ns: "onboarding" }
                        ),
                        title: t(
                            "sign_up_success.letter_writer.item_motivate_photos_title",
                            { ns: "onboarding" }
                        ),
                    },
                    {
                        icon: (
                            <MaterialIcon
                                className={styles.icon}
                                mode={
                                    MaterialIconMode.MATERIAL_SYMBOLS_OUTLINED
                                }
                                name="featured_seasonal_and_gifts"
                            />
                        ),
                        subtitle: t(
                            "sign_up_success.letter_writer.item_add_ons_subtitle",
                            { ns: "onboarding" }
                        ),
                        title: t(
                            "sign_up_success.letter_writer.item_add_ons_title",
                            { ns: "onboarding" }
                        ),
                    },
                    {
                        icon: (
                            <MaterialIcon
                                className={styles.icon}
                                mode={
                                    MaterialIconMode.MATERIAL_SYMBOLS_OUTLINED
                                }
                                name="local_shipping"
                            />
                        ),
                        subtitle: t(
                            "sign_up_success.letter_writer.item_shipping_subtitle",
                            { ns: "onboarding" }
                        ),
                        title: t(
                            "sign_up_success.letter_writer.item_shipping_title",
                            { ns: "onboarding" }
                        ),
                    },
                ],
                subtitle: t("sign_up_success.letter_writer.subtitle", {
                    ns: "onboarding",
                }),
                title: t("sign_up_success.title", { ns: "onboarding" }),
            };
    }
}
