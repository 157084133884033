import React from "react";
import { Link } from "react-router-dom";
import cx from "classnames";

// Components
import { Text } from "components/Text/Text";

// CSS
// import styles from "../../navbar.module.scss";
import styles from "./nav-bar-item.module.scss";

// Hooks
import { useNavBarItem } from "./hooks/useNavBarItem";

export const NavBarItem = ({ data, history }) => {
    const {
        href,
        ImageSrc,
        ImageSrcSelected,
        isInternalLink,
        onClick,
        subnavigation,
        text,
        textStyle,
        unread,
    } = data;

    /**
     * Custom Hooks
     */

    const { isSelected, shouldShowSubnavigation } = useNavBarItem({
        data,
        history,
    });

    /**
     * End Hooks
     */

    function renderNavItemContent() {
        return (
            <div
                className={cx(styles.content, {
                    [styles["content--subnav"]]: subnavigation,
                    [styles["content--unread"]]: unread,
                })}
            >
                <div className={styles.label}>
                    {isSelected ? (
                        <ImageSrcSelected
                            className={styles.icon}
                            color="#2B536A"
                            height="24"
                            width="24"
                        />
                    ) : (
                        <ImageSrc
                            className={styles.icon}
                            color="#b3b3b3"
                            height="24"
                            width="24"
                        />
                    )}
                    <Text classes={cx(styles.text, textStyle)}>{text}</Text>
                </div>
            </div>
        );
    }

    function renderSubnavigation(data, showSubnav) {
        const currentScreen = history.location.pathname;
        return (
            showSubnav && (
                <div className={styles.subnavigation}>
                    {data.subnavigation.map((item, i) => {
                        const itemIsActive =
                            item.link !== "/"
                                ? currentScreen.indexOf(item.link) > -1
                                : currentScreen === "/";
                        return (
                            <Link
                                className={cx(styles.navBarItem, {
                                    [styles["navBarItem--selected"]]:
                                        itemIsActive,
                                    [styles["navBarItem--unread"]]: item.unread,
                                })}
                                data-active={itemIsActive}
                                data-cy={`nav-bar-subnavigation-${item.text}`}
                                to={item.link}
                                key={i}
                            >
                                {item.text}
                            </Link>
                        );
                    })}
                </div>
            )
        );
    }

    if (isInternalLink) {
        return (
            <div data-active={isSelected} data-cy={`nav-bar-item-${data.key}`}>
                <Link
                    className={cx(styles.navBarItem, {
                        [styles["navBarItem--selected"]]: isSelected,
                        [styles["navBarItem--subnav"]]: shouldShowSubnavigation,
                    })}
                    to={href}
                >
                    {renderNavItemContent()}
                </Link>
                {renderSubnavigation(data, shouldShowSubnavigation)}
            </div>
        );
    } else {
        return (
            <a
                {...{ onClick }}
                className={cx(styles.navBarItem, {
                    [styles["navBarItem--selected"]]: isSelected,
                })}
                href={href}
                rel="noreferrer"
                target="_blank"
            >
                {renderNavItemContent()}
            </a>
        );
    }
};
