import { useContext, useState } from "react";

// Context
import { NotificationBarContext } from "context/notificationBar";
import { SandboxxRestAPI } from "utils/sandboxx";

// Localization
import { useTranslation } from "localization/localization";

// Utils
import { CurrentUser } from "utils/sandboxx";

export const useSignInPasswordReset = () => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useState
     */

    const [form, setForm] = useState({
        confirmNewPassword: "",
        newPassword: "",
        password: "",
    });
    const [loading, setLoading] = useState(false);
    const [shouldShowSuccessModal, setShouldShowSuccessModal] = useState(false);

    /**
     * useContext
     */

    const { showNotification } = useContext(NotificationBarContext);

    /**
     * End Hooks
     */

    function isFormValid() {
        if (
            form?.password &&
            form?.newPassword &&
            form.newPassword === form.confirmNewPassword
        ) {
            return true;
        } else return false;
    }

    function onSubmit(e) {
        e.preventDefault();
        setLoading(true);
        if (form.newPassword.length < 8) {
            showNotification({
                text: t(
                    "sign_in_password_reset.password_character_requirement",
                    {
                        ns: "onboarding",
                    }
                ),
                type: "warning",
            });
        } else {
            SandboxxRestAPI.resetPassword(
                { ...form },
                onSubmitPasswordResetSuccess,
                onSubmitPasswordResetFailure,
                onSubmitPasswordResetFailure
            );
        }
    }

    function onSubmitPasswordResetFailure({ message }) {
        setLoading(false);
        showNotification({
            text: message,
            type: "warning",
        });
    }

    function onSubmitPasswordResetSuccess() {
        setLoading(false);
        setShouldShowSuccessModal(true);
        SandboxxRestAPI.getAccount((res) => {
            const { user, auth, onboarding, verification } = res;
            const token = CurrentUser.getUser().token;
            CurrentUser.storeUserSignIn(
                user,
                { ...auth, token: token },
                verification,
                onboarding
            );
        });
    }

    return {
        isFormValid,
        onSubmit,
        form,
        loading,
        setForm,
        setShouldShowSuccessModal,
        shouldShowSuccessModal,
    };
};
