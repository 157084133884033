import { useEffect, useState } from "react";

// Utils
import { SandboxxRestAPI } from "utils/sandboxx";

export const useDashboardNews = () => {
    /**
     * useState
     */

    const [isLoading, setIsLoading] = useState(false);
    const [newsItems, setNewsItems] = useState(null);

    /**
     * useEffect
     */

    useEffect(() => {
        fetchNewsItems();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * End Hooks
     */

    function fetchNewsItems() {
        setIsLoading(true);
        return SandboxxRestAPI.getNewsItems(
            { page: 1, pageSize: 10 },
            onFetchNewsItemsSuccess,
            onFetchNewsItemsFailure,
            onFetchNewsItemsFailure
        );
    }

    function onFetchNewsItemsFailure(err) {
        setIsLoading(false);
        setNewsItems([]);
    }

    function onFetchNewsItemsSuccess(res) {
        setIsLoading(false);
        setNewsItems(res);
    }

    return {
        isLoading,
        newsItems,
    };
};
