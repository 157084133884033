import React, { createContext, useState } from "react";

// Platforms
import { SandboxxRestAPI } from "../utils/sandboxx";

export const BundlesContext = createContext({});

export const BundlesProvider = (props) => {
    const { children } = props;

    /**
     * useState
     **/

    const [bundles, setBundles] = useState(null);

    /**
     * End Hooks
     **/

    function fetchBundles(recipient) {
        const { id, zipcode } = recipient || {};
        SandboxxRestAPI.getBundles(
            { recipientUserId: id || "", zipCode: zipcode || "" },
            onFetchBundlesSuccess
        );
    }

    function onFetchBundlesSuccess({ bundles }) {
        setBundles(bundles);
    }

    function resetBundlesContext() {
        setBundles(null);
    }

    const bundlesContext = { bundles, fetchBundles, resetBundlesContext };

    return (
        <BundlesContext.Provider value={bundlesContext}>
            {children}
        </BundlesContext.Provider>
    );
};
