// Constants
import { LETTER_CHARACTER_LIMIT } from "./constants";

// Enums
import { NotificationSeverity } from "enums/notifications/NotificationSeverity";

// Modals
import { Notification } from "models/notification/Notification";

/**
 * Contains all client-coded notifications that are not dependent on dynamic or back-end data
 */
export const Notifications = Object.freeze({
    letters: {
        overCharacterLimit: new Notification({
            id: "LETTERS_COMPOSE_OVER_CHARACTER_LIMIT",
            message: `You're quite the author! However, we can't send letters over ${LETTER_CHARACTER_LIMIT} characters. Please shorten your message so that it falls under the character limit.`,
            options: {
                isPersistent: true,
                severity: NotificationSeverity.ERROR,
                shouldHideCloseUI: true,
            },
        }),
    },
    supportSquad: {
        createSuccess: new Notification({
            id: "SUPPORT_SQUAD_CREATE_SUCCESS",
            message: "You successfully created a new Support Squad.",
            options: { severity: NotificationSeverity.SUCCESS },
        }),
        deleteSuccess: new Notification({
            id: "SUPPORT_SQUAD_DELETE_SUCCESS",
            message: "You successfully deleted your Support Squad.",
            options: { severity: NotificationSeverity.SUCCESS },
        }),
        updateSuccess: new Notification({
            id: "SUPPORT_SQUAD_UPDATE_SUCCESS",
            message: "You successfully updated your Squad details.",
            options: { severity: NotificationSeverity.SUCCESS },
        }),
    },
});
