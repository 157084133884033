import React from "react";
import PropTypes from "prop-types";
import TextFieldMUI from "@mui/material/TextField";
import { ThemeProvider } from "@mui/material/styles";

// Theme
import { theme } from "./theme";

export const Select = ({
    children,
    className,
    cypressTestId,
    helperText,
    id,
    label,
    name,
    onChange,
    required,
    selectProps,
    size,
    value,
}) => {
    return (
        <ThemeProvider theme={theme}>
            <TextFieldMUI
                className={className}
                helperText={helperText}
                id={id}
                inputProps={{ "data-cy": cypressTestId }}
                label={label}
                name={name}
                onChange={onChange}
                required={required}
                select
                SelectProps={selectProps}
                size={size}
                value={value}
            >
                {children}
            </TextFieldMUI>
        </ThemeProvider>
    );
};

Select.defaultProps = {
    className: "",
    helperText: "",
    selectProps: { native: true },
    size: "normal",
};

Select.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    cypressTestId: PropTypes.string,
    helperText: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    selectProps: PropTypes.object,
    size: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
