import { useContext, useEffect, useState } from "react";

// Context
import { ContactsContext } from "context/contacts";

// Enums
import { ContactModalNavigation } from "scenes/Contacts/ContactModal/enums/ContactModalNavigation";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Utils
import { emptyFunction } from "utils/miscUtils";
import { CurrentUser } from "utils/sandboxx";

export const useContactModalForm = ({ setCurrentSection, setHeaderText }) => {
    /**
     * useContext
     **/

    const {
        contactModal,
        onBackButtonClickCallback,
        setOnSubmitContactSuccessFinalCallback,
        toggleContactModal,
    } = useContext(ContactsContext);

    /**
     * useState
     **/

    const [selectedTitle, setSelectedTitle] = useState("");

    /**
     * useEffect
     **/

    useEffect(() => {
        setHeaderText(
            contactModal.type === "edit"
                ? "Edit Recipient"
                : "Who are you writing to?"
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * End Hooks
     **/

    function handleGoBack(e) {
        toggleContactModal({
            contactToEdit: {
                firstName: "",
                fullName: "",
                lastName: "",
                title: "",
                address: {
                    line1: "",
                    line2: "",
                    city: "",
                    state: "",
                    zipcode: "",
                },
            },
            shouldShow: false,
            targetScreen: null,
        });
        setOnSubmitContactSuccessFinalCallback(() => emptyFunction);
        onBackButtonClickCallback();
    }

    function handleGoToSelectBase() {
        AnalyticsLogger.logBaseSelect({ role: CurrentUser.getUser()?.role });
        setCurrentSection(ContactModalNavigation.ADDRESS_FORMATTER);
    }

    return {
        handleGoBack,
        handleGoToSelectBase,
        selectedTitle,
        setSelectedTitle,
    };
};
