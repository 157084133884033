// Utils
import { CurrentUser } from "../utils/sandboxx";
import { DEV } from "../utils/urlUtils";

function intercomAppId() {
    return DEV
        ? process.env.REACT_APP_INTERCOM_ID_DEV
        : process.env.REACT_APP_INTERCOM_ID_PROD;
}

export class Intercom {
    static boot() {
        if (CurrentUser.isLoggedIn()) {
            const { intercomHash, userId } = CurrentUser.getUser();
            window.Intercom("boot", {
                api_base: "https://api-iam.intercom.io",
                app_id: intercomAppId(),
                user_id: userId,
                user_hash: intercomHash,
            });
        } else {
            window.Intercom("boot", {
                app_id: intercomAppId(),
            });
        }
    }

    static hideWidget() {
        window.Intercom("update", { hide_default_launcher: true });
    }

    static registerUser() {
        const { branch, email, fullName, intercomHash, rank, role, userId } =
            CurrentUser.getUser();
        window.Intercom("update", {
            app_id: intercomAppId(),
            Branch: branch,
            email,
            name: fullName,
            Rank: rank,
            Role: role,
            user_id: userId,
            user_hash: intercomHash,
        });
    }

    static showNewMessage(content = "") {
        window.Intercom("showNewMessage", content);
    }

    static showWidget() {
        window.Intercom("update", { hide_default_launcher: false });
    }

    static shutdown() {
        window.Intercom("shutdown");
    }

    static trackEvent(eventName, metaData) {
        window.Intercom("trackEvent", eventName, metaData);
    }

    static update() {
        window.Intercom("update");
    }

    static updateUser(currentUser) {
        window.Intercom("update", {});
    }
}
