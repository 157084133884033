import React from "react";
import PropTypes from "prop-types";

// Components
import { ButtonPrimary } from "components/Buttons/Buttons";
import { CheckmarkSuccessIcon } from "components/Icons/CheckmarkSuccessIcon";
import { Modal } from "components/Modals/Modal";
import { Text } from "components/Text/Text";

// CSS
import styles from "./muster-checklist-success-modal.module.scss";

export const MusterChecklistSuccessModal = (props) => {
    const { setShouldShowSuccessModal, shouldShowSuccessModal } = props;

    function handleModalClose() {
        setShouldShowSuccessModal(false);
    }

    function renderBody() {
        return (
            <div className={styles.body}>
                <CheckmarkSuccessIcon height="45px" width="45px" />
                <div className={styles.textContainer}>
                    <div className={styles.header}>
                        <Text type="h2" isBold={true}>
                            Well done!
                        </Text>
                    </div>
                    <div className={styles.bodyText}>
                        <Text type="h4">
                            You successfully collected this document.
                        </Text>
                    </div>
                </div>
            </div>
        );
    }

    function renderButtons() {
        return (
            <div className={styles.buttons}>
                <ButtonPrimary
                    classes={styles.button}
                    cypressTestId="document-checklist-modal-continue-button"
                    onClick={handleModalClose}
                    text="Continue"
                    type="button"
                />
            </div>
        );
    }

    return (
        <Modal
            centered
            className={styles["muster-checklist-success-modal"]}
            dialogClassName={styles["muster-checklist-success-modal-dialog"]}
            onHide={handleModalClose}
            shouldHideHeader={true}
            headerSubText=""
            show={shouldShowSuccessModal}
            size="md"
        >
            <div className={styles.content}>
                {renderBody()}
                {renderButtons()}
            </div>
        </Modal>
    );
};

MusterChecklistSuccessModal.propTypes = {
    setShouldShowSuccessModal: PropTypes.func.isRequired,
    shouldShowSuccessModal: PropTypes.bool.isRequired,
};
