// Utils
import { put } from "utils/fetchUtils";
import { CurrentUser } from "utils/sandboxx";
import { DEV, DEVBASE, PRODBASE } from "utils/urlUtils";

// Constants
const v2Base = DEV ? `${DEVBASE}/v2` : `${PRODBASE}/v2`;

export class SandboxxV2API {
    static generateHeaders() {
        const headers = new Headers();
        const { token, userId } = CurrentUser.getUser();
        const authorization = `Basic ${btoa(`${userId}:${token}`)}`;
        headers.append("Accept-Encoding", "gzip");
        headers.append("Accept-Language", "en-US,en;q=0.9");
        headers.append("Authorization", authorization);
        headers.append("Content-Type", "application/json");
        return headers;
    }

    /** Image Upload **/

    static uploadImage({ imageFile, imagePath, onFailure, onSuccess }) {
        const url = `${v2Base}/media/i/${imagePath}`;
        const headers = SandboxxV2API.generateHeaders();
        headers.delete("Content-Type"); // Remove so that browser auto-generates content type with multi-file boundaries
        const body = new FormData();
        body.append(
            "file",
            new File([imageFile], imagePath, { type: "image/jpeg" })
        );
        return put(url, headers, body, onSuccess, onFailure, onFailure);
    }
}
