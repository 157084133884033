// Enums
import { NotificationAnchorOrigin } from "enums/notifications/NotificationAnchorOrigin";

/**
 * Letters
 */

export const LETTER_CHARACTER_LIMIT = 6000;
export const LETTER_LINE_BREAK_CHARACTER_WEIGHT = 44;

/**
 * Notifications
 */

export const NOTIFICATION_DEFAULT_ANCHOR_ORIGIN = Object.freeze({
    horizontal: NotificationAnchorOrigin.CENTER,
    vertical: NotificationAnchorOrigin.BOTTOM,
});
export const NOTIFICATION_DEFAULT_AUTO_HIDE_DURATION = 5000;
