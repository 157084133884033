/**
 * Generates a "pretty" ship date string based on an inputted ship date object
 *
 * @param {Object} shipDateObj ship date in object format
 * @return {String}
 */
export function generateShipDateText(shipDateObj) {
    const formattedShipDate = {
        day: shipDateObj.day,
        month: generatePrettyMonth(shipDateObj.month),
        year: shipDateObj.year,
    };
    return `${formattedShipDate.month} ${formattedShipDate.day}, ${formattedShipDate.year}`;
}

/**
 * Generates the "pretty" version of the month value
 *
 * @param {String} month month in integer format
 * @return {String}
 */
function generatePrettyMonth(month) {
    switch (month) {
        case "01":
            return "January";
        case "02":
            return "February";
        case "03":
            return "March";
        case "04":
            return "April";
        case "05":
            return "May";
        case "06":
            return "June";
        case "07":
            return "July";
        case "08":
            return "August";
        case "09":
            return "September";
        case "10":
            return "October";
        case "11":
            return "November";
        case "12":
            return "December";
        default:
            return month;
    }
}
