export function CalendarColorIcon({ className, height, width }) {
    return (
        <svg
            className={className}
            width={width}
            height={height}
            viewBox="0 0 31 32"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g
                id="Web-Dashboard"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g
                    id="WEB-Dashboard"
                    transform="translate(-320.000000, -504.000000)"
                >
                    <g
                        id="Group-13"
                        transform="translate(320.000000, 504.000000)"
                    >
                        <path
                            d="M28.2778065,9.14908263 L1.66261291,9.14908263 L1.66261291,6.65303181 C1.66261291,5.27730583 2.78106804,4.15847675 4.15716796,4.15847675 L6.65172302,4.15847675 L6.65172302,5.82251063 L8.31725266,5.82251063 L8.31725266,4.15847675 L21.6246625,4.15847675 L21.6246625,5.82251063 L23.2886964,5.82251063 L23.2886964,4.15847675 L25.7832514,4.15847675 C27.1589774,4.15847675 28.2778065,5.27730583 28.2778065,6.65303181 L28.2778065,9.14908263 Z M28.2778065,26.6139596 C28.2778065,27.9893116 27.1589774,29.1081407 25.7832514,29.1081407 L4.15866372,29.1081407 C2.7825638,29.1081407 1.66410867,27.9893116 1.66410867,26.6139596 L1.66410867,10.8116207 L28.2778065,10.8116207 L28.2778065,26.6139596 Z M25.7813817,2.49444288 L23.2868266,2.49444288 L23.2868266,-0.000112182059 L21.6231667,-0.000112182059 L21.6231667,2.49444288 L8.31725266,2.49444288 L8.31725266,-0.000112182059 L6.65284484,-0.000112182059 L6.65284484,2.49444288 L4.15866372,2.49444288 C1.86491455,2.49444288 7.47880395e-05,4.35965658 7.47880395e-05,6.65303181 L7.47880395e-05,26.6139596 C7.47880395e-05,28.9069608 1.86491455,30.7721746 4.15866372,30.7721746 L25.7813817,30.7721746 C28.0751309,30.7721746 29.9399706,28.9069608 29.9399706,26.6139596 L29.9399706,10.8116207 L29.9399706,9.14908263 L29.9399706,6.65303181 C29.9399706,4.36115234 28.0751309,2.49444288 25.7813817,2.49444288 L25.7813817,2.49444288 Z"
                            id="Fill-1"
                            fill="#212121"
                        ></path>
                        <path
                            d="M13.3059514,14.138118 L16.6325234,14.138118 L16.6340192,17.46469 L13.3059514,17.46469 L13.3059514,14.138118 Z M13.3059514,19.1287238 L16.6325234,19.1287238 C17.5505466,19.1287238 18.2965573,18.3823392 18.2965573,17.46469 L18.2965573,14.138118 C18.2965573,13.2204687 17.5505466,12.4740841 16.6325234,12.4740841 L13.3059514,12.4740841 C12.3883022,12.4740841 11.6419176,13.2204687 11.6419176,14.138118 L11.6419176,17.46469 C11.6434133,18.3823392 12.389798,19.1287238 13.3059514,19.1287238 L13.3059514,19.1287238 Z"
                            id="Fill-3"
                            fill="#FF5522"
                        ></path>
                        <path
                            d="M21.6232041,14.138118 L24.9497761,14.138118 L24.9508979,17.46469 L21.6232041,17.46469 L21.6232041,14.138118 Z M21.6232041,19.1287238 L24.9497761,19.1287238 C25.8674253,19.1287238 26.61381,18.3823392 26.61381,17.46469 L26.61381,14.138118 C26.61381,13.2204687 25.8674253,12.4740841 24.9497761,12.4740841 L21.6232041,12.4740841 C20.7055549,12.4740841 19.9591702,13.2204687 19.9591702,14.138118 L19.9591702,17.46469 C19.960666,18.3823392 20.7070506,19.1287238 21.6232041,19.1287238 L21.6232041,19.1287238 Z"
                            id="Fill-5"
                            fill="#212121"
                        ></path>
                        <path
                            d="M4.99053109,22.4551836 L8.31710309,22.4551836 L8.31859885,25.7817556 L4.99053109,25.7817556 L4.99053109,22.4551836 Z M4.99053109,27.4457895 L8.31710309,27.4457895 C9.23475233,27.4457895 9.98113696,26.6994049 9.98113696,25.7817556 L9.98113696,22.4551836 C9.98113696,21.5375344 9.23475233,20.7911498 8.31710309,20.7911498 L4.99053109,20.7911498 C4.07288184,20.7911498 3.32649721,21.5375344 3.32649721,22.4551836 L3.32649721,25.7817556 C3.32649721,26.6994049 4.07288184,27.4457895 4.99053109,27.4457895 L4.99053109,27.4457895 Z"
                            id="Fill-7"
                            fill="#FF5522"
                        ></path>
                        <path
                            d="M13.3059514,22.4551836 L16.6325234,22.4551836 L16.6340192,25.7817556 L13.3059514,25.7817556 L13.3059514,22.4551836 Z M13.3059514,27.4457895 L16.6325234,27.4457895 C17.5505466,27.4457895 18.2965573,26.6994049 18.2965573,25.7817556 L18.2965573,22.4551836 C18.2965573,21.5375344 17.5505466,20.7911498 16.6325234,20.7911498 L13.3059514,20.7911498 C12.3883022,20.7911498 11.6419176,21.5375344 11.6419176,22.4551836 L11.6419176,25.7817556 C11.6434133,26.6994049 12.389798,27.4457895 13.3059514,27.4457895 L13.3059514,27.4457895 Z"
                            id="Fill-9"
                            fill="#212121"
                        ></path>
                        <path
                            d="M21.6232041,22.4551836 L24.9497761,22.4551836 L24.9508979,25.7817556 L21.6232041,25.7817556 L21.6232041,22.4551836 Z M21.6232041,27.4457895 L24.9497761,27.4457895 C25.8674253,27.4457895 26.61381,26.6994049 26.61381,25.7817556 L26.61381,22.4551836 C26.61381,21.5375344 25.8674253,20.7911498 24.9497761,20.7911498 L21.6232041,20.7911498 C20.7055549,20.7911498 19.9591702,21.5375344 19.9591702,22.4551836 L19.9591702,25.7817556 C19.960666,26.6994049 20.7070506,27.4457895 21.6232041,27.4457895 L21.6232041,27.4457895 Z"
                            id="Fill-11"
                            fill="#FF5522"
                        ></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}

CalendarColorIcon.defaultProps = {
    className: "",
    height: "32px",
    width: "31px",
};
