import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// Components
import { CartPaymentMethodSelect } from "./components/CartPaymentMethodSelect/CartPaymentMethodSelect";
import { Text } from "components/Text/Text";

// CSS
import styles from "./cart-payment-method.module.scss";

// Enums
import { CartNavigation } from "components/Cart/enums/CartNavigation";

// Localization
import { useTranslation } from "localization/localization";

export const CartPaymentMethod = ({
    handleSelectPaymentMethod,
    isSubmittingPaymentMethod,
    selectedPaymentMethod,
    setCartCurrentSection,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * End Hooks
     */

    return (
        <div className={styles.cartPaymentMethod}>
            <div className={styles.header}>
                <Text>{t("cart.payment_method", { ns: "components" })}</Text>
            </div>
            <CartPaymentMethodSelect
                handleSelectPaymentMethod={handleSelectPaymentMethod}
                isSubmittingPaymentMethod={isSubmittingPaymentMethod}
                selectedPaymentMethod={selectedPaymentMethod}
            />
            <Text
                classes={cx(styles.link, "link")}
                onClick={() =>
                    setCartCurrentSection(CartNavigation.PAYMENT_METHOD_FORM)
                }
            >
                {t("cart.add_new_payment_method", { ns: "components" })}
            </Text>
        </div>
    );
};

CartPaymentMethod.propTypes = {
    handleSelectPaymentMethod: PropTypes.func,
    isSubmittingPaymentMethod: PropTypes.bool,
    selectedPaymentMethod: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    setCartCurrentSection: PropTypes.func,
};
