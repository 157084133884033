import React, { useContext, useEffect } from "react";

// Components
import { HeaderBar } from "components/HeaderBar/HeaderBar";
import { LoadingPage } from "components/Loading/Loading";
import { MusterHeaderBarContent } from "../MusterHeaderBarContent/MusterHeaderBarContent";

// Context
import { HeaderBarContext } from "context/headerBar";
import { MusterContext } from "context/muster";

// CSS
import styles from "./muster-article.module.scss";

// Hooks
import { useDocumentTitle } from "@uidotdev/usehooks";

export const MusterArticle = (props) => {
    const { history } = props;

    /**
     * Custom Hooks
     */

    useDocumentTitle("Sandboxx - Waypoints Overview");

    /**
     * useContext
     */

    const { setHeaderBarContent } = useContext(HeaderBarContext);
    const { selectedMusterArticle } = useContext(MusterContext);

    /**
     * Constants
     */

    const { featuredImageUrl, plainHtml } = selectedMusterArticle;
    const imageStyle = { backgroundImage: `url(${featuredImageUrl})` };

    /**
     * useEffect
     */

    useEffect(() => {
        if (!selectedMusterArticle.plainHtml) {
            history.push("/waypoints");
        }

        // State calls
        setHeaderBarContent({
            backPathname: "",
            onBackClick: () => history.push("/waypoints"),
            text: "DEP Intel",
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * End Hooks & Constants
     */

    return (
        <div className="page">
            <HeaderBar>
                <MusterHeaderBarContent />
            </HeaderBar>
            {selectedMusterArticle.plainHtml ? (
                <div className={styles.musterArticle}>
                    <div className={styles.image} style={imageStyle}></div>
                    <div
                        className={styles.article}
                        dangerouslySetInnerHTML={{
                            __html: plainHtml,
                        }}
                    />
                </div>
            ) : (
                <LoadingPage />
            )}
        </div>
    );
};
