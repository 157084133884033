export class LettersValidation {
    static validateCredits({ credits, recipient, supportSquad }) {
        if (recipient.isSquadRecipient) {
            return supportSquad.tokenCount > 0;
        } else {
            return credits > 0;
        }
    }

    /**
     * * Validates presence of message and recipient when transitioning from compose screen to review
     *
     * @param {Object} message
     * @param {Object} recipient
     * @param {Object} config
     *
     * TODO: Add validation for image file, when applicable
     * TODO: Remove showNotification and any UI indicators
     */
    static validateInputs(
        message,
        recipient,
        { showNotification, shouldShowNotification }
    ) {
        if (LettersValidation.validateMessage(message)) {
            return true;
        } else {
            if (shouldShowNotification) {
                let text = null;
                if (!recipient.firstName && !recipient.lastName) {
                    text =
                        "Please select an existing recipient or add a new recipient.";
                } else if (!message.content) {
                    text = "Please include a message with your letter.";
                } else if (recipient.firstName && !recipient.address.line1) {
                    text =
                        "The selected recipient does not have an address. Please click the 'Add A New Recipient' link to create a new recipient with a valid address.";
                }
                showNotification({ text, type: "warning" });
            }
            return false;
        }
    }

    static validateMessage(message) {
        return Object.keys(message).reduce((acc, key) => {
            const isPresent = !!message[key];
            switch (key) {
                case "content":
                    return isPresent ? acc : false;
                case "imageFile":
                    // If user is uploading image...
                    if (isPresent) {
                        // Check to see if we receive the URL from API and was uploaded successfully to Amazon
                        const { imageUploadUrl, imageUploadSuccess } = message;
                        return imageUploadUrl && imageUploadSuccess
                            ? acc
                            : false;
                    } else {
                        // Otherwise, don't worry about validating image
                        return acc;
                    }
                default:
                    return acc;
            }
        }, true);
    }

    /**
     * TODO: Remove showNotification and any UI indicators
     */
    static validateRecipientInitial(
        recipient,
        { showNotification, shouldShowNotification }
    ) {
        const isValidated = Object.keys(recipient).reduce((acc, key) => {
            const isPresent = !!recipient[key];
            switch (key) {
                case "firstName":
                case "lastName":
                    return isPresent ? acc : false;
                default:
                    return acc;
            }
        }, true);
        !isValidated &&
            shouldShowNotification &&
            showNotification({
                text: "Please either select a previous recipient or click 'Add New Recipient' to create a new one.",
                type: "warning",
            });
        return isValidated;
    }

    static validateRecipientExistence(recipient) {
        return recipient !== undefined || recipient !== "undefined";
    }

    static validateRecipientSenderFull(person) {
        // Recipient or sender is not valid if they do not have an address
        if (!person.address?.line1) {
            return;
        }
        // If address is present, continue to validate other fields
        return Object.keys(person).reduce((acc, key) => {
            switch (key) {
                case "address":
                    const { city, line1, state, zipcode } = person.address;
                    return !!(city && line1 && state && zipcode);
                case "firstName":
                case "lastName":
                    const isNameValuePresent = !!person[key];
                    return isNameValuePresent ? acc : false;
                default:
                    return acc;
            }
        }, true);
    }
}
