import React, { useEffect, useState } from "react";
import cx from "classnames";

// CSS
import styles from "css/letters/components/letters-add-ons-panel.module.scss";

// Localization
import { useTranslation } from "localization/localization";

// Platforms
import { SandboxxRestAPI } from "utils/sandboxx";

// Utils
import { handleKeyDown } from "utils/eventUtils";

// Enums
import { AddOnsNavigation } from "../enums/enums";

export const LettersComposeReviewAddOnsPanelItem = (props) => {
    const {
        addOn,
        giftCard,
        handleAddOnsModalToggle,
        handleRemoveAddOn,
        handleGiftCardClear,
        isGiftCard,
    } = props;
    const { imageUrl, lineItemDescription, productDetails } = addOn || {};
    const { amount, selected } = giftCard || {};
    const { photoUrlFull } = selected || {};

    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * Constants
     */

    const displayText = {
        description: isGiftCard ? selected.provider : lineItemDescription,
        price: isGiftCard ? `${amount.total}.00` : productDetails.price,
        title: isGiftCard
            ? t("gift_card_ip_singular", { ns: "common" })
            : addOn.title,
    };
    const onClickRemove = isGiftCard
        ? handleGiftCardClear
        : () => handleRemoveAddOn(addOn);

    /**
     * useState
     */

    const [imageSrc, setImageSrc] = useState(null);

    /**
     * useEffect
     */

    useEffect(() => {
        (imageUrl || photoUrlFull) && fetchLetterImage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * End Hooks
     */

    function fetchLetterImage() {
        if (isGiftCard) {
            setImageSrc(photoUrlFull);
        } else {
            SandboxxRestAPI.getImageCloudfront(imageUrl, (res) =>
                setImageSrc(res)
            );
        }
    }

    function handleGiftCardEditToggle() {
        handleAddOnsModalToggle(true, {
            section: AddOnsNavigation.SELECT_DESIGN,
        });
    }

    return (
        <div className={styles["letters-add-ons-panel-list-item"]}>
            <div className={styles.imageWrapper}>
                <img
                    alt={displayText.title}
                    className={styles.image}
                    src={imageSrc}
                />
            </div>
            <div className={styles.info}>
                <div className={styles.title}>
                    <span
                        className={styles.text}
                        data-cy="letters-compose-review-add-ons-panel-item-gift-card-title"
                    >
                        {displayText.title}
                    </span>
                    {isGiftCard && (
                        <span
                            className={cx(styles.link, "link")}
                            onClick={handleGiftCardEditToggle}
                        >
                            {t("edit", { ns: "common" })}
                        </span>
                    )}
                </div>
                <div className={styles.description}>
                    <span className={styles.text}>
                        {isGiftCard ? "Amount" : displayText.description}
                    </span>
                    <span className={styles.price}>
                        $
                        {isGiftCard
                            ? `${giftCard.amount.base}.00`
                            : displayText.price}
                    </span>
                </div>
                {isGiftCard && (
                    <div className={styles.description}>
                        <span className={styles.text}>Fee</span>
                        <span className={styles.price}>
                            ${giftCard.amount.fee}.00
                        </span>
                    </div>
                )}
                <span
                    className={cx(styles.remove, "link")}
                    data-cy="letters-compose-review-add-ons-panel-item-remove"
                    onClick={onClickRemove}
                    onKeyDown={(e) => {
                        handleKeyDown(e, onClickRemove);
                    }}
                    tabIndex="0"
                >
                    {t("remove", { ns: "common" })}
                </span>
            </div>
        </div>
    );
};
