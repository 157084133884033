import React from "react";
import cx from "classnames";

// Components
import { ButtonPrimary } from "components/Buttons/Buttons";
import { SignInPasswordResetSuccessModal } from "./components/SignInPasswordResetSuccessModal";
import { Text } from "components/Text/Text";

// CSS
import styles from "./sign-in-password-reset.module.scss";

// Hooks
import { useSignInPasswordReset } from "./hooks/useSignInPasswordReset";

// Localization
import { useTranslation } from "localization/localization";

// Media
import LockIcon from "media/signin/lock-icon.png";

export const SignInPasswordReset = ({ handleGoToOnboarding, history }) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    const {
        isFormValid,
        loading,
        onSubmit,
        form,
        setForm,
        setShouldShowSuccessModal,
        shouldShowSuccessModal,
    } = useSignInPasswordReset({ handleGoToOnboarding });

    /**
     * End Hooks
     */

    function handleChange({ target }) {
        const { name, value } = target;
        setForm((prev) => ({
            ...prev,
            [name]: value,
        }));
    }

    return (
        <div className={styles.signInPasswordReset}>
            <SignInPasswordResetSuccessModal
                history={history}
                setShouldShowSuccessModal={setShouldShowSuccessModal}
                shouldShowSuccessModal={shouldShowSuccessModal}
            />
            <div className={styles.header}>
                <img alt="Lock" className={styles.icon} src={LockIcon} />
                <Text classes={styles.title} type="h1">
                    {t("sign_in_password_reset.title", { ns: "onboarding" })}
                </Text>
                <Text classes={styles.subtext} type="p">
                    {t("sign_in_password_reset.subtitle", { ns: "onboarding" })}
                </Text>
            </div>
            <form className={cx(styles.form)}>
                <input
                    aria-label={t("sign_in_password_reset.current_password", {
                        ns: "onboarding",
                    })}
                    className={styles.input}
                    data-cy="password-reset-current-password-input"
                    name="password"
                    placeholder={t("sign_in_password_reset.current_password", {
                        ns: "onboarding",
                    })}
                    onChange={handleChange}
                    type="password"
                    value={form.password}
                />
                <input
                    aria-label={t("sign_in_password_reset.new_password", {
                        ns: "onboarding",
                    })}
                    className={styles.input}
                    data-cy="password-reset-new-password-input"
                    name="newPassword"
                    placeholder={t("sign_in_password_reset.new_password", {
                        ns: "onboarding",
                    })}
                    onChange={handleChange}
                    type="password"
                    value={form.newPassword}
                />
                <input
                    aria-label={t(
                        "sign_in_password_reset.confirm_new_password",
                        {
                            ns: "onboarding",
                        }
                    )}
                    className={styles.input}
                    data-cy="password-reset-confirm-password-input"
                    name="confirmNewPassword"
                    placeholder={t(
                        "sign_in_password_reset.confirm_new_password",
                        {
                            ns: "onboarding",
                        }
                    )}
                    onChange={handleChange}
                    type="password"
                    value={form.confirmNewPassword}
                />
                <Text classes={styles.inputSubtext} type="small">
                    {t(
                        "sign_in_password_reset.password_character_requirement",
                        {
                            ns: "onboarding",
                        }
                    )}
                </Text>
                <ButtonPrimary
                    classes={styles.submit}
                    cypressTestId="sign-in-main-form-email-submit-button"
                    isDisabled={!isFormValid()}
                    isLoading={loading}
                    onClick={onSubmit}
                    type="submit"
                >
                    <Text>
                        {t("sign_in_password_reset.save_password", {
                            ns: "onboarding",
                        })}
                    </Text>
                </ButtonPrimary>
            </form>
        </div>
    );
};
