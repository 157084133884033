import { useState, useEffect, useRef } from "react";

const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
};

export function useVisibleDimensions() {
    const [dimensions, setDimensions] = useState({
        visibleWidth: 0,
        visibleHeight: 0,
    });
    const elementRef = useRef(null);

    useEffect(() => {
        const handleResize = debounce(() => {
            if (elementRef.current) {
                const rect = elementRef.current.getBoundingClientRect();
                const visibleWidth = Math.min(
                    rect.width,
                    window.innerWidth - Math.max(rect.left, 0)
                );
                const visibleHeight = Math.min(
                    rect.height,
                    window.innerHeight - Math.max(rect.top, 0)
                );

                setDimensions({
                    visibleWidth: Math.max(0, visibleWidth),
                    visibleHeight: Math.max(0, visibleHeight),
                });
            }
        }, 100); // Debounce for 100 milliseconds

        // Set initial dimensions
        handleResize();

        // Setup resize event listener
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return [elementRef, dimensions];
}
