import { useState } from "react";

// Localization
import { useTranslation } from "localization/localization";

// Validation
import { Validation } from "validation/validation";

// Utils
import { SandboxxRestAPI } from "utils/sandboxx";
import { generateAttributes } from "../utils/attributeUtils";

// Constants
import { NavigationEnum } from "../../../../constants/constants";

export const useSignUpName = ({
    handleUpdateName,
    isUsingEmail,
    isUsingEmailVerify,
    isUsingPhone,
    needsNameForSSO,
    setCurrentSection,
    setHasCompletedSignUpName,
    setShouldShowEmailInUseModal,
    showNotification,
    storeUserAfterUpdate,
    userInfo,
}) => {
    const { email, firstName, lastName, password } = userInfo;

    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useState
     */

    const [isLoadingDetailsUpdate, setIsLoadingDetailsUpdate] = useState(false);

    /**
     * Constants
     */

    const hasValidEmail = Validation.isEmailAddress(email);
    const hasValidFirstName = Validation.isPresent(firstName);
    const hasValidLastName = Validation.isPresent(lastName);
    const hasValidPassword = Validation.isAtLeastOfLength(password, 6);
    const hasValidForm =
        hasValidFirstName &&
        hasValidLastName &&
        (needsNameForSSO ||
            (hasValidEmail && hasValidPassword) ||
            (isUsingPhone && email.length === 0));
    const isUsingOnlyNameFields =
        (isUsingEmail && !isUsingEmailVerify) ||
        needsNameForSSO ||
        (isUsingPhone && email.length === 0);

    /**
     * End Hooks & Constants
     */

    function handleInfoUpdate() {
        setIsLoadingDetailsUpdate(true);
        const attributesPayload = generateAttributes(userInfo, {
            isUsingEmailVerify,
            isUsingOnlyNameFields,
        });
        SandboxxRestAPI.phoneSignUpUpdateAttributes(
            attributesPayload,
            updateAttributeSuccess,
            updateAttributesFailure,
            updateAttributesFailure
        );
    }

    function handleSubmit(e) {
        e && e.preventDefault();
        if (hasValidForm) {
            if (isUsingEmailVerify) {
                handleInfoUpdate();
            } else {
                needsNameForSSO ? handleUpdateName() : handleInfoUpdate();
            }
            localStorage.setItem("hasCompletedSignUpName", true);
            setHasCompletedSignUpName(true);
        } else {
            showFailedValidationNotification();
        }
    }

    function showFailedValidationNotification() {
        if (!hasValidFirstName || !hasValidLastName) {
            showNotification({
                text: t("sign_up_name.name_missing_error", {
                    ns: "onboarding",
                }),
                type: "warning",
            });
        } else if (!hasValidEmail) {
            showNotification({
                text: t("sign_up_name.email_missing_error", {
                    ns: "onboarding",
                }),
                type: "warning",
            });
        } else if (!hasValidPassword) {
            showNotification({
                text: t("sign_up_name.password_missing_error", {
                    ns: "onboarding",
                }),
                type: "warning",
            });
        }
    }

    function updateAttributesFailure({ message }) {
        setIsLoadingDetailsUpdate(false);
        if (
            message.includes(
                t("sign_up_name.email_in_use_backend_error", {
                    ns: "onboarding",
                })
            )
        ) {
            setShouldShowEmailInUseModal(true);
        } else {
            showNotification({
                text: message,
                type: "warning",
            });
        }
    }

    function updateAttributeSuccess() {
        setCurrentSection(NavigationEnum.SIGN_UP_PHONE);
        storeUserAfterUpdate();
        setIsLoadingDetailsUpdate(false);
    }

    return {
        handleSubmit,
        handleInfoUpdate,
        hasValidEmail,
        hasValidFirstName,
        hasValidForm,
        hasValidLastName,
        hasValidPassword,
        isLoadingDetailsUpdate,
    };
};
