export function InfoIcon({ className, color, height, width }) {
    return (
        <svg
            className={className}
            width={width}
            height={height}
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.7493 15.2872C11.2441 15.2872 11.6452 14.8861 11.6452 14.3914V9.85622C11.6452 9.36147 11.2441 8.96039 10.7493 8.96039C10.2546 8.96039 9.85352 9.36147 9.85352 9.85622V14.3914C9.85352 14.8861 10.2546 15.2872 10.7493 15.2872Z"
                fill={color}
            />
            <path
                d="M10.7494 8.22804C11.3036 8.22804 11.7528 7.77883 11.7528 7.22471C11.7528 6.67058 11.3036 6.22137 10.7494 6.22137C10.1953 6.22137 9.74609 6.67058 9.74609 7.22471C9.74609 7.77883 10.1953 8.22804 10.7494 8.22804Z"
                fill={color}
            />
            <path
                d="M10.7493 19.7664C15.6969 19.7664 19.7077 15.7556 19.7077 10.8081C19.7077 5.86051 15.6969 1.84973 10.7493 1.84973C5.8018 1.84973 1.79102 5.86051 1.79102 10.8081C1.79102 13.184 2.73484 15.4625 4.41485 17.1426C6.09486 18.8226 8.37345 19.7664 10.7493 19.7664ZM10.7493 3.6414C14.7074 3.6414 17.916 6.85002 17.916 10.8081C17.916 14.7661 14.7074 17.9747 10.7493 17.9747C6.79131 17.9747 3.58268 14.7661 3.58268 10.8081C3.58268 6.85002 6.79131 3.6414 10.7493 3.6414Z"
                fill={color}
            />
        </svg>
    );
}

InfoIcon.defaultProps = {
    className: "",
    color: "#2A526A",
    height: "22px",
    width: "22px",
};
