import React from "react";
import PropTypes from "prop-types";

// Components
import { UserList } from "components/UserList/UserList";

// CSS
import styles from "./support-squad-settings-modal-members.module.scss";

// Hooks
import { useSupportSquadSettingsModalMembers } from "./hooks/useSupportSquadSettingsModalMembers";

// Utils
import { generateFormattedSupportSquadMembersListData } from "./utils/utils";

export const SupportSquadSettingsModalMembers = ({
    setHeaderSubText,
    setHeaderText,
    squadAdmins,
    squadMembers,
}) => {
    /**
     * Custom Hooks
     */

    useSupportSquadSettingsModalMembers({ setHeaderSubText, setHeaderText });

    /**
     * End Hooks
     */

    return (
        <div className={styles.supportSquadSettingsModalMembers}>
            <UserList
                classNames={styles.userList}
                isClickable={false}
                itemsClassNames={styles.items}
                userList={generateFormattedSupportSquadMembersListData(
                    squadAdmins
                )}
            />
            <UserList
                classNames={styles.userList}
                isClickable={false}
                itemsClassNames={styles.items}
                userList={generateFormattedSupportSquadMembersListData(
                    squadMembers
                )}
            />
        </div>
    );
};

SupportSquadSettingsModalMembers.defaultProps = {
    squadAdmins: [],
    squadMembers: [],
};

SupportSquadSettingsModalMembers.propTypes = {
    setHeaderSubText: PropTypes.func.isRequired,
    setHeaderText: PropTypes.func.isRequired,
    squadAdmins: PropTypes.array,
    squadMembers: PropTypes.array,
};
