import React from "react";
import cx from "classnames";

// Components
import { ButtonInvisible, ButtonPrimary } from "../../Buttons/Buttons";
import { Modal } from "components/Modals/Modal";

// CSS
import styles from "./modal-prompt.module.scss";

export const ModalPrompt = (props) => {
    const {
        bodyText,
        buttonInvisible,
        buttonPrimary,
        classes,
        cypressTestId,
        headerText,
        icon,
        onHide,
        show,
    } = props;

    return (
        <Modal
            centered="true"
            dialogClassName={styles["modal-prompt"]}
            onHide={onHide}
            shouldHideHeader={true}
            show={show}
            size="small"
        >
            <div
                className={cx(styles.content, classes)}
                cypressTestId={cypressTestId}
            >
                {icon && <div className={styles.icon}>{icon}</div>}
                {headerText && (
                    <div className={styles.header}>
                        <span>{headerText}</span>
                    </div>
                )}
                {bodyText && (
                    <div className={styles.text}>
                        <span>{bodyText}</span>
                    </div>
                )}
                <div className={styles.buttons}>
                    <ButtonPrimary
                        classes={styles.button}
                        cypressTestId={buttonPrimary.cypressTestId}
                        isDisabled={buttonPrimary.isDisabled}
                        onClick={buttonPrimary.onClick}
                        text={buttonPrimary.text}
                        type="button"
                    />
                    <ButtonInvisible
                        classes={styles.button}
                        onClick={buttonInvisible.onClick}
                        text={buttonInvisible.text}
                        type="button"
                    />
                </div>
            </div>
        </Modal>
    );
};
