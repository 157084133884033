import { useState } from "react";

// Enums
import { CartNavigation } from "../enums/CartNavigation";

// Localization
import { useTranslation } from "localization/localization";

export const useCartNavigation = () => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useState
     */

    const [currentSection, setCurrentSection] = useState(CartNavigation.MAIN);
    const [headerText, setHeaderText] = useState(
        t("checkout", { ns: "common" })
    );

    /**
     * End Hooks
     */

    function resetCartNavigation() {
        setCurrentSection(() => CartNavigation.MAIN);
        setHeaderText(() => t("checkout", { ns: "common" }));
    }

    return {
        currentSection,
        headerText,
        resetCartNavigation,
        setCurrentSection,
        setHeaderText,
    };
};
