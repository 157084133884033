export function moveCaretToEnd(textAreaRef) {
    if (typeof textAreaRef.current.selectionStart === "number") {
        textAreaRef.current.selectionStart = textAreaRef.current.selectionEnd =
            textAreaRef.current.value.length;
    } else if (typeof textAreaRef.current.createTextRange !== "undefined") {
        textAreaRef.current.focus();
        const range = textAreaRef.current.createTextRange();
        range.collapse(false);
        range.select();
    }
}
