import { useState } from "react";

export const useQuantitySelector = ({
    initialQuantity,
    maximumQuantity,
    minimumQuantity,
    onChange,
    onChangeFailure,
    onChangeSuccess,
}) => {
    /**
     * useState
     */

    const [quantity, setQuantity] = useState(initialQuantity);

    /**
     * Constants
     */

    const canDecrement = quantity > minimumQuantity;
    const canIncrement = !maximumQuantity || quantity < maximumQuantity;

    /**
     * End Hooks & Constants
     */

    const handleDecrement = () => {
        if (quantity > minimumQuantity) {
            const newQuantity = quantity - 1;
            setQuantity(newQuantity);
            onChange({ quantity: newQuantity, onFailure: onChangeFailure });
        }
    };

    const handleIncrement = () => {
        if (!maximumQuantity || quantity < maximumQuantity) {
            const newQuantity = quantity + 1;
            setQuantity(newQuantity);
            onChange({ quantity: newQuantity, onSuccess: onChangeSuccess });
        }
    };

    return {
        canDecrement,
        canIncrement,
        handleDecrement,
        handleIncrement,
        quantity,
    };
};
