import React, { useEffect } from "react";
import cx from "classnames";

// Components
import { ButtonPrimary } from "components/Buttons/Buttons";
import { PasswordInput } from "components/Inputs/PasswordInput/PasswordInput";
import { Text } from "components/Text/Text";
import { TextFieldValidated } from "components/Inputs/TextFieldValidated/TextFieldValidated";

// CSS
import signInStyles from "../../../../../sign-in.module.scss";
import signInMainStyles from "../../sign-in-main.module.scss";

// Enums
import { SignInPlatform } from "../../constants/constants";

// Localization
import { useTranslation } from "localization/localization";

export const SignInMainLoginForm = ({
    handleChangeEmail,
    handleChangePassword,
    handleGoToForgotPassword,
    handleLogin,
    hasValidEmail,
    hasValidPassword,
    setSignInPlatform,
    signInLoading,
    signInPlatform,
    userInfo,
}) => {
    const { email, password } = userInfo;

    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useEffect
     */

    useEffect(() => {
        setSignInPlatform(SignInPlatform.EMAIL_VERIFY);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * End Hooks
     */

    if (signInPlatform === SignInPlatform.EMAIL) {
        return (
            <form className={cx(signInStyles.form, signInMainStyles.form)}>
                <TextFieldValidated
                    className={signInMainStyles.input}
                    cypressTestId="sign-in-name-email-input"
                    isValid={hasValidEmail}
                    id="email"
                    label={t("email", { ns: "common" })}
                    name="email"
                    onChange={handleChangeEmail}
                    value={email}
                />
                <PasswordInput
                    className={signInMainStyles.input}
                    cypressTestId="sign-in-name-password-input"
                    id="password"
                    isValid={hasValidPassword}
                    label={t("password", { ns: "common" })}
                    name="password"
                    onChange={handleChangePassword}
                    value={password}
                />
                <Text
                    classes={signInMainStyles["forgot-password-link"]}
                    onClick={handleGoToForgotPassword}
                    type="p"
                >
                    {t("sign_in_forgot_password.title", { ns: "onboarding" })}
                </Text>
                <ButtonPrimary
                    classes={signInMainStyles.submit}
                    cypressTestId="sign-in-form-email-submit-button"
                    isDisabled={!hasValidEmail || !hasValidPassword}
                    isLoading={signInLoading}
                    onClick={handleLogin}
                    type="submit"
                >
                    <Text>{t("continue", { ns: "common" })}</Text>
                </ButtonPrimary>
            </form>
        );
    }
    if (signInPlatform === SignInPlatform.EMAIL_VERIFY) {
        return (
            <form className={cx(signInStyles.form, signInMainStyles.form)}>
                <TextFieldValidated
                    className={signInStyles["input-validatable"]}
                    cypressTestId="sign-in-name-email-input"
                    id="email"
                    isValid={hasValidEmail}
                    label={t("email", { ns: "common" })}
                    name="email"
                    onChange={handleChangeEmail}
                    value={email}
                />
                <ButtonPrimary
                    classes={signInMainStyles.submit}
                    cypressTestId="sign-in-main-form-email-submit-button"
                    isDisabled={!hasValidEmail}
                    isLoading={signInLoading}
                    onClick={handleLogin}
                    type="submit"
                >
                    <Text>{t("continue", { ns: "common" })}</Text>
                </ButtonPrimary>
            </form>
        );
    }
};
