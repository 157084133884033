import React, { useEffect } from "react";

// Components
import { ArrowForwardIcon } from "components/Icons/ArrowForwardIcon";
import { ButtonPrimary } from "components/Buttons/Buttons";
import { LettersAddOnsCarousel } from "../../../components/LettersAddOnsCarousel/LettersAddOnsCarousel";
import { LettersComposeMessageContent } from "../../../components/LettersComposeMessageContent/LettersComposeMessageContent";
import { LettersComposeRecipientSearchContainer } from "../Recipient/Search/LettersComposeRecipientSearchContainer";
import { LettersDraftSaveButton } from "../../../components/LettersDraftSaveButton/LettersDraftSaveButton";
import { Text } from "components/Text/Text";

// CSS
import "../../../../../css/letters/scenes/Compose/letters-compose-message.scss";

// Localization
import { useTranslation } from "localization/localization";

// Utils
import { calculateRemainingMessageCharacters } from "utils/lettersUtils";

export const LettersComposeMessage = (props) => {
    const {
        changeComposeCurrentSection,
        contacts,
        handleInitializeSaveDraft,
        handleRecipientSelect,
        handleSubmitMessageForReview,
        hasSandboxxPlus,
        history,
        isSavingDraft,
        loading,
        message,
        recentContacts,
        recipient,
        setHeaderBarContent,
        setIsSavingDraft,
        showNotification,
        setRecipient,
        setShouldShowDeleteModal,
        setShouldShowPhotoManageModal,
        toggleContactModal,
        togglePhoneVerificationModal,
        triggerGoToReview,
    } = props;

    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * Constants
     */

    const hasRecipient = Object.keys(recipient).length !== 0;

    /**
     * useEffect
     */

    useEffect(() => {
        if (recipient.isSquadRecipient) {
            setHeaderBarContent({
                backPathname: "/support-squad",
                text: t("compose_your_letter", { ns: "letters" }),
                textSecondary: t("start_writing_your_letter_below", {
                    ns: "letters",
                }),
            });
        } else if (hasRecipient) {
            setHeaderBarContent({
                onBackClick: handleGoBack,
                text: t("compose_your_letter", { ns: "letters" }),
                textSecondary: t("start_writing_your_letter_below", {
                    ns: "letters",
                }),
            });
        } else {
            setHeaderBarContent({
                onBackClick: handleGoBack,
                text: t("select_recipient", { ns: "common" }),
                textSecondary: t("search_create_recipient", { ns: "letters" }),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recipient]);

    useEffect(() => {
        triggerGoToReview && handleSubmitMessageForReview();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [triggerGoToReview]);

    /**
     * End Hooks & Constants
     */

    function handleGoBack() {
        if (hasRecipient) {
            setRecipient({});
            setHeaderBarContent({
                text: t("choose_your_recipient", { ns: "letters" }),
                textSecondary: t("search_create_recipient", { ns: "letters" }),
            });
        } else {
            history.push("/letters");
        }
    }

    const { remainingCharacters } = calculateRemainingMessageCharacters(
        message.content
    );
    return (
        <div className="letters-compose__message">
            <div className="letters-compose__message__search">
                <LettersComposeRecipientSearchContainer
                    {...props}
                    changeComposeCurrentSection={changeComposeCurrentSection}
                    contacts={contacts}
                    handleComposeRecipientSelect={handleRecipientSelect}
                    recentContacts={recentContacts}
                    recipient={recipient}
                    setHeaderBarContent={setHeaderBarContent}
                    setRecipient={setRecipient}
                    showNotification={showNotification}
                    togglePhoneVerificationModal={togglePhoneVerificationModal}
                />
            </div>
            {hasRecipient && (
                <div className="letters-compose__message__detail panel">
                    <LettersComposeMessageContent
                        {...props}
                        handleSubmit={handleSubmitMessageForReview}
                        hasSandboxxPlus={hasSandboxxPlus}
                        setShouldShowDeleteModal={setShouldShowDeleteModal}
                        setShouldShowPhotoManageModal={
                            setShouldShowPhotoManageModal
                        }
                        shouldRenderPhotosInline={true}
                        toggleContactModal={toggleContactModal}
                    />
                    <div className="buttons">
                        <LettersDraftSaveButton
                            handleInitializeSaveDraft={
                                handleInitializeSaveDraft
                            }
                            isSavingDraft={isSavingDraft}
                            message={message}
                            setIsSavingDraft={setIsSavingDraft}
                        />
                        <ButtonPrimary
                            classes="next-button"
                            cypressTestId="letters-compose-message-content-next-button"
                            isDisabled={remainingCharacters < 0}
                            isLoading={loading.isSubmittingForReview}
                            onClick={handleSubmitMessageForReview}
                        >
                            <Text>{t("next", { ns: "common" })}</Text>
                            <ArrowForwardIcon className="forward-arrow-icon" />
                        </ButtonPrimary>
                    </div>
                    <LettersAddOnsCarousel {...props} />
                </div>
            )}
        </div>
    );
};
