import { getYear } from "date-fns";
import range from "lodash/range";

export function generateEarliestDate(yearsPast) {
    const today = new Date();
    const earliestShipDate = today.setFullYear(today.getFullYear() - yearsPast);
    return earliestShipDate;
}

export function generateYearsRange(yearsPast) {
    const today = new Date();
    const years = range(getYear(today) - yearsPast, 2030, 1);
    return years;
}
