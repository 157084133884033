import { useEffect } from "react";

export const useImagePreloader = ({ images }) => {
    useEffect(() => {
        const controllers = [];

        if (images) {
            const imagePromises = images.map((url) => {
                return new Promise((resolve, reject) => {
                    const img = new Image();
                    img.src = url;

                    const controller = new AbortController();
                    controllers.push(controller);

                    img.onload = () => resolve(url);
                    img.onerror = () => reject(url);

                    controller.signal.addEventListener("abort", () => {
                        img.src = "";
                        reject(url);
                    });
                });
            });

            Promise.all(imagePromises);
        }

        return () => {
            controllers.forEach((controller) => controller.abort());
        };
    }, [images]);
};
