import { useEffect, useState } from "react";

// Localization
import { useTranslation } from "localization/localization";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Platforms
import { SandboxxRestAPI } from "utils/sandboxx";

// Utils
import { openImageInNewTab } from "utils/imageUtils";

export const useWalletDetail = ({ selectedGiftCard }) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useState
     */

    const [barcodeImageSrc, setBarcodeImageSrc] = useState("");
    const [shouldHidePin, setShouldHidePin] = useState(true);

    /**
     * useEffect
     */

    useEffect(() => {
        setBarcodeImageSrc("");
        selectedGiftCard?.barcodeImageUrl && fetchBarcodeImage();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedGiftCard]);

    /**
     * Constants
     */

    const amountText =
        selectedGiftCard?.remainingBalance === null
            ? "0"
            : selectedGiftCard?.remainingBalance;
    const pinText = shouldHidePin ? "****" : selectedGiftCard?.pin;
    const showPinText = shouldHidePin
        ? t("show_pin", { ns: "wallet" })
        : t("hide_pin", { ns: "wallet" });

    /**
     * End Hooks
     */

    function fetchBarcodeImage() {
        SandboxxRestAPI.getImageCloudfront(
            selectedGiftCard?.barcodeImageUrl,
            (res) => setBarcodeImageSrc(res)
        );
    }

    function handlePrintBarcodeImage() {
        if (barcodeImageSrc) {
            openImageInNewTab(barcodeImageSrc);
            AnalyticsLogger.logWalletPrintGiftCard();
        }
    }

    function handleToggleShouldHidePin() {
        shouldHidePin
            ? AnalyticsLogger.logWalletGiftCardShowPin()
            : AnalyticsLogger.logWalletGiftCardHidePin();
        setShouldHidePin((prev) => !prev);
    }

    return {
        amountText,
        barcodeImageSrc,
        handlePrintBarcodeImage,
        handleToggleShouldHidePin,
        pinText,
        shouldHidePin,
        showPinText,
    };
};
