// Platforms
import { Auth } from "./auth";
import { DEV } from "../utils/urlUtils";

export class Apple {
    static init(callbacks, mode) {
        const AppleID = window.AppleID;
        const redirectURIBase = DEV ? "app-dev" : "app";
        const redirectURI =
            mode === "login"
                ? `https://${redirectURIBase}.sandboxx.us/signin`
                : `https://${redirectURIBase}.sandboxx.us/profile`;

        document.addEventListener("AppleIDSignInOnFailure", (err) =>
            Apple.onSignInFailure(err, callbacks)
        );
        document.addEventListener("AppleIDSignInOnSuccess", (res) =>
            mode === "login"
                ? Apple.onSignInSuccess(res, callbacks)
                : Apple.onVerifySuccess(res, callbacks)
        );
        AppleID.auth.init({
            clientId: "com.sandboxx.web",
            scope: "name email",
            redirectURI,
            usePopup: true,
        });
    }

    static onSignInFailure(err, callbacks) {
        const { showNotification, toggleButtonLoading } = callbacks;
        showNotification({
            text: "Sign in with Apple failed. You may need to disable any ad or pop-up blocker you have installed.",
            type: "warning",
        });
        toggleButtonLoading(false);
    }

    static onSignInSuccess(res, callbacks) {
        const { onLoginSuccess } = callbacks;
        const { code, id_token } = res.detail.authorization;
        const appleUserData = { accessToken: id_token, userID: code };
        Auth.socialSignOn("APPLE", appleUserData, (res) => {
            localStorage.setItem("authProvider", "apple");
            onLoginSuccess(res);
        });
    }

    static onVerifySuccess(res, callbacks) {
        const { onVerifySuccess } = callbacks;
        const { code, id_token } = res.detail.authorization;
        const appleUserData = { accessToken: id_token, userID: code };
        localStorage.setItem("authProvider", "apple");
        onVerifySuccess(appleUserData);
    }
}
