export const NavBarItemKeys = Object.freeze({
    INBOX: "INBOX",
    LETTERS: "LETTERS",
    MUSTER: "MUSTER",
    NEWS: "NEWS",
    PROFILE: "PROFILE",
    SQUADS: "SQUADS",
    REFERRALS: "REFERRALS",
    WALLET: "WALLET",
});
