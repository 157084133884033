import React from "react";
import cx from "classnames";

// CSS
import "css/components/loading/loading.scss";

// Constants
const loaderGifSrc = require("media/shared/loader.gif").default;
const sandboxxLoadingLogoGifSrc =
    require("media/loading/loading-sandboxx-logo.gif").default;

export const LoadingOverlay = () => {
    return (
        <div className="loading-overlay-container">
            <img
                alt="Loading animation"
                className="loading-overlay-image"
                src={loaderGifSrc}
            />
        </div>
    );
};

export const LoadingPage = () => {
    return (
        <div className="loading-page">
            <img
                alt="Loading animation"
                className="loading-page-image"
                src={sandboxxLoadingLogoGifSrc}
            />
            <span className="loading-page-text">LOADING...</span>
        </div>
    );
};

export const LoadingSection = ({ className, style }) => {
    return (
        <div
            className={cx("loading-section-container", className)}
            style={style}
        >
            <img
                alt="Loading animation"
                className="loading-section-image"
                src={loaderGifSrc}
            />
        </div>
    );
};

export const LoadingSentLetterPreview = () => {
    return (
        <div className="loading-sent-letter-preview-container panel">
            <div className="loading-sent-letter-preview-item image loading" />
            <div className="loading-sent-letter-preview-content">
                <div className="loading-sent-letter-preview-item loading" />
                <div className="loading-sent-letter-preview-item loading" />
                <div className="loading-sent-letter-preview-item loading" />
            </div>
        </div>
    );
};

export const LoadingSentLetterPreviewList = () => {
    return (
        <div className="loading-sent-letter-preview-list-container">
            <LoadingSentLetterPreview />
            <LoadingSentLetterPreview />
            <LoadingSentLetterPreview />
        </div>
    );
};
