// Localization
import { t } from "localization/localization";

export function generateBundleCardData(sandboxxPlusBundle, multiPhotoBundle) {
    return [
        {
            benefits: [
                t("purchase_modal.bundle_highlight.sandboxx_plus.benefit_1", {
                    ns: "components",
                }),
                t("purchase_modal.bundle_highlight.sandboxx_plus.benefit_2", {
                    ns: "components",
                }),
                t("purchase_modal.bundle_highlight.sandboxx_plus.benefit_3", {
                    ns: "components",
                }),
            ],
            bundle: sandboxxPlusBundle,
            buttonText: t(
                "purchase_modal.bundle_highlight.sandboxx_plus.button_text",
                {
                    ns: "components",
                }
            ),
            buttonType: "primary",
            description: t(
                "purchase_modal.bundle_highlight.sandboxx_plus.description",
                {
                    ns: "components",
                }
            ),
            title: t("purchase_modal.bundle_highlight.sandboxx_plus.title_en", {
                ns: "components",
            }),
            image: "",
            price: "59.99",
        },
        {
            benefits: [
                t("purchase_modal.bundle_highlight.multi_photo.benefit_1", {
                    ns: "components",
                }),
            ],
            bundle: multiPhotoBundle,
            buttonText: t(
                "purchase_modal.bundle_highlight.multi_photo.button_text",
                {
                    ns: "components",
                }
            ),
            buttonType: "tertiary",
            description: t(
                "purchase_modal.bundle_highlight.multi_photo.description",
                {
                    ns: "components",
                }
            ),
            title: t("purchase_modal.bundle_highlight.multi_photo.title", {
                ns: "components",
            }),
            image: "",
            price: "1.99",
        },
    ];
}
