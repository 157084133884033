export function ArrowForwardIcon({ className, color, height, width }) {
    return (
        <svg
            className={className}
            width={width}
            height={height}
            viewBox="0 0 16 16"
            fill={color}
            xmlns="http://www.w3.org/2000/svg"
        >
            <mask
                id="mask0_46_4387"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="16"
                height="16"
            >
                <path
                    d="M0 7H12.17L6.58 1.41L8 0L16 8L8 16L6.59 14.59L12.17 9H0V7Z"
                    fill={color}
                />
            </mask>
            <g mask="url(#mask0_46_4387)">
                <path
                    d="M0 7H12.17L6.58 1.41L8 0L16 8L8 16L6.59 14.59L12.17 9H0V7Z"
                    fill={color}
                />
                <path d="M-4 -4H20V20H-4V-4Z" fill={color} />
            </g>
        </svg>
    );
}

ArrowForwardIcon.defaultProps = {
    color: "#fff",
    className: "",
    height: "16px",
    width: "16px",
};
