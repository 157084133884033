import React from "react";
import cx from "classnames";

// Components
import { ButtonBlock } from "components/Buttons/ButtonBlock/ButtonBlock";

// CSS
import styles from "../profile-persona-selection.module.scss";

export const ProfilePersonaModalSelectRoot = ({
    handleSelectRootPersona,
    setCurrentSection,
    rootPersonas,
    NavigationEnum,
}) => {
    function renderRootPersonas() {
        if (rootPersonas) {
            return rootPersonas.map((persona) => {
                return (
                    <ButtonBlock
                        bodyText={persona.description}
                        classes={cx(styles.persona, styles.rootPersona)}
                        cypressTestId={`profile-persona-modal-select-root-${persona.id}`}
                        headerText={persona.displayName || persona.name}
                        key={persona.id}
                        onClick={() => {
                            handleSelectRootPersona(persona);
                            setCurrentSection(NavigationEnum.CHILD);
                        }}
                    />
                );
            });
        } else {
            return (
                <>
                    <ButtonBlock
                        classes={cx(styles.persona, styles.rootPersona)}
                        isLoading={true}
                    />
                    <ButtonBlock
                        classes={cx(styles.persona, styles.rootPersona)}
                        isLoading={true}
                    />
                    <ButtonBlock
                        classes={cx(styles.persona, styles.rootPersona)}
                        isLoading={true}
                    />
                </>
            );
        }
    }

    return <>{renderRootPersonas()}</>;
};
