import React from "react";

// Components
import { ModalPrompt } from "components/Modals/ModalPrompt/ModalPrompt";
import { WarningCircleIcon } from "components/Icons/WarningCircleIcon";

// Utils
import { emptyFunction } from "utils/miscUtils";

// Constants
const bodyText =
    "This email has previously been used to create a Sandboxx account. Would you like to log in?";
const buttonInvisibleText = "Cancel";
const buttonPrimaryText = "Log In";
const headerText = "Email Already In Use";

export const SignInMainModalPrompt = (props) => {
    const { onHide, show } = props;
    return (
        <ModalPrompt
            buttonInvisible={{ onClick: onHide, text: buttonInvisibleText }}
            buttonPrimary={{
                cypressTestId: "sign-in-main-modal-prompt-log-in-button",
                isDisabled: false,
                onClick: emptyFunction,
                text: buttonPrimaryText,
            }}
            bodyText={bodyText}
            headerText={headerText}
            icon={<WarningCircleIcon height="42px" width="42px" />}
            show={show}
        />
    );
};
