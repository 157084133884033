import React from "react";

// Components
import { CartConfirmation } from "./components/CartConfirmation/CartConfirmation";
import { CartHeader } from "./components/CartHeader/CartHeader";
import { CartMain } from "./components/CartMain/CartMain";
import { CartPaymentMethodForm } from "./components/CartPaymentMethodForm/CartPaymentMethodForm";
import { CartShippingAddressForm } from "./components/CartShippingAddressForm/CartShippingAddressForm";
import Drawer from "@mui/material/Drawer";

// CSS
import styles from "./cart.module.scss";

// Enums
import { CartNavigation } from "./enums/CartNavigation";

// Hooks
import { useCart } from "./hooks/useCart";
import { useCartNavigation } from "./hooks/useCartNavigation";
import { useCartPaymentMethod } from "./hooks/useCartPaymentMethod";

export const Cart = () => {
    /**
     * Custom Hooks
     */

    const {
        currentSection,
        headerText,
        resetCartNavigation,
        setCurrentSection,
        setHeaderText,
    } = useCartNavigation();

    const {
        handleSelectPaymentMethod,
        handleSelectPaymentMethodNew,
        isSubmittingPaymentMethod,
        resetCartPaymentMethod,
        selectedPaymentMethod,
    } = useCartPaymentMethod();

    const { handleCompleteCheckout, hideCart, shouldDisplayCart } = useCart({
        resetCartPaymentMethod,
        setCurrentSection,
    });

    /**
     * End Hooks
     */

    function renderCurrentSection() {
        return {
            [CartNavigation.CONFIRMATION]: (
                <CartConfirmation resetCartNavigation={resetCartNavigation} />
            ),
            [CartNavigation.MAIN]: (
                <CartMain
                    handleCompleteCheckout={handleCompleteCheckout}
                    handleSelectPaymentMethod={handleSelectPaymentMethod}
                    isSubmittingPaymentMethod={isSubmittingPaymentMethod}
                    selectedPaymentMethod={selectedPaymentMethod}
                    setCartCurrentSection={setCurrentSection}
                    setHeaderText={setHeaderText}
                />
            ),
            [CartNavigation.PAYMENT_METHOD_FORM]: (
                <CartPaymentMethodForm
                    handleSelectPaymentMethodNew={handleSelectPaymentMethodNew}
                    setCartCurrentSection={setCurrentSection}
                    setHeaderText={setHeaderText}
                />
            ),
            [CartNavigation.SHIPPING_ADDRESS_FORM]: (
                <CartShippingAddressForm
                    setCartCurrentSection={setCurrentSection}
                    setHeaderText={setHeaderText}
                />
            ),
        }[currentSection];
    }

    return (
        <Drawer
            anchor="right"
            classes={{ paper: styles.paper }}
            className={styles.drawer}
            onClose={() => {
                hideCart();
                resetCartNavigation();
            }}
            open={shouldDisplayCart}
        >
            <div className={styles.cart}>
                <CartHeader
                    headerText={headerText}
                    hideCart={hideCart}
                    resetCartNavigation={resetCartNavigation}
                />
                <div className={styles.currentSection}>
                    {renderCurrentSection()}
                </div>
            </div>
        </Drawer>
    );
};
