import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// Components
import { AddressFormatterInput } from "./components/AddressFormatterInput/AddressFormatterInput";
import {
    ButtonInvisible,
    ButtonPrimary,
} from "../../components/Buttons/Buttons";

// CSS
import styles from "./address-formatter.module.scss";

// Hooks
import { useAddressFormatter } from "./hooks/useAddressFormatter";

export const AddressFormatter = (props) => {
    const { base, classNames, handleSectionChange, removeBackOption } = props;

    /**
     * Custom Hooks
     */

    const {
        address,
        errorMessage,
        formStack,
        handleInputChange,
        handleSubmitAddressForm,
        isFormComplete,
        isSubmitting,
    } = useAddressFormatter({ base });

    /**
     * End Hooks
     */

    function renderButtons() {
        const onSubmit = isFormComplete
            ? (e) => props.handleSubmit(e, address)
            : handleSubmitAddressForm;
        return (
            <div className={cx(classNames.buttons, styles.buttons)}>
                <ButtonPrimary
                    cypressTestId="address-formatter-button-next"
                    isDisabled={isSubmitting || !isFormComplete}
                    onClick={onSubmit}
                    text="Continue"
                    type="button"
                />
                {!removeBackOption && (
                    <ButtonInvisible
                        cypressTestId="address-formatter-button-manual"
                        onClick={handleSectionChange}
                        text="I don't see the location I'm looking for"
                        type="button"
                    />
                )}
            </div>
        );
    }

    function renderErrorMessage() {
        return (
            errorMessage && (
                <div className={styles.error}>
                    <span>{errorMessage}</span>
                </div>
            )
        );
    }

    function renderInput() {
        return formStack.map((formBlock, i) => {
            return (
                <AddressFormatterInput
                    classNames={classNames}
                    formBlock={formBlock}
                    formStack={formStack}
                    handleInputChange={handleInputChange}
                    handleSubmitAddressForm={handleSubmitAddressForm}
                    isSubmitting={isSubmitting}
                    index={i}
                    key={i}
                />
            );
        });
    }

    return (
        <div className={cx(styles.addressFormatter, classNames.container)}>
            {renderInput()}
            {renderErrorMessage()}
            {renderButtons()}
        </div>
    );
};

AddressFormatter.defaultProps = {
    classNames: {},
    removeBackOption: false,
};

AddressFormatter.propTypes = {
    classNames: PropTypes.shape({
        buttons: PropTypes.string,
        container: PropTypes.string,
        panel: PropTypes.string,
    }),
    base: PropTypes.shape({
        base: PropTypes.string,
        city: PropTypes.string,
        id: PropTypes.string,
        label: PropTypes.string,
        name: PropTypes.string,
        value: PropTypes.string,
        zipcode: PropTypes.string,
        zipcodes: PropTypes.arrayOf(PropTypes.string),
    }),
    handleSectionChange: PropTypes.func,
    removeBackOption: PropTypes.bool,
};
