import React from "react";
import PropTypes from "prop-types";

// Components
import { ButtonPrimary } from "components/Buttons/Buttons";
import { Modal } from "components/Modals/Modal";

// CSS
import styles from "./muster-join-success-modal.module.scss";

// Media
import chatsImageSrc from "media/muster/chats.png";

export const MusterJoinSuccessModal = (props) => {
    const { setShouldShowJoinSuccessModal, shouldShowJoinSuccessModal } = props;

    function handleModalClose() {
        setShouldShowJoinSuccessModal(false);
    }

    function renderBody() {
        return (
            <div className={styles.body} data-cy="invite-to-squad-success">
                <img
                    src={chatsImageSrc}
                    alt="speech bubbles"
                    className={styles.image}
                />
                <span className={styles.header}>Success!</span>
                <span className={styles.text}>
                    You are connected with your recruiter.
                </span>
            </div>
        );
    }

    function renderButtons() {
        return (
            <div className={styles.buttons}>
                <ButtonPrimary
                    classes={styles.button}
                    cypressTestId="invite-to-squad-success-continue-button"
                    onClick={handleModalClose}
                    text="Continue"
                    type="button"
                />
            </div>
        );
    }

    return (
        <Modal
            className={styles["muster-join-success-modal"]}
            dialogClassName={styles["muster-join-success-modal-dialog"]}
            onHide={handleModalClose}
            shouldHideHeader={true}
            headerSubText=""
            show={shouldShowJoinSuccessModal}
            size="sm"
        >
            <div className={styles["muster-recruit-invite-code-success"]}>
                {renderBody()}
                {renderButtons()}
            </div>
        </Modal>
    );
};

MusterJoinSuccessModal.propTypes = {
    setShouldShowJoinSuccessModal: PropTypes.func.isRequired,
    shouldShowJoinSuccessModal: PropTypes.bool.isRequired,
};
