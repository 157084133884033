// Utils
import {
    determineIfIsSandboxxUser,
    generateFullName,
    generateId,
    generateSource,
} from "./utils/utils";

export class Contact {
    constructor(contactData) {
        Object.keys(contactData).forEach((key) => {
            this[key] = contactData[key];
        });
        this.address =
            contactData.address || contactData.supporteeAddress || {};
        this.fullName = generateFullName(contactData);
        this.hasInitialAddress = !!contactData.address?.line1;
        this.id = generateId(contactData);
        this.isSandboxxUser = determineIfIsSandboxxUser(contactData);
        this.isSquadRecipient = !!contactData.squadId;
        this.source = generateSource(contactData);
        this.connected = this.isSandboxxUser ? contactData.connected : true;
    }

    generateContactObject() {
        return Object.keys(this).reduce((acc, key) => {
            return { ...acc, [key]: this[key] };
        }, {});
    }
}
