import React, { useEffect } from "react";
import cx from "classnames";

// Components
import { ButtonPrimary } from "components/Buttons/Buttons";
import { Text } from "components/Text/Text";
import { TextFieldValidated } from "components/Inputs/TextFieldValidated/TextFieldValidated";

// CSS
import signInStyles from "../../../../../sign-in.module.scss";
import signInMainStyles from "../../sign-in-main.module.scss";

// Enums
import { SignInPlatform } from "../../constants/constants";

// Localization
import { useTranslation } from "localization/localization";

export const SignInMainSignUpForm = ({
    handleChangeUserInfo,
    handleSignUp,
    hasValidEmail,
    loading,
    setSignInPlatform,
    userInfo,
}) => {
    const { email } = userInfo;

    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * End Hooks
     */

    useEffect(() => {
        setSignInPlatform(SignInPlatform.EMAIL_VERIFY);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <form className={cx(signInStyles.form, signInMainStyles.form)}>
            <TextFieldValidated
                className={signInStyles["input-validatable"]}
                cypressTestId="sign-in-main-email-input"
                id="email"
                isValid={hasValidEmail}
                label={t("email", { ns: "common" })}
                name="email"
                onChange={handleChangeUserInfo}
                value={email}
            />
            <ButtonPrimary
                classes={signInMainStyles.submit}
                cypressTestId="sign-in-main-form-email-submit-button"
                isDisabled={!hasValidEmail}
                isLoading={loading.signUp}
                onClick={handleSignUp}
                type="submit"
            >
                <Text>{t("continue", { ns: "common" })}</Text>
            </ButtonPrimary>
        </form>
    );
};
