import React from "react";

// CSS
import "./search-field.scss";

// Hooks
import { useToggleOnFocus } from "../../../hooks/forms/useToggleOnFocus";

export const SearchField = (props) => {
    const { cypressTestId, handleSearchInputChange, placeholder, term } = props;

    /**
     * Custom Hooks
     */

    const [isFocusing, focusEventHandlers] = useToggleOnFocus(false);

    /**
     * End Hooks
     */

    function renderInputPlaceholder() {
        return (
            !isFocusing &&
            !term && (
                <div className="search-field__placeholder">
                    <span>
                        <span className="material-icons">search</span>
                        {placeholder}
                    </span>
                </div>
            )
        );
    }

    return (
        <>
            <input
                {...focusEventHandlers}
                className="search-field search"
                data-cy={cypressTestId}
                onChange={handleSearchInputChange}
                type="text"
            />
            {renderInputPlaceholder()}
        </>
    );
};
