// Logging
import { AnalyticsLogger } from "../loggers/AnalyticsLogger";

// Platforms
import { Branch } from "./branch";
import { SandboxxRestAPI } from "../utils/sandboxx";

// Utils
import { isEmptyObject } from "../utils/miscUtils";

export class Auth {
    static socialSignOn(provider, userData, onSuccess) {
        AnalyticsLogger.logSignUpEnd(userData);
        const { accessToken, email, userID } = userData;
        const branch = Branch.generateSignUpParams();
        const installParams = isEmptyObject(branch) ? {} : { branch };
        const { musterInviteCode } =
            JSON.parse(sessionStorage.getItem("musterInviteCode")) || "";

        const payload = {
            email,
            installParams,
            musterInviteCode,
            platform: "WEB",
            provider,
            providerUserId: userID,
            token: accessToken,
        };
        SandboxxRestAPI.socialSignOn(payload, onSuccess);
    }
}
