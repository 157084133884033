export function generateSquadIdPayload({ contact }) {
    return contact.isSandboxxUser
        ? { associatedUserId: contact.id }
        : { abContactId: contact.id };
}

export function generateSquadImagePayload({
    newImage,
    previousImage = {},
} = {}) {
    return newImage?.url
        ? {
              headerLargeUrl: newImage.url,
              headerSmallUrl: newImage.url,
          }
        : previousImage;
}
