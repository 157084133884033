export function CheckmarkIcon({ className, color, height, width }) {
    return (
        <svg
            className={className}
            width={width}
            height={height}
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.74566 17.4001C6.44387 18.0905 7.58618 18.0295 8.20657 17.268L19.4954 3.41954C20.076 2.70689 19.9694 1.65794 19.2568 1.07735C18.5442 0.496804 17.4952 0.603398 16.9146 1.31598L6.80125 13.757L2.96837 9.97194C2.31458 9.3248 1.26005 9.33149 0.614024 9.98528C-0.0331215 10.6391 -0.0264282 11.6936 0.627357 12.3396L5.74564 17.4013"
                fill={color}
            />
        </svg>
    );
}

CheckmarkIcon.defaultProps = {
    className: "",
    color: "#5E95EB",
    height: "20px",
    width: "18px",
};
