const regex = {
    city: /^.{2,128}$/,
    firstName: /^[a-zA-Z0-9' -]{1,40}$/,
    lastName: /^[a-zA-Z0-9' -]{1,40}$/,
    line1: /^.{2,128}$/,
    line2: /^.{2,128}$/,
    state: /^[A-Z]{2}$/,
    zipcode: /^.{5,10}$/,
};

export class Validation {
    static isValidNewRecipient({
        city,
        firstName,
        lastName,
        line1,
        state,
        zipcode,
    }) {
        return (
            Validation.isValidNewRecipientAttribute("city", city) &&
            Validation.isValidNewRecipientAttribute("firstName", firstName) &&
            Validation.isValidNewRecipientAttribute("lastName", lastName) &&
            Validation.isValidNewRecipientAttribute("line1", line1) &&
            Validation.isValidNewRecipientAttribute("state", state) &&
            Validation.isValidNewRecipientAttribute("zipcode", zipcode)
        );
    }

    static isValidNewRecipientAttribute(attribute, str) {
        return regex[attribute].test(str);
    }
}
