import React from "react";
import PropTypes from "prop-types";

// Components
import { Snackbar, Alert } from "@mui/material";

export const NotificationHandler = ({ notifications, handleClose }) => {
    return (
        <>
            {notifications.map((notification) => (
                <Snackbar
                    action={notification.action}
                    anchorOrigin={notification.options.anchorOrigin}
                    autoHideDuration={
                        notification.options.isPersistent
                            ? null
                            : notification.options.autoHideDuration
                    }
                    key={notification.id}
                    message={notification.message}
                    onClose={handleClose(notification.id)}
                    open={true}
                >
                    <Alert
                        onClose={
                            notification.options.shouldHideCloseUI
                                ? null
                                : handleClose(notification.id)
                        }
                        severity={notification.options.severity}
                        variant={notification.options.variant}
                    >
                        {notification.message}
                    </Alert>
                </Snackbar>
            ))}
        </>
    );
};

NotificationHandler.defaultProps = {
    notifications: [],
};

NotificationHandler.propTypes = {
    handleClose: PropTypes.func,
    notifications: PropTypes.array,
};
