export function PlusIcon({
    height = "17px",
    width = "18px",
    color = "#212121",
}) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 18 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.5 8.5H16.5M9 1V16"
                stroke={color}
                strokeWidth="1.40625"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
