import React, { createContext, useState } from "react";

// Platforms
import { SandboxxRestAPI } from "../utils/sandboxx";

export const BasesContext = createContext({});

export const BasesProvider = (props) => {
    const { children } = props;

    /**
     * useState
     **/

    const [bases, setBases] = useState(null);

    /**
     * End Hooks
     **/

    function fetchBases() {
        return SandboxxRestAPI.getAddressFormatterBases(onFetchBasesSuccess);
    }

    function onFetchBasesSuccess(bases) {
        setBases(bases);
    }

    const basesContext = { bases, fetchBases };

    return (
        <BasesContext.Provider value={basesContext}>
            {children}
        </BasesContext.Provider>
    );
};
