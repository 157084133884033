import { useEffect, useLayoutEffect, useRef } from "react";

// Config
import { storylyCategoryTokens } from "config/storyly";

// Utils
import { CurrentUser } from "utils/sandboxx";
import {
    generateCustomStorylyGroupHTML,
    setStorylyGroupMarginLeft,
} from "../utils/utils";

export const useStorylyGroup = ({ category, classNames, segments }) => {
    /**
     * useRef
     */

    const storylyRef = useRef();

    /**
     * Constants
     **/

    const storyGroupListWidth = storylyRef?.current?.groupsList_?.scrollWidth;

    /**
     * useEffect
     */

    useLayoutEffect(() => {
        initializeStorylyContent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storylyRef]);

    useEffect(() => {
        if (storyGroupListWidth) {
            setStorylyGroupMarginLeft(storylyRef);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storyGroupListWidth]);

    /**
     * End Hooks & Constants
     **/

    function initializeStorylyContent() {
        try {
            const token = storylyCategoryTokens[category];
            if (token) {
                storylyRef.current.init({
                    customParameter: CurrentUser.getUser().userId,
                    customStoryGroupHTML: generateCustomStorylyGroupHTML(
                        classNames.storylyContainer
                    ),
                    props: {
                        scrollIndicator: true,
                        storyGroupAlign: "left",
                    },
                    token,
                    segments: [...segments],
                });
            }
        } catch (err) {
            console.error("Unable to initialize Storyly content");
        }
    }

    return {
        storylyRef,
    };
};
