export const NewsIcon = ({ className, color }) => {
    return (
        <svg
            className={className}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2 9V15.7C2 17.3802 2 18.7202 2.32698 19.362C2.6146 19.9265 3.07354 20.3854 3.63803 20.673C4.27976 21 5.11984 21 6.8 21H10.5"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M2.00009 8.99992L2.20009 8.99992C3.88025 8.99992 5 9 5.5 9"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12 16H10M18 8H10M18 16H16M12 12H10M18 12H16M22 7.8V16.2C22 17.8802 22 18.7202 21.673 19.362C21.3854 19.9265 20.9265 20.3854 20.362 20.673C19.7202 21 18.8802 21 17.2 21H10.8C9.11984 21 8.27976 21 7.63803 20.673C7.07354 20.3854 6.6146 19.9265 6.32698 19.362C6 18.7202 6 17.8802 6 16.2V7.8C6 6.11984 6 5.27976 6.32698 4.63803C6.6146 4.07354 7.07354 3.6146 7.63803 3.32698C8.27976 3 9.11984 3 10.8 3H17.2C18.8802 3 19.7202 3 20.362 3.32698C20.9265 3.6146 21.3854 4.07354 21.673 4.63803C22 5.27976 22 6.11984 22 7.8Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export const NewsIconFilled = ({ className, color }) => {
    return (
        <svg
            className={className}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill={color}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.7587 2H17.2413H17.2413C18.0463 1.99999 18.7106 1.99998 19.2518 2.0442C19.8139 2.09012 20.3306 2.18869 20.816 2.43598C21.5686 2.81947 22.1805 3.43139 22.564 4.18404C22.8113 4.66938 22.9099 5.18608 22.9558 5.74818C23 6.28937 23 6.95372 23 7.75868V16.2413C23 17.0463 23 17.7106 22.9558 18.2518C22.9099 18.8139 22.8113 19.3306 22.564 19.816C22.1805 20.5686 21.5686 21.1805 20.816 21.564C20.3306 21.8113 19.8139 21.9099 19.2518 21.9558C18.7106 22 18.0463 22 17.2413 22H10.7587C9.95372 22 9.28937 22 8.74818 21.9558C8.18608 21.9099 7.66938 21.8113 7.18404 21.564C6.43139 21.1805 5.81947 20.5686 5.43598 19.816C5.18868 19.3306 5.09012 18.8139 5.0442 18.2518C4.99998 17.7106 4.99999 17.0463 5 16.2413V16.2413V7.75869V7.75867C4.99999 6.95372 4.99998 6.28936 5.0442 5.74818C5.09012 5.18608 5.18868 4.66938 5.43598 4.18404C5.81947 3.43139 6.43139 2.81947 7.18404 2.43598C7.66938 2.18869 8.18608 2.09012 8.74818 2.0442C9.28936 1.99998 9.95372 1.99999 10.7587 2H10.7587ZM10 7C9.44772 7 9 7.44772 9 8C9 8.55229 9.44772 9 10 9H14H18C18.5523 9 19 8.55229 19 8C19 7.44772 18.5523 7 18 7H14H10ZM10 11C9.44772 11 9 11.4477 9 12C9 12.5523 9.44772 13 10 13H12C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11H10ZM16 11C15.4477 11 15 11.4477 15 12C15 12.5523 15.4477 13 16 13H18C18.5523 13 19 12.5523 19 12C19 11.4477 18.5523 11 18 11H16ZM10 15C9.44772 15 9 15.4477 9 16C9 16.5523 9.44772 17 10 17H12C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15H10ZM16 15C15.4477 15 15 15.4477 15 16C15 16.5523 15.4477 17 16 17H18C18.5523 17 19 16.5523 19 16C19 15.4477 18.5523 15 18 15H16Z"
                fill={color}
            />
        </svg>
    );
};
