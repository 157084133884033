import branch from "branch-sdk";

// Utils
import { emptyFunction } from "../utils/miscUtils";
import { CurrentUser } from "../utils/sandboxx";
import { DEV } from "../utils/urlUtils";

function getBranchKey() {
    return DEV
        ? process.env.REACT_APP_BRANCH_KEY_DEV
        : process.env.REACT_APP_BRANCH_KEY_PROD;
}

function getHostUrl() {
    return DEV ? "https://app-dev.sandboxx.us/" : "https://app.sandboxx.us/";
}

export class Branch {
    static first(callback = emptyFunction) {
        branch.first(callback);
    }

    static async generateReferralLink(
        referralObj = {},
        callback = emptyFunction
    ) {
        const { referringRecruitId, referringRecruitName, channel } =
            referralObj;
        const { userId } = CurrentUser.getUser();
        const og_url = getHostUrl();
        let baseData = {
            $og_url: og_url,
            $og_type: "website",
            referring_id: userId,
        };
        const idFinal = referringRecruitId ? referringRecruitId : "";
        const nameFinal = referringRecruitName ? referringRecruitName : "";
        const referringRecruitInfo = {
            referring_recruit_id: idFinal,
            referring_recruit_name: nameFinal,
        };
        const channelFinal = channel ? channel : "web";
        branch.link(
            {
                channel: channelFinal,
                data: idFinal
                    ? { ...baseData, ...referringRecruitInfo }
                    : baseData,
                feature: "invite",
            },
            callback
        );
    }

    static generateSignUpParams() {
        const deps_short_code = sessionStorage.getItem("deps_short_code");
        const referring_id = sessionStorage.getItem("referring_id");
        const referring_recruit_id = sessionStorage.getItem(
            "referring_recruit_id"
        );
        const fac_card_user = sessionStorage.getItem("fac_card_user");
        const facCardUserParams = fac_card_user ? { fac_card_user } : {};
        const depsShortCodeParams = deps_short_code ? { deps_short_code } : {};
        const referringIdParams = referring_id ? { referring_id } : {};
        const referringRecruitIdParams = referring_recruit_id
            ? { referring_recruit_id }
            : {};
        return {
            ...depsShortCodeParams,
            ...referringIdParams,
            ...referringRecruitIdParams,
            ...facCardUserParams,
        };
    }

    static identify(callback = emptyFunction) {
        const { userId } = CurrentUser.getUser();
        branch.setIdentity(userId, callback);
    }

    static init() {
        let options = { no_journeys: true };
        branch.init(getBranchKey(), options, (err, data) => {
            if (err) {
                console.warn(err);
            } else {
                Branch.storeReferringIdInSession(data);
                Branch.storeMusterInviteCodeInSession(data);
                Branch.storeReferralInviteCodeInSession(data);
                Branch.identify();
            }
        });
    }

    static logout(callback = emptyFunction) {
        branch.logout(callback);
    }

    static sendSMS(phone, linkData, options, callback) {
        branch.sendSMS(phone, linkData, options, callback);
    }

    static storeMusterInviteCodeInSession({ data_parsed }) {
        const { musterInviteCode } = data_parsed;
        musterInviteCode &&
            sessionStorage.setItem(
                "musterInviteCode",
                JSON.stringify({ musterInviteCode })
            );
    }

    static storeReferralInviteCodeInSession({ data_parsed }) {
        const { referralCode } = data_parsed;
        referralCode && sessionStorage.setItem("referralCode", referralCode);
    }

    static storeReferringIdInSession({ data_parsed }) {
        const {
            deps_short_code,
            referring_id,
            referring_recruit_id,
            referring_recruit_name,
            fac_card_user,
        } = data_parsed;
        referring_id && sessionStorage.setItem("referring_id", referring_id);
        deps_short_code &&
            sessionStorage.setItem("deps_short_code", deps_short_code);
        referring_recruit_id &&
            sessionStorage.setItem(
                "referring_recruit_id",
                referring_recruit_id
            );
        referring_recruit_name &&
            sessionStorage.setItem(
                "referring_recruit_name",
                referring_recruit_name
            );
        fac_card_user && sessionStorage.setItem("fac_card_user", fac_card_user);
    }
}
