import React from "react";
import cx from "classnames";
import propTypes from "prop-types";

// CSS
import styles from "./divider.module.scss";

export const Divider = ({ className, text }) => {
    return (
        <div className={cx(styles.divider, className)}>
            <span>{text}</span>
        </div>
    );
};

Divider.defaultProps = {
    className: "",
};

Divider.propTypes = {
    className: propTypes.string,
    text: propTypes.string,
};
