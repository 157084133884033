import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// CSS
import styles from "./text.module.scss";

// Utils
import { handleKeyDown } from "utils/eventUtils";

export const Text = ({
    children,
    classes,
    cyId,
    isBold,
    isCentered,
    onClick,
    type,
}) => {
    const tabIndex = onClick ? "0" : null;
    const onKeyDown = onClick ? (e) => handleKeyDown(e, onClick) : null;

    switch (type) {
        case "h1":
            return (
                <h1
                    className={cx(classes, styles.text, styles.h1, {
                        [styles["text--centered"]]: isCentered,
                    })}
                    data-cy={cyId}
                    onClick={onClick}
                    onKeyDown={onKeyDown}
                    tabIndex={tabIndex}
                >
                    {children}
                </h1>
            );
        case "h2":
            return (
                <h2
                    className={cx(classes, styles.text, styles.h2, {
                        [styles["text--bold"]]: isBold,
                        [styles["text--centered"]]: isCentered,
                    })}
                    data-cy={cyId}
                    onClick={onClick}
                    onKeyDown={onKeyDown}
                    tabIndex={tabIndex}
                >
                    {children}
                </h2>
            );
        case "h3":
            return (
                <h3
                    className={cx(classes, styles.text, styles.h3, {
                        [styles["text--bold"]]: isBold,
                        [styles["text--centered"]]: isCentered,
                    })}
                    data-cy={cyId}
                    onClick={onClick}
                    onKeyDown={onKeyDown}
                    tabIndex={tabIndex}
                >
                    {children}
                </h3>
            );
        case "h4":
            return (
                <h4
                    className={cx(classes, styles.text, styles.h4, {
                        [styles["text--bold"]]: isBold,
                        [styles["text--centered"]]: isCentered,
                    })}
                    data-cy={cyId}
                    onClick={onClick}
                    onKeyDown={onKeyDown}
                    tabIndex={tabIndex}
                >
                    {children}
                </h4>
            );
        case "p":
            return (
                <p
                    className={cx(classes, styles.text, styles.p, {
                        [styles["text--bold"]]: isBold,
                        [styles["text--centered"]]: isCentered,
                    })}
                    data-cy={cyId}
                    onClick={onClick}
                    onKeyDown={onKeyDown}
                    tabIndex={tabIndex}
                >
                    {children}
                </p>
            );
        case "body":
            return (
                <span
                    className={cx(classes, styles.text, styles.body, {
                        [styles["text--bold"]]: isBold,
                        [styles["text--centered"]]: isCentered,
                    })}
                    data-cy={cyId}
                    onClick={onClick}
                    onKeyDown={onKeyDown}
                    tabIndex={tabIndex}
                >
                    {children}
                </span>
            );
        case "caption":
            return (
                <span
                    className={cx(classes, styles.text, styles.caption, {
                        [styles["text--bold"]]: isBold,
                        [styles["text--centered"]]: isCentered,
                    })}
                    data-cy={cyId}
                    onClick={onClick}
                    onKeyDown={onKeyDown}
                    tabIndex={tabIndex}
                >
                    {children}
                </span>
            );
        case "small":
            return (
                <span
                    className={cx(classes, styles.text, styles.small, {
                        [styles["text--bold"]]: isBold,
                        [styles["text--centered"]]: isCentered,
                    })}
                    data-cy={cyId}
                    onClick={onClick}
                    onKeyDown={onKeyDown}
                    tabIndex={tabIndex}
                >
                    {children}
                </span>
            );
        default:
            return (
                <span
                    className={cx(classes, styles.text, {
                        [styles["text--bold"]]: isBold,
                        [styles["text--centered"]]: isCentered,
                    })}
                    data-cy={cyId}
                    onClick={onClick}
                    onKeyDown={onKeyDown}
                    tabIndex={tabIndex}
                >
                    {children}
                </span>
            );
    }
};

Text.defaultProps = {
    children: null,
    classes: "",
    cyId: "",
    isBold: false,
    isCentered: false,
    type: null,
};

Text.propTypes = {
    children: PropTypes.node.isRequired,
    classes: PropTypes.string,
    cyId: PropTypes.string,
    isBold: PropTypes.bool,
    isCentered: PropTypes.bool,
    type: PropTypes.string,
};
