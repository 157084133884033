import { useMemo } from "react";
import PropTypes from "prop-types";

export const FeatureFlagBoundary = ({ children, featureFlags }) => {
    /**
     * useMemo
     */

    const shouldRender = useMemo(() => {
        return featureFlags.every((flag) => flag);
    }, [featureFlags]);

    /**
     * End Hooks
     */

    if (shouldRender) {
        return children;
    } else {
        return null;
    }
};

FeatureFlagBoundary.defaultProps = {
    featureFlags: [],
};

FeatureFlagBoundary.propTypes = {
    children: PropTypes.node.isRequired,
    featureFlags: PropTypes.array.isRequired,
};
