import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// Components
import { ButtonPill } from "components/Buttons/ButtonPill/ButtonPill";
import { ButtonPrimary } from "components/Buttons/Buttons";
import { Checkbox } from "components/Inputs/Checkbox/Checkbox";
import { InfoIcon } from "components/Icons/InfoIcon";
import { Text } from "components/Text/Text";
import { WalletGiftCardsPurchaseSelectOptionDeliveryInfoModal } from "./components/WalletGiftCardsPurchaseSelectOptionDeliveryInfoModal/WalletGiftCardsPurchaseSelectOptionDeliveryInfoModal";

// CSS
import styles from "./wallet-gift-cards-purchase-select-option.module.scss";

// Hooks
import { useWalletGiftCardsPurchaseSelectOption } from "./hooks/useWalletGiftCardsPurchaseSelectOption";

// Media
import sandboxxGiftCardHorizontalBackSrc from "media/photos/wallet/sandboxx-gift-card-horizontal-back.png";
import sandboxxGiftCardHorizontalFrontSrc from "media/photos/wallet/sandboxx-gift-card-horizontal.png";

// Validation
import { Validation } from "./validation/validation";

export const WalletGiftCardsPurchaseSelectOption = (props) => {
    const {
        giftCardOptions,
        hasAcceptedTermsAndConditions,
        selectedGiftCardOption,
        setHasAcceptedTermsAndConditions,
    } = props;

    /**
     * Custom Hooks
     */

    const {
        handleClickDeliveryInfo,
        handleSelectGiftCardOption,
        handleSubmit,
        setShouldShowDeliveryInfoModal,
        shouldShowDeliveryInfoModal,
    } = useWalletGiftCardsPurchaseSelectOption(props);

    /**
     * End Hooks
     */

    function renderOptions() {
        if (giftCardOptions) {
            const options = giftCardOptions?.map((option, i) => {
                const { display, id } = option;
                return (
                    <ButtonPill
                        cypressTestId={`wallet-gift-card-purchase-select-option-option-${i}`}
                        isActive={id === selectedGiftCardOption?.id}
                        key={id}
                        onClick={() => handleSelectGiftCardOption(option)}
                    >
                        <span>{display}</span>
                    </ButtonPill>
                );
            });
            return <div className={styles.options}>{options}</div>;
        } else {
            return (
                <div className={styles.options}>
                    <ButtonPill isLoading />
                    <ButtonPill isLoading />
                    <ButtonPill isLoading />
                    <ButtonPill isLoading />
                </div>
            );
        }
    }

    return (
        <div className={styles["wallet-gift-cards-purchase-select-option"]}>
            <WalletGiftCardsPurchaseSelectOptionDeliveryInfoModal
                setShouldShowDeliveryInfoModal={setShouldShowDeliveryInfoModal}
                shouldShowDeliveryInfoModal={shouldShowDeliveryInfoModal}
            />
            <div className={styles.images}>
                <div className={cx(styles.image, styles["image--front"])}>
                    <img
                        alt="Sandboxx Gift Card Front Side"
                        src={sandboxxGiftCardHorizontalFrontSrc}
                    />
                </div>
                <div className={cx(styles.image, styles["image--back"])}>
                    <img
                        alt="Sandboxx Gift Card Back Side"
                        src={sandboxxGiftCardHorizontalBackSrc}
                    />
                </div>
            </div>
            <div className={styles["choose-value"]}>
                <div className={styles.header}>
                    <Text type="h2">Choose Value (USD)</Text>
                </div>
                {renderOptions()}
            </div>
            <div className={styles["delivery-method"]}>
                <div className={styles.header}>
                    <Text type="h2">Delivery Method - Mail</Text>
                    <div
                        className={styles.icon}
                        onClick={handleClickDeliveryInfo}
                    >
                        <InfoIcon />
                    </div>
                </div>
                <Text classes={styles.disclaimer} type="caption">
                    Sandboxx Gift Cards are shipped separately from letters and
                    are mailed within 3 business days.
                </Text>
            </div>
            <div className={styles.buttons}>
                <Checkbox
                    checked={hasAcceptedTermsAndConditions}
                    classes={styles.checkbox}
                    cyId="wallet-gift-cards-purchase-select-option-checkbox"
                    id="wallet-gift-cards-purchase-select-option-checkbox"
                    onChange={() =>
                        setHasAcceptedTermsAndConditions((prev) => !prev)
                    }
                >
                    <span>
                        I accept the Sandboxx Gift Card{" "}
                        <a
                            className={styles.link}
                            href="https://sandboxx.us/sandboxx-giftcard-terms-and-conditions"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Terms & Conditions
                        </a>
                    </span>
                </Checkbox>
                <ButtonPrimary
                    classes={styles.button}
                    cypressTestId="wallet-gift-cards-purchase-select-option-submit-button"
                    isDisabled={
                        !Validation.canSubmit(
                            selectedGiftCardOption,
                            hasAcceptedTermsAndConditions
                        )
                    }
                    onClick={handleSubmit}
                    text="Continue"
                />
            </div>
        </div>
    );
};

WalletGiftCardsPurchaseSelectOption.propTypes = {
    giftCardOptions: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
    hasAcceptedTermsAndConditions: PropTypes.bool.isRequired,
    selectedGiftCardOption: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.object,
    ]),
    setCurrentSection: PropTypes.func.isRequired,
    setHasAcceptedTermsAndConditions: PropTypes.func.isRequired,
    setOrderProgress: PropTypes.func.isRequired,
    setSelectedGiftCardOption: PropTypes.func.isRequired,
};
