// TODO: Method needs to be updated to handle spaces in a string
export function convertStringToCamelCase(str) {
    return str.replace(/([-_][a-z])/gi, (char) =>
        char.toUpperCase().replace("-", "").replace("_", "")
    );
}

// TODO: `execCommand` is deprecated; replace with modern alternative
export function copyTextToClipboard(str) {
    const el = document.createElement("textarea");
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
}

/**
 *
 * @param {Object}
 *  @param {Number} characterLimit the maximum allowed length of text
 *  @param {String} currentValue the text value prior to any incoming change
 *  @param {String} incomingValue the incoming new text value
 * @returns {String} the new text value formatted according to character limit requirements
 */
export function formatValueForCharacterLimit({
    characterLimit,
    currentValue,
    incomingValue,
}) {
    const charactersRemaining = characterLimit - incomingValue.length;
    /**
     * If the incoming text value is below the character limit, then
     * return the incoming text value.
     */
    if (charactersRemaining > 0) {
        return incomingValue;
    }
    /**
     * If the incoming text value meets the character limit, then the
     * current text is returned.
     */
    if (charactersRemaining === 1) {
        return currentValue;
    }
    /**
     * If the incoming text value exceeds the character limit, then only
     * the substring of that value that satisfies the character limit
     * is returned.
     */
    if (charactersRemaining <= 0) {
        return incomingValue.substring(0, characterLimit);
    }
}

/**
 * Takes a fullName string and splits it into an array.
 * Also accounts for a title and will remove it from the array if detected.
 * @param {string} fullName
 * @returns {Array} fullName split into an array, eg: ["John", "Jameson"]
 */
export function splitFullName(fullName) {
    const fullNameSplit = fullName.split(" ");
    fullNameSplit.length === 3 && fullNameSplit.shift();
    return fullNameSplit;
}

export function splitOnFirstWord(str = "") {
    const split = str.split(" ");
    return {
        first: split[0],
        rest: split.slice(1).join(" "),
    };
}

// TODO: Rename or reformat to indicate first/last name context
/* istanbul ignore next */
export function splitOnLastSpace(str) {
    return {
        firstName: str.substring(0, str.lastIndexOf(" ")),
        lastName: str.substring(str.lastIndexOf(" ") + 1, str.length),
    };
}

/* istanbul ignore next */
export function titleCase(str) {
    // eslint-disable-next-line
    if (!str || !str.replace(/\s/g, "").length) {
        return "";
    } // Return empty string if str only contains spaces or is empty
    str = str.trim().replace(/\s\s+/g, " ");
    str = str.toLowerCase().split(" ");
    return str
        .map((word) => {
            const chars = word.split("");
            chars[0] = chars[0].toUpperCase();
            return chars.join("");
        })
        .join(" ");
}
