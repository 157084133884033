import { useMemo, useState } from "react";

export const useToggleOnFocus = (initialState = false) => {
    const [isFocusing, toggle] = useState(initialState);

    const focusEventHandlers = useMemo(
        () => ({
            onFocus: () => toggle(true),
            onBlur: () => toggle(false),
        }),
        []
    );

    return [isFocusing, focusEventHandlers];
};
