import { useContext, useEffect, useState } from "react";

// Contexts
import { ContactsContext } from "context/contacts";
import { PurchaseContext } from "context/purchase";

// Enums
import { NavigationEnum } from "../enums/NavigationEnum";

// Models
import { Contact } from "models/contacts/Contact";

// Utils
import { CurrentUser, SandboxxRestAPI } from "utils/sandboxx";
import { generateWalletGiftCardPurchasePayload } from "../utils/utils";

export const useWalletGiftCardsPurchase = ({ showNotification }) => {
    const currentUser = CurrentUser.getUser() || {};

    /**
     * useContext
     */

    const { contacts, fetchContacts } = useContext(ContactsContext);
    const { fetchSavedCards, savedCards } = useContext(PurchaseContext);

    /**
     * useState
     */

    const [currentSection, setCurrentSection] = useState(
        NavigationEnum.SELECT_OPTION
    );
    const [giftCardOptions, setGiftCardOptions] = useState(null);
    const [hasAcceptedTermsAndConditions, setHasAcceptedTermsAndConditions] =
        useState(false);
    const [loading, setLoading] = useState({
        braintreeNewCard: false,
        purchase: false,
    });
    const [orderNumber, setOrderNumber] = useState(null);
    const [orderProgress, setOrderProgress] = useState({
        giftCardOption: false,
        paymentMethod: false,
        recipient: false,
        sender: false,
    });
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [recipient, setRecipient] = useState(null);
    const [recipientSource, setRecipientSource] = useState(null);
    const [selectedGiftCardOption, setSelectedGiftCardOption] = useState(null);
    const [sender, setSender] = useState({
        email: currentUser.email || "",
        message: "",
        name: "",
    });
    const [suggestedContacts, setSuggestedContacts] = useState(null);

    /**
     * useEffect
     */

    useEffect(() => {
        fetchContacts();
        fetchGiftCardOptions();
        fetchSavedCards();
        fetchSuggestedContacts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * End Hooks
     */

    function clearRecipient() {
        setRecipient(null);
    }

    function fetchGiftCardOptions() {
        return SandboxxRestAPI.getWalletGiftCardOptionsV2(
            onFetchGiftCardOptionsSuccess
        );
    }

    function fetchSuggestedContacts() {
        SandboxxRestAPI.getWalletSuggestedContacts(
            onFetchSuggestedContactsSuccess
        );
    }

    function handleSelectRecipient(recipient, recipientSource) {
        setRecipient(recipient);
        setRecipientSource(recipientSource);
    }

    function onFetchGiftCardOptionsSuccess({ values }) {
        setGiftCardOptions(values);
    }

    function onFetchSuggestedContactsSuccess({ contacts }) {
        const formattedContacts = contacts.map(
            (contact) => new Contact(contact)
        );
        setSuggestedContacts(formattedContacts);
    }

    function onPurchaseGiftCardFailure(err) {
        setLoading((prev) => ({ ...prev, purchase: false }));
        showNotification({ text: err.message, type: "warning" });
    }

    function onPurchaseGiftCardSuccess({ orderNumber }) {
        setOrderNumber(orderNumber);
        setCurrentSection(NavigationEnum.CONFIRMATION);
        setLoading((prev) => ({ ...prev, purchase: false }));
    }

    function purchaseGiftCard() {
        setLoading((prev) => ({ ...prev, purchase: true }));
        const payload = generateWalletGiftCardPurchasePayload({
            paymentMethod,
            recipient,
            selectedGiftCardOption,
            sender,
        });
        return SandboxxRestAPI.purchaseWalletGiftCardV2(
            payload,
            onPurchaseGiftCardSuccess,
            onPurchaseGiftCardFailure,
            onPurchaseGiftCardFailure
        );
    }

    function resetPurchaseState() {
        setLoading({
            braintreeNewCard: false,
        });
        setOrderNumber(null);
        setOrderProgress({
            giftCardOption: false,
            paymentMethod: false,
            recipient: false,
            sender: false,
        });
        setPaymentMethod(null);
        setRecipient(null);
        setRecipientSource(null);
        setSelectedGiftCardOption(null);
        setSender({
            email: currentUser.email || "",
            message: "",
            name: "",
        });
    }

    return {
        clearRecipient,
        contacts,
        currentSection,
        giftCardOptions,
        handleSelectRecipient,
        hasAcceptedTermsAndConditions,
        loading,
        orderNumber,
        orderProgress,
        paymentMethod,
        purchaseGiftCard,
        recipient,
        recipientSource,
        resetPurchaseState,
        savedCards,
        selectedGiftCardOption,
        sender,
        setCurrentSection,
        setHasAcceptedTermsAndConditions,
        setLoading,
        setOrderProgress,
        setPaymentMethod,
        setRecipientSource,
        setSelectedGiftCardOption,
        setSender,
        suggestedContacts,
    };
};
