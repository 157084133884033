export function LockIcon({
    className,
    height = "16px",
    width = "16px",
    color = "#a2a2a2",
}) {
    return (
        <svg
            className={className}
            width={width}
            height={height}
            viewBox="0 0 12 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.0902 5.95634V3.78042C4.0902 3.05511 4.52538 1.60449 6.26612 1.60449C8.00686 1.60449 8.44205 3.05511 8.44205 3.78042V5.95634M4.0902 5.95634H2.45825V11.3962H10.074V5.95634H8.44205M4.0902 5.95634H8.44205"
                stroke={color}
                strokeWidth="0.815972"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
