import React, { useContext } from "react";
import PropTypes from "prop-types";

// Context
import { NavBarContext } from "context/navbar";

// Components
import { ButtonPrimary } from "components/Buttons/Buttons";
import { Modal } from "components/Modals/Modal";
import { SandboxxIcon } from "components/Icons/SandboxxIcon";
import { Text } from "components/Text/Text";

// CSS
import styles from "./sign-in-password-reset-success-modal.module.scss";

// Localization
import { useTranslation } from "localization/localization";

export const SignInPasswordResetSuccessModal = ({
    history,
    setShouldShowSuccessModal,
    shouldShowSuccessModal,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useContext
     */

    const { runShouldShowNavBarCheck } = useContext(NavBarContext);

    function handleModalClose() {
        history.push("/waypoints/chat");
        runShouldShowNavBarCheck();
        setShouldShowSuccessModal(false);
    }

    function renderBody() {
        return (
            <div className={styles.body}>
                <div className={styles.iconCircle}>
                    <SandboxxIcon color="white" />
                </div>
                <div className={styles.textContainer}>
                    <div className={styles.header}>
                        <Text type="h4" isBold={true}>
                            {t("sign_in_password_reset.change_password", {
                                ns: "onboarding",
                            })}
                        </Text>
                    </div>
                    <div className={styles.bodyText}>
                        <Text type="p">
                            {t("sign_in_password_reset.password_updated", {
                                ns: "onboarding",
                            })}
                        </Text>
                    </div>
                </div>
            </div>
        );
    }

    function renderButtons() {
        return (
            <div className={styles.buttons}>
                <ButtonPrimary
                    classes={styles.button}
                    cypressTestId="sign-in-password-reset-success-modal-ok-button"
                    onClick={handleModalClose}
                >
                    <Text>{t("ok", { ns: "common" })}</Text>
                </ButtonPrimary>
            </div>
        );
    }

    return (
        <Modal
            centered
            className={styles["sign-in-password-reset-success-modal"]}
            dialogClassName={
                styles["sign-in-password-reset-success-modal-dialog"]
            }
            onHide={handleModalClose}
            shouldHideHeader={true}
            headerSubText=""
            show={shouldShowSuccessModal}
            size="sm"
        >
            <div className={styles.content}>
                {renderBody()}
                {renderButtons()}
            </div>
        </Modal>
    );
};

SignInPasswordResetSuccessModal.propTypes = {
    setShouldShowSuccessModal: PropTypes.func.isRequired,
    shouldShowSuccessModal: PropTypes.bool.isRequired,
};
