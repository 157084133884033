export function generateWalletGiftCardPurchasePayload({
    paymentMethod,
    recipient,
    selectedGiftCardOption,
    sender,
}) {
    const recipientEmailPayload = recipient.email
        ? { email: recipient.email }
        : {};
    return {
        message: { body: sender.message, from: sender.name },
        options: { valueOptionId: selectedGiftCardOption.id },
        payment: {
            paymentMethod: "BRAINTREE",
            paymentToken: paymentMethod.token,
        },
        recipient: {
            ...recipientEmailPayload,
            contactResource: { id: recipient.id, source: recipient.source },
        },
        sender: { email: sender.email },
    };
}
