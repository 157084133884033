import { useContext, useEffect, useMemo, useState } from "react";

// Context
import { CartContext } from "context/Cart/cart";

// Hooks
import { useImagePreloader } from "hooks/images/useImagePreloader";

export const useMemoryBookImage = () => {
    /**
     * useContext
     */

    const { cart } = useContext(CartContext);

    /**
     * useState
     */

    const [hasLoaded, setHasLoaded] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [nextImageIndex, setNextImageIndex] = useState(1);
    const [fade, setFade] = useState(false);

    /**
     * useMemo
     */

    const memoryBookItem = useMemo(
        () =>
            cart?.items.find(
                (item) => item?.variant?.product?.handle === "memory-book"
            ),
        [cart?.items]
    );

    const images = useMemo(
        () =>
            memoryBookItem
                ? memoryBookItem?.variant?.product?.images?.map(
                      (img) => img.url
                  )
                : [],
        [memoryBookItem]
    );

    /**
     * useEffect
     */

    useEffect(() => {
        if (memoryBookItem && images?.length) {
            handleSwitchBackground();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fade, images, memoryBookItem]);

    /**
     * Custom Hooks
     */

    useImagePreloader({ images });

    /**
     * End Hooks
     */

    const handleSwitchBackground = () => {
        if (hasLoaded) {
            setTimeout(() => {
                fade
                    ? setCurrentImageIndex(
                          (currentImageIndex + 2) % images?.length
                      )
                    : setNextImageIndex((nextImageIndex + 2) % images?.length);
            }, 1000);
        }
        return setTimeout(() => {
            !hasLoaded && setHasLoaded(true);
            setFade((prev) => !prev);
        }, 5000);
    };

    return {
        cart,
        currentImageIndex,
        fade,
        images,
        nextImageIndex,
    };
};
