import React from "react";
import cx from "classnames";

// Components
import { SavedPaymentOptionsOption } from "components/PaymentOption/SavedPaymentOptions";

// CSS
import styles from "./wallet-gift-cards-purchase-order-progress.module.scss";

// Media
import sandboxxGiftCardHorizontalSrc from "media/photos/wallet/sandboxx-gift-card-horizontal.png";
import sandboxxGiftCardVerticalSrc from "media/photos/wallet/sandboxx-gift-card-vertical.png";

export const WalletGiftCardsPurchaseOrderProgress = (props) => {
    const {
        isFullWidth,
        orderProgress,
        paymentMethod,
        selectedGiftCardOption,
        recipient,
        sender,
    } = props;

    function renderImage() {
        const src = isFullWidth
            ? sandboxxGiftCardVerticalSrc
            : sandboxxGiftCardHorizontalSrc;
        return (
            <div className={styles.image}>
                <img alt="Sandboxx Gift Card" src={src} />
            </div>
        );
    }

    function renderMessage() {
        return (
            orderProgress.sender && (
                <div className={cx(styles.section, styles["message"])}>
                    <div className={cx(styles.item, styles["item--column"])}>
                        <div className={styles.key}>
                            <span>Message:</span>
                        </div>
                        <div className={styles.value}>
                            <span>{sender.message}</span>
                        </div>
                    </div>
                </div>
            )
        );
    }

    function renderPaymentMethod() {
        return (
            orderProgress.paymentMethod &&
            paymentMethod && (
                <div className={cx(styles.section, styles["payment-method"])}>
                    <div className={styles.item}>
                        <div className={styles.key}>
                            <span>Payment:</span>
                        </div>
                        <div className={styles.value}>
                            <SavedPaymentOptionsOption
                                card={paymentMethod}
                                classNames={{
                                    cardNumber: styles.cardNumber,
                                    container: styles.card,
                                    expirationDate: styles.expirationDate,
                                    icon: styles.icon,
                                }}
                                config={{ shouldShowLongNumber: true }}
                            />
                        </div>
                    </div>
                </div>
            )
        );
    }

    function renderSelectedGiftCardOption() {
        const { cardValue, fee, totalCharge } = selectedGiftCardOption || {};
        return (
            orderProgress.giftCardOption && (
                <div className={cx(styles.section, styles["line-items"])}>
                    <div className={styles.item}>
                        <div className={styles.key}>
                            <span>Amount on Card:</span>
                        </div>
                        <div className={styles.value}>
                            <span>{cardValue.display}</span>
                        </div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.key}>
                            <span>Card Activation Fee:</span>
                        </div>
                        <div className={styles.value}>
                            <span>{fee.display}</span>
                        </div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.key}>
                            <span>Total Cost:</span>
                        </div>
                        <div className={styles.value}>
                            <span>{totalCharge.display}</span>
                        </div>
                    </div>
                </div>
            )
        );
    }

    function renderSenderRecipients() {
        return (
            (orderProgress.recipient || orderProgress.sender) && (
                <div className={cx(styles.section, styles["sender-recipient"])}>
                    {orderProgress.sender && (
                        <div className={styles.item}>
                            <div className={styles.key}>
                                <span>From:</span>
                            </div>
                            <div className={styles.value}>
                                <span>{sender.name}</span>
                            </div>
                        </div>
                    )}
                    {orderProgress.recipient && (
                        <div className={styles.item}>
                            <div className={styles.key}>
                                <span>To:</span>
                            </div>
                            <div className={styles.value}>
                                <span>
                                    {recipient.firstName} {recipient.lastName}
                                </span>
                                <span>{recipient.address.line1}</span>
                                <span>{recipient.address.line2}</span>
                                <span>
                                    {recipient.address.city},{" "}
                                    {recipient.address.state}{" "}
                                    {recipient.address.zipcode}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            )
        );
    }

    return (
        <div
            className={cx(styles["wallet-gift-cards-purchase-order-progress"], {
                [styles[
                    "wallet-gift-cards-purchase-order-progress--full-width"
                ]]: isFullWidth,
            })}
        >
            {renderImage()}
            <div className={styles.body}>
                {renderSelectedGiftCardOption()}
                {renderSenderRecipients()}
                {renderMessage()}
                {renderPaymentMethod()}
            </div>
        </div>
    );
};
