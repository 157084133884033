export function PhoneIcon({ className, height, width, color }) {
    return (
        <svg
            className={className}
            width={width}
            height={height}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.5791 20.0009C12.2485 20.0009 8.25764 17.1906 5.54386 14.4532C2.6606 11.5936 -0.307407 7.31105 0.0256559 3.87991C0.0970502 3.2149 0.39649 2.59513 0.873095 2.1259L2.47929 0.519712C2.81194 0.186952 3.26318 0 3.73369 0C4.20421 0 4.65545 0.186952 4.9881 0.519712L7.77479 3.30641C8.10755 3.63906 8.29451 4.0903 8.29451 4.56081C8.29451 5.03133 8.10755 5.48257 7.77479 5.81522L6.09765 7.48842L12.5086 13.9014L14.1897 12.2183C14.5224 11.8855 14.9736 11.6986 15.4441 11.6986C15.9146 11.6986 16.3659 11.8855 16.6985 12.2183L19.4813 15.0129C20.1729 15.7054 20.1729 16.8272 19.4813 17.5197L17.8751 19.1279C17.4049 19.6042 16.7846 19.9036 16.1191 19.9753C15.9417 19.9911 15.7663 20.0009 15.5791 20.0009ZM3.74453 1.1819C3.5871 1.18151 3.43601 1.24393 3.32475 1.35533L1.70871 2.95561C1.42479 3.23277 1.24579 3.59983 1.20222 3.99421C0.96178 6.48135 2.99366 10.2593 6.37948 13.6156C9.74164 17.0073 13.5196 19.0392 16.0068 18.7968C16.3989 18.7532 16.7634 18.5741 17.0375 18.2903L18.6457 16.6841C18.8762 16.4533 18.8762 16.0793 18.6457 15.8485L15.859 13.0618C15.748 12.9505 15.5973 12.8879 15.4402 12.8879C15.283 12.8879 15.1323 12.9505 15.0214 13.0618L12.9264 15.1568C12.6956 15.3873 12.3217 15.3873 12.0908 15.1568L4.84423 7.91017C4.61369 7.67933 4.61369 7.30538 4.84423 7.07455L6.93918 4.97763C7.16972 4.7468 7.16972 4.37285 6.93918 4.14202L4.15249 1.35533C4.04414 1.2468 3.89786 1.18461 3.74453 1.1819Z"
                fill={color}
            />
        </svg>
    );
}

PhoneIcon.defaultProps = {
    className: "",
    height: "25px",
    width: "25px",
    color: "#212121",
};
