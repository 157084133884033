import React, { useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// CSS
import styles from "./tabs.module.scss";

// Utils
import { calculateHighlightBarLeftValue } from "./utils/utils";
import { handleKeyDown } from "utils/eventUtils";

export const Tabs = ({ data }) => {
    /**
     * useState
     */

    const [activeIndex, setActiveIndex] = useState(0);

    /**
     * Constants
     */

    const highlightBarLeftValue = calculateHighlightBarLeftValue(
        data,
        activeIndex
    );

    /**
     * End Hooks
     */

    function handleTabSelect({ index, onClick }) {
        setActiveIndex(index);
        onClick(index);
    }

    function renderTabs() {
        return data.map((tab) => {
            const { id, isActive, text, cyId } = tab;
            return (
                <div
                    className={cx(styles.tab, {
                        [styles["tab--active"]]: isActive,
                        [styles["tab--single"]]: data.length === 1,
                    })}
                    data-cy={cyId}
                    key={id}
                    onClick={() => handleTabSelect(tab)}
                    onKeyDown={(e) =>
                        handleKeyDown(e, () => handleTabSelect(tab))
                    }
                    tabIndex="0"
                >
                    {text}
                </div>
            );
        });
    }

    return (
        <div className={styles.tabs}>
            <div className={styles.items}>{renderTabs()}</div>
            <div className={styles.highlight}>
                <div
                    className={cx(styles.bar, {
                        [styles["bar--single"]]: data.length === 1,
                    })}
                    style={{ left: `${highlightBarLeftValue}%` }}
                />
            </div>
        </div>
    );
};

Tabs.defaultProps = {
    data: [],
};

Tabs.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            cyId: PropTypes.string,
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            index: PropTypes.number,
            isActive: PropTypes.bool,
            onClick: PropTypes.func,
            text: PropTypes.string,
        })
    ).isRequired,
};
