// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

export const useSupportSquadDetail = ({
    setCurrentSection,
    setShouldShowCreateModal,
    setShouldShowDonateTokensModal,
    setSquadName,
    selectedSquad,
}) => {
    function handleOpenAdminSettings() {
        AnalyticsLogger.logSquadManageSquadSelected();
        setCurrentSection("settings");
        setSquadName(selectedSquad.squadName);
        setShouldShowCreateModal(true);
    }

    function handleOpenMembersList() {
        setCurrentSection("members");
        setShouldShowCreateModal(true);
    }

    function handleOpenDonateTokenModal() {
        AnalyticsLogger.logSquadDonateTokens();
        setShouldShowDonateTokensModal(true);
    }

    return {
        handleOpenAdminSettings,
        handleOpenDonateTokenModal,
        handleOpenMembersList,
    };
};
