import React from "react";
import PropTypes from "prop-types";

// Components
import { ButtonInvisible, ButtonPrimary } from "components/Buttons/Buttons";
import { ErrorCircleIcon } from "components/Icons/ErrorCircleIcon";

// CSS
import styles from "./muster-recruit-invite-code-failure-modal.module.scss";

export const MusterRecruitInviteCodeFailureModal = (props) => {
    const { failureText, handleGoBack, handleModalClose } = props;

    function renderBody() {
        return (
            <div className={styles.body} data-cy="invite-to-muster-failure">
                <ErrorCircleIcon height="55" width="55" />
                <span className={styles.failureTextHeader}>
                    Something went wrong
                </span>
                <span className={styles.failureText}>{failureText}</span>
            </div>
        );
    }

    function renderButtons() {
        return (
            <div className={styles.buttons}>
                <ButtonPrimary
                    classes={styles.button}
                    cypressTestId="invite-to-muster-failure-retry-button"
                    onClick={handleGoBack}
                    text="TRY AGAIN"
                    type="button"
                />
                <ButtonInvisible
                    classes={styles.button}
                    cypressTestId="invite-to-muster-failure-cancel-button"
                    onClick={handleModalClose}
                    text="Cancel"
                    type="button"
                />
            </div>
        );
    }

    return (
        <div className={styles["muster-recruit-invite-code-failure"]}>
            {renderBody()}
            {renderButtons()}
        </div>
    );
};

MusterRecruitInviteCodeFailureModal.propTypes = {
    handleGoBack: PropTypes.func.isRequired,
    handleModalClose: PropTypes.func.isRequired,
};
