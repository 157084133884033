import { useState } from "react";

// Enums
import { NavigationEnum } from "../../../enums/NavigationEnum";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

export const useWalletGiftCardsPurchaseSelectOption = (props) => {
    const { setCurrentSection, setOrderProgress, setSelectedGiftCardOption } =
        props;

    /**
     * useState
     */

    const [shouldShowDeliveryInfoModal, setShouldShowDeliveryInfoModal] =
        useState(false);

    /**
     * End Hooks
     */

    function handleClickDeliveryInfo() {
        AnalyticsLogger.logGiftCardDeliveryInfoViewed();
        setShouldShowDeliveryInfoModal(true);
    }

    function handleSelectGiftCardOption(option) {
        AnalyticsLogger.logGiftCardAmountSelected({ option });
        setSelectedGiftCardOption(option);
    }

    function handleSubmit() {
        setOrderProgress((prev) => ({ ...prev, giftCardOption: true }));
        setCurrentSection(NavigationEnum.RECIPIENT);
    }

    return {
        handleClickDeliveryInfo,
        handleSelectGiftCardOption,
        handleSubmit,
        setShouldShowDeliveryInfoModal,
        shouldShowDeliveryInfoModal,
    };
};
