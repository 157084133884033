export function HeartIcon({ height, width }) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 21 19"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g
                id="Web"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g
                    id="Profile-+Address-book"
                    transform="translate(-318.000000, -515.000000)"
                    fill="#000000"
                    fillRule="nonzero"
                >
                    <g
                        id="noun_Heart_778890"
                        transform="translate(318.000000, 515.000000)"
                    >
                        <path
                            d="M19.1050094,1.85041312 C16.7498754,-0.469982 13.0141456,-0.603338041 10.4965886,1.42367378 C7.97903157,-0.603338041 4.24330177,-0.469982 1.88816778,1.85041312 C-0.629389258,4.33083548 -0.629389258,8.33151671 1.88816778,10.8119391 C1.91523828,10.8386103 1.91523828,10.8386103 1.94230879,10.8386103 L10.03639,18.8133015 C10.1717425,18.9466576 10.3341656,19 10.5236591,19 C10.7131526,19 10.8755757,18.9466576 11.0109282,18.8133015 L19.0508684,10.8919527 C19.0779389,10.8652815 19.1050094,10.8386103 19.1591504,10.8119391 C21.6225665,8.33151671 21.6225665,4.33083548 19.1050094,1.85041312 Z M10.4965886,17.3997275 L2.83563548,9.85177558 C2.83563548,9.85177558 2.80856497,9.82510437 2.80856497,9.82510437 C0.859488557,7.87810617 0.859488557,4.7309036 2.83563548,2.7839054 C4.8117824,0.836907203 8.00610207,0.836907203 9.98224899,2.75723419 C9.98224899,2.75723419 10.0093195,2.7839054 10.0093195,2.7839054 C10.144672,2.91726144 10.3070951,2.97060386 10.4965886,2.97060386 C10.6590116,2.97060386 10.8485052,2.91726144 10.9838577,2.7839054 C10.9838577,2.7839054 11.0109282,2.75723419 11.0109282,2.75723419 C12.9870751,0.836907203 16.1813948,0.836907203 18.1575417,2.7839054 C20.1066181,4.70423239 20.1336886,7.85143496 18.2116827,9.79843316 C18.1846122,9.82510437 18.1575417,9.82510437 18.1575417,9.85177558 L10.4965886,17.3997275 Z"
                            id="Shape"
                        ></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}

HeartIcon.defaultProps = {
    height: "19px",
    width: "21px",
};
