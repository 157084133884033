import React from "react";

// Components
import { Skeleton } from "@mui/material";

// CSS
import styles from "./cart-shipping-address-loading.module.scss";

export const CartShippingAddressLoading = () => {
    return (
        <div className={styles.cartShippingAddressLoading}>
            <div className={styles.address}>
                <Skeleton variant="text" width={150} />
                <Skeleton variant="text" width={200} />
                <Skeleton variant="text" width={100} />
                <Skeleton variant="text" width={150} />
            </div>
        </div>
    );
};
