import React from "react";
import PropTypes from "prop-types";

// Components
import { ButtonInvisible, ButtonPrimary } from "components/Buttons/Buttons";
import { ConfirmVerificationCodeInput } from "components/Inputs/ConfirmVerificationCodeInput/ConfirmVerificationCodeInput";

// CSS
import styles from "./muster-recruit-invite-code-modal.module.scss";

export const MusterRecruitInviteCodeModal = (props) => {
    const {
        handleChange,
        handleModalClose,
        handleSubmit,
        loading,
        verificationCode,
    } = props;

    return (
        <div
            className={styles["muster-recruit-invite-code-modal"]}
            data-cy="muster-recruit-invite-code-modal"
        >
            <div className={styles["options"]}>
                <ConfirmVerificationCodeInput
                    characterType="all"
                    label=""
                    length={8}
                    onChange={handleChange}
                    size="small"
                />
                <div className={styles["buttons"]}>
                    <ButtonPrimary
                        classes={styles["submit-code"]}
                        cypressTestId="muster-join-modal-join-button"
                        onClick={handleSubmit}
                        isDisabled={
                            loading || verificationCode?.length !== 8
                                ? true
                                : false
                        }
                        text={loading ? "Joining..." : "Join Waypoints"}
                        type="button"
                    />
                    <ButtonInvisible
                        cypressTestId="muster-join-modal-cancel-button"
                        onClick={handleModalClose}
                        text="Cancel"
                        type="button"
                    />
                </div>
            </div>
        </div>
    );
};

MusterRecruitInviteCodeModal.propTypes = {
    handleChange: PropTypes.func.isRequired,
    handleModalClose: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    verificationCode: PropTypes.string,
};
