import React, { useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// CSS
import styles from "./facebook-login-button.module.scss";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Platforms
import { Facebook } from "../../../platforms/facebook";

// Constants
const facebookIconSrc = require("media/signin/facebook-icon-blue.png").default;

export const FacebookLoginButton = (props) => {
    const { classes, mode, size, text } = props;

    /**
     * useState
     */

    const [display, setDisplay] = useState({
        buttonText: text,
        isLoading: false,
    });

    /**
     * End Hooks
     */

    function handleFacebookLogin() {
        const callbacks = {
            ...props.callbacks,
            toggleButtonLoading,
        };
        if (mode === "verify") {
            AnalyticsLogger.logDeleteAccountAuthSelected();
        } else {
            AnalyticsLogger.logSignUpStart("facebook", "selectFacebook");
        }
        toggleButtonLoading(true);
        Facebook.login(callbacks, { mode });
    }

    function toggleButtonLoading(isLoading) {
        const buttonText = isLoading && mode !== "verify" ? "Loading..." : text;
        setDisplay((prev) => ({
            ...prev,
            buttonText,
            isLoading,
        }));
    }

    return (
        <button
            className={cx(
                styles["facebook-login-button"],
                "button",
                `button--size-${size}`,
                classes,
                {
                    disabled: display.isLoading,
                }
            )}
            onClick={handleFacebookLogin}
        >
            <img
                alt="Facebook logo"
                className={styles.icon}
                src={facebookIconSrc}
            />
            <span className={styles.text}>{display.buttonText}</span>
        </button>
    );
};

FacebookLoginButton.defaultProps = {
    classes: "",
    size: "big",
};

FacebookLoginButton.propTypes = {
    classes: PropTypes.string,
    mode: PropTypes.string,
    size: PropTypes.string,
    text: PropTypes.string.isRequired,
};
