import React from "react";

// Components
import { Skeleton } from "@mui/material";
import { Text } from "components/Text/Text";

// CSS
import styles from "./support-squad-detail-header-title.module.scss";

export const SupportSquadDetailHeaderTitle = ({
    isFetchingSquad,
    selectedSquad,
}) => {
    function renderContent() {
        return (
            <div className={styles.supportSquadDetailHeaderTitle}>
                <Text
                    classes={styles.title}
                    cyId="support-squad-detail-header-name"
                    isBold
                >
                    {selectedSquad.squadName}
                </Text>
                <Text classes={styles.subtitle}>
                    {selectedSquad.supporteeFullName}
                </Text>
            </div>
        );
    }
    function renderLoading() {
        return (
            <div className={styles.supportSquadDetailHeaderTitle}>
                <Skeleton
                    className={styles.skeleton}
                    height="16px"
                    width="200px"
                />
                <Skeleton
                    className={styles.skeleton}
                    height="14px"
                    width="100px"
                />
            </div>
        );
    }

    return isFetchingSquad ? renderLoading() : renderContent();
};
