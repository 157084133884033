import React from "react";

// Components
import { Text } from "components/Text/Text";

// CSS
import styles from "./cart-help.module.scss";

// Localization
import { useTranslation } from "localization/localization";

// Platforms
import { Intercom } from "platforms/intercom";

export const CartHelp = () => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * End Hooks
     */

    return (
        <div className={styles.cartHelp}>
            <Text>
                {t("cart.help_start", { ns: "components" })}{" "}
                <Text
                    classes={styles.link}
                    onClick={() =>
                        Intercom.showNewMessage(
                            t("customer_happiness_message", {
                                ns: "memory_book",
                            })
                        )
                    }
                >
                    {t("customer_happiness", { ns: "common" })}
                </Text>{" "}
                {t("cart.help_end", { ns: "components" })}
            </Text>
        </div>
    );
};
