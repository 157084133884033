import React, { useEffect } from "react";
import cx from "classnames";

// Components
import { ButtonPrimary } from "components/Buttons/Buttons";
import { PasswordInput } from "components/Inputs/PasswordInput/PasswordInput";
import { Text } from "components/Text/Text";
import { TextFieldValidated } from "components/Inputs/TextFieldValidated/TextFieldValidated";

// CSS
import signInStyles from "../../../sign-in.module.scss";
import signUpNameStyles from "./sign-up-name.module.scss";

// Hooks
import { useSignUpName } from "./hooks/useSignUpName";

// Localization
import { useTranslation } from "localization/localization";

// Utils
import { CurrentUser } from "utils/sandboxx";

export const SignUpName = (props) => {
    const { isUsingEmail, isUsingEmailVerify, setUserInfo, userInfo } = props;
    const { email, firstName, lastName, password } = userInfo;

    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    const {
        handleSubmit,
        hasValidEmail,
        hasValidFirstName,
        hasValidForm,
        hasValidLastName,
        hasValidPassword,
        isLoadingDetailsUpdate,
        passwordType,
    } = useSignUpName(props);

    /**
     * useEffect
     */

    useEffect(() => {
        // Check local storage for value, set state appropriately
        const { email } = CurrentUser.getUser() || {};
        if (email) {
            setUserInfo((prev) => ({ ...prev, email }));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * End Hooks
     */

    function handleChange({ target }) {
        const { name, value } = target;
        setUserInfo({
            ...userInfo,
            [name]: value,
        });
    }

    function renderForm() {
        return (
            <form
                className={cx(signInStyles.form, signUpNameStyles.form, "form")}
                onSubmit={handleSubmit}
            >
                <input type="submit" hidden />
                <TextFieldValidated
                    autoFocus={true}
                    className={signUpNameStyles.input}
                    cypressTestId="sign-up-name-first-name-input"
                    id="firstName"
                    isValid={hasValidFirstName}
                    label={t("first_name", { ns: "common" })}
                    name="firstName"
                    onChange={handleChange}
                    value={firstName}
                />
                <TextFieldValidated
                    className={signUpNameStyles.input}
                    cypressTestId="sign-up-name-last-name-input"
                    id="lastName"
                    isValid={hasValidLastName}
                    label={t("last_name", { ns: "common" })}
                    name="lastName"
                    onChange={handleChange}
                    value={lastName}
                />
                {!isUsingEmail && (
                    <TextFieldValidated
                        className={signUpNameStyles.input}
                        cypressTestId="sign-up-name-email-input"
                        disabled={isUsingEmailVerify}
                        id="email"
                        isValid={hasValidEmail}
                        label={t("email", { ns: "common" })}
                        name="email"
                        onChange={handleChange}
                        value={email}
                    />
                )}
                {!isUsingEmail && email && (
                    <PasswordInput
                        className={signUpNameStyles.input}
                        cypressTestId="sign-up-name-password-input"
                        id="password"
                        isValid={hasValidPassword}
                        label={t("password_8_characters_requirement", {
                            ns: "common",
                        })}
                        name="password"
                        onChange={handleChange}
                        type={passwordType}
                        value={password}
                    />
                )}
                <ButtonPrimary
                    classes={signInStyles.button}
                    cypressTestId="sign-up-name-next-button"
                    isDisabled={!hasValidForm}
                    isLoading={isLoadingDetailsUpdate}
                    onClick={(e) => handleSubmit(e)}
                    type="submit"
                >
                    <Text>{t("sign_up", { ns: "common" })}</Text>
                </ButtonPrimary>
            </form>
        );
    }

    return (
        <div
            className={cx(
                signInStyles.content,
                signUpNameStyles["sign-up-name"]
            )}
        >
            <div className={signInStyles.top}>
                <div className={signInStyles.header}>
                    <span
                        className={signInStyles.text}
                        data-cy="sign-in-content-top-header-text"
                    >
                        {t("sign_up_name.title", { ns: "onboarding" })}
                    </span>
                    <span className={signInStyles["text-secondary"]}>
                        {t("sign_up_name.subtitle", { ns: "onboarding" })}
                    </span>
                </div>
                {renderForm()}
            </div>
        </div>
    );
};
