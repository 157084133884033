import React from "react";

// Components
import { ButtonPrimary } from "components/Buttons/Buttons";
import { NotificationCorner } from "components/Notification/NotificationCorner/NotificationCorner";

// CSS
import styles from "./profile-onboarding-notification-corner.module.scss";

// Localization
import { useTranslation } from "localization/localization";

export const ProfileOnboardingNotificationCorner = ({
    handleGoToOnboarding,
    handleShowCompleteProfileNotification,
    shouldShowOnboardingNotification,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * End Hooks
     */

    function handleHideProfileNotificationCorner() {
        handleShowCompleteProfileNotification({
            overrideBool: false,
            shouldOverride: true,
        });
    }

    return (
        <NotificationCorner
            classes={styles["profile-notification-corner"]}
            cyId="profile-onboarding-notification-corner"
            cypressCloseButtonTestId="profile-onboarding-notification-corner-close-button"
            onClose={handleHideProfileNotificationCorner}
            shouldShow={shouldShowOnboardingNotification}
        >
            <span className={styles.text}>
                {t("finish_setting_up_your_profile", { ns: "profile" })}
            </span>
            <ButtonPrimary
                classes={styles.button}
                cypressTestId="profile-onboarding-notification-corner-continue-button"
                onClick={handleGoToOnboarding}
                text={t("continue", { ns: "common" })}
            />
        </NotificationCorner>
    );
};
