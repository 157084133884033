// Utils
import { splitFullName } from "utils/textUtils";

/**
 * This method generates an array of objects that contains name
 * data formatted as expected by the UserList and UserListItem
 * components
 *
 * @param {Array} list of squad members
 * @returns {Array} of formatted name objects
 */
export function generateFormattedSupportSquadMembersListData(list) {
    return list?.map(({ memberFullName, memberId, memberPhotoUrl }) => {
        const fullNameSplit = splitFullName(memberFullName);
        return {
            avatar: memberPhotoUrl,
            firstName: fullNameSplit[0],
            fullName: memberFullName,
            lastName: fullNameSplit[1],
            id: memberId,
        };
    });
}
