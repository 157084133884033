// Utils
import { removeHTMLTags } from "utils/htmlUtils";

/**
 * Formats news item data so that it conforms to the prop requirements
 * of the PreviewCard component
 *
 * @param {Object} param0
 *  @param {Object} param0.newsItem
 * @returns {Object} containing news item data
 */
export function formatNewsItemForPreviewCard({ newsItem }) {
    const { contentHtml, id, image, title, url } = newsItem;
    return {
        id,
        imageSrc: image,
        text: {
            body: removeHTMLTags(contentHtml),
            cta: "Read More",
            header: title,
        },
        urls: { card: url, cta: url },
    };
}
