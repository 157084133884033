import React from "react";
import { Modal } from "components/Modals/Modal";

// Components
import { ButtonInvisible, ButtonPrimary } from "components/Buttons/Buttons";
import { Text } from "components/Text/Text";

// Localization
import { useTranslation } from "localization/localization";

export const LettersComposeModalNoCredits = ({
    handlePurchaseModalToggle,
    setDisplay,
    showNoCreditsModal,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * End Hooks
     */

    const buttonPrimaryStyle = { marginBottom: 0 };

    function onClick() {
        onHide();
        handlePurchaseModalToggle();
    }

    function onHide() {
        setDisplay((prev) => ({
            ...prev,
            showNoCreditsModal: false,
        }));
    }

    return (
        <Modal
            className="letters__modal__no-credits"
            cypressTestId="letters-compose-no-credits-modal"
            onHide={onHide}
            shouldHideHeader={true}
            show={showNoCreditsModal}
        >
            <div className="letters__modal__no-credits__body">
                <div className="letters__modal__no-credits__header">
                    <span>{t("oops", { ns: "common" })}</span>
                </div>
                <div className="letters__modal__no-credits__text">
                    <span>
                        {t("you_dont_have_enough_credits", { ns: "letters" })}
                    </span>
                </div>
                <ButtonPrimary onClick={onClick} style={buttonPrimaryStyle}>
                    <Text>{t("buy_credits", { ns: "common" })}</Text>
                </ButtonPrimary>
                <ButtonInvisible onClick={onHide}>
                    <Text>{t("no_thanks", { ns: "common" })}</Text>
                </ButtonInvisible>
            </div>
        </Modal>
    );
};
