import React, { useState } from "react";
import cx from "classnames";

// Components
import { ProfilePersonaModalSelectChild } from "./components/ProfilePersonaModalSelectChild";
import { ProfilePersonaModalSelectRoot } from "./components/ProfilePersonaModalSelectRoot";

// CSS
import styles from "./profile-persona-selection.module.scss";

// Enums
import { NavigationEnum } from "./constants/constants.js";

export const ProfilePersonaModalSelect = ({
    childPersonas,
    handleSelectProfileChildPersona,
    handleSelectRootPersona,
    isLoadingChildPersonas,
    onComplete,
    rootPersonas,
}) => {
    /**
     * useState
     */

    const [currentSection, setCurrentSection] = useState(NavigationEnum.ROOT);

    /**
     * Constants
     */

    const lastChildPersonasArray = childPersonas[childPersonas?.length - 1];
    const shouldDisplayPersonasGrid = childPersonas?.length > 3;

    /**
     * End Hooks
     */

    return (
        <div
            className={cx(styles.personas, {
                [styles["personas--grid"]]:
                    isLoadingChildPersonas || shouldDisplayPersonasGrid,
            })}
        >
            {currentSection === NavigationEnum.ROOT ? (
                <ProfilePersonaModalSelectRoot
                    handleSelectRootPersona={handleSelectRootPersona}
                    rootPersonas={rootPersonas}
                    NavigationEnum={NavigationEnum}
                    setCurrentSection={setCurrentSection}
                />
            ) : (
                <ProfilePersonaModalSelectChild
                    handleSelectProfileChildPersona={
                        handleSelectProfileChildPersona
                    }
                    isLoadingChildPersonas={isLoadingChildPersonas}
                    lastChildPersonasArray={lastChildPersonasArray}
                    onComplete={onComplete}
                    setCurrentSection={setCurrentSection}
                />
            )}
        </div>
    );
};
