export class Validation {
    /**
     * Determines whether the user's existing profile address is complete (phone-excluded)
     *
     * @param {Object} param0
     * @param {Object} param0.user
     * @returns {Boolean}
     */
    static hasValidProfileAddress({ user = {} } = {}) {
        return (
            user.city &&
            user.firstName &&
            user.line1 &&
            user.state &&
            user.zipcode
        );
    }

    /**
     * Determines whether provided shipping address can be submitted to cart
     *
     * @param {Object} param0
     * @param {Object} param0.shippingAddress
     * @returns {Boolean}
     */
    static hasValidShippingAddress({ shippingAddress }) {
        return (
            shippingAddress.city &&
            shippingAddress.countryCode &&
            shippingAddress.firstName &&
            shippingAddress.lastName &&
            shippingAddress.line1 &&
            shippingAddress.phone.baseNumber &&
            shippingAddress.phone.countryCode &&
            shippingAddress.phone.isValid &&
            shippingAddress.postalCode &&
            shippingAddress.province
        );
    }
}
