import BraintreeSDK from "braintree-web-drop-in";

// Utils
import { DEV } from "../utils/urlUtils";

// Constants
const braintreeKey = DEV
    ? process.env.REACT_APP_BRAINTREE_KEY_DEV
    : process.env.REACT_APP_BRAINTREE_KEY_PROD;

export class Braintree {
    static createDropinForm(refs, submitCallback) {
        const { submitButton } = refs;
        BraintreeSDK.create(
            {
                authorization: braintreeKey,
                container: "#dropin-container",
            },
            (err, dropinInstance) => {
                if (err) {
                    return;
                }
                submitButton.addEventListener("click", () =>
                    submitCallback(dropinInstance)
                );
            }
        );
    }
}
