import React, { useContext } from "react";
import cx from "classnames";

// Components
import { Skeleton } from "@mui/material";
import { Text } from "components/Text/Text";

// Context
import { CartContext } from "context/Cart/cart";

// CSS
import styles from "./cart-line-items.module.scss";

// Localization
import { useTranslation } from "localization/localization";

// Utils
import { convertWholeNumberToDollars } from "utils/cartUtils";

export const CartLineItems = () => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useContext
     */

    const { cart, isUpdatingCart } = useContext(CartContext);

    /**
     * End Hooks
     */

    if (isUpdatingCart) {
        return (
            <div className={styles.cartLineItems}>
                <div className={styles.item}>
                    <Text>{t("cart.subtotal", { ns: "components" })}</Text>
                    <Skeleton variant="text" width={50} />
                </div>
                <div className={styles.item}>
                    <Text>{t("cart.shipping", { ns: "components" })}</Text>
                    <Skeleton variant="text" width={50} />
                </div>
                <div className={styles.item}>
                    <Text>{t("cart.tax", { ns: "components" })}</Text>
                    <Skeleton variant="text" width={50} />
                </div>
                <div className={styles.item}>
                    <Text isBold>{t("cart.total", { ns: "components" })}</Text>
                    <Skeleton variant="text" width={50} />
                </div>
            </div>
        );
    } else {
        return (
            <div className={styles.cartLineItems}>
                <div className={styles.item}>
                    <Text classes={styles.label}>
                        {t("cart.subtotal", { ns: "components" })}
                    </Text>
                    <Text classes={styles.value}>
                        {convertWholeNumberToDollars(cart?.subtotal)}
                    </Text>
                </div>
                {cart?.discount_total > 0 && (
                    <div className={cx(styles.item, styles["item--discount"])}>
                        <Text classes={styles.label}>
                            {t("cart.discount", { ns: "components" })}
                        </Text>
                        <Text classes={styles.value}>
                            -{convertWholeNumberToDollars(cart?.discount_total)}
                        </Text>
                    </div>
                )}
                <div className={styles.item}>
                    <Text classes={styles.label}>
                        {t("cart.shipping", { ns: "components" })}
                    </Text>
                    <Text classes={styles.value}>
                        {cart?.shipping_methods[0]
                            ? convertWholeNumberToDollars(cart?.shipping_total)
                            : t(
                                  "cart.calculated_after_selecting_shipping_method",
                                  { ns: "components" }
                              )}
                    </Text>
                </div>
                <div className={styles.item}>
                    <Text classes={styles.label}>
                        {t("cart.tax", { ns: "components" })}
                    </Text>
                    <Text classes={styles.value}>
                        {convertWholeNumberToDollars(cart?.tax_total)}
                    </Text>
                </div>
                <div className={styles.item}>
                    <Text classes={styles.label} isBold>
                        {t("cart.total", { ns: "components" })}
                    </Text>
                    <Text classes={styles.value} isBold>
                        {convertWholeNumberToDollars(cart?.total)}
                    </Text>
                </div>
            </div>
        );
    }
};
