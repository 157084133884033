import { useContext, useState } from "react";

// Context
import { CartContext } from "context/Cart/cart";
import { NotificationBarContext } from "context/notificationBar";

export const useCartDiscount = () => {
    /**
     * useContext
     */

    const { applyDiscountCode, cart, isSubmittingCart, isUpdatingCart } =
        useContext(CartContext);
    const { showNotification } = useContext(NotificationBarContext);

    /**
     * useState
     */

    const [discountCode, setDiscountCode] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);

    /**
     * End Hooks
     */

    function handleChange(e) {
        setDiscountCode(e.target.value);
    }

    function handleSubmit() {
        setIsSubmitting(true);
        applyDiscountCode({
            discountCode,
            onFailure: onApplyDiscountCodeFailure,
            onSuccess: onApplyDiscountCodeSuccess,
        });
    }

    function onApplyDiscountCodeFailure(err) {
        showNotification({
            text: err.message,
            type: "warning",
        });
        setIsSubmitting(false);
    }

    function onApplyDiscountCodeSuccess(res) {
        showNotification({
            text: res.message,
            type: "success",
        });
        setIsSubmitting(false);
    }

    return {
        cart,
        discountCode,
        handleChange,
        handleSubmit,
        isSubmitting,
        isSubmittingCart,
        isUpdatingCart,
    };
};
