export const LettersComposeNavigation = Object.freeze({
    COMPOSE: "COMPOSE",
    REVIEW: "REVIEW",
});

export const AddOnsNavigation = Object.freeze({
    SELECT_ITEMS: "SELECT_ITEMS",
    SELECT_DESIGN: "SELECT_DESIGN",
    SELECT_AMOUNT: "SELECT_AMOUNT",
    SELECT_PAYMENT: "SELECT_PAYMENT",
    CONFIRMATION: "CONFIRMATION",
});
