import sha256 from "crypto-js/sha256";

export function generateAttributes(userInfo, { isUsingOnlyNameFields } = {}) {
    if (isUsingOnlyNameFields) {
        return generateNameAttributes(userInfo);
    }
    return generateAllAttributes(userInfo);
}

function generateAllAttributes({ firstName, lastName, email, password }) {
    return {
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: sha256(password).toString(),
    };
}

function generateNameAttributes({ firstName, lastName }) {
    return {
        firstName: firstName,
        lastName: lastName,
    };
}
