import { useEffect } from "react";

export const useSupportSquadSettingsModalCreateInviteSuccess = ({
    setCurrentSection,
    setHeaderText,
    setHeaderSubText,
    setInviteEmails,
}) => {
    /**
     * useEffect
     */

    useEffect(() => {
        setHeaderText("");
        setHeaderSubText("");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * End Hooks
     */

    function handleGoToInviteMore() {
        setCurrentSection("invite");
        setInviteEmails(["", "", ""]);
    }

    return { handleGoToInviteMore };
};
