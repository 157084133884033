import { useState } from "react";

export const useSignInUserInfo = (props) => {
    const [userInfo, setUserInfo] = useState({
        address: {
            city: "",
            line1: "",
            line2: "",
            state: "",
            zipcode: "",
        },
        base: {
            full: "",
            id: "",
        },
        branchId: "",
        depLocation: {},
        email: "",
        firstName: "",
        lastName: "",
        password: "",
        personas: [],
        phoneNumber: { baseNumber: "", countryCode: "", isValid: false },
        rank: "",
        recipient: {
            title: "",
            firstName: "",
            lastName: "",
            fullName: "",
        },
        role: "",
        shipDate: {
            day: "",
            month: "",
            year: "",
        },
        verificationType: "email",
    });

    function handleChangePhoneNumber(isValid, value, countryData) {
        const { dialCode } = countryData;
        setUserInfo((prev) => ({
            ...prev,
            phoneNumber: {
                baseNumber: value,
                countryCode: dialCode,
                isValid,
            },
        }));
    }

    function handleChangeUserInfo({ target }) {
        const { name, value } = target;
        setUserInfo((prev) => ({ ...prev, [name]: value }));
    }

    function handleClearPhoneNumber() {
        const { dialCode } = arguments[1];
        setUserInfo({
            ...userInfo,
            phoneNumber: {
                baseNumber: "",
                countryCode: dialCode,
                isValid: false,
            },
        });
    }

    return {
        handleChangePhoneNumber,
        handleClearPhoneNumber,
        handleChangeUserInfo,
        userInfo,
        setUserInfo,
    };
};
