import React from "react";

// Components
import { WrappedDashboard as Dashboard } from "scenes/Dashboard/Dashboard";
import { Muster } from "scenes/Muster/scenes/Muster";

// Enums
import { PersonaEnum } from "enums/PersonaEnum";

// Utils
import { CurrentUser } from "./sandboxx";

export function generateBaseURLComponent(props) {
    const user = CurrentUser.getUser();
    const rootPersona = user?.personas[0];
    if (
        rootPersona?.id === PersonaEnum.RECRUITER ||
        rootPersona?.id === PersonaEnum.RECRUIT ||
        rootPersona?.id === PersonaEnum.RECRUITER_COMMAND
    ) {
        return <Muster {...props} />;
    } else {
        return <Dashboard {...props} />;
    }
}
