import PropTypes from "prop-types";

export const SaveIcon = ({ className, color }) => {
    return (
        <svg
            className={className}
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.75 2.75V5.3C5.75 5.72004 5.75 5.93006 5.83175 6.09049C5.90365 6.23161 6.01839 6.34635 6.15951 6.41825C6.31994 6.5 6.52996 6.5 6.95 6.5H12.05C12.47 6.5 12.6801 6.5 12.8405 6.41825C12.9816 6.34635 13.0964 6.23161 13.1683 6.09049C13.25 5.93006 13.25 5.72004 13.25 5.3V3.5M13.25 16.25V11.45C13.25 11.03 13.25 10.8199 13.1683 10.6595C13.0964 10.5184 12.9816 10.4037 12.8405 10.3317C12.6801 10.25 12.47 10.25 12.05 10.25H6.95C6.52996 10.25 6.31994 10.25 6.15951 10.3317C6.01839 10.4037 5.90365 10.5184 5.83175 10.6595C5.75 10.8199 5.75 11.03 5.75 11.45V16.25M16.25 7.49411V12.65C16.25 13.9101 16.25 14.5402 16.0048 15.0215C15.789 15.4448 15.4448 15.789 15.0215 16.0048C14.5402 16.25 13.9101 16.25 12.65 16.25H6.35C5.08988 16.25 4.45982 16.25 3.97852 16.0048C3.55516 15.789 3.21095 15.4448 2.99524 15.0215C2.75 14.5402 2.75 13.9101 2.75 12.65V6.35C2.75 5.08988 2.75 4.45982 2.99524 3.97852C3.21095 3.55516 3.55516 3.21095 3.97852 2.99524C4.45982 2.75 5.08988 2.75 6.35 2.75H11.5059C11.8728 2.75 12.0562 2.75 12.2288 2.79145C12.3819 2.82819 12.5282 2.8888 12.6624 2.97104C12.8138 3.0638 12.9435 3.19352 13.2029 3.45294L15.5471 5.79706C15.8065 6.05648 15.9362 6.1862 16.029 6.33757C16.1112 6.47178 16.1718 6.6181 16.2086 6.77115C16.25 6.94378 16.25 7.12723 16.25 7.49411Z"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

SaveIcon.defaultProps = {
    className: "",
    color: "#2B536A",
};

SaveIcon.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
};
