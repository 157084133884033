import React, { useEffect } from "react";

// Components
import { Panel } from "components/Panel/Panel";

// Components
import { WalletGiftCardsLandingFeatures } from "./components/WalletGiftCardsLandingLead/WalletGiftCardsLandingFeatures/WalletGiftCardsLandingFeatures";
import { WalletGiftCardsLandingLead } from "./components/WalletGiftCardsLandingLead/WalletGiftCardsLandingLead/WalletGiftCardsLandingLead";

// CSS
import styles from "./wallet-gift-cards-landing.module.scss";

// Utils
import { scrollToTop } from "utils/scrollUtils";

export const WalletGiftCardsLanding = (props) => {
    /**
     * useEffect
     */

    useEffect(() => {
        scrollToTop();
    }, []);

    /**
     * End Hooks
     */

    return (
        <div className={styles["wallet-gift-cards-landing"]}>
            <Panel classes={styles.panel}>
                <WalletGiftCardsLandingLead {...props} />
                <WalletGiftCardsLandingFeatures />
            </Panel>
        </div>
    );
};
