import React, { useState } from "react";

// Components
import { AddPaymentOption } from "../PaymentOption/AddPaymentOption";
import { ButtonPrimary } from "components/Buttons/Buttons";
import { SavedPaymentOptions } from "../PaymentOption/SavedPaymentOptions";
import { Text } from "components/Text/Text";

// Enums
import { PurchaseModalNavigation } from "./enums/enums";

// Localization
import { useTranslation } from "localization/localization";

export const PurchaseModalPayment = ({
    changeCurrentSection,
    handleCardSelect,
    handleCloseModal,
    loading,
    purchaseMode,
    savedCards,
    selectedCard,
    setLoading,
    setSelectedBundle,
    setSelectedCard,
    showNotification,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useState
     */

    const [newCard, setNewCard] = useState({
        id: null,
        name: null,
        saveCard: true,
        token: null,
    });

    /**
     * End Hooks
     */

    function handleCardInputChange(e) {
        const { name, value } = e.target;
        setNewCard({
            ...newCard,
            [name]: value,
        });
    }

    function handleCardSetToken(token) {
        setNewCard({ ...newCard, token });
    }

    function handleGoBack() {
        setSelectedBundle(null);
        purchaseMode === "lettersPhotoManageModal"
            ? changeCurrentSection(PurchaseModalNavigation.BUNDLE_HIGHLIGHT)
            : changeCurrentSection(PurchaseModalNavigation.BUNDLES);
    }

    function handleSavedCardSelect(card, index, callback) {
        setSelectedCard((prev) => ({
            ...prev,
            card,
            index,
        }));
        callback(card);
    }

    function renderButtons(stripeCardType, submitCallback) {
        return (
            <div className="button__container force-right">
                <button
                    className="button button--invisible button-small"
                    onClick={handleGoBack}
                >
                    <Text>{t("back", { ns: "common" })}</Text>
                </button>
                <ButtonPrimary
                    className="button button--primary button-small"
                    isLoading={loading[stripeCardType]}
                    onClick={submitCallback}
                >
                    <Text>{t("next", { ns: "common" })}</Text>
                </ButtonPrimary>
            </div>
        );
    }

    const savedCardEndItem = { type: "select" };
    return (
        <div className="letters-compose-purchase-payment-container">
            <AddPaymentOption
                changeCurrentSection={changeCurrentSection}
                context="credits"
                handleCardInputChange={handleCardInputChange}
                handleCardSelect={handleCardSelect}
                handleCardSetToken={handleCardSetToken}
                handleCloseModal={handleCloseModal}
                handleGoBack={handleGoBack}
                loading={loading}
                newCard={newCard}
                setLoading={setLoading}
                showNotification={showNotification}
            />
            <div className="new-modal-text-divider horizontal">
                {t("or", { ns: "common" })}
            </div>
            <SavedPaymentOptions
                changeCurrentSection={changeCurrentSection}
                context="credits"
                endItem={savedCardEndItem}
                handleCardSelect={handleCardSelect} // Updates LetterCompose
                handleCloseModal={handleCloseModal}
                handleSavedCardSelect={handleSavedCardSelect} // Updates LettersComposePurchasePayment
                renderButtons={renderButtons}
                savedCards={savedCards}
                selectedCard={selectedCard}
            />
        </div>
    );
};
