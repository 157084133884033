import { useState } from "react";

export const useSlider = ({ images }) => {
    /**
     * useState
     */

    const [sliderIndex, setSliderIndex] = useState(0);

    /**
     * End Hooks
     */

    function handleSelectMarker(i) {
        setSliderIndex(i);
    }

    function handleSelectLeftArrow() {
        if (images[sliderIndex - 1]) {
            setSliderIndex((prev) => prev - 1);
        }
    }

    function handleSelectRightArrow() {
        if (images[sliderIndex + 1]) {
            setSliderIndex((prev) => prev + 1);
        }
    }

    return {
        handleSelectLeftArrow,
        handleSelectMarker,
        handleSelectRightArrow,
        sliderIndex,
    };
};
