import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// Components
import { LockIcon } from "components/Icons/LockIcon";
import { UploadIcon } from "components/Icons/UploadIcon";
import { Text } from "components/Text/Text";

// CSS
import styles from "./button-photo-upload.module.scss";

export const ButtonPhotoUpload = (props) => {
    const {
        classes,
        cypressTestId,
        handleImageFileSelect,
        index,
        isDisabled,
        isLocked,
        isSquare,
        photo,
        onClick,
        shouldShowBoxShadow,
        text,
        type,
    } = props;

    function renderEmpty() {
        return (
            <>
                {isLocked ? (
                    <LockIcon className={styles.icon} color="#a2a2a2" />
                ) : (
                    <UploadIcon className={styles.icon} color="#2b536a" />
                )}
                <Text classes={styles.text} type="caption">
                    {isLocked ? text.locked : text.unlocked}
                </Text>
                {!isLocked && isSquare && (
                    <>
                        <input
                            accept="image/*"
                            className="letters-compose__message__content__buttons__photo__input"
                            data-cy={`${cypressTestId}-input`}
                            id={`image-upload-${index}`}
                            name="image-upload"
                            type="file"
                            onChange={(e) => handleImageFileSelect(e, index)}
                            disabled={false}
                            tabIndex={-1}
                        />
                        <label
                            className="letters-compose__message__content__buttons__photo__input__label"
                            htmlFor={`image-upload-${index}`}
                            tabIndex={-1}
                        />
                    </>
                )}
            </>
        );
    }

    function renderImagePreview() {
        return (
            <>
                <img
                    alt="Placeholder"
                    className={styles.imagePreview}
                    src={photo.url}
                />
                {photo.text && !isSquare && (
                    <Text type="caption">
                        {photo.text.substring(photo.text.lastIndexOf("-") + 1)}
                    </Text>
                )}
            </>
        );
    }

    return (
        <button
            className={cx(
                styles.buttonPhotoUpload,
                {
                    [styles["buttonPhotoUpload--filled"]]: photo.url,
                    [styles["buttonPhotoUpload--locked"]]: isLocked,
                    [styles["buttonPhotoUpload--square"]]: isSquare,
                    [styles["buttonPhotoUpload--shadow"]]:
                        shouldShowBoxShadow && photo.url,
                },
                classes
            )}
            data-cy={cypressTestId}
            disabled={isDisabled}
            onClick={isLocked ? onClick.locked : onClick.unlocked}
            type={type}
        >
            {photo.url ? renderImagePreview() : renderEmpty()}
        </button>
    );
};

ButtonPhotoUpload.defaultProps = {
    shouldShowBoxShadow: true,
};

ButtonPhotoUpload.propTypes = {
    shouldShowBoxShadow: PropTypes.bool,
};
