import React from "react";

// Components
import { ButtonPrimary, ButtonSecondary } from "components/Buttons/Buttons";
import { Text } from "components/Text/Text";
import { WalletGiftCardsPurchaseOrderProgress } from "../WalletGiftCardsPurchaseOrderProgress/WalletGiftCardsPurchaseOrderProgress";

// CSS
import styles from "./wallet-gift-cards-purchase-review.module.scss";

// Enums
import { NavigationEnum } from "../../enums/NavigationEnum";

export const WalletGiftCardsPurchaseReview = (props) => {
    const { loading, purchaseGiftCard, setCurrentSection, setOrderProgress } =
        props;

    function handleGoBack() {
        setOrderProgress((prev) => ({ ...prev, paymentMethod: false }));
        setCurrentSection(NavigationEnum.BILLING_DETAILS);
    }

    function handleSubmit() {
        purchaseGiftCard();
    }

    return (
        <div className={styles["wallet-gift-cards-purchase-review"]}>
            <div className={styles.header}>
                <Text type="h2">Order Review</Text>
            </div>
            <div className={styles["order-progress"]}>
                <WalletGiftCardsPurchaseOrderProgress {...props} isFullWidth />
            </div>
            <div className={styles.disclaimer}>
                <Text isBold type="p">
                    All Sandboxx Gift Card sales are final
                </Text>
            </div>
            <div className={styles.buttons}>
                <ButtonSecondary onClick={handleGoBack} text="Back" />
                <ButtonPrimary
                    cypressTestId="wallet-gift-cards-purchase-review-submit-button"
                    isDisabled={loading.purchase}
                    onClick={handleSubmit}
                    text={loading.purchase ? "Placing Order..." : "Place Order"}
                />
            </div>
        </div>
    );
};
