import React, { useContext, useEffect, useState } from "react";
import lottie from "lottie-web";

// Animations
import animation from "../../lottie-animations/calendar.json";

// Components
import { ButtonPrimary } from "../../components/Buttons/Buttons";
import { HeaderBar } from "../../components/HeaderBar/HeaderBar";
import { LoadingPage } from "../../components/Loading/Loading";
import { Panel } from "components/Panel/Panel";

// Context
import { HeaderBarContext } from "../../context/headerBar";
import { NotificationBarContext } from "../../context/notificationBar";

// CSS
import styles from "./weekly-updates.module.scss";

// Logging
import { AnalyticsLogger } from "../../loggers/AnalyticsLogger";

// Models
import { WeeklyUpdate } from "../../models/weekly-updates/WeeklyUpdate";

// Platforms
import { firebase } from "../../platforms/firebase";
import { SandboxxRestAPI } from "../../utils/sandboxx";

// Scenes
import { WeeklyUpdatesDetail } from "./components/WeeklyUpdatesDetail/WeeklyUpdatesDetail";
import { WeeklyUpdatesList } from "./components/WeeklyUpdatesList/WeeklyUpdatesList";

// Utils
import { generateDepContentTitle, generateDepContentUrl } from "utils/depUtils";
import { CurrentUser } from "../../utils/sandboxx";
import { RedirectUtil } from "../../utils/RedirectUtil";
import { scrollToTop } from "../../utils/scrollUtils";

export const WeeklyUpdates = (props) => {
    const { isDep, location, history } = props;

    /************************************************
     * useContext
     ************************************************/

    const { setHeaderBarContent, headerBarContent } =
        useContext(HeaderBarContext);
    const { showNotification } = useContext(NotificationBarContext);
    /************************************************
     * useState
     ************************************************/

    const [animationRef, setAnimationRef] = useState(null);
    const [categories, setCategories] = useState([]);
    const [currentSection, setCurrentSection] = useState("list");
    const [flags, setFlags] = useState({
        hasFetchedConsumedStatuses: false,
    });
    const [loading, setLoading] = useState({
        graduation: true,
        weeklyUpdates: true,
    });
    const [weeklyUpdates, setWeeklyUpdates] = useState({
        graduation: null,
        selected: null,
        updates: null,
    });

    const { branchId } = CurrentUser.getUser();
    const hasFetchedUpdates = !!weeklyUpdates.updates;
    const hasGraduation = !!weeklyUpdates?.graduation?.length;
    const hasWeeklyUpdates = !!weeklyUpdates?.updates?.length;

    /************************************************
     * useEffect
     ************************************************/

    useEffect(() => {
        /**
         * Analytics
         */

        if (isDep) {
            AnalyticsLogger.logDepListViewed();
        }

        /**
         * Set HeaderBar content
         */

        const headerBarText = isDep
            ? generateDepContentTitle(branchId)
            : "Weekly Updates";
        setHeaderBarContent({ backPathname: "/", text: headerBarText });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        /**
         * If WeeklyUpdates data is already present on page load
         */

        if (location?.state?.weeklyUpdates) {
            setLoading((prevLoading) => ({
                ...prevLoading,
                graduation: false,
                weeklyUpdates: false,
            }));
            setWeeklyUpdates((prevWeeklyUpdates) => ({
                ...prevWeeklyUpdates,
                ...props.location.state.weeklyUpdates,
            }));
        }

        scrollToTop();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        lottie.loadAnimation({
            animationData: animation,
            autoplay: true,
            container: animationRef,
            loop: true,
            renderer: "svg",
        });
    }, [animationRef]);

    /**
     * Trigger fetch method(s) if WeeklyUpdates data is present
     */

    useEffect(() => {
        handleFetching();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [weeklyUpdates.graduation]);

    /**
     * Fetch consumed statuses once updates have been fetched
     */

    useEffect(() => {
        if (weeklyUpdates.selected) {
            handleConsumeWeeklyUpdate();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [weeklyUpdates.selected]);

    useEffect(() => {
        if (weeklyUpdates.updates && !flags.hasFetchedConsumedStatuses) {
            fetchWeeklyUpdatesConsumed();
            setFlags((prevFlags) => ({
                ...prevFlags,
                hasFetchedConsumedStatuses: true,
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [weeklyUpdates.updates]);

    /************************************************
     * End Hooks
     ************************************************/

    async function fetchDepUpdates() {
        setLoading((prevLoading) => ({
            ...prevLoading,
            graduation: false,
            weeklyUpdates: true,
        }));

        /**
         * Fetch DEP content and categories from Firestore
         */
        const depContentUrl = generateDepContentUrl(branchId);
        const { categories } = await firebase.getDocument(
            "DepContentCategories",
            depContentUrl
        );
        const { depContentTemplates } = await firebase.getDocument(
            "DepContentTracks",
            depContentUrl
        );

        /**
         * Format and store DEP categories in state
         */

        const categoriesFormatted = categories.map((category) => ({
            isSelected: false,
            text: category,
        }));
        setCategories((prevCategories) => categoriesFormatted);

        /**
         * Format and store DEP updates in state
         */

        const updates = depContentTemplates.map((depContent) => {
            return new WeeklyUpdate(depContent);
        });
        setWeeklyUpdates((prevWeeklyUpdates) => ({
            ...prevWeeklyUpdates,
            updates: updates.reverse(),
        }));

        /**
         * Set loading values and fetch content consumed states
         */

        setLoading((prevLoading) => ({
            ...prevLoading,
            weeklyUpdates: false,
        }));
        fetchWeeklyUpdatesConsumed();
    }

    function fetchGraduation() {
        return SandboxxRestAPI.getGraduation(
            onFetchGraduationSuccess,
            onFetchGraduationError,
            onFetchGraduationError
        );
    }

    function fetchWeeklyUpdates() {
        if (hasGraduation) {
            setLoading((prevLoading) => ({
                ...prevLoading,
                graduation: false,
                weeklyUpdates: true,
            }));
            const { specialization, trainingBase } =
                weeklyUpdates.graduation[0];
            const baseSpecialization = specialization
                ? `${trainingBase}-${specialization}`.toLowerCase()
                : trainingBase.toLowerCase();

            SandboxxRestAPI.getWeeklyUpdates(
                {
                    baseSpecialization,
                },
                onFetchWeeklyUpdatesSuccess,
                onFetchWeeklyUpdatesFailure,
                onFetchWeeklyUpdatesFailure
            );
        }
    }

    function onFetchWeeklyUpdatesFailure(err) {
        showNotification({
            text: "Content failed to load, please try again. If this error persists, Please use the chat widget in the lower-right corner of the screen to get in touch with a Sandboxx representative.",
            type: "warning",
        });
    }

    function onFetchWeeklyUpdatesSuccess(res) {
        const updates = res.map((weeklyUpdate) => {
            return new WeeklyUpdate(weeklyUpdate);
        });
        setLoading((prev) => ({
            ...prev,
            weeklyUpdates: false,
        }));
        setWeeklyUpdates((prev) => ({
            ...prev,
            updates,
        }));
        fetchWeeklyUpdatesConsumed();
    }

    async function fetchWeeklyUpdatesConsumed() {
        if (isDep) {
            return SandboxxRestAPI.getDepUpdatesConsumed(
                onFetchWeeklyUpdatesConsumedSuccess,
                (err) => console.error("fetchDepConsumed", err),
                (err) => console.error("fetchDepConsumed", err)
            );
        } else {
            return SandboxxRestAPI.getWeeklyUpdatesConsumed(
                onFetchWeeklyUpdatesConsumedSuccess
            );
        }
    }

    function handleConsumeWeeklyUpdate() {
        if (isDep) {
            return SandboxxRestAPI.consumeDepUpdate(
                { weeklyUpdates },
                fetchWeeklyUpdatesConsumed
            );
        } else {
            return SandboxxRestAPI.consumeWeeklyUpdate(
                { weeklyUpdates },
                fetchWeeklyUpdatesConsumed
            );
        }
    }

    function handleFetching() {
        if (isDep) {
            fetchDepUpdates();
        } else {
            if (!hasGraduation) {
                fetchGraduation();
                return;
            }
            if (hasGraduation && !hasFetchedUpdates) {
                fetchWeeklyUpdates();
                return;
            }
        }
    }

    function handleSelectCategory(category, i) {
        /**
         * DEP analytics on mount
         */
        AnalyticsLogger.logDepCategoryClicked();
        /**
         * Select DEP category logic
         */

        const categoriesCopy = categories;
        categoriesCopy[i] = {
            ...category,
            isSelected: !category.isSelected,
        };
        setCategories([...categoriesCopy]);
    }

    function handleSelectWeeklyUpdate(selected) {
        /**
         * DEP analytics on mount
         */

        if (isDep) {
            AnalyticsLogger.logDepArticleClicked({
                article: selected,
                branch: branchId,
            });
        }

        /**
         * Select weekly update logic
         */

        setWeeklyUpdates((prevWeeklyUpdates) => ({
            ...prevWeeklyUpdates,
            selected,
        }));
        setCurrentSection("detail");
        setHeaderBarContent({
            ...headerBarContent,
            backPathname: "",
            onBackClick: handleBackClick,
        });
        scrollToTop();
    }

    function onFetchGraduationError(err) {
        setLoading((prevLoading) => ({
            ...prevLoading,
            graduation: false,
            weeklyUpdates: false,
        }));
    }

    function onFetchGraduationSuccess(graduation) {
        const hasGraduation = graduation.length;
        setLoading((prevLoading) => ({
            ...prevLoading,
            graduation: false,
        }));
        if (hasGraduation) {
            setWeeklyUpdates((prevWeeklyUpdates) => ({
                ...prevWeeklyUpdates,
                graduation,
            }));
            fetchWeeklyUpdates();
        } else {
            setLoading((prevLoading) => ({
                ...prevLoading,
                weeklyUpdates: false,
            }));
        }
    }

    function onFetchWeeklyUpdatesConsumedSuccess(res) {
        const hasConsumedWeeklyUpdates = !!res.length;
        if (hasConsumedWeeklyUpdates) {
            const weekNumbersConsumed = isDep
                ? res[0].readContentNumbers
                : res[0].weekNumbers;
            const newWeeklyUpdates = weeklyUpdates.updates.map(
                (weeklyUpdate) => {
                    const { contentNumber, week } = weeklyUpdate;
                    const comparisonValue = isDep ? contentNumber : week;
                    const hasBeenConsumed = weekNumbersConsumed.some(
                        (number) => number === comparisonValue
                    );
                    weeklyUpdate.markAsConsumed(hasBeenConsumed);
                    return weeklyUpdate;
                }
            );
            setWeeklyUpdates((prevWeeklyUpdates) => ({
                ...prevWeeklyUpdates,
                updates: newWeeklyUpdates,
            }));
        }
    }

    function handleBackClick() {
        setCurrentSection("list");
        setHeaderBarContent({ ...headerBarContent, backPathname: "/" });
    }

    function renderContent() {
        const hasLoaded = !loading.graduation && !loading.weeklyUpdates;
        if (hasLoaded) {
            if (hasWeeklyUpdates) {
                return renderCurrentSection();
            } else {
                return renderEmpty();
            }
        } else {
            return <LoadingPage />;
        }
    }

    function renderCurrentSection() {
        const sections = {
            detail: (
                <WeeklyUpdatesDetail
                    handleConsumeWeeklyUpdate={handleConsumeWeeklyUpdate}
                    handleSelectWeeklyUpdate={handleSelectWeeklyUpdate}
                    isDep={isDep}
                    setCurrentSection={setCurrentSection}
                    weeklyUpdates={weeklyUpdates}
                />
            ),
            list: (
                <WeeklyUpdatesList
                    categories={categories}
                    handleSelectCategory={handleSelectCategory}
                    handleSelectWeeklyUpdate={handleSelectWeeklyUpdate}
                    isDep={isDep}
                    setCurrentSection={setCurrentSection}
                    weeklyUpdates={weeklyUpdates}
                />
            ),
        };
        return sections[currentSection];
    }

    function renderEmpty() {
        return (
            <Panel classes={styles.empty} cypressTestId="weekly-updates-empty">
                <div
                    className={styles.animation}
                    ref={(ref) => setAnimationRef(ref)}
                />
                <span className={styles.header}>
                    No Available Weekly Updates
                </span>
                <span className={styles.text}>
                    Hmm. There doesn't seem to be any weekly updates, yet. Check
                    back soon.
                </span>
                <ButtonPrimary
                    cypressTestId="weekly-updates-back-button"
                    onClick={() => RedirectUtil.handleRedirectToHome(history)}
                    text="Go Back"
                    type="button"
                />
            </Panel>
        );
    }

    return (
        <div className="page">
            <HeaderBar />
            <div className="page__body">
                <div className={styles.weeklyUpdates}>{renderContent()}</div>
            </div>
        </div>
    );
};
