import React, { useContext } from "react";
import PropTypes from "prop-types";

// Components
import { Banner } from "components/Banner/Banner";
import { Text } from "components/Text/Text";

// Context
import { CartContext } from "context/Cart/cart";

// CSS
import styles from "./cart-shipping-options-display.module.scss";

// Localization
import { useTranslation } from "localization/localization";

export const CartShippingOptionsDisplay = () => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useContext
     */

    const { hasShippingAddress } = useContext(CartContext);

    /**
     * End Hooks
     */

    if (!hasShippingAddress) {
        return (
            <div className={styles.cartShippingOptionsDisplay}>
                <Banner
                    config={{
                        isActive: true,
                        shouldShowChevron: true,
                        shouldShowIcon: true,
                    }}
                    theme="blue"
                >
                    <Text isBold>
                        {t("cart.shipping_options_missing_address_title", {
                            ns: "components",
                        })}
                    </Text>
                    <Text>
                        {t("cart.shipping_options_missing_address_subtitle", {
                            ns: "components",
                        })}
                        Method
                    </Text>
                </Banner>
            </div>
        );
    }
    return <div className={styles.cartShippingOptionsDisplay}></div>;
};

CartShippingOptionsDisplay.propTypes = {
    hasShippingAddress: PropTypes.bool,
};
