import React from "react";

// Components
import { LoadingPage } from "../Loading/Loading";

// CSS
import "../../css/components/redirect.scss";

export class RedirectMobile extends React.Component {
    UNSAFE_componentWillMount() {
        window.location = "https://link.sandboxx.us/app";
    }

    render() {
        return (
            <div className="redirect-mobile-container">
                <LoadingPage />
            </div>
        );
    }
}
