export function generateImagePath() {
    try {
        const uuid = crypto.randomUUID();
        const imagePath = "pic/" + uuid + ".jpg";
        return imagePath;
    } catch (err) {
        console.error(err);
    }
}

export function generateImageNameAndPath() {
    try {
        const imageName = crypto.randomUUID();
        const imagePath = "pic/" + imageName + ".jpg";
        return { imageName, imagePath };
    } catch (err) {
        console.error(err);
    }
}

export function openImageInNewTab(src) {
    // If there is no src present, exit the function
    if (!src) return;

    // Otherwise, open the image in a new tab using the src parameter
    const image = new Image();
    image.src = src;
    const newWindow = window.open("");
    newWindow.document.write(image.outerHTML);
}
