import React from "react";
import cx from "classnames";
import { useHistory } from "react-router";
import PropTypes from "prop-types";

// Components
import { ArrowForwardIcon } from "components/Icons/ArrowForwardIcon";
import { ButtonPrimary } from "components/Buttons/Buttons";
import { ListIcon } from "components/ListIcon/ListIcon";
import { Text } from "components/Text/Text";

// CSS
import signInStyles from "../../../sign-in.module.scss";
import styles from "./sign-up-success.module.scss";

// Localization
import { useTranslation } from "localization/localization";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Utils
import { generateLandingPath } from "utils/navigationUtils";
import { generatePersonaContent } from "./utils/utils";

export const SignUpSuccess = ({
    runShouldShowNavBarCheck,
    selectedRootPersona,
    userInfo,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * Router Hooks
     */

    const history = useHistory();

    /**
     * Constants
     */

    const content = generatePersonaContent({ selectedRootPersona, userInfo });

    /**
     * End Hooks & Constants
     */

    function handleGoHome() {
        AnalyticsLogger.logOnboardingEnd();
        localStorage.setItem("sandboxxSkipOnboarding", false);
        const landingUrl = generateLandingPath(selectedRootPersona);
        history.push(landingUrl.path);
        runShouldShowNavBarCheck();
    }

    return (
        <div className={cx(signInStyles.content, styles.signUpSuccess)}>
            <div className={signInStyles.top}>
                <div className={styles.header}>
                    <Text classes={styles.title} type="h1">
                        {content.title}
                    </Text>
                    <Text classes={styles.subtitle}>{content.subtitle}</Text>
                </div>
                <ListIcon
                    classNames={{
                        container: styles.items,
                        item: styles.item,
                        subtitle: styles.subtitle,
                        title: styles.title,
                    }}
                    items={content.items}
                />
            </div>
            <div className={styles.buttons}>
                <ButtonPrimary
                    classes={cx(signInStyles.button, styles.button)}
                    cypressTestId="sign-up-success-get-started-button"
                    onClick={handleGoHome}
                >
                    <Text>
                        {t("sign_up_success.enter_sandboxx", {
                            ns: "onboarding",
                        })}
                    </Text>
                    <ArrowForwardIcon className="forward-arrow-icon" />
                </ButtonPrimary>
            </div>
        </div>
    );
};

SignUpSuccess.propTypes = {
    runShouldShowNavBarCheck: PropTypes.func.isRequired,
    selectedRootPersona: PropTypes.object,
    userInfo: PropTypes.object.isRequired,
};
