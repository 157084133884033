import React, { useEffect } from "react";

// Components
import { Chips } from "../../../../components/Chips/Chips";
import { LoadingPage } from "../../../../components/Loading/Loading";
import { Panel } from "components/Panel/Panel";
import { Text } from "components/Text/Text";
import { WeeklyUpdatesListItem } from "./components/WeeklyUpdatesListItem/WeeklyUpdatesListItem";

// CSS
import styles from "./weekly-updates-list.module.scss";

// Hooks
import { Fade } from "../../../../hooks/Fade";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

export const WeeklyUpdatesList = (props) => {
    const {
        categories,
        handleSelectCategory,
        handleSelectWeeklyUpdate,
        isDep,
        weeklyUpdates,
    } = props;

    /**
     * useEffect
     */

    useEffect(() => {
        AnalyticsLogger.logWeeklyUpdatesViewed();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * End Hooks
     */

    const categoriesSelected = categories.filter(
        ({ isSelected }) => isSelected
    );
    const hasCategories = !!categories.length;
    const hasCategoriesSelected = !!categoriesSelected.length;

    function renderChips() {
        return (
            isDep &&
            hasCategories && (
                <Chips collection={categories} onClick={handleSelectCategory} />
            )
        );
    }

    function renderContent() {
        return weeklyUpdates.updates ? (
            <Fade show={weeklyUpdates.updates}>{renderListItems()}</Fade>
        ) : (
            <LoadingPage />
        );
    }

    function renderEmpty() {
        return (
            <Panel>
                <Text>
                    There are no DEP updates that match your selected
                    categories.
                </Text>
            </Panel>
        );
    }

    function renderListItems() {
        const listItems = weeklyUpdates.updates
            ?.filter(({ tags }) => {
                if (isDep && hasCategoriesSelected) {
                    return categoriesSelected.some(({ text }) => {
                        return tags.includes(text);
                    });
                } else {
                    return true;
                }
            })
            .map((weeklyUpdate, i) => {
                return (
                    <WeeklyUpdatesListItem
                        handleSelectWeeklyUpdate={handleSelectWeeklyUpdate}
                        isDep={isDep}
                        weeklyUpdate={weeklyUpdate}
                        key={i}
                        index={i}
                    />
                );
            });
        return listItems.length ? listItems : renderEmpty();
    }

    return (
        <div className={styles.weeklyUpdatesList}>
            {renderChips()}
            {renderContent()}
        </div>
    );
};
