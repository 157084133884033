import React from "react";

// Components
import { FeatureFlagBoundary } from "components/Boundaries/FeatureFlagBoundary";
import { StorylyGroup } from "components/StorylyGroup/StorylyGroup";

// CSS
import styles from "./muster-overview-storyly.module.scss";

// Enums
import { StorylyCategory } from "enums/StorylyCategory";

// Globals
import { featureFlags } from "globals/featureFlags";

// Hooks
import { useMusterOverviewStoryly } from "./hooks/useMusterOverviewStoryly";

export const MusterOverviewStoryly = ({ branchPersona, rootPersona }) => {
    /**
     * Custom Hooks
     */

    const { isRecruit, segments } = useMusterOverviewStoryly({
        branchPersona,
        rootPersona,
    });

    /**
     * End Hooks
     */

    return (
        <FeatureFlagBoundary
            featureFlags={[featureFlags.global.isStorylyContentEnabled]}
        >
            <div
                className={styles.musterOverviewStoryly}
                data-cy="muster-overview-storyly"
            >
                {isRecruit ? (
                    <>
                        <StorylyGroup
                            category={StorylyCategory.FEATURED_RECRUIT}
                            classNames={{
                                container: "",
                                storylyContainer: "featured",
                            }}
                            headerText="Featured"
                            segments={segments}
                        />
                        <StorylyGroup
                            category={StorylyCategory.BASICS}
                            classNames={{
                                container: "",
                                storylyContainer: "basics",
                            }}
                            headerText="The Basics"
                            segments={segments}
                        />
                        <StorylyGroup
                            category={StorylyCategory.PREPARE}
                            classNames={{
                                container: "",
                                storylyContainer: "prepare",
                            }}
                            headerText="Prepare"
                            segments={segments}
                        />
                        <StorylyGroup
                            category={StorylyCategory.BENEFITS}
                            classNames={{
                                container: "",
                                storylyContainer: "benefits",
                            }}
                            headerText="Benefits"
                            segments={segments}
                        />
                    </>
                ) : (
                    <>
                        <StorylyGroup
                            category={StorylyCategory.FEATURED_RECRUITER}
                            classNames={{
                                container: "",
                                storylyContainer: "featured",
                            }}
                            headerText="Featured"
                            segments={segments}
                        />
                        <StorylyGroup
                            category={StorylyCategory.BENEFITS}
                            classNames={{
                                container: "",
                                storylyContainer: "benefits",
                            }}
                            headerText="Benefits"
                            segments={segments}
                        />
                    </>
                )}
            </div>
        </FeatureFlagBoundary>
    );
};
