export const NavigationEnum = Object.freeze({
    RECIPIENT_DETAILS: "RECIPIENT_DETAILS",
    REVIEW: "REVIEW",
    SELECT_AMOUNT: "SELECT_AMOUNT",
    SELECT_DESIGN: "SELECT_DESIGN",
    SELECT_PAYMENT: "SELECT_PAYMENT",
});

export const ProvidersEnum = Object.freeze({
    AAFES: "AAFES",
    MCX: "MCX",
    NEX: "NEX",
});

export const branchesForAnalytics = {
    [ProvidersEnum.AAFES]: "Army Air Force",
    [ProvidersEnum.MCX]: "Marines",
    [ProvidersEnum.NEX]: "Navy",
};
