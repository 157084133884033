import { useMemo } from "react";

// Data
import countriesData from "data/countries.json";

// Localization
import { useTranslation } from "localization/localization";

// Utils
import { sortCountriesAlphabeticallyByLanguage } from "./utils";

export const useDropdownCountry = () => {
    /**
     * Custom Hooks
     */

    const { i18n } = useTranslation();

    /**
     * useMemo
     */

    const sortedCountries = useMemo(() => {
        return sortCountriesAlphabeticallyByLanguage(
            countriesData,
            i18n.resolvedLanguage
        );
    }, [i18n.resolvedLanguage]);

    /**
     * End useMemo
     */

    return { i18n, sortedCountries };
};
