import { useContext } from "react";

// Context
import { CartContext } from "context/Cart/cart";
import { PurchaseContext } from "context/purchase";

export const useCartPaymentMethodSelect = () => {
    /**
     * useContext
     */

    const { isSubmittingCart } = useContext(CartContext);
    const { savedCards } = useContext(PurchaseContext);

    /**
     * End Hooks
     */

    return { isSubmittingCart, savedCards };
};
