import React from "react";
import cx from "classnames";

// Components
import { AddressCard } from "components/AddressCard/AddressCard";
import { ButtonPrimary } from "components/Buttons/Buttons";
import { Text } from "components/Text/Text";

// CSS
import signInStyles from "../../../sign-in.module.scss";
import styles from "./sign-up-connected.module.scss";

// Enums
import { NavigationEnum } from "scenes/SignIn/constants/constants";

// Localization
import { useTranslation } from "localization/localization";

export const SignUpConnected = ({ connections, setCurrentSection }) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * End Hooks
     */

    function handleGoToOnboarding() {
        setCurrentSection(NavigationEnum.SIGN_UP_PERSONA_CHILD);
    }

    return (
        <div className={cx(signInStyles.content, styles.content)}>
            <div className={styles.header}>
                <Text classes={styles.headline} type="h1">
                    {t("sign_up_connected.title", { ns: "onboarding" })}
                </Text>
                <Text classes={styles.subHeader} type="h4">
                    {t("sign_up_connected.subtitle", { ns: "onboarding" })}
                </Text>
            </div>
            <div className={styles.container}>
                <div>
                    <div
                        className={cx(styles.connections, {
                            [styles[`connections--scroll`]]:
                                connections?.length > 3,
                        })}
                    >
                        <div className={styles.list}>
                            {connections?.length > 0 &&
                                connections.map((connection, i) => {
                                    return (
                                        <AddressCard
                                            className={styles.connection}
                                            contact={connection}
                                            key={i}
                                        />
                                    );
                                })}
                        </div>
                    </div>
                </div>
                <ButtonPrimary
                    classes={styles.button}
                    onClick={handleGoToOnboarding}
                >
                    <Text>{t("continue", { ns: "common" })}</Text>
                </ButtonPrimary>
            </div>
        </div>
    );
};
