export function generateCustomStorylyGroupHTML(className) {
    return `<div class="custom-story-group-container ${className}">
                <div class="custom-story-group ${className}">
                    <img class="storyly__story-group-cover ${className}"/>
                    <span class="storyly__story-group-title ${className}"></span>
                </div>
            </div>`;
}

export function setStorylyGroupMarginLeft(storylyRef) {
    const groupWidth = storylyRef.current.groupsList_.scrollWidth;
    const containerWidth = storylyRef.current.offsetWidth;
    if (groupWidth < containerWidth && groupWidth !== 0) {
        storylyRef.current.groupsList_.style.marginLeft = "40px";
    } else {
        storylyRef.current.groupsList_.style.marginLeft = "0px";
    }
}
