import React from "react";
import { withRouter } from "react-router-dom";

// Components
import { ContactModalAddressFormatter } from "./components/ContactModalAddressFormatter/ContactModalAddressFormatter";
import { ContactModalAddressManual } from "./components/ContactModalAddressManual/ContactModalAddressManual";
import { ContactModalConfirm } from "./components/ContactModalConfirm/ContactModalConfirm";
import { ContactModalForm } from "./components/ContactModalForm/ContactModalForm";
import { ContactModalName } from "./components/ContactModalName/ContactModalName";
import { Modal } from "components/Modals/Modal";

// Enums
import { ContactModalNavigation } from "./enums/ContactModalNavigation";

// Hooks
import { useContactModal } from "./hooks/useContactModal";

export const ContactModal = (props) => {
    /**
     * Custom Hooks
     */

    const {
        bases,
        contactModal,
        contactStaging,
        currentSection,
        exampleAddresses,
        handleChange,
        handleCloseModal,
        handleSubmit,
        handleSubmitAddressFormatter,
        headerText,
        loading,
        setCurrentSection,
        setHeaderText,
        shouldReverseFullName,
        titles,
        toggleShouldReverseFullName,
    } = useContactModal(props);

    /**
     * Constants
     */

    const { shouldShow, shouldShowBackButton } = contactModal;

    /**
     * End Hooks & Constants
     */

    function renderCurrentSection() {
        return {
            [ContactModalNavigation.ADDRESS_FORMATTER]: (
                <ContactModalAddressFormatter
                    {...props}
                    bases={bases}
                    handleSubmitAddressFormatter={handleSubmitAddressFormatter}
                    setCurrentSection={setCurrentSection}
                    setHeaderText={setHeaderText}
                />
            ),
            [ContactModalNavigation.ADDRESS_MANUAL]: (
                <ContactModalAddressManual
                    contactStaging={contactStaging}
                    exampleAddresses={exampleAddresses}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    setCurrentSection={setCurrentSection}
                />
            ),
            [ContactModalNavigation.CONFIRM]: (
                <ContactModalConfirm
                    {...props}
                    contactStaging={contactStaging}
                    handleSubmit={handleSubmit}
                    setCurrentSection={setCurrentSection}
                    setHeaderText={setHeaderText}
                    shouldReverseFullName={shouldReverseFullName}
                />
            ),
            [ContactModalNavigation.FORM]: (
                <ContactModalForm
                    {...props}
                    contactStaging={contactStaging}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    loading={loading}
                    setCurrentSection={setCurrentSection}
                    setHeaderText={setHeaderText}
                    shouldReverseFullName={shouldReverseFullName}
                    shouldShowBackButton={shouldShowBackButton}
                    titles={titles}
                    toggleShouldReverseFullName={toggleShouldReverseFullName}
                />
            ),
            [ContactModalNavigation.NAME]: (
                <ContactModalName
                    contactStaging={contactStaging}
                    handleChange={handleChange}
                    setCurrentSection={setCurrentSection}
                    setHeaderText={setHeaderText}
                    shouldReverseFullName={shouldReverseFullName}
                    titles={titles}
                    toggleShouldReverseFullName={toggleShouldReverseFullName}
                />
            ),
        }[currentSection];
    }

    return (
        <Modal
            className="contact-modal"
            cypressTestId="contact-modal"
            headerText={headerText}
            onClose={handleCloseModal}
            onHide={handleCloseModal}
            show={shouldShow}
        >
            {renderCurrentSection()}
        </Modal>
    );
};

export const WrappedContactModal = withRouter(ContactModal);
