import React from "react";
import { useHistory } from "react-router-dom";

// Components
import { ButtonPrimary } from "components/Buttons/Buttons";
import { Text } from "components/Text/Text";

// CSS
import styles from "../phone-verification-modal.module.scss";

// Localization
import { useTranslation } from "localization/localization";

export const PhoneVerificationModalCompleteProfile = ({
    togglePhoneVerificationModal,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * Router Hooks
     */

    const history = useHistory();

    /**
     * End Hooks
     */

    function handleGoToLetters() {
        history.push("/letters/compose");
        togglePhoneVerificationModal(false);
    }

    return (
        <div className={styles["complete-profile"]}>
            <div className={styles.checkmark} />
            <Text classes={styles.header} type="h2">
                {t("phone_verification_modal.complete_profile_title", {
                    ns: "components",
                })}
            </Text>
            <ButtonPrimary onClick={handleGoToLetters}>
                <Text>{t("send_a_letter", { ns: "common" })}</Text>
            </ButtonPrimary>
        </div>
    );
};
