export function SandboxxIcon({ color, height, width }) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <mask
                id="mask0_20_1824"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
            >
                <path
                    d="M23.3973 0.604004H0.603516V23.3962H23.3973V0.604004Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_20_1824)">
                <mask
                    id="mask1_20_1824"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="24"
                    height="24"
                >
                    <path
                        d="M23.3973 0.604004H0.603516V23.3962H23.3973V0.604004Z"
                        fill="white"
                    />
                </mask>
                <g mask="url(#mask1_20_1824)">
                    <path
                        d="M21.9334 0.6041H2.06693C1.26661 0.6041 0.603516 1.25969 0.603516 2.06751V18.7547C0.603516 19.5625 1.2591 20.2181 2.06693 20.2181H17.6949C18.099 20.2181 18.4877 20.3859 18.7621 20.6828L20.4164 22.4513C20.4469 22.4893 20.8736 22.9391 20.8736 22.9391C21.7806 23.8922 23.3893 23.2516 23.3893 21.9329L23.3968 2.06644C23.3968 1.25861 22.7412 0.603027 21.9334 0.603027M21.6739 10.9477C21.6739 11.0088 21.6664 11.0699 21.6589 11.123C16.9095 11.108 16.2695 5.98495 12.0004 5.98495C7.73134 5.98495 7.08326 11.1155 2.34192 11.123C2.33441 11.0619 2.32691 11.0088 2.32691 10.9477V3.24145C2.32691 2.67753 2.7922 2.21224 3.35612 2.21224H20.6458C21.2097 2.21224 21.675 2.67753 21.675 3.24145V10.9487L21.6739 10.9477Z"
                        fill={color}
                    />
                </g>
            </g>
        </svg>
    );
}

SandboxxIcon.defaultProps = {
    height: "24px",
    width: "24px",
    color: "#ffffff",
};
