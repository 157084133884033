import React from "react";
import PropTypes from "prop-types";

// Components
import { Accordion } from "components/Accordion/Accordion";
import { ButtonPrimary } from "components/Buttons/Buttons";
import { MusterChecklistItem } from "../MusterChecklistItem/MusterChecklistItem";
import { ProgressBarCircle } from "components/ProgressBar/ProgressBarCircle/ProgressBarCircle";
import { Text } from "components/Text/Text";

// CSS
import styles from "./muster-checklist-collection.module.scss";

// Enums
import { MusterChecklistModalTypes } from "enums/MusterChecklistModalTypes";

// Utils
import { calculateCompletedDocuments } from "scenes/Muster/utils/utils";

export const MusterChecklistCollection = ({
    activeAccordionId,
    collection,
    onToggleAddEditModal,
    onToggleDeleteModal,
    setActiveDocument,
    title,
    updateMusterDocument,
}) => {
    function addDocumentClick(categoryId) {
        onToggleAddEditModal(true, MusterChecklistModalTypes.ADD);
        setActiveDocument({ categoryId });
    }

    return (
        <>
            <div className={styles.musterChecklistCollection}>
                <Text isBold={true} type="p">
                    {title}
                </Text>
                <div className={styles.body}>
                    {collection?.map((collection, i) => {
                        const completed = calculateCompletedDocuments(
                            collection.documents
                        );
                        const length = collection.documents.length;
                        const subtext = `${completed}/${length} document${
                            length > 1 ? "s" : ""
                        } collected`;

                        return (
                            <Accordion
                                classNames={{
                                    container: styles.accordion,
                                }}
                                headerIcon={
                                    <ProgressBarCircle
                                        completed={completed}
                                        total={length}
                                    />
                                }
                                indicatorType="chevron"
                                key={i}
                                subtext={subtext}
                                shouldForceActive={
                                    activeAccordionId === collection.id
                                }
                                title={collection.name.toUpperCase()}
                                type="p"
                            >
                                {collection.documents.map((document, j) => {
                                    return (
                                        <MusterChecklistItem
                                            completed={document.completed}
                                            document={document}
                                            id={document.id}
                                            key={j}
                                            onToggleAddEditModal={
                                                onToggleAddEditModal
                                            }
                                            onToggleDeleteModal={
                                                onToggleDeleteModal
                                            }
                                            text={document.description}
                                            updateMusterDocument={
                                                updateMusterDocument
                                            }
                                        />
                                    );
                                })}
                                <ButtonPrimary
                                    classes={styles.button}
                                    cypressTestId="muster-checklist-collection-add-button"
                                    onClick={() =>
                                        addDocumentClick(collection.id)
                                    }
                                    text="+ Add"
                                    type="button"
                                />
                            </Accordion>
                        );
                    })}
                </div>
            </div>
        </>
    );
};

MusterChecklistCollection.propTypes = {
    activeAccordionId: PropTypes.string,
    collection: PropTypes.array,
    onToggleAddEditModal: PropTypes.func.isRequired,
    onToggleDeleteModal: PropTypes.func.isRequired,
    setActiveDocument: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    updateMusterDocument: PropTypes.func.isRequired,
};
