import React from "react";
import PropTypes from "prop-types";

// Components
import { ButtonPrimary } from "components/Buttons/Buttons";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Text } from "components/Text/Text";

// CSS
import styles from "./cart-confirmation.module.scss";

// Hooks
import { useCartConfirmation } from "./hooks/useCartConfirmation";

// Localization
import { useTranslation } from "localization/localization";

/**
 * TODO: Replace description text once cart offerings expand beyond Memory Books
 */
export const CartConfirmation = ({ resetCartNavigation }) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    const { closeCartAfterConfirmation, completedOrder, orderPlacedDateTime } =
        useCartConfirmation({
            resetCartNavigation,
        });

    /**
     * End Hooks
     */

    return (
        <div className={styles.cartConfirmation} data-cy="cart-confirmation">
            <div className={styles.header}>
                <CheckCircleIcon className={styles.icon} />
                <Text classes={styles.header} isBold>
                    {t("thank_you_for_your_order", { ns: "common" })}
                </Text>
            </div>
            <div className={styles.orderNumber}>
                <Text isBold>{t("order_number", { ns: "common" })}</Text>
                <Text>{completedOrder.display_id}</Text>
            </div>
            <div className={styles.orderPlaced}>
                <Text isBold>{t("order_placed", { ns: "common" })}</Text>
                <Text>{orderPlacedDateTime}</Text>
            </div>
            <Text classes={styles.description}>
                {t("next_24_hours_print", { ns: "memory_book" })}
            </Text>
            <ButtonPrimary
                className={styles.button}
                onClick={closeCartAfterConfirmation}
            >
                <Text>{t("continue", { ns: "common" })}</Text>
            </ButtonPrimary>
        </div>
    );
};

CartConfirmation.propTypes = {
    resetCartNavigation: PropTypes.func.isRequired,
};
