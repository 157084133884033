export function PencilIcon({ color, className, height, width }) {
    return (
        <svg
            className={className}
            width={width}
            height={height}
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14.0529 0.583019C13.4246 0.58107 12.8215 0.831443 12.3779 1.27845L11.7598 1.89937L15.1284 5.23988L15.7465 4.61897C16.4093 3.93419 16.6 2.9175 16.2307 2.03738C15.8613 1.15726 15.0037 0.584502 14.0529 0.583019Z"
                fill={color}
            />
            <path
                d="M1.21958 16.4162H3.76381C3.93288 16.4171 4.09537 16.3508 4.21541 16.2317L13.6227 6.81803L10.1816 3.37695L0.767974 12.7843C0.648913 12.9043 0.58254 13.0668 0.583507 13.2359V15.7801C0.583507 16.1314 0.86829 16.4162 1.21958 16.4162Z"
                fill={color}
            />
        </svg>
    );
}

PencilIcon.defaultProps = {
    color: "#2B536A",
    className: "",
    height: "17px",
    width: "17px",
};
