import React from "react";
import IntlTelInput from "react-intl-tel-input";
import cx from "classnames";

// Components
import { ButtonPrimary } from "components/Buttons/Buttons";
import { Text } from "components/Text/Text";

// CSS
import styles from "../phone-verification-modal.module.scss";

// Localization
import { useTranslation } from "localization/localization";

export const PhoneVerificationModalPhoneNumber = ({
    handleChangePhoneNumber,
    handleClearPhoneNumber,
    handleSubmitPhoneNumber,
    loading,
    phoneNumber,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * End Hooks
     */

    return (
        <div className={styles["phone-number"]}>
            <Text classes={styles.header} type="h2">
                {t("phone_verification_modal.phone_number_title", {
                    ns: "components",
                })}
            </Text>
            <Text classes={styles.subheader} type="p">
                {t("phone_verification_modal.phone_number_subtitle", {
                    ns: "components",
                })}
            </Text>
            <form
                className={styles.form}
                data-cy="phone-verification-modal-form"
                onSubmit={handleSubmitPhoneNumber}
            >
                <div className={styles["input-wrapper"]}>
                    <IntlTelInput
                        autoFocus={true}
                        autoPlaceholder={true}
                        defaultCountry="us"
                        excludeCountries={["kp"]}
                        fieldName="phoneNumber"
                        format={true}
                        inputClassName={cx(
                            styles.input,
                            styles["input--phone-intl"]
                        )}
                        preferredCountries={["us"]}
                        value={phoneNumber.baseNumber}
                        onPhoneNumberChange={handleChangePhoneNumber}
                        onSelectFlag={handleClearPhoneNumber}
                    />
                </div>
                <ButtonPrimary
                    cypressTestId="phone-verification-modal-phone-number-submit"
                    isLoading={loading.phoneNumber}
                    onClick={handleSubmitPhoneNumber}
                    type="submit"
                >
                    <Text>{t("send_confirmation_code", { ns: "common" })}</Text>
                </ButtonPrimary>
                <Text classes={styles.description} type="small">
                    {t("phone_verification_modal.phone_number_footer", {
                        ns: "components",
                    })}
                </Text>
            </form>
        </div>
    );
};
