import React from "react";
import PropTypes from "prop-types";

// CSS
import "./panel.scss";

export const Panel = ({ children, classes, cypressTestId, style }) => {
    return (
        <div
            className={`panel ${classes}`}
            data-cy={cypressTestId}
            style={style}
        >
            {children}
        </div>
    );
};

Panel.defaultProps = {
    classes: "",
    style: {},
};

Panel.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.string,
    cypressTestId: PropTypes.string,
    style: PropTypes.object,
};
