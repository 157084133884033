import React from "react";
import PropTypes from "prop-types";

// Components
import { Banner } from "components/Banner/Banner";
import { Text } from "components/Text/Text";

// Enums
import { CartNavigation } from "components/Cart/enums/CartNavigation";

// Localization
import { useTranslation } from "localization/localization";

export const CartShippingAddressEmpty = ({ setCartCurrentSection }) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * End Hooks
     */

    return (
        <Banner
            config={{
                isActive: true,
                shouldShowChevron: true,
                shouldShowIcon: true,
            }}
            onClick={() =>
                setCartCurrentSection(
                    () => CartNavigation.SHIPPING_ADDRESS_FORM
                )
            }
        >
            <Text isBold>
                {t("cart.shipping_address_empty_title", { ns: "components" })}
            </Text>
            <Text>
                {t("cart.shipping_address_empty_subtitle", {
                    ns: "components",
                })}
            </Text>
        </Banner>
    );
};

CartShippingAddressEmpty.propTypes = {
    setCartCurrentSection: PropTypes.func,
};
