import { useEffect, useState } from "react";

// Models
import { Persona } from "models/persona/Persona";

// Enums
import { NavigationEnum } from "scenes/SignIn/constants/constants";

// Utils
import { emptyFunction } from "utils/miscUtils";
import { CurrentUser, SandboxxRestAPI } from "utils/sandboxx";

export const usePersonaWizard = ({
    setCurrentSection,
    setUserInfo,
    storeUserAfterUpdate,
}) => {
    /**
     * useState
     */

    const [childPersonas, setChildPersonas] = useState([]);
    const [isLoadingRootPersonaSelection, setIsLoadingRootPersonaSelection] =
        useState(false);
    const [isLoadingChildPersonas, setIsLoadingChildPersonas] = useState(false);
    const [rootPersonas, setRootPersonas] = useState(null);
    const [selectedRootPersona, setSelectedRootPersona] = useState(null);

    /**
     * useEffect
     */

    useEffect(() => {
        fetchRootPersonas();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * End Hooks
     */

    function fetchChildPersonas({ id }) {
        setIsLoadingChildPersonas(true);
        return SandboxxRestAPI.getChildPersonas(
            { id },
            (res) => onFetchChildPersonasSuccess(res),
            onFetchChildPersonasFailure,
            onFetchChildPersonasFailure
        );
    }

    function fetchRootPersonas() {
        if (!rootPersonas) {
            return SandboxxRestAPI.getRootPersonas(
                onFetchRootPersonasSuccess,
                onFetchRootPersonasFailure,
                onFetchRootPersonasFailure
            );
        }
    }

    function handleSelectChildPersona(persona, { onComplete }) {
        // If the selected child persona has children, fetch those
        // Otherwise, trigger onComplete callback
        if (persona.hasChildren()) {
            setIsLoadingChildPersonas(true);
            fetchChildPersonas(persona);
        } else {
            onComplete && onComplete();
        }
        // In either scenario, we want to tell the server what persona the user selected
        updatePersona(persona);
    }

    function handleSelectProfileChildPersona(persona, { onComplete }) {
        // If the selected child persona has children, fetch those and
        // trigger onComplete callback
        if (persona.hasChildren()) {
            setIsLoadingChildPersonas(true);
            fetchChildPersonas(persona);
            updatePersona(persona);
        } else {
            updatePersona(persona, onComplete);
        }
    }

    function handleGoBackToPersonaSelection() {
        SandboxxRestAPI.getAccount((res) => {
            const { user } = res;

            // Remove last persona
            user.personas.pop();

            // Create new mapping with remaing personas
            const newPersonas = user.personas.map((persona) => {
                return { id: persona.id };
            });

            // Update Sign Up User Info
            const userInfoPersonas = user.personas.map((persona) => {
                return persona.id;
            });

            setUserInfo((prev) => ({
                ...prev,
                personas: [...userInfoPersonas],
            }));

            /**
             * Update Personas and then fetch the child personas of the last
             * in the array
             */
            SandboxxRestAPI.updatePersona(newPersonas, () => {
                fetchChildPersonas(newPersonas[newPersonas.length - 1]);
            });

            /**
             * Switch back to SignUpPersonaRoot if newPersonas is empty,
             * otherwise stay on the SignUpPersonaChild component
             */

            if (newPersonas.length === 0) {
                setCurrentSection(NavigationEnum.SIGN_UP_PERSONA_ROOT);
            } else {
                setCurrentSection(NavigationEnum.SIGN_UP_PERSONA_CHILD);
            }
        });
    }

    function handleSelectRootPersona(persona, callbacks) {
        setIsLoadingRootPersonaSelection(true);
        setIsLoadingChildPersonas(true);

        setUserInfo &&
            setUserInfo((prev) => ({
                ...prev,
                personas: [],
            }));

        SandboxxRestAPI.updatePersona(
            [{ id: persona.id }],
            () => {
                // Fetch latest account info
                SandboxxRestAPI.getAccount(
                    ({ onboarding, user, verification }) => {
                        CurrentUser.storeUser(user, verification, onboarding);
                    }
                );

                // Fetch the child personas, given the selected one
                SandboxxRestAPI.getChildPersonas(
                    { id: persona.id },
                    // Call the provided "onSuccess" callback
                    (res) => {
                        onFetchChildPersonasSuccess(res);
                        callbacks?.onSuccess && callbacks.onSuccess();
                    },
                    onFetchChildPersonasFailure,
                    onFetchChildPersonasFailure
                );
            },
            onUpdatePersonaFailure,
            onUpdatePersonaFailure
        );

        setSelectedRootPersona(persona);
    }

    function onFetchChildPersonasFailure(err) {
        setIsLoadingChildPersonas(false);
    }

    function onFetchChildPersonasSuccess({ personas }) {
        const personaInstances = personas.map(
            (persona) => new Persona(persona)
        );
        setChildPersonas((prev) => prev.concat([personaInstances]));
        setIsLoadingChildPersonas(false);
    }

    function onFetchRootPersonasFailure(err) {}

    function onFetchRootPersonasSuccess({ personas }) {
        const personaInstances = personas.map(
            (persona) => new Persona(persona)
        );
        setRootPersonas(personaInstances);
    }

    function updatePersona(persona, onComplete) {
        SandboxxRestAPI.getAccount(({ onboarding, user, verification }) => {
            CurrentUser.storeUser(user, verification, onboarding);
            const { personas } = user;
            const personaIds = personas.map((persona) => {
                return { id: persona.id };
            });

            SandboxxRestAPI.updatePersona(
                [...personaIds, { id: persona.id }],
                (res) => onUpdatePersonaSuccess(res, onComplete),
                onUpdatePersonaFailure,
                onUpdatePersonaFailure
            );
        });
    }

    function onUpdatePersonaFailure(err) {}

    function onUpdatePersonaSuccess(res, onComplete = emptyFunction) {
        storeUserAfterUpdate();
        onComplete();
    }

    return {
        childPersonas,
        fetchChildPersonas,
        fetchRootPersonas,
        isLoadingRootPersonaSelection,
        handleGoBackToPersonaSelection,
        handleSelectChildPersona,
        handleSelectProfileChildPersona,
        handleSelectRootPersona,
        isLoadingChildPersonas,
        rootPersonas,
        selectedRootPersona,
        setIsLoadingChildPersonas,
        setIsLoadingRootPersonaSelection,
    };
};
