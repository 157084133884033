import React from "react";
import PropTypes from "prop-types";

// Components
import { Skeleton } from "@mui/material";
import { Text } from "components/Text/Text";

// CSS
import styles from "./support-squad-detail-activity-item.module.scss";

// Utils
import {
    generateSquadActivityTimeString,
    renderSquadActivityIcon,
} from "scenes/SupportSquad/components/SupportSquadDetail/utils/utils";

export const SupportSquadDetailActivityItem = ({
    activity,
    isFetchingSquad,
}) => {
    function renderContent() {
        return (
            <div className={styles.supportSquadDetailActivityItem}>
                <div className={styles.icon}>
                    {renderSquadActivityIcon(activity?.activityType)}
                </div>
                <div className={styles.message}>
                    <Text isBold>{activity?.initiatedByName}</Text>
                    <Text>{activity?.plainTextDescriptionNoUser}</Text>
                </div>
                <div className={styles.time}>
                    <Text>
                        {generateSquadActivityTimeString(activity?.createdAt)}
                    </Text>
                </div>
            </div>
        );
    }

    function renderLoading() {
        return (
            <div className={styles.supportSquadDetailActivityItem}>
                <Skeleton
                    className={styles.skeleton}
                    height="45px"
                    width="45px"
                    style={{ borderRadius: "50%" }}
                />
                <div className={styles.message}>
                    <Skeleton
                        className={styles.skeleton}
                        height="12px"
                        width="50px"
                        style={{ marginBottom: "4px" }}
                    />
                    <Skeleton
                        className={styles.skeleton}
                        height="12px"
                        width="150px"
                    />
                </div>
                <div className={styles.time}>
                    <Skeleton
                        className={styles.skeleton}
                        height="10px"
                        width="75px"
                    />
                </div>
            </div>
        );
    }

    return isFetchingSquad ? renderLoading() : renderContent();
};

SupportSquadDetailActivityItem.propTypes = {
    activity: PropTypes.object,
    isFetchingSquad: PropTypes.bool,
};
