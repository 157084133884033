import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Formatters
import { formatters } from "./formatters";

// Resources: English
import commonEN from "./locales/en/common.json";
import componentsEN from "./locales/en/components.json";
import dateTimeEN from "./locales/en/date-time.json";
import dashboardEN from "./locales/en/dashboard.json";
import inboxEN from "./locales/en/inbox.json";
import lettersEN from "./locales/en/letters.json";
import memoryBookEN from "./locales/en/memory-book.json";
import notificationsEN from "./locales/en/notifications.json";
import onboardingEN from "./locales/en/onboarding.json";
import profileEN from "./locales/en/profile.json";
import purchaseEN from "./locales/en/purchase.json";
import referralsEN from "./locales/en/referrals.json";
import supportSquadEN from "./locales/en/support-squad.json";
import timeEN from "./locales/en/time.json";
import walletEN from "./locales/en/wallet.json";

// Resources: Spanish
import commonES from "./locales/es/common.json";
import componentsES from "./locales/es/components.json";
import dashboardES from "./locales/es/dashboard.json";
import inboxES from "./locales/es/inbox.json";
import lettersES from "./locales/es/letters.json";
import dateTimeES from "./locales/es/date-time.json";
import memoryBookES from "./locales/es/memory-book.json";
import notificationsES from "./locales/es/notifications.json";
import onboardingES from "./locales/es/onboarding.json";
import profileES from "./locales/es/profile.json";
import purchaseES from "./locales/es/purchase.json";
import referralsES from "./locales/es/referrals.json";
import supportSquadES from "./locales/es/support-squad.json";
import timeES from "./locales/es/time.json";
import walletES from "./locales/es/wallet.json";
// Utils
import { detectUserLanguage } from "./utils";

// Constants
const debug = false;
const fallbackLng = "en";
const lng = detectUserLanguage();

i18n.use(initReactI18next).init({
    debug,
    fallbackLng,
    interpolation: {
        escapeValue: false,
        format: (value, format, lng, options) => {
            if (formatters[format]) {
                return formatters[format](value, format, lng, options);
            }
            return value;
        },
    },
    lng,
    ns: [
        "common",
        "components",
        "dashboard",
        "inbox",
        "letters",
        "date_time",
        "memory_book",
        "notifications",
        "onboarding",
        "profile",
        "purchase",
        "referrals",
        "support_squad",
        "time",
        "wallet",
    ],
    resources: {
        en: {
            common: commonEN,
            components: componentsEN,
            date_time: dateTimeEN,
            dashboard: dashboardEN,
            inbox: inboxEN,
            letters: lettersEN,
            memory_book: memoryBookEN,
            notifications: notificationsEN,
            onboarding: onboardingEN,
            profile: profileEN,
            purchase: purchaseEN,
            referrals: referralsEN,
            support_squad: supportSquadEN,
            time: timeEN,
            wallet: walletEN,
        },
        es: {
            common: commonES,
            components: componentsES,
            date_time: dateTimeES,
            dashboard: dashboardES,
            inbox: inboxES,
            letters: lettersES,
            memory_book: memoryBookES,
            notifications: notificationsES,
            onboarding: onboardingES,
            profile: profileES,
            purchase: purchaseES,
            referrals: referralsES,
            support_squad: supportSquadES,
            time: timeES,
            wallet: walletES,
        },
    },
});
