import React, { useEffect, useRef, useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// Components
import { AppleIcon } from "components/Icons/AppleIcon";

// CSS
import styles from "./apple-login-button.module.scss";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Platforms
import { Apple } from "../../../platforms/apple";

export const AppleLoginButton = (props) => {
    const { callbacks, displayType, mode, text } = props;

    /************************************************
     * useState
     ************************************************/

    const [buttonText, setButtonText] = useState(text);
    const [isLoading, setIsLoading] = useState(false);

    /************************************************
     * useRef
     ************************************************/

    const buttonElement = useRef(null);

    /************************************************
     * useEffect
     ************************************************/

    useEffect(
        () => Apple.init({ ...callbacks, toggleButtonLoading }, mode),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    useEffect(() => {
        if (displayType !== "logo-only") {
            const textElement =
                buttonElement.current.getElementsByTagName("text")[0];
            textElement.removeAttribute("textLength");
            textElement.textContent = buttonText;
        }
    }, [buttonElement, buttonText, displayType]);

    /************************************************
     * End Hooks
     ************************************************/

    // This method does not account for Apple logic that actually handles auth
    function handleButtonClick() {
        toggleButtonLoading(true);
        AnalyticsLogger.logSignUpStart("apple", "selectApple");
    }

    function toggleButtonLoading(isLoading) {
        const newText = isLoading ? "Signing In..." : text;
        setButtonText(newText);
        setIsLoading(isLoading);
    }

    return (
        <div className={styles["apple-login-button"]}>
            <div className={styles.icon}>
                <AppleIcon />
            </div>
            <div
                className={cx(styles.button, { disabled: isLoading })}
                data-border="false"
                data-color="white"
                data-mode={displayType}
                data-type="continue"
                id="appleid-signin"
                ref={buttonElement}
                onClick={handleButtonClick}
            />
        </div>
    );
};

AppleLoginButton.defaultProps = {
    callbacks: {},
    displayType: "center-align",
    mode: "login",
};

AppleLoginButton.propTypes = {
    callbacks: PropTypes.object,
    displayType: PropTypes.string,
    mode: PropTypes.string,
};
