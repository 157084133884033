// Validation
import { Validation as GeneralValidation } from "validation/validation";

// Constants
const printedValueRegex =
    /^[a-zA-Z0-9`~!@#$%^&*()\-_=+\\{.*\\}\\[\]|;:'",<.>/?áéíóúÁÉÍÓÚàèìòùÀÈÌÒÙâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸåÅøØæÆœŒßçÇšžŠŽþðÞÐ ]*$/;

export class Validation {
    static isValidEmail(email) {
        return GeneralValidation.isEmailAddress(email);
    }

    static isValidMessage(message) {
        return (
            GeneralValidation.isAtLeastOfLength(message, 1) &&
            GeneralValidation.isLessThanOrEqualToMaxLength(message, 100)
        );
    }

    static isValidName(name) {
        return (
            GeneralValidation.isAtLeastOfLength(name, 1) &&
            GeneralValidation.isLessThanOrEqualToMaxLength(name, 18)
        );
    }

    static isValidPrintedValue(str) {
        return printedValueRegex.test(str);
    }

    static canSubmit({ email, message, name }) {
        return (
            Validation.isValidEmail(email) &&
            Validation.isValidMessage(message) &&
            Validation.isValidName(name)
        );
    }
}
