import React from "react";
import PropTypes from "prop-types";

// CSS
import styles from "./card.module.scss";

// Utils
import { handleKeyDown } from "utils/eventUtils";

export const Card = ({ children, cypressTestId, onClick }) => {
    return (
        <div
            className={styles.card}
            data-cy={cypressTestId}
            onClick={onClick}
            tabIndex="0"
            onKeyDown={(e) => handleKeyDown(e, onClick)}
        >
            {children}
        </div>
    );
};

Card.propTypes = {
    cypressTestId: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};
