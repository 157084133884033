// Utils
import { cleanPhoneNumber } from "utils/phoneUtils";

export function generateSubmitShippingAddressPayload({ shippingAddress }) {
    return {
        address_1: shippingAddress.line1,
        address_2: shippingAddress.line2 || "",
        city: shippingAddress.city,
        country_code: "us",
        first_name: shippingAddress.firstName,
        last_name: shippingAddress.lastName,
        phone: cleanPhoneNumber(
            shippingAddress.phone.countryCode + shippingAddress.phone.baseNumber
        ),
        postal_code: shippingAddress.postalCode,
        province: shippingAddress.province,
    };
}

export function generateSetShippingOptionPayload({ cart, shippingOption }) {
    return {
        address: cart.shipping_address,
        shippingOptionId: shippingOption.id,
    };
}

export function generateSubmitPaymentDetailsPayload({ paymentMethod }) {
    return {
        paymentMethodToken: paymentMethod.token,
    };
}
