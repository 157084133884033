import React from "react";

// Components
import { HeaderBar } from "../../components/HeaderBar/HeaderBar";
import { InboxDetail } from "./InboxDetail/InboxDetail";
import { InboxListItem } from "./InboxListItem/InboxListItem";
import { InboxEmptyIcon } from "components/Icons/InboxEmptyIcon";

// CSS
import styles from "./inbox.module.scss";

// Hooks
import { useDocumentTitle } from "@uidotdev/usehooks";
import { useInbox } from "./hooks/useInbox";

// Localization
import { useTranslation } from "localization/localization";

export const Inbox = (props) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation("inbox");

    useDocumentTitle(t("document_title", { ns: "inbox" }));

    const { inboxManager, refresh, selectedMessage, setSelectedMessage } =
        useInbox();
    const { hasMadeInitialMessagesFetch, hasMessages, messages } = inboxManager;

    /**
     * End Hooks
     */

    function renderEmptyList() {
        return (
            <div className={styles.empty}>
                <InboxEmptyIcon className={styles.icon} />
                <span className={styles.empty__header}>
                    {t("no_messages", { ns: "inbox" })}
                </span>
                <span className={styles.empty__description}>
                    {t("empty_inbox", { ns: "inbox" })}
                </span>
            </div>
        );
    }

    function renderLoading() {
        return (
            <>
                <InboxListItem isLoading />
                <InboxListItem isLoading />
                <InboxListItem isLoading />
            </>
        );
    }

    function renderMessages() {
        if (!hasMadeInitialMessagesFetch) {
            // Loading state
            return renderLoading();
        } else if (hasMadeInitialMessagesFetch && hasMessages) {
            // Content state
            return messages?.map((message) => {
                return (
                    <InboxListItem
                        {...props}
                        inboxManager={inboxManager}
                        key={message.id}
                        message={message}
                        refresh={refresh}
                        selectedMessage={selectedMessage}
                        setSelectedMessage={setSelectedMessage}
                    />
                );
            });
        } else {
            // Empty state
            return renderEmptyList();
        }
    }

    return (
        <div className="page">
            <HeaderBar text="Inbox" />
            <div className="page__body">
                <div className={styles.inbox}>
                    <div className={styles.list}>{renderMessages()}</div>
                    <InboxDetail
                        {...props}
                        refresh={refresh}
                        selectedMessage={selectedMessage}
                    />
                </div>
            </div>
        </div>
    );
};
