import React, { useContext, useEffect } from "react";

// Components
import { SignInForgotPassword } from "./scenes/SignIn/SignInForgotPassword";
import { SignInMain } from "./scenes/SignIn/SignInMain/SignInMain";
import { SignInPasswordReset } from "./scenes/SignIn/SignInPasswordReset/SignInPasswordReset";
import { SignInSlideshow } from "./SignInSlideshow";
import { SignUpConfirmEmail } from "./scenes/SignUp/SignUpConfirmEmail/SignUpConfirmEmail";
import { SignUpConfirmPhone } from "./scenes/SignUp/SignUpConfirmPhone/SignUpConfirmPhone";
import { SignUpConnected } from "./scenes/SignUp/SignUpConnected/SignUpConnected";
import { SignUpEmailInUseModal } from "./scenes/SignUp/SignUpEmailInUseModal/SignUpEmailInUseModal";
import { SignUpName } from "./scenes/SignUp/SignUpName/SignUpName";
import { SignUpPersonaChild } from "./scenes/SignUp/SignUpPersonaChild/SignUpPersonaChild";
import { SignUpPersonaRoot } from "./scenes/SignUp/SignUpPersonaRoot/SignUpPersonaRoot";
import { SignUpPhone } from "./scenes/SignUp/SignUpPhone/SignUpPhone";
import { SignUpRecruitingStation } from "./scenes/SignUp/SignUpRecruitingStation/SignUpRecruitingStation";
import { SignUpShipDate } from "./scenes/SignUp/SignUpShipDate/SignUpShipDate";
import { SignUpSuccess } from "./scenes/SignUp/SignUpSuccess/SignUpSuccess";
import { SignInWelcome } from "scenes/SignIn/components/SignInWelcome/SignInWelcome";

// Context
import { DepsContext } from "context/deps";
import { NavBarContext } from "context/navbar";
import { NotificationBarContext } from "../../context/notificationBar";

// CSS
import styles from "./sign-in.module.scss";

// Enums
import { NavigationEnum } from "./constants/constants";
import { RightColumnMode } from "./enums/RightColumnMode";

// Hooks
import { usePersonaWizard } from "hooks/wizards/usePersonaWizard/usePersonaWizard";
import { useSignIn } from "./hooks/useSignIn";
import { useSignInNavigation } from "./hooks/useSignInNavigation";
import { useSignInUserInfo } from "./hooks/useSignInUserInfo";
import { useSignUp } from "./hooks/useSignUp";

// Localization
import { useTranslation } from "localization/localization";

// Media
import sandboxxHorizontalLogo from "media/logos/sandboxx-horizontal.svg";

// Utils
import { loadRecaptchaScript } from "utils/recaptchaUtils";

export const SignIn = (props) => {
    const { goToForgotPassword, goToPasswordReset, history } = props;

    /**
     * useContext
     */

    const { depsLocations, fetchDepsLocations } = useContext(DepsContext);
    const { runShouldShowNavBarCheck } = useContext(NavBarContext);
    const { showNotification } = useContext(NotificationBarContext);

    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useSignInNavigation
     * -------------------
     * Custom hook for managing navigation state
     */

    const {
        currentSection,
        handleGoToForgotPassword,
        handleGoToNameEntry,
        handleGoToOnboarding,
        handleGoToPasswordReset,
        handleGoToPhoneEntry,
        needsNameForSSO,
        setCurrentSection,
        setHasCompletedSignUpName,
    } = useSignInNavigation(props);

    /**
     * useSignInUserInfo
     * -------------------
     * Custom hook for managing userInfo state
     */

    const {
        handleChangePhoneNumber,
        handleClearPhoneNumber,
        handleChangeUserInfo,
        setUserInfo,
        userInfo,
    } = useSignInUserInfo(props);

    /**
     * useSignIn
     * ---------
     * Custom hook for general state management
     */

    const {
        handleChangeVerificationCode,
        handleLogin,
        handleChangeEmail,
        handleChangePassword,
        handleSelectSignInPlatform,
        isUsingEmail,
        isUsingEmailVerify,
        isUsingPhone,
        rightColumnMode,
        signInLoading,
        signInMode,
        setRightColumnMode,
        setSignInMode,
        setSignInPlatform,
        storeUserAfterUpdate,
        signInPlatform,
        toggleSignInMode,
        verificationCode,
    } = useSignIn({
        ...props,
        runShouldShowNavBarCheck,
        setCurrentSection,
        showNotification,
        setUserInfo,
        userInfo,
    });

    /**
     * useSignUp
     * ---------
     * Custom hook for managing state in the SignUp flow
     */

    const {
        connections,
        handleLoadingToggle,
        handleSignUp,
        handleUpdateName,
        loading,
        setConnections,
        setShouldShowEmailInUseModal,
        shouldShowEmailInUseModal,
    } = useSignUp({
        ...props,
        isUsingEmail,
        isUsingEmailVerify,
        isUsingPhone,
        setCurrentSection,
        showNotification,
        userInfo,
        setUserInfo,
    });

    /**
     * usePersonaWizard
     * ----------------
     * Custom hook for determining user's persona
     */

    const {
        childPersonas,
        fetchRootPersonas,
        handleGoBackToPersonaSelection,
        handleSelectRootPersona,
        handleSelectChildPersona,
        isLoadingChildPersonas,
        isLoadingRootPersonaSelection,
        rootPersonas,
        selectedRootPersona,
        setIsLoadingRootPersonaSelection,
    } = usePersonaWizard({
        ...props,
        setCurrentSection,
        setUserInfo,
        storeUserAfterUpdate,
    });

    /**
     * useEffect
     */

    // Load Google ReCAPTCHA script with environment-based site key

    useEffect(() => {
        loadRecaptchaScript();
        if (goToForgotPassword) {
            handleGoToForgotPassword();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (goToPasswordReset) {
            handleGoToPasswordReset();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [goToPasswordReset]);

    /************************************************
     * End Hooks
     ************************************************/

    function renderCurrentSection() {
        return {
            [NavigationEnum.SIGN_IN_FORGOT_PASSWORD]: (
                <SignInForgotPassword
                    setCurrentSection={setCurrentSection}
                    showNotification={showNotification}
                />
            ),
            [NavigationEnum.SIGN_IN_MAIN]: (
                <SignInMain
                    {...props}
                    handleChangePhoneNumber={handleChangePhoneNumber}
                    handleChangeUserInfo={handleChangeUserInfo}
                    handleChangeEmail={handleChangeEmail}
                    handleChangePassword={handleChangePassword}
                    handleClearPhoneNumber={handleClearPhoneNumber}
                    handleGoToForgotPassword={handleGoToForgotPassword}
                    handleGoToNameEntry={handleGoToNameEntry}
                    handleGoToOnboarding={handleGoToOnboarding}
                    handleGoToPasswordReset={handleGoToPasswordReset}
                    handleGoToPhoneEntry={handleGoToPhoneEntry}
                    handleSelectSignInPlatform={handleSelectSignInPlatform}
                    handleSignUp={handleSignUp}
                    handleLogin={handleLogin}
                    isUsingEmail={isUsingEmail}
                    isUsingEmailVerify={isUsingEmailVerify}
                    loading={loading}
                    signInLoading={signInLoading}
                    signInMode={signInMode}
                    setHasCompletedSignUpName={setHasCompletedSignUpName}
                    setSignInMode={setSignInMode}
                    setSignInPlatform={setSignInPlatform}
                    setUserInfo={setUserInfo}
                    signInPlatform={signInPlatform}
                    showNotification={showNotification}
                    toggleSignInMode={toggleSignInMode}
                    userInfo={userInfo}
                />
            ),
            [NavigationEnum.SIGN_IN_PASSWORD_RESET]: (
                <SignInPasswordReset
                    handleGoToOnboarding={handleGoToOnboarding}
                    history={history}
                />
            ),
            [NavigationEnum.SIGN_UP_CONFIRM_EMAIL]: (
                <SignUpConfirmEmail
                    {...props}
                    handleChangeVerificationCode={handleChangeVerificationCode}
                    handleLoadingToggle={handleLoadingToggle}
                    handleSignUp={handleSignUp}
                    isUsingEmail={isUsingEmail}
                    isUsingEmailVerify={isUsingEmailVerify}
                    loading={loading}
                    setConnections={setConnections}
                    runShouldShowNavBarCheck={runShouldShowNavBarCheck}
                    setCurrentSection={setCurrentSection}
                    showNotification={showNotification}
                    storeUserAfterUpdate={storeUserAfterUpdate}
                    verificationCode={verificationCode}
                    userInfo={userInfo}
                />
            ),
            [NavigationEnum.SIGN_UP_CONFIRM_PHONE]: (
                <SignUpConfirmPhone
                    handleChangeVerificationCode={handleChangeVerificationCode}
                    handleLoadingToggle={handleLoadingToggle}
                    loading={loading}
                    setConnections={setConnections}
                    setCurrentSection={setCurrentSection}
                    storeUserAfterUpdate={storeUserAfterUpdate}
                    userInfo={userInfo}
                    verificationCode={verificationCode}
                />
            ),
            [NavigationEnum.SIGN_UP_CONNECTED]: (
                <SignUpConnected
                    connections={connections}
                    setCurrentSection={setCurrentSection}
                />
            ),
            [NavigationEnum.SIGN_UP_NAME]: (
                <SignUpName
                    {...props}
                    handleSignUp={handleSignUp}
                    handleUpdateName={handleUpdateName}
                    isUsingEmail={isUsingEmail}
                    isUsingEmailVerify={isUsingEmailVerify}
                    isUsingPhone={isUsingPhone}
                    loading={loading}
                    needsNameForSSO={needsNameForSSO}
                    setCurrentSection={setCurrentSection}
                    setHasCompletedSignUpName={setHasCompletedSignUpName}
                    setShouldShowEmailInUseModal={setShouldShowEmailInUseModal}
                    setUserInfo={setUserInfo}
                    showNotification={showNotification}
                    storeUserAfterUpdate={storeUserAfterUpdate}
                    userInfo={userInfo}
                />
            ),
            [NavigationEnum.SIGN_UP_PERSONA_CHILD]: (
                <SignUpPersonaChild
                    childPersonas={childPersonas}
                    handleGoBackToPersonaSelection={
                        handleGoBackToPersonaSelection
                    }
                    handleSelectChildPersona={handleSelectChildPersona}
                    isLoadingChildPersonas={isLoadingChildPersonas}
                    selectedRootPersona={selectedRootPersona}
                    setCurrentSection={setCurrentSection}
                    setUserInfo={setUserInfo}
                />
            ),
            [NavigationEnum.SIGN_UP_PERSONA_ROOT]: (
                <SignUpPersonaRoot
                    connections={connections}
                    fetchRootPersonas={fetchRootPersonas}
                    handleSelectRootPersona={handleSelectRootPersona}
                    isLoadingRootPersonaSelection={
                        isLoadingRootPersonaSelection
                    }
                    rootPersonas={rootPersonas}
                    setConnections={setConnections}
                    setCurrentSection={setCurrentSection}
                    setIsLoadingRootPersonaSelection={
                        setIsLoadingRootPersonaSelection
                    }
                    setRightColumnMode={setRightColumnMode}
                    setUserInfo={setUserInfo}
                />
            ),
            [NavigationEnum.SIGN_UP_PHONE]: (
                <SignUpPhone
                    handleChangePhoneNumber={handleChangePhoneNumber}
                    handleClearPhoneNumber={handleClearPhoneNumber}
                    handleLoadingToggle={handleLoadingToggle}
                    loading={loading}
                    setCurrentSection={setCurrentSection}
                    setUserInfo={setUserInfo}
                    userInfo={userInfo}
                />
            ),
            [NavigationEnum.SIGN_UP_RECRUITING_STATION]: (
                <SignUpRecruitingStation
                    depsLocations={depsLocations}
                    fetchDepsLocations={fetchDepsLocations}
                    handleGoBackToPersonaSelection={
                        handleGoBackToPersonaSelection
                    }
                    setCurrentSection={setCurrentSection}
                    userInfo={userInfo}
                />
            ),
            [NavigationEnum.SIGN_UP_SHIP_DATE]: (
                <SignUpShipDate
                    handleLoadingToggle={handleLoadingToggle}
                    loading={loading}
                    setCurrentSection={setCurrentSection}
                    setUserInfo={setUserInfo}
                    showNotification={showNotification}
                    storeUserAfterUpdate={storeUserAfterUpdate}
                    userInfo={userInfo}
                />
            ),
            [NavigationEnum.SIGN_UP_SUCCESS]: (
                <SignUpSuccess
                    runShouldShowNavBarCheck={runShouldShowNavBarCheck}
                    selectedRootPersona={selectedRootPersona}
                    userInfo={userInfo}
                />
            ),
        }[currentSection];
    }

    function renderSandboxxLogo() {
        return (
            <div className={styles.mainHeader}>
                <img
                    alt={t("sandboxx_logo", { ns: "common" })}
                    className={styles.logo}
                    src={sandboxxHorizontalLogo}
                />
            </div>
        );
    }

    function renderRightColumn() {
        if (rightColumnMode === RightColumnMode.SLIDESHOW) {
            return <SignInSlideshow />;
        } else if (rightColumnMode === RightColumnMode.WELCOME) {
            return <SignInWelcome />;
        }
    }

    return (
        <div className={styles["sign-in"]}>
            <div className={styles.grid}>
                <div className={styles.gridContent}>
                    {renderSandboxxLogo()}
                    {renderCurrentSection()}
                </div>
                {renderRightColumn()}
            </div>
            <SignUpEmailInUseModal
                setCurrentSection={setCurrentSection}
                setShouldShowEmailInUseModal={setShouldShowEmailInUseModal}
                setSignInPlatform={setSignInPlatform}
                setUserInfo={setUserInfo}
                shouldShow={shouldShowEmailInUseModal}
                toggleSignInMode={toggleSignInMode}
                userInfo={userInfo}
            />
        </div>
    );
};
