export function isMobile() {
    const ua = navigator.userAgent;
    // eslint-disable-next-line
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return true;
    }
    if (
        // eslint-disable-next-line
        /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
            ua
        )
    ) {
        return true;
    }
    return false;
}

export function isUsingIPad() {
    // eslint-disable-next-line
    return navigator.userAgent.match(/iPad/i) !== null;
}

/**
 * This method returns true if the processed route contains a string
 * that corresponds with a page that does not require the user to be
 * logged-into the app
 *
 * @returns {Boolean}
 */
export function isVisitingNoAuthURL() {
    const pathnames = [];
    return pathnames.some((pathname) =>
        window.location.pathname.includes(pathname)
    );
}
