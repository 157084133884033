export const HomeIcon = ({ className, color }) => {
    return (
        <svg
            className={className}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9 21V13.6C9 13.0399 9 12.7599 9.10899 12.546C9.20487 12.3578 9.35785 12.2049 9.54601 12.109C9.75992 12 10.0399 12 10.6 12H13.4C13.9601 12 14.2401 12 14.454 12.109C14.6422 12.2049 14.7951 12.3578 14.891 12.546C15 12.7599 15 13.0399 15 13.6V21M11.0177 2.764L4.23539 8.03912C3.78202 8.39175 3.55534 8.56806 3.39203 8.78886C3.24737 8.98444 3.1396 9.20478 3.07403 9.43905C3 9.70352 3 9.9907 3 10.5651V17.8C3 18.9201 3 19.4801 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.07989 21 6.2 21H17.8C18.9201 21 19.4802 21 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C21 19.4801 21 18.9201 21 17.8V10.5651C21 9.9907 21 9.70352 20.926 9.43905C20.8604 9.20478 20.7526 8.98444 20.608 8.78886C20.4447 8.56806 20.218 8.39175 19.7646 8.03913L12.9823 2.764C12.631 2.49075 12.4553 2.35412 12.2613 2.3016C12.0902 2.25526 11.9098 2.25526 11.7387 2.3016C11.5447 2.35412 11.369 2.49075 11.0177 2.764Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export const HomeIconFilled = ({ className, color }) => {
    return (
        <svg
            className={className}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.5226 1.33636C12.1803 1.24368 11.8195 1.24368 11.4772 1.33636C11.0798 1.44395 10.7453 1.7066 10.4783 1.91623L10.4037 1.97465L3.54364 7.31012C3.16701 7.6024 2.83519 7.85991 2.58794 8.19421C2.37094 8.48759 2.2093 8.8181 2.11094 9.1695C1.99886 9.56992 1.99931 9.98993 1.99982 10.4667L1.99989 17.8385C1.99988 18.3657 1.99986 18.8204 2.03046 19.195C2.06277 19.5904 2.13408 19.9836 2.32688 20.362C2.6145 20.9264 3.07344 21.3854 3.63792 21.673C4.0163 21.8658 4.40952 21.9371 4.80487 21.9694C5.17943 22 5.63419 22 6.16133 22H17.8385C18.3656 22 18.8204 22 19.1949 21.9694C19.5903 21.9371 19.9835 21.8658 20.3619 21.673C20.9264 21.3854 21.3853 20.9264 21.6729 20.362C21.8657 19.9836 21.937 19.5904 21.9693 19.195C21.9999 18.8204 21.9999 18.3657 21.9999 17.8386L22 10.4667C22.0005 9.98993 22.0009 9.56992 21.8889 9.1695C21.7905 8.8181 21.6288 8.48759 21.4119 8.19421C21.1646 7.8599 20.8328 7.6024 20.4561 7.31011L13.5961 1.97465L13.5215 1.91623C13.2545 1.7066 12.92 1.44395 12.5226 1.33636ZM8.99991 13.6V20H14.9999V13.6C14.9999 13.0399 14.9999 12.7599 14.8909 12.546C14.795 12.3578 14.6421 12.2049 14.4539 12.109C14.24 12 13.96 12 13.3999 12H10.5999C10.0399 12 9.75983 12 9.54592 12.109C9.35776 12.2049 9.20478 12.3578 9.1089 12.546C8.99991 12.7599 8.99991 13.0399 8.99991 13.6Z"
                fill={color}
            />
        </svg>
    );
};
