/**
 * This polyfill was implemented because older versions of Safari do not
 * support `crypto.randomUUID`
 *
 * More info on support for this method can be found here:
 * https://caniuse.com/mdn-api_crypto_randomuuid
 */

if (!(crypto.randomUUID instanceof Function)) {
    crypto.randomUUID = function uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
            // eslint-disable-next-line no-use-before-define
            (
                c ^
                (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
            ).toString(16)
        );
    };
}
