export class Persona {
    constructor(persona) {
        Object.keys(persona).forEach((attr) => {
            this[attr] = persona[attr];
        });
    }

    hasChildren() {
        return this.children?.length;
    }
}
