import React from "react";
import { withRouter } from "react-router-dom";

// Components
import { Modal } from "components/Modals/Modal";
import { PhoneVerificationModalCompleteLetters } from "./PhoneVerificationModalCompleteLetters/PhoneVerificationModalCompleteLetters";
import { PhoneVerificationModalCompleteLettersConnection } from "./PhoneVerificationModalCompleteLettersConnection/PhoneVerificationModalCompleteLettersConnection";
import { PhoneVerificationModalCompleteProfile } from "./PhoneVerificationModalCompleteProfile/PhoneVerificationModalCompleteProfile";
import { PhoneVerificationModalPhoneNumber } from "./PhoneVerificationModalPhoneNumber/PhoneVerificationModalPhoneNumber";
import { PhoneVerificationModalVerificationCode } from "./PhoneVerificationModalVerificationCode/PhoneVerificationModalVerificationCode";

// CSS
import styles from "./phone-verification-modal.module.scss";

// Hooks
import { usePhoneVerificationModal } from "./hooks/usePhoneVerificationModal";

export const PhoneVerificationModal = (props) => {
    /**
     * Custom Hook
     */

    const {
        connection,
        currentSection,
        handleChangePhoneNumber,
        handleChangeVerificationCode,
        handleClearPhoneNumber,
        handleCloseModal,
        handleGoToLetterCompose,
        handleSubmitPhoneNumber,
        handleSubmitVerificationCode,
        loading,
        phoneNumber,
        phoneVerificationModalSetting,
        resendVerificationCode,
        showPhoneVerificationModal,
        togglePhoneVerificationModal,
    } = usePhoneVerificationModal();

    /**
     * End Hooks
     */

    function renderCurrentSection() {
        if (phoneVerificationModalSetting === "letters") {
            return {
                complete: (
                    <PhoneVerificationModalCompleteLetters
                        {...props}
                        togglePhoneVerificationModal={
                            togglePhoneVerificationModal
                        }
                    />
                ),
                completeConnection: (
                    <PhoneVerificationModalCompleteLettersConnection
                        {...props}
                        connection={connection}
                        handleGoToLetterCompose={handleGoToLetterCompose}
                    />
                ),
                phoneNumber: (
                    <PhoneVerificationModalPhoneNumber
                        {...props}
                        handleChangePhoneNumber={handleChangePhoneNumber}
                        handleClearPhoneNumber={handleClearPhoneNumber}
                        handleSubmitPhoneNumber={handleSubmitPhoneNumber}
                        loading={loading}
                        phoneNumber={phoneNumber}
                    />
                ),
                verificationCode: (
                    <PhoneVerificationModalVerificationCode
                        {...props}
                        handleChangeVerificationCode={
                            handleChangeVerificationCode
                        }
                        handleSubmitVerificationCode={
                            handleSubmitVerificationCode
                        }
                        loading={loading}
                        resendVerificationCode={resendVerificationCode}
                    />
                ),
            }[currentSection];
        } else {
            return {
                complete: (
                    <PhoneVerificationModalCompleteProfile
                        {...props}
                        togglePhoneVerificationModal={
                            togglePhoneVerificationModal
                        }
                    />
                ),
                completeConnection: (
                    <PhoneVerificationModalCompleteProfile
                        {...props}
                        togglePhoneVerificationModal={
                            togglePhoneVerificationModal
                        }
                    />
                ),
                phoneNumber: (
                    <PhoneVerificationModalPhoneNumber
                        {...props}
                        handleChangePhoneNumber={handleChangePhoneNumber}
                        handleClearPhoneNumber={handleClearPhoneNumber}
                        handleSubmitPhoneNumber={handleSubmitPhoneNumber}
                        loading={loading}
                        phoneNumber={phoneNumber}
                    />
                ),
                verificationCode: (
                    <PhoneVerificationModalVerificationCode
                        {...props}
                        handleChangeVerificationCode={
                            handleChangeVerificationCode
                        }
                        handleSubmitVerificationCode={
                            handleSubmitVerificationCode
                        }
                        loading={loading}
                        resendVerificationCode={resendVerificationCode}
                    />
                ),
            }[currentSection];
        }
    }

    return (
        <Modal
            bodyClasses={styles.body}
            centered="true"
            className={styles["phone-verification-modal"]}
            cypressTestId="phone-verification-modal"
            onClose={handleCloseModal}
            onHide={handleCloseModal}
            show={showPhoneVerificationModal}
            size="lg"
        >
            {renderCurrentSection()}
        </Modal>
    );
};

export const PhoneVerificationModalWithRouter = withRouter(
    PhoneVerificationModal
);
