import React, { useContext } from "react";

// Components
import { DepModal } from "components/DepModal/DepModal";
import { HeaderBar } from "components/HeaderBar/HeaderBar";
import { LoadingPage } from "components/Loading/Loading";
import { MusterHeaderBarContent } from "../MusterHeaderBarContent/MusterHeaderBarContent";
import { MusterOverviewActiveRecruitsPanel } from "./components/MusterOverviewActiveRecruitsPanel/MusterOverviewActiveRecruitsPanel";
import { MusterOverviewDocumentCarousel } from "./components/MusterOverviewDocumentCarousel/MusterOverviewDocumentCarousel";
import { MusterOverviewHeader } from "./components/MusterOverviewHeader/MusterOverviewHeader";
import { MusterOverviewStoryly } from "./components/MusterOverviewStoryly/MusterOverviewStoryly";
import { MusterRecruitDetailModal } from "../MusterRecruitDetailModal/MusterRecruitDetailModal";
import { MusterRecruitsListModal } from "../MusterRecruitsListModal/MusterRecruitsListModal";

// Context
import { DepsContext } from "context/deps";
import { HeaderBarContext } from "context/headerBar";
import { MusterContext } from "context/muster";
import { NotificationBarContext } from "context/notificationBar";

// CSS
import styles from "./muster-overview.module.scss";

// Enums
import { PersonaEnum } from "enums/PersonaEnum";

// Hooks
import { useDocumentTitle } from "@uidotdev/usehooks";
import { useMusterOverview } from "./hooks/useMusterOverview";

export const MusterOverview = (props) => {
    const { history } = props;

    /**
     * useContext
     */

    const { setHeaderBarContent } = useContext(HeaderBarContext);
    const {
        contactList,
        currentDepLocation,
        currentShipDate,
        daysUntilShip,
        fetchRecruitDashboard,
        isLoadingContactList,
        isLoadingDashboard,
        isLoadingShipDate,
        musterDocuments,
        recruits,
        shipDates,
        updateShipDate,
    } = useContext(MusterContext);
    const { showNotification } = useContext(NotificationBarContext);
    const { depsLocations, fetchDepsLocations } = useContext(DepsContext);

    /**
     * Custom Hooks
     */

    useDocumentTitle("Sandboxx - Waypoints Overview");

    const {
        branchPersona,
        hasShipDates,
        rootPersona,
        selectedRecruit,
        setSelectedRecruit,
        setShouldShowRecruitDetailModal,
        setShouldShowDepModal,
        setShouldShowRecruitsModal,
        shouldShowRecruitsModal,
        shouldShowDepModal,
        shouldShowRecruitDetailModal,
        user,
    } = useMusterOverview({ setHeaderBarContent, shipDates });

    /**
     * End Hooks & Constants
     */

    function handleToggleRecruitsModal() {
        setShouldShowRecruitsModal((prev) => !prev);
    }

    function handleToggleRecruitDetailModal() {
        setShouldShowRecruitDetailModal((prev) => !prev);
    }

    function onSetDepLocation() {
        setShouldShowDepModal(false);
        fetchRecruitDashboard();
    }

    function renderContent() {
        if (!isLoadingDashboard) {
            return renderMusterOverview();
        } else {
            return (
                <div className={styles.loadingContainer}>
                    <LoadingPage />
                </div>
            );
        }
    }

    function renderModals() {
        return (
            <>
                <MusterRecruitsListModal
                    contactList={contactList}
                    handleToggleRecruitsModal={handleToggleRecruitsModal}
                    handleToggleRecruitDetailModal={
                        handleToggleRecruitDetailModal
                    }
                    isLoadingContactList={isLoadingContactList}
                    setSelectedRecruit={setSelectedRecruit}
                    shipDates={shipDates}
                    shouldShow={shouldShowRecruitsModal}
                />
                <MusterRecruitDetailModal
                    handleToggleRecruitDetailModal={
                        handleToggleRecruitDetailModal
                    }
                    selectedRecruit={selectedRecruit}
                    shouldShow={shouldShowRecruitDetailModal}
                />
                <DepModal
                    depsLocations={depsLocations}
                    fetchDepsLocations={fetchDepsLocations}
                    handleShowDepModal={setShouldShowDepModal}
                    headerTextOverride={true}
                    onSetDepLocation={onSetDepLocation}
                    shouldShow={shouldShowDepModal}
                    user={user}
                />
            </>
        );
    }

    function renderMusterOverview() {
        return (
            <>
                <MusterOverviewHeader
                    branchPersona={branchPersona}
                    currentDepLocation={currentDepLocation}
                    currentShipDate={currentShipDate}
                    daysUntilShip={daysUntilShip}
                    fetchRecruitDashboard={fetchRecruitDashboard}
                    isLoadingShipDate={isLoadingShipDate}
                    handleToggleRecruitsModal={handleToggleRecruitsModal}
                    recruits={recruits}
                    rootPersona={rootPersona}
                    setShouldShowDepModal={setShouldShowDepModal}
                    showNotification={showNotification}
                    updateShipDate={updateShipDate}
                />
                {rootPersona.id === PersonaEnum.RECRUITER && hasShipDates && (
                    <MusterOverviewActiveRecruitsPanel
                        handleToggleRecruitsModal={handleToggleRecruitsModal}
                        shipDates={shipDates}
                    />
                )}
                {rootPersona.id === PersonaEnum.RECRUIT && (
                    <MusterOverviewDocumentCarousel
                        musterDocuments={musterDocuments}
                        history={history}
                    />
                )}
                <MusterOverviewStoryly
                    branchPersona={branchPersona}
                    rootPersona={rootPersona}
                />
            </>
        );
    }

    return (
        <div className="page">
            <HeaderBar>
                <MusterHeaderBarContent />
            </HeaderBar>
            <div className={styles.musterOverview}>{renderContent()}</div>
            {renderModals()}
        </div>
    );
};
