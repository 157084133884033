import React from "react";

// Components
import { Modal } from "components/Modals/Modal";
import { UserList } from "components/UserList/UserList";

// CSS
import styles from "./wallet-gift-cards-purchase-recipient-contact-modal.module.scss";

// Enums
import { RecipientSourceEnum } from "../../../../enums/RecipientSourceEnum";

export const WalletGiftCardsPurchaseRecipientContactsModal = (props) => {
    const {
        contacts,
        handleSelectRecipient,
        setShouldShowContactsModal,
        shouldShowContactsModal,
    } = props;

    function handleSelectContact({ suggestion }) {
        handleSelectRecipient(suggestion, RecipientSourceEnum.CONTACTS);
        setShouldShowContactsModal(false);
    }

    return (
        <Modal
            bodyClasses={
                styles["wallet-gift-cards-purchase-recipient-contact-modal"]
            }
            headerSubText="Select a contact from below"
            headerText="My Contacts"
            onClose={() => setShouldShowContactsModal(false)}
            show={shouldShowContactsModal}
        >
            <UserList
                classNames={styles.list}
                handleUserSelect={handleSelectContact}
                isClickable
                userList={contacts}
            />
        </Modal>
    );
};
