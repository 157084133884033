// Localization
import { t } from "localization/localization";

export function generateDisplayText(personas) {
    if (personas) {
        if (personas.find((persona) => persona.id === "NATIONAL_GUARD")) {
            return {
                header: t("sign_up_persona_child.military_status_title", {
                    ns: "onboarding",
                }),
                subHeader: "",
            };
        } else if (
            personas.find(
                (persona) =>
                    persona.id === "MARINES" || persona.id === "ARMY_GUARD"
            )
        ) {
            return {
                header: t("sign_up_persona_child.branch_title", {
                    ns: "onboarding",
                }),
                subHeader: "",
            };
        } else if (personas.find((persona) => persona.id === "MOM")) {
            return {
                header: t("sign_up_persona_child.relationship_title", {
                    ns: "onboarding",
                }),
                subHeader: t("sign_up_persona_child.relationship_subtitle", {
                    ns: "onboarding",
                }),
            };
        } else {
            return {
                header: t("sign_up_persona_child.generic_title", {
                    ns: "onboarding",
                }),
                subHeader: "",
            };
        }
    }
}
