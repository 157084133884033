// Utils
import { areObjectsEqual } from "utils/miscUtils";

export class Validation {
    static areRecipientsEqual({ originalRecipient, recipientStaging }) {
        return areObjectsEqual(originalRecipient, recipientStaging);
    }

    static isRecipientEmpty({ recipientStaging }) {
        return (
            recipientStaging.city === "" ||
            recipientStaging.line1 === "" ||
            recipientStaging.name === "" ||
            recipientStaging.state === "" ||
            recipientStaging.zipcode === ""
        );
    }
}
