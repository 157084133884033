import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// Context
import { ContactsContext } from "../../../../context/contacts";
import { HeaderBarContext } from "../../../../context/headerBar";
import { NotificationBarContext } from "../../../../context/notificationBar";

// Enums
import { ContactModalNavigation } from "scenes/Contacts/ContactModal/enums/ContactModalNavigation";

// Logging
import { AnalyticsLogger } from "../../../../loggers/AnalyticsLogger";

// Models
import { Contact as ContactModel } from "models/contacts/Contact";

export const useContact = (props) => {
    const { location } = props;
    const { contact } = location?.state || {};

    /**
     * Router Hooks
     */

    const history = useHistory();

    /**
     * useContext
     */

    const {
        deleteContact,
        toggleContactModal,
        setOnSubmitContactSuccessFinalCallback,
    } = useContext(ContactsContext);
    const { setHeaderBarContent } = useContext(HeaderBarContext);
    const { showNotification } = useContext(NotificationBarContext);

    /**
     * useState
     */

    const [isDeleting, setIsDeleting] = useState(false);
    const [showDeleteContactModal, setShowDeleteContactModal] = useState(false);

    /**
     * useEffect
     */

    useEffect(() => {
        // Log analytics event
        AnalyticsLogger.logContactView();

        // React to whether contact data is present
        if (!contact) {
            history.push("/profile");
        } else {
            setHeaderBarContent({
                backPathname: "/profile",
                text: "Recipient Overview",
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * End Hooks
     */

    function handleDeleteContact() {
        AnalyticsLogger.logContactDelete();
        setIsDeleting(true);
        deleteContact(
            contact,
            onDeleteContactSuccess,
            onDeleteContactFailure,
            onDeleteContactFailure
        );
    }

    function handleDuplicateConnection() {
        toggleContactModal({
            contactToEdit: contact,
            isSupportSquad: false,
            isWritingLetter: false,
            shouldShow: true,
            targetScreen: ContactModalNavigation.FORM,
            type: "create",
        });
        setOnSubmitContactSuccessFinalCallback(() => onCreateContactSuccess);
    }

    function handleGoToLetterCompose() {
        const isContactInstance = contact instanceof ContactModel;
        if (isContactInstance) {
            localStorage.setItem(
                "sandboxxMessageRecipient",
                JSON.stringify(contact.generateContactObject())
            );
        }
        history.push("/letters/compose");
    }

    function handleToggleContactModal() {
        AnalyticsLogger.logEditContactView();
        toggleContactModal({
            contactToEdit: contact,
            isWritingLetter: false,
            shouldShow: true,
            targetScreen: ContactModalNavigation.FORM,
            type: "edit",
        });
        setOnSubmitContactSuccessFinalCallback(() => onEditContactSuccess);
    }

    function onCreateContactSuccess(contact) {
        history.replace(`/profile`);
        history.push(`/contact/${contact.abContactId}`, {
            contact,
        });
    }

    function onDeleteContactFailure(err) {
        setIsDeleting(false);
        showNotification({
            text: "There was an issue deleting your contact. Please reach out to customer support by clicking the bubble in the bottom right corner of the page.",
            type: "warning",
        });
    }

    function onDeleteContactSuccess(res) {
        setIsDeleting(false);
        showNotification({
            text: "Your contact has been successfully deleted.",
            type: "success",
        });
        history.push("/profile");
    }

    function onEditContactSuccess(res, { contactStaging }) {
        const contact = { ...contactStaging, abContactId: res.abContactId };
        history.replace(`/profile`);
        history.replace(`/contact/${res.abContactId}`, {
            contact,
        });
    }

    return {
        contact,
        handleDeleteContact,
        handleDuplicateConnection,
        handleGoToLetterCompose,
        handleToggleContactModal,
        isDeleting,
        setShowDeleteContactModal,
        showDeleteContactModal,
    };
};
