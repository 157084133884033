import React from "react";

// Components
import { Panel } from "components/Panel/Panel";
import { ReferralsRewards } from "../ReferralsRewards/ReferralsRewards";
import { Text } from "components/Text/Text";

// CSS
import styles from "./referrals-instructions.module.scss";

// Localization
import { useTranslation } from "localization/localization";

export const ReferralsInstructions = () => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * End Hooks
     */

    return (
        <Panel classes={styles.referralsInstructions}>
            <div className={styles.header}>
                <Text type="h1">
                    {t("instructions.title", { ns: "referrals" })}
                </Text>
            </div>
            <div className={styles.steps}>
                <div className={styles.step}>
                    <div className={styles.number}>
                        <span>1</span>
                    </div>
                    <div className={styles.text}>
                        <Text type="h3">
                            {t("instructions.step_1_title", {
                                ns: "referrals",
                            })}
                        </Text>
                        <Text type="body">
                            {t("instructions.step_1_subtitle", {
                                ns: "referrals",
                            })}
                        </Text>
                    </div>
                </div>
                <div className={styles.step}>
                    <div className={styles.number}>
                        <span>2</span>
                    </div>
                    <div className={styles.text}>
                        <Text type="h3">
                            {t("instructions.step_2_title", {
                                ns: "referrals",
                            })}
                        </Text>
                        <Text type="body">
                            {t("instructions.step_2_subtitle", {
                                ns: "referrals",
                            })}
                        </Text>
                    </div>
                </div>
                <div className={styles.step}>
                    <div className={styles.number}>
                        <span>3</span>
                    </div>
                    <div className={styles.text}>
                        <Text type="h3">
                            {t("instructions.step_3_title", {
                                ns: "referrals",
                            })}
                        </Text>
                        <Text type="body">
                            {t("instructions.step_3_subtitle", {
                                ns: "referrals",
                            })}
                        </Text>
                    </div>
                </div>
            </div>
            <ReferralsRewards />
        </Panel>
    );
};
