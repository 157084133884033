// Utils
import { splitFullName } from "utils/textUtils";

/**
 * This method generates an array of objects that contains name
 * data formatted as expected by the UserList and UserListItem
 * components
 *
 * @param {Array} list of full name strings
 * @returns {Array} of formatted name objects
 */
export function generateFormattedReferralsListData(list) {
    return list?.map((fullName) => {
        const fullNameSplit = splitFullName(fullName);
        return {
            firstName: fullNameSplit[0],
            fullName,
            lastName: fullNameSplit[1],
            id: crypto.randomUUID(),
        };
    });
}
