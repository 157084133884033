import React from "react";

// Components
import { PreviewCard } from "components/PreviewCard/PreviewCard";

// CSS
import styles from "./dashboard-news-preview-card.module.scss";

// Hooks
import { useDashboardNewsPreviewCard } from "./hooks/useDashboardNewsPreviewCard";

export const DashboardNewsPreviewCard = ({ isLoading, newsItem }) => {
    /**
     * Custom Hooks
     */

    const { handleCardClick, item } = useDashboardNewsPreviewCard({ newsItem });
    /**
     * End Hooks
     */

    return (
        <PreviewCard
            className={styles.dashboardNewsPreviewCard}
            isLoading={isLoading}
            item={item}
            onCardClick={handleCardClick}
        />
    );
};
