import React, { useEffect, useRef, useState } from "react";
import { useSpring, animated } from "react-spring";
import cx from "classnames";
import PropTypes from "prop-types";

// CSS
import styles from "./carousel-item.module.scss";

export const CarouselItem = (props) => {
    const { children, className, itemStyle, position } = props;

    /************************************************
     * useRef
     ************************************************/

    const itemRef = useRef(null);

    /************************************************
     * useState
     ************************************************/

    const [itemWidth, setItemWidth] = useState(null);

    /************************************************
     * useEffect
     ************************************************/

    useEffect(() => {
        if (itemRef) {
            setItemWidth(itemRef.current.offsetWidth);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemRef]);

    /************************************************
     * Constants
     ************************************************/

    const { left: itemLeft } = useSpring({
        left: 0 - position * itemWidth,
    });

    /************************************************
     * End Hooks
     ************************************************/

    return (
        <animated.div
            className={cx(styles["carousel-item"], className)}
            ref={itemRef}
            style={{ ...itemStyle, left: itemLeft }}
        >
            {children}
        </animated.div>
    );
};

CarouselItem.defaultProps = {
    className: "",
    itemStyle: {},
};

CarouselItem.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    itemStyle: PropTypes.object,
    position: PropTypes.number.isRequired,
};
