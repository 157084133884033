import React from "react";

// Localization
import { useTranslation } from "localization/localization";

// Constants
const year = new Date().getFullYear();

export const Footer = (props) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * End Hooks
     */

    return (
        <footer className="footer-container">
            <div className="footer-links">
                <span>
                    <span>&copy; {year} Sandboxx</span>
                    <span className="footer-divider">|</span>
                    <a
                        className="footer-links-link"
                        href="https://www.sandboxx.us/privacy"
                    >
                        {t("privacy_policy", { ns: "common" })}
                    </a>
                    <span className="footer-divider">|</span>
                    <a
                        className="footer-links-link"
                        href="https://www.sandboxx.us/terms"
                    >
                        {t("terms_of_service", { ns: "common" })}
                    </a>
                </span>
            </div>
            <div className="footer-dod">
                <span>{t("footer.dod_disclaimer", { ns: "components" })}</span>
            </div>
        </footer>
    );
};
