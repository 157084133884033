import React from "react";

// Components
import { ButtonPrimary } from "components/Buttons/Buttons";

// CSS
import styles from "./weekly-updates-list-item.module.scss";

export const WeeklyUpdatesListItem = (props) => {
    const { handleSelectWeeklyUpdate, index, isDep, weeklyUpdate } = props;
    const { featuredImageUrl, hasBeenConsumed, sanitizedHTML, title, week } =
        weeklyUpdate;

    function renderConsumedStatus() {
        const hasSetConsumedStatus = hasBeenConsumed !== null;
        return (
            hasSetConsumedStatus &&
            !hasBeenConsumed && (
                <div
                    className={styles.consumedStatus}
                    data-cy="weekly-updates-list-item-consumed-status"
                />
            )
        );
    }

    return (
        <div
            className={styles.weeklyUpdatesListItem}
            data-cy={`weekly-updates-list-item-${index}`}
        >
            <div
                className={styles.left}
                style={{
                    backgroundImage: `url(${featuredImageUrl})`,
                }}
            >
                {!isDep && (
                    <div className={styles.week}>
                        <span className={styles.description}>WEEK</span>
                        <span className={styles.number}>{week}</span>
                    </div>
                )}
            </div>
            <div className={styles.right}>
                <div className={styles.top}>
                    <h2 className={styles.title}>{title}</h2>
                    <div
                        className={styles.text}
                        dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
                    />
                </div>
                <div className={styles.bottom}>
                    <ButtonPrimary
                        classes={styles.button}
                        cypressTestId={`weekly-updates-list-item-${index}-button`}
                        onClick={() => handleSelectWeeklyUpdate(weeklyUpdate)}
                    >
                        <span>Read More</span>
                    </ButtonPrimary>
                </div>
                {renderConsumedStatus()}
            </div>
        </div>
    );
};
