import React, { createContext, useState } from "react";

// Platforms
import { SandboxxRestAPI } from "../utils/sandboxx";

// Context
export const DepsContext = createContext({});

export const DepsProvider = (props) => {
    const { children } = props;

    /**
     * useState
     **/

    const [depsLocations, setDepsLocations] = useState([]);

    /**
     * End Hooks
     **/

    function fetchDepsLocations({ branchId } = {}) {
        SandboxxRestAPI.getDepLocations(
            { branchId },
            onFetchDepsLocationsSuccess
        );
    }

    function onFetchDepsLocationsSuccess(res) {
        setDepsLocations(res.recruitingRegions);
    }

    const depsContext = { depsLocations, fetchDepsLocations };

    return (
        <DepsContext.Provider value={depsContext}>
            {children}
        </DepsContext.Provider>
    );
};
