import React from "react";

// Components
import { PurchaseModalBundleTile } from "components/Purchase/components/PurchaseModalBundle/PurchaseModalBundleTile/PurchaseModalBundleTile";
import { SandboxxPlusBadge } from "components/Badges/SandboxxPlusBadge/SandboxxPlusBadge";
import { Text } from "components/Text/Text";

// CSS
import styles from "./purchase-modal-bundle-options-sandboxx-plus.module.scss";

// Localization
import { useTranslation } from "localization/localization";

// Utils
import { CurrentUser } from "utils/sandboxx";
import { titleCase } from "utils/textUtils";

export const PurchaseModalBundleOptionsSandboxxPlus = ({
    bundles,
    selectedBundle,
    setSelectedBundle,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * End Hooks
     */

    const bundle = bundles?.find((bundle) => bundle.includesSandboxxPlus);
    const hasSandboxxPlus = CurrentUser.getUser().hasSandboxxPlus;

    if (hasSandboxxPlus) {
        return (
            <div className={styles.purchaseModalBundleOptionsSandboxxPlus}>
                <div className={styles.headline}>
                    <SandboxxPlusBadge className={styles.badge} />
                    <Text>
                        {t(
                            "purchase_modal.bundle_options.sandboxx_plus.active",
                            {
                                ns: "components",
                            }
                        )}
                    </Text>
                </div>
            </div>
        );
    }
    if (bundle) {
        return (
            <div className={styles.purchaseModalBundleOptionsSandboxxPlus}>
                <div className={styles.headline}>
                    <div className={styles.title}>
                        <Text classes={styles.text} type="h2">
                            {bundle.title}
                        </Text>
                        {bundle.banners.map((banner, i) => (
                            <div
                                className={styles.pill}
                                key={i}
                                style={{
                                    backgroundColor:
                                        banner.backgroundColor.light,
                                    color: banner.fontColor.light,
                                }}
                            >
                                <Text>{titleCase(banner.title)}</Text>
                            </div>
                        ))}
                    </div>
                    <Text>
                        {t(
                            "purchase_modal.bundle_options.sandboxx_plus.includes",
                            {
                                ns: "components",
                            }
                        )}
                    </Text>
                </div>
                <PurchaseModalBundleTile
                    bundle={bundle}
                    className={styles.tile}
                    selectedBundle={selectedBundle}
                    setSelectedBundle={setSelectedBundle}
                />
            </div>
        );
    }
    return (
        <div className={styles.purchaseModalBundleOptionsSandboxxPlus}>
            <PurchaseModalBundleTile isLoading />
        </div>
    );
};
