import React, { createContext, useState } from "react";

export const NotificationBarContext = createContext({});

export const NotificationBarProvider = (props) => {
    const { children } = props;

    /**
     * useState
     **/

    const [notificationBarContent, setNotificationBarContent] = useState({
        text: "",
        time: 5000,
        type: "",
    });
    const [shouldShowNotificationBar, setShouldShowNotificationBar] =
        useState(false);

    /**
     * End Hooks
     **/

    function showNotification({ text, time = 5000, type }) {
        setNotificationBarContent({ text, time, type });
        setShouldShowNotificationBar(true);
        setTimeout(() => {
            setShouldShowNotificationBar(false);
        }, time);
    }

    const notificationBarContext = {
        notificationBarContent,
        shouldShowNotificationBar,
        showNotification,
    };

    return (
        <NotificationBarContext.Provider value={notificationBarContext}>
            {children}
        </NotificationBarContext.Provider>
    );
};
