// Constants
import {
    NOTIFICATION_DEFAULT_ANCHOR_ORIGIN,
    NOTIFICATION_DEFAULT_AUTO_HIDE_DURATION,
} from "globals/constants";

// Enums
import { NotificationSeverity } from "enums/notifications/NotificationSeverity";
import { NotificationVariant } from "enums/notifications/NotificationVariant";

export class Notification {
    /**
     * @param {Object} param0
     * @param {String} param0.id
     * @param {String} param0.message text displayed in notification
     * @param {Object} param0.options
     * @param {Object} param0.options.anchorOrigin object containing horizontal/vertical placement of Snackbar
     * @param {Number} param0.options.autoHideDuration seconds after which notification should auto-hide
     * @param {Boolean} param0.options.isPersistent whether notification should hide after autoHideDuration elapses
     * @param {String} param0.options.severity severity level of notification (e.g., "error", "info")
     * @param {Boolean} param0.options.shouldHideCloseUI toggles UI display of close icon
     * @param {String} param0.options.variant UI theme (e.g., "filled", "outlined")
     */
    constructor({ id, message, options }) {
        this.id = id;
        this.message = message;
        this.options = {
            anchorOrigin:
                options.anchorOrigin || NOTIFICATION_DEFAULT_ANCHOR_ORIGIN,
            autoHideDuration:
                options.autoHideDuration ||
                NOTIFICATION_DEFAULT_AUTO_HIDE_DURATION,
            isPersistent: options.isPersistent || false,
            severity: options.severity || NotificationSeverity.INFO,
            shouldHideCloseUI: options.shouldHideCloseUI || false,
            variant: options.variant || NotificationVariant.FILLED,
        };
    }
}
