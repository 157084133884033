export function WarningTriangleIcon({ className, color, height, width }) {
    return (
        <svg
            className={className}
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M21.8936 20.548L12.893 2.50898C12.5544 1.83034 11.444 1.83034 11.1054 2.50898L2.10482 20.548C1.95127 20.8597 1.96702 21.2267 2.14813 21.5226C2.32925 21.8185 2.6521 22 2.99858 22H20.9998C21.3463 22 21.6692 21.8185 21.8503 21.5226C22.0314 21.2267 22.0511 20.8558 21.8936 20.548ZM4.61681 19.9917L11.9992 5.1959L19.3816 19.9917H4.61681Z"
                fill={color}
            />
            <path
                d="M11.9111 15.7692C12.5248 15.7692 13.0222 15.3073 13.0222 14.7375V9.51429C13.0222 8.94447 12.5248 8.48254 11.9111 8.48254C11.2975 8.48254 10.8 8.94447 10.8 9.51429V14.7375C10.8 15.3073 11.2975 15.7692 11.9111 15.7692Z"
                fill={color}
            />
            <path
                d="M11.9111 18.8794C12.5248 18.8794 13.0222 18.3819 13.0222 17.7683C13.0222 17.1546 12.5248 16.6572 11.9111 16.6572C11.2975 16.6572 10.8 17.1546 10.8 17.7683C10.8 18.3819 11.2975 18.8794 11.9111 18.8794Z"
                fill={color}
            />
        </svg>
    );
}

WarningTriangleIcon.defaultProps = {
    className: "",
    color: "#212121",
    height: "25px",
    width: "25px",
};
