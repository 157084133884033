import { useLayoutEffect, useState } from "react";

export const useIsOverflow = (ref, { isHorizontal = false } = {}) => {
    const [isOverflow, setIsOverflow] = useState(null);

    useLayoutEffect(() => {
        const { current } = ref;

        const trigger = () => {
            const hasOverflow = isHorizontal
                ? current.scrollWidth > current.clientWidth
                : current.scrollHeight > current.clientHeight;
            setIsOverflow(hasOverflow);
        };

        if (current) {
            trigger();
        }
    }, [isHorizontal, ref]);

    return isOverflow;
};
