// Utils
import { trimArrayAtIndex } from "utils/collectionUtils";

/**
 * If the user edits a block that is not the last one in the formStack,
 * all formStack entries corresponding to subsequent blocks should be
 * removed from the formStack before re-submitting the form.
 *
 * This function returns filtered versions of the formIdStack and formStack
 * that can be manipulated and set in state prior to submitting the
 * address formatter form
 *
 * @param {Object} param0
 * - @param {Object} formIdStack
 * - @param {Object} formStack
 * - @param {Number} index
 * @returns {Object}
 * - @returns {Object} formIdStackFiltered
 * - @returns {Object} formStackFiltered
 */
export function filterOutInvalidatedBlocks({ formStack, formIdStack, index }) {
    const isLastItemInStack = index === formStack.length - 1;
    if (!isLastItemInStack) {
        const formIdStackPortion = trimArrayAtIndex(formIdStack, index);
        const formStackPortion = trimArrayAtIndex(formStack, index);
        formStackPortion[formStackPortion.length - 1].isComplete = false;
        return {
            formIdStackFiltered: formIdStackPortion,
            formStackFiltered: formStackPortion,
        };
    } else {
        return {
            formIdStackFiltered: formIdStack,
            formStackFiltered: formStack,
        };
    }
}

/**
 * Generates a copy of the current formStack, as well as variables
 * containing the formStack block and its field that is being updated
 * by the user
 *
 * @param {Array} formStack
 * @param {Number} fieldIndex
 * @returns {Object} containing formStackCopy, formStackCopyTargetBlock, and formStackCopyTargetBlockField
 */
export function generateStackCopyValues({
    formStack,
    formIdStack,
    fieldIndex,
}) {
    const formIdStackCopy = formIdStack?.slice();
    const formStackCopy = formStack?.slice();
    const formStackCopyTargetBlock =
        formStackCopy && formStackCopy[formStackCopy.length - 1];
    const formStackCopyTargetBlockField =
        formStackCopyTargetBlock?.fields[fieldIndex];
    return {
        formIdStackCopy,
        formStackCopy,
        formStackCopyTargetBlock,
        formStackCopyTargetBlockField,
    };
}

export function generateSubmitAddressFormPayload(formIdStack, formStack) {
    return {
        formId: formIdStack[formIdStack.length - 1],
        input: formStack.reduce((acc, arr) => acc.concat(arr.fields), []), // Flatten user inputs
    };
}
