import React, { createContext, useContext, useState } from "react";

// Context
import { NotificationBarContext } from "../context/notificationBar";

// Platforms
import { SandboxxRestAPI } from "../utils/sandboxx";

export const CreditsContext = createContext({});

export const CreditsProvider = (props) => {
    const { children } = props;

    /**
     * useContext
     **/

    const { showNotification } = useContext(NotificationBarContext);

    /**
     * useState
     **/

    const [credits, setCredits] = useState(null);

    /**
     * End Hooks
     **/

    function fetchCredits() {
        return SandboxxRestAPI.getCredits(
            onFetchCreditsSuccess,
            onFetchCreditsFailure,
            onFetchCreditsFailure
        );
    }

    function onFetchCreditsFailure(err) {
        showNotification({
            text: err.message,
            type: "warning",
        });
    }

    function onFetchCreditsSuccess({ user_credits }) {
        setCredits(parseInt(user_credits, 10));
    }

    function resetCreditsContext() {
        setCredits(null);
    }

    const creditsContext = { credits, fetchCredits, resetCreditsContext };

    return (
        <CreditsContext.Provider value={creditsContext}>
            {children}
        </CreditsContext.Provider>
    );
};
