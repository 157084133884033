// Platforms
import { CurrentUser } from "utils/sandboxx";

// Utils
import { convertAndSanitizeStringToHTML } from "utils/htmlUtils";
import { generateStylelessHTML } from "utils/htmlUtils";

export class WeeklyUpdate {
    constructor(weeklyUpdate) {
        this.assignValues(weeklyUpdate);
    }

    assignValues(weeklyUpdate) {
        Object.keys(weeklyUpdate).forEach((key) => {
            this[key] = weeklyUpdate[key];
        });
        this.hasBeenConsumed = null;
        this.sanitizedHTML = WeeklyUpdate.generateSanitizedHTML(weeklyUpdate);
        this.stylelessHTML = generateStylelessHTML(weeklyUpdate.iosLightHtml);
    }

    markAsConsumed(bool) {
        this.hasBeenConsumed = bool;
    }

    static generateSanitizedHTML(weeklyUpdate) {
        const { iosLightHtml } = weeklyUpdate;
        const populatedHTML = WeeklyUpdate.populateNames(iosLightHtml);
        return convertAndSanitizeStringToHTML(populatedHTML);
    }

    static populateNames(str) {
        const { firstName: userFirstName } = CurrentUser.getUser();
        return str
            .replace("{{first_name}}", userFirstName)
            .replace("{{recruit_first_name}}", "Your recruit");
    }
}
