import React, { useEffect, useState } from "react";
import cx from "classnames";

// Components
import { ButtonInvisible, ButtonPrimary } from "components/Buttons/Buttons";
import { ButtonRounded } from "components/Buttons/ButtonRounded/ButtonRounded";
import { Text } from "components/Text/Text";

// CSS
import signInStyles from "../../../sign-in.module.scss";
import styles from "./sign-up-ship-date.module.scss";

// Enums
import { NavigationEnum } from "scenes/SignIn/constants/constants";

// Localization
import { useTranslation } from "localization/localization";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Utils
import { SandboxxRestAPI } from "utils/sandboxx";

export const SignUpShipDate = ({
    handleLoadingToggle,
    loading,
    setCurrentSection,
    setUserInfo,
    showNotification,
    storeUserAfterUpdate,
    userInfo,
}) => {
    const { base, role, shipDate } = userInfo;

    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useState
     */

    const [knowsShipDate, setKnowsShipDate] = useState(null);
    const [selectedTile, setSelectedTile] = useState(null);

    /**
     * useEffect
     */

    useEffect(() => {
        if (knowsShipDate === false) {
            handleSubmit();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [knowsShipDate]);

    /**
     * Constants
     */

    const hasSelectedDate = shipDate.day && shipDate.month && shipDate.year;

    /**
     * End Hooks & Constants
     */

    function handleGoBack() {
        setCurrentSection(NavigationEnum.SIGN_UP_RECRUITING_STATION);
    }

    function handleSelectDate(e) {
        const { name, value } = e.target;
        setUserInfo({
            ...userInfo,
            shipDate: {
                ...userInfo.shipDate,
                [name]: value,
            },
        });
    }

    function handleSelectTile(bool) {
        setKnowsShipDate(bool);
        setSelectedTile(bool);
    }

    function handleSubmit(e) {
        e && e?.preventDefault();
        if (validateBeforeSubmit()) {
            if (hasSelectedDate) {
                handleLoadingToggle("shipDate", true);
                setShipDate();
            } else {
                AnalyticsLogger.logShipDateSelect(knowsShipDate, role);
                setCurrentSection(NavigationEnum.SIGN_UP_SUCCESS);
            }
        } else {
            showFailedValidationNotification();
        }
    }

    function onSetShipDateFailure(res) {
        handleLoadingToggle("shipDate", false);
        showNotification({
            text: res.message,
            type: "warning",
        });
    }

    function onSetShipDateSuccess() {
        handleLoadingToggle("shipDate", false);
        AnalyticsLogger.logShipDateSelect(knowsShipDate, role);
        storeUserAfterUpdate();
        setCurrentSection(NavigationEnum.SIGN_UP_SUCCESS);
    }

    function showFailedValidationNotification() {
        if (knowsShipDate === null) {
            showNotification({
                text: t("sign_up_ship_date.knows_ship_date_missing_error", {
                    ns: "onboarding",
                }),
                type: "warning",
            });
        } else if (knowsShipDate && !hasSelectedDate) {
            showNotification({
                text: t("sign_up_ship_date.ship_date_missing_error", {
                    ns: "onboarding",
                }),
                type: "warning",
            });
        }
    }

    function setShipDate() {
        const { day, month, year } = shipDate;
        const formattedShipDate = `${year}-${month}-${day}`;
        SandboxxRestAPI.setUserShipDate(
            { baseId: base.id, shipDate: formattedShipDate },
            onSetShipDateSuccess,
            onSetShipDateFailure,
            onSetShipDateFailure
        );
    }

    function validateBeforeSubmit() {
        // If user knows ship date, make sure they have provided a ship date
        if (knowsShipDate === true) {
            return hasSelectedDate;
        }
        // If user does not know ship date, proceed
        else if (knowsShipDate === false) {
            return true;
        }
        // If user has not indicated whether they know ship date or not, show error message
        else if (knowsShipDate === null) {
            return false;
        }
    }

    function renderButtons() {
        return (
            <div className={signInStyles.buttons}>
                <ButtonInvisible
                    classes={cx(
                        signInStyles.button,
                        signInStyles["button--invisible"]
                    )}
                    onClick={handleGoBack}
                >
                    <Text>{t("back", { ns: "common" })}</Text>
                </ButtonInvisible>
                {renderSubmitButton()}
            </div>
        );
    }

    function renderDateSelect() {
        return (
            knowsShipDate && (
                <div className={signInStyles["select-date"]}>
                    <div className={signInStyles.text}>
                        <Text type="h4">
                            {t("sign_up_ship_date.date_select_title", {
                                ns: "onboarding",
                            })}
                        </Text>
                    </div>
                    <div className={signInStyles.inputs}>
                        <select
                            className={cx(
                                signInStyles.form,
                                "select",
                                styles.select
                            )}
                            data-cy="ship-date-month"
                            id="ship-date-month"
                            name="month"
                            onChange={handleSelectDate}
                        >
                            <option value="" disabled selected>
                                {t("month", { ns: "date_time" })}
                            </option>
                            <option value="01">
                                {t("january", { ns: "date_time" })}
                            </option>
                            <option value="02">
                                {t("february", { ns: "date_time" })}
                            </option>
                            <option value="03">
                                {t("march", { ns: "date_time" })}
                            </option>
                            <option value="04">
                                {t("april", { ns: "date_time" })}
                            </option>
                            <option value="05">
                                {t("may", { ns: "date_time" })}
                            </option>
                            <option value="06">
                                {t("june", { ns: "date_time" })}
                            </option>
                            <option value="07">
                                {t("july", { ns: "date_time" })}
                            </option>
                            <option value="08">
                                {t("august", { ns: "date_time" })}
                            </option>
                            <option value="09">
                                {t("september", { ns: "date_time" })}
                            </option>
                            <option value="10">
                                {t("october", { ns: "date_time" })}
                            </option>
                            <option value="11">
                                {t("november", { ns: "date_time" })}
                            </option>
                            <option value="12">
                                {t("december", { ns: "date_time" })}
                            </option>
                        </select>
                        <select
                            className={cx(
                                signInStyles.form,
                                "select",
                                styles.select
                            )}
                            data-cy="ship-date-day"
                            id="ship-date-day"
                            name="day"
                            onChange={handleSelectDate}
                        >
                            <option value="" disabled selected>
                                {t("day", { ns: "date_time" })}
                            </option>
                            <option value="01">1</option>
                            <option value="02">2</option>
                            <option value="03">3</option>
                            <option value="04">4</option>
                            <option value="05">5</option>
                            <option value="06">6</option>
                            <option value="07">7</option>
                            <option value="08">8</option>
                            <option value="09">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                            <option value="16">16</option>
                            <option value="17">17</option>
                            <option value="18">18</option>
                            <option value="19">19</option>
                            <option value="20">20</option>
                            <option value="21">21</option>
                            <option value="22">22</option>
                            <option value="23">23</option>
                            <option value="24">24</option>
                            <option value="25">25</option>
                            <option value="26">26</option>
                            <option value="27">27</option>
                            <option value="28">28</option>
                            <option value="29">29</option>
                            <option value="30">30</option>
                            <option value="31">31</option>
                        </select>
                        <select
                            className={cx(
                                signInStyles.form,
                                "select",
                                styles.select
                            )}
                            data-cy="ship-date-year"
                            id="ship-date-year"
                            name="year"
                            onChange={handleSelectDate}
                        >
                            <option value="" disabled selected>
                                {t("year", { ns: "date_time" })}
                            </option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                            <option value="2027">2027</option>
                            <option value="2028">2028</option>
                            <option value="2029">2029</option>
                            <option value="2030">2030</option>
                        </select>
                    </div>
                </div>
            )
        );
    }

    function renderOptions() {
        return (
            <div className={cx(signInStyles.options, styles.options)}>
                <ButtonRounded
                    classes={styles["yes-button"]}
                    cypressTestId="sign-up-ship-date-button-yes"
                    isFilled={selectedTile}
                    onClick={() => handleSelectTile(true)}
                    text={t("yes", { ns: "common" })}
                    type="button"
                />
                <ButtonRounded
                    cypressTestId="sign-up-ship-date-button-not-yet"
                    onClick={() => handleSelectTile(false)}
                    text={t("no", { ns: "common" })}
                    type="button"
                />
            </div>
        );
    }

    function renderSubmitButton() {
        return (
            knowsShipDate && (
                <ButtonPrimary
                    classes={signInStyles.button}
                    cypressTestId="sign-up-ship-date-next-button"
                    isDisabled={!hasSelectedDate}
                    isLoading={loading.shipDate}
                    onClick={handleSubmit}
                >
                    <Text>{t("next", { ns: "common" })}</Text>
                </ButtonPrimary>
            )
        );
    }

    return (
        <div
            className={cx(
                signInStyles.content,
                signInStyles["ship-date"],
                styles["sign-up-ship-date"]
            )}
        >
            <div className={cx(signInStyles.top, styles.top)}>
                <div className={signInStyles.header}>
                    <Text classes={signInStyles.text}>
                        {t("sign_up_ship_date.title", { ns: "onboarding" })}
                    </Text>
                    <Text classes={signInStyles["text-secondary"]}>
                        {t("sign_up_ship_date.subtitle", { ns: "onboarding" })}
                    </Text>
                </div>
                {renderOptions()}
                {renderDateSelect()}
            </div>
            <div
                className={cx(
                    signInStyles.bottom,
                    styles["navigation-buttons"]
                )}
            >
                {renderButtons()}
            </div>
        </div>
    );
};
