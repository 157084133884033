import React from "react";

// Components
import { ButtonPrimary } from "components/Buttons/Buttons";
import { ButtonTertiary } from "components/Buttons/ButtonTertiary/ButtonTertiary";
import { Text } from "components/Text/Text";

// CSS
import styles from "./purchase-modal-bundle-highlight-card.module.scss";

export const PurchaseModalBundleHighlightCard = (props) => {
    const { data, handleSubmit } = props;

    function renderBenefits(data) {
        return data.benefits.map((benefit, index) => {
            return (
                <div className={styles.benefit} key={index}>
                    <Text classes={styles.text} type="p">
                        {benefit}
                    </Text>
                </div>
            );
        });
    }

    return (
        <div className={styles.card}>
            <Text classes={styles.title} isBold={true} type="h3">
                {data.title}
            </Text>
            <Text classes={styles.description} type="p">
                {data.description}
            </Text>
            <div className={styles.benefits}>{renderBenefits(data)}</div>
            {data.buttonType === "primary" ? (
                <ButtonPrimary
                    classes={styles.button}
                    onClick={() => handleSubmit(data.bundle)}
                    text={data.buttonText}
                />
            ) : (
                <ButtonTertiary
                    classes={styles.button}
                    onClick={() => handleSubmit(data.bundle)}
                    text={data.buttonText}
                />
            )}
        </div>
    );
};
