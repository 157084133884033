import React from "react";
import PropTypes from "prop-types";
import AutocompleteMUI from "@mui/material/Autocomplete";
import TextFieldMUI from "@mui/material/TextField";
import { ThemeProvider } from "@mui/material/styles";

// Theme
import { theme } from "./theme";

export const AutocompleteGrouped = ({
    className,
    cypressTestId,
    id,
    groupBy,
    getOptionLabel,
    label,
    onChange,
    options,
}) => {
    return (
        <ThemeProvider theme={theme}>
            <AutocompleteMUI
                className={className}
                data-cy={cypressTestId}
                id={id}
                groupBy={groupBy}
                getOptionLabel={getOptionLabel}
                onChange={onChange}
                renderInput={(params) => (
                    <TextFieldMUI {...params} label={label} />
                )}
                options={options}
            />
        </ThemeProvider>
    );
};

AutocompleteGrouped.defaultProps = { className: "" };

AutocompleteGrouped.propTypes = {
    className: PropTypes.string,
    cypressTestId: PropTypes.string,
    id: PropTypes.string,
    groupBy: PropTypes.func.isRequired,
    getOptionLabel: PropTypes.func.isRequired,
    label: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.array,
};
