export function ReplyIcon({ className, height, width }) {
    return (
        <svg
            className={className}
            width={width}
            height={height}
            viewBox="0 0 15 14"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g
                id="Web-Dashboard"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g
                    id="Sent-Letter"
                    transform="translate(-344.000000, -517.000000)"
                    fill="#B3B3B3"
                    fillRule="nonzero"
                >
                    <g
                        id="Group-2"
                        transform="translate(309.000000, 492.000000)"
                    >
                        <g
                            id="noun_Reply_3368288"
                            transform="translate(35.000000, 25.000000)"
                        >
                            <path
                                d="M7.82321396,3.08305438 L7.82321396,1.12362707 C7.82475542,0.858278112 7.66577891,0.618369117 7.42097566,0.516615466 C7.17617241,0.414861814 6.89420541,0.471490509 6.70754995,0.659895942 L2.79293939,4.57875055 C2.53997491,4.83350056 2.53997491,5.24493137 2.79293939,5.49968138 L6.70754995,9.41853599 C6.89336941,9.60609327 7.17374873,9.6631315 7.41795544,9.56305545 C7.66216216,9.46297941 7.82210758,9.22549588 7.82321396,8.96133629 L7.82321396,7.00190898 C10.7330745,7.00190898 13.6951298,9.96717564 13.6951298,12.8801909 C13.6951298,13.2409115 13.987235,13.5333333 14.3475649,13.5333333 C14.7078949,13.5333333 15,13.2409115 15,12.8801909 L15,9.61447872 C15,5.75440693 11.4572774,3.36390562 7.82321396,3.08305438 Z M7.82321396,5.69562411 L7.17077887,5.69562411 C6.81044892,5.69562411 6.51834377,5.98804594 6.51834377,6.34876655 L6.51834377,7.38726302 L4.17610179,5.04248168 L6.51834377,2.69770034 L6.51834377,3.73619681 C6.51834377,4.09691742 6.81044892,4.38933925 7.17077887,4.38933925 C10.1589316,4.38933925 13.2449496,6.08750958 13.6494594,8.96133629 C12.3242282,7.0207277 10.1685149,5.81241282 7.82321396,5.69562411 L7.82321396,5.69562411 Z M5.02426741,8.49760516 C5.21045683,8.65722656 5.29155829,8.90785594 5.23422763,9.14644925 C5.17689698,9.38504256 4.99081024,9.57133104 4.75247533,9.62872385 C4.51414041,9.68611666 4.26378246,9.60492727 4.10433393,9.41853599 L0.18972336,5.49968138 C-0.06324112,5.24493137 -0.06324112,4.83350056 0.18972336,4.57875055 L4.10433393,0.659895942 C4.36319425,0.437973298 4.74906648,0.452894028 4.99005581,0.694144628 C5.23104514,0.935395228 5.24594971,1.3216858 5.02426741,1.58082678 L1.56636141,5.04248168 L5.02426741,8.49760516 Z"
                                id="Shape"
                            ></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

ReplyIcon.defaultProps = {
    className: "",
    height: "14px",
    width: "15px",
};
