export const SignInModeEnum = Object.freeze({
    LOG_IN: "LOG_IN",
    SIGN_UP: "SIGN_UP",
});

export const SignInPlatform = Object.freeze({
    EMAIL: "EMAIL",
    EMAIL_VERIFY: "EMAIL_VERIFY",
    PHONE: "PHONE",
});
