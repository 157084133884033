import React from "react";
import { Link } from "react-router-dom";

export default class BadRoute extends React.Component {
    render() {
        return (
            <div>
                <h1 className="text-center">SORRY</h1>
                <p className="text-center sbx-font-reg">
                    {" "}
                    We couldn't find what you were looking for. Would you like
                    to go <Link to="/">home?</Link>
                </p>
            </div>
        );
    }
}
