export function CalendarIcon({
    className = "",
    height = "25px",
    width = "25px",
    color = "#212121",
}) {
    return (
        <svg
            className={className}
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M20.3787 7.94639H3.08054V6.32411C3.08054 5.42998 3.80747 4.70281 4.70184 4.70281H6.32314V5.78433H7.40563V4.70281H16.0546V5.78433H17.1361V4.70281H18.7574C19.6515 4.70281 20.3787 5.42998 20.3787 6.32411V7.94639ZM20.3787 19.2974C20.3787 20.1913 19.6515 20.9185 18.7574 20.9185H4.70281C3.80844 20.9185 3.08151 20.1913 3.08151 19.2974V9.02693H20.3787V19.2974ZM18.7562 3.6213H17.1349V2H16.0536V3.6213H7.40563V2H6.32387V3.6213H4.70281C3.21203 3.6213 2 4.83357 2 6.32411V19.2974C2 20.7877 3.21203 22 4.70281 22H18.7562C20.247 22 21.459 20.7877 21.459 19.2974V9.02693V7.94639V6.32411C21.459 4.83454 20.247 3.6213 18.7562 3.6213Z"
                fill={color}
            />
            <path
                d="M10.648 11.1889H12.81L12.811 13.351H10.648V11.1889ZM10.648 14.4325H12.81C13.4067 14.4325 13.8915 13.9474 13.8915 13.351V11.1889C13.8915 10.5925 13.4067 10.1074 12.81 10.1074H10.648C10.0515 10.1074 9.56644 10.5925 9.56644 11.1889V13.351C9.56741 13.9474 10.0525 14.4325 10.648 14.4325Z"
                fill={color}
            />
            <path
                d="M16.0537 11.1889H18.2157L18.2164 13.351H16.0537V11.1889ZM16.0537 14.4325H18.2157C18.8121 14.4325 19.2972 13.9474 19.2972 13.351V11.1889C19.2972 10.5925 18.8121 10.1074 18.2157 10.1074H16.0537C15.4572 10.1074 14.9721 10.5925 14.9721 11.1889V13.351C14.9731 13.9474 15.4582 14.4325 16.0537 14.4325Z"
                fill={color}
            />
            <path
                d="M5.24347 16.5945H7.40553L7.4065 18.7565H5.24347V16.5945ZM5.24347 19.8381H7.40553C8.00194 19.8381 8.48704 19.353 8.48704 18.7565V16.5945C8.48704 15.9981 8.00194 15.513 7.40553 15.513H5.24347C4.64706 15.513 4.16196 15.9981 4.16196 16.5945V18.7565C4.16196 19.353 4.64706 19.8381 5.24347 19.8381Z"
                fill={color}
            />
            <path
                d="M10.648 16.5945H12.81L12.811 18.7565H10.648V16.5945ZM10.648 19.8381H12.81C13.4067 19.8381 13.8915 19.353 13.8915 18.7565V16.5945C13.8915 15.9981 13.4067 15.513 12.81 15.513H10.648C10.0515 15.513 9.56644 15.9981 9.56644 16.5945V18.7565C9.56741 19.353 10.0525 19.8381 10.648 19.8381Z"
                fill={color}
            />
            <path
                d="M16.0537 16.5945H18.2157L18.2164 18.7565H16.0537V16.5945ZM16.0537 19.8381H18.2157C18.8121 19.8381 19.2972 19.353 19.2972 18.7565V16.5945C19.2972 15.9981 18.8121 15.513 18.2157 15.513H16.0537C15.4572 15.513 14.9721 15.9981 14.9721 16.5945V18.7565C14.9731 19.353 15.4582 19.8381 16.0537 19.8381Z"
                fill={color}
            />
        </svg>
    );
}
