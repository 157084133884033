import React from "react";

// Components
import { Text } from "components/Text/Text";
import { WalletGiftCardsLandingEasyToSendIcon } from "./components/Icons/WalletGiftCardsLandingEasyToSendIcon";
import { WalletGiftCardsLandingFinancialSupportIcon } from "./components/Icons/WalletGiftCardsLandingFinancialSupportIcon";
import { WalletGiftCardsLandingFundsNeverExpireIcon } from "./components/Icons/WalletGiftCardsLandingFundsNeverExpireIcon";
import { WalletGiftCardsLandingPhysicalCardIcon } from "./components/Icons/WalletGiftCardsLandingPhysicalCardIcon";

// CSS
import styles from "./wallet-gift-cards-landing-features.module.scss";

// Localization
import { useTranslation } from "localization/localization";

export const WalletGiftCardsLandingFeatures = (props) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * End Hooks
     */

    return (
        <section className={styles["wallet-gift-cards-landing-features"]}>
            <div className={styles.header}>
                <Text isBold type="h2">
                    {t("features.title", { ns: "wallet" })}
                </Text>
                <span className={styles.description}>
                    {t("features.subtitle", { ns: "wallet" })}
                </span>
            </div>
            <div className={styles.features}>
                <div className={styles.feature}>
                    <div className={styles.image}>
                        <WalletGiftCardsLandingFinancialSupportIcon />
                    </div>
                    <div className={styles.text}>
                        <Text isBold type="h3">
                            {t("features.financial_support.title", {
                                ns: "wallet",
                            })}
                        </Text>
                        <Text type="p">
                            {t("features.financial_support.subtitle", {
                                ns: "wallet",
                            })}
                        </Text>
                    </div>
                </div>
                <div className={styles.feature}>
                    <div className={styles.image}>
                        <WalletGiftCardsLandingPhysicalCardIcon />
                    </div>
                    <div className={styles.text}>
                        <Text isBold type="h3">
                            {t("features.gift_card_via_mail.title", {
                                ns: "wallet",
                            })}
                        </Text>
                        <Text type="p">
                            {t("features.gift_card_via_mail.subtitle", {
                                ns: "wallet",
                            })}
                        </Text>
                    </div>
                </div>
                <div className={styles.feature}>
                    <div className={styles.image}>
                        <WalletGiftCardsLandingFundsNeverExpireIcon />
                    </div>
                    <div className={styles.text}>
                        <Text isBold type="h3">
                            {t("features.funds_never_expire.title", {
                                ns: "wallet",
                            })}
                        </Text>
                        <Text type="p">
                            {t("features.funds_never_expire.subtitle", {
                                ns: "wallet",
                            })}
                        </Text>
                    </div>
                </div>
                <div className={styles.feature}>
                    <div className={styles.image}>
                        <WalletGiftCardsLandingEasyToSendIcon />
                    </div>
                    <div className={styles.text}>
                        <Text isBold type="h3">
                            {t("features.easy_to_send.title", {
                                ns: "wallet",
                            })}
                        </Text>
                        <Text type="p">
                            {t("features.easy_to_send.subtitle", {
                                ns: "wallet",
                            })}
                        </Text>
                    </div>
                </div>
            </div>
        </section>
    );
};
