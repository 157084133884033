import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

// Components
import { MusterArticle } from "./MusterArticle/MusterArticle";
import { MusterChecklist } from "./MusterChecklist/MusterChecklist";
import { MusterJoinSuccessModal } from "./MusterJoinSuccessModal/MusterJoinSuccessModal";
import { MusterOverview } from "./MusterOverview/MusterOverview";
import { MusterRecruitJoinModal } from "./MusterRecruitJoinModal/MusterRecruitJoinModal";
import { MusterRecruitInviteModal } from "./MusterRecruitInviteModal/MusterRecruitInviteModal";

// Constants
import { NavigationEnum } from "../constants/constants";

// Context
import { MusterContext } from "context/muster";
import { NotificationBarContext } from "context/notificationBar";

// Enums
import { PersonaEnum } from "enums/PersonaEnum";

// Hooks
import { useDocumentTitle } from "@uidotdev/usehooks";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Utils
import { CurrentUser } from "utils/sandboxx";
import { isOnMusterLookupShipDatePage } from "../utils/utils";

export const Muster = (props) => {
    const { config, initialSection } = props;

    /**
     * Custom Hooks
     */

    useDocumentTitle("Sandboxx - Waypoints");

    /**
     * useContext
     */

    const {
        contactList,
        fetchMagicLink,
        fetchRecruitDashboard,
        fetchRecruiterDashboard,
        fetchRecruits,
        fetchRecruiters,
        hasContactListLoaded,
        hasDashboardLoaded,
        magicLink,
        redeemMusterCode,
        setShouldShowJoinModal,
        setShouldShowRecruitModal,
        setShouldShowJoinSuccessModal,
        shouldShowJoinModal,
        shouldShowRecruitModal,
        shouldShowJoinSuccessModal,
    } = useContext(MusterContext);

    const { showNotification } = useContext(NotificationBarContext);

    /**
     * Constants
     */

    const isMusterLocationShipDate = isOnMusterLookupShipDatePage();
    const user = CurrentUser.getUser();
    const rootPersona = (user.personas && user.personas[0]) || "UNKNOWN";

    /**
     * useState
     */

    const [currentSection, setCurrentSection] = useState(initialSection);

    /**
     * useEffect
     */

    useEffect(() => {
        if (rootPersona.id === PersonaEnum.RECRUITER) {
            setShouldShowRecruitModal(
                config?.shouldShowMusterRecruitInviteModal
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        AnalyticsLogger.logMusterStartSession();

        // Fetch calls
        if (!hasDashboardLoaded) {
            rootPersona.id === PersonaEnum.RECRUIT
                ? fetchRecruitDashboard()
                : fetchRecruiterDashboard();
        }
        if (!hasContactListLoaded) {
            rootPersona.id === PersonaEnum.RECRUIT
                ? fetchRecruiters()
                : fetchRecruits();
        }
        if (isMusterLocationShipDate) {
            fetchRecruits();
        }

        setCurrentSection(initialSection);

        const hasMusterConnection = JSON.parse(
            sessionStorage.getItem("hasMusterConnection")
        );
        if (hasMusterConnection) {
            fetchRecruiters();
            setShouldShowJoinSuccessModal(true);
            sessionStorage.removeItem("hasMusterConnection");
        }
        if (magicLink === "" && rootPersona.id === PersonaEnum.RECRUITER) {
            fetchMagicLink();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialSection, rootPersona.id, isMusterLocationShipDate]);

    /**
     * End Hooks & Constants
     */

    function renderContent() {
        if (currentSection === NavigationEnum.MUSTER_ARTICLE) {
            return (
                <MusterArticle
                    {...props}
                    setCurrentSection={setCurrentSection}
                />
            );
        } else if (
            currentSection === NavigationEnum.MUSTER_CHECKLIST &&
            rootPersona.id === PersonaEnum.RECRUIT
        ) {
            return <MusterChecklist {...props} />;
        } else if (currentSection === NavigationEnum.MUSTER_OVERVIEW) {
            return (
                <MusterOverview
                    {...props}
                    setCurrentSection={setCurrentSection}
                />
            );
        } else {
            return <MusterOverview {...props} />;
        }
    }

    return (
        <div className="page">
            <MusterJoinSuccessModal
                setShouldShowJoinSuccessModal={setShouldShowJoinSuccessModal}
                shouldShowJoinSuccessModal={shouldShowJoinSuccessModal}
            />
            <MusterRecruitJoinModal
                contactList={contactList}
                fetchRecruiters={fetchRecruiters}
                redeemMusterCode={redeemMusterCode}
                rootPersona={rootPersona}
                setShouldShowJoinModal={setShouldShowJoinModal}
                setShouldShowJoinSuccessModal={setShouldShowJoinSuccessModal}
                shouldShowJoinModal={shouldShowJoinModal}
                showNotification={showNotification}
            />
            <MusterRecruitInviteModal
                magicLink={magicLink}
                setShouldShowRecruitModal={setShouldShowRecruitModal}
                shouldShowRecruitModal={shouldShowRecruitModal}
                showNotification={showNotification}
            />
            <div>{renderContent()}</div>
        </div>
    );
};

Muster.defaultProps = {
    config: {},
};

Muster.propTypes = {
    config: PropTypes.object,
    initialSection: PropTypes.string,
};
