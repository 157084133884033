import React, { useRef } from "react";
import cx from "classnames";

// Components
import { Text } from "components/Text/Text";
import { WalletGiftCardsPurchaseRecipientCard } from "../WalletGiftCardsPurchaseRecipientCard/WalletGiftCardsPurchaseRecipientCard";

// CSS
import styles from "./wallet-gift-cards-purchase-recipient-previous-recipients.module.scss";

// Enums
import { RecipientSourceEnum } from "../../../../enums/RecipientSourceEnum";

// Hooks
import { useIsOverflow } from "hooks/scroll/useIsOverflow";

export const WalletGiftCardsPurchaseRecipientPreviousRecipients = (props) => {
    const {
        handleSelectRecipient,
        recipient,
        recipientSource,
        suggestedContacts,
    } = props;

    /**
     * useRef
     */

    const previousRecipientsRef = useRef(null);

    /**
     * Custom Hooks
     */

    const isPreviousRecipientsOverflow = useIsOverflow(previousRecipientsRef, {
        isHorizontal: true,
    });

    /**
     * End Hooks
     */

    function handleSelectPreviousRecipient(suggestedContact) {
        handleSelectRecipient(
            suggestedContact,
            RecipientSourceEnum.PREVIOUS_RECIPIENTS
        );
    }

    function renderPreviousRecipients() {
        return suggestedContacts?.map((suggestedContact, i) => {
            const { id } = suggestedContact;
            const isActive = suggestedContact?.id === recipient?.id;
            return (
                <WalletGiftCardsPurchaseRecipientCard
                    cypressTestId={`wallet-gift-cards-purchase-recipient-card-${i}`}
                    isActive={isActive}
                    key={id}
                    onClick={() =>
                        handleSelectPreviousRecipient(suggestedContact)
                    }
                    recipient={suggestedContact}
                />
            );
        });
    }

    return (
        <div
            className={cx(
                styles[
                    "wallet-gift-cards-purchase-recipient-previous-recipients"
                ],
                {
                    [styles[
                        "wallet-gift-cards-purchase-recipient-previous-recipients--active"
                    ]]:
                        recipientSource ===
                        RecipientSourceEnum.PREVIOUS_RECIPIENTS,
                }
            )}
        >
            <div className={styles.header}>
                <Text isBold type="h4">
                    Select Previous Recipient
                </Text>
            </div>
            <div
                className={cx(styles.recipients, {
                    [styles["recipients--overflow"]]:
                        isPreviousRecipientsOverflow,
                })}
                ref={previousRecipientsRef}
            >
                {renderPreviousRecipients()}
            </div>
        </div>
    );
};
