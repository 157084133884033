export function MultipleImagesIcon({
    className,
    height = "22px",
    width = "22px",
    color = "#a2a2a2",
}) {
    return (
        <svg
            className={className}
            fill="none"
            height={height}
            viewBox="0 0 22 22"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.16146 6H11.8385C12.3657 5.99998 12.8205 5.99997 13.195 6.03057C13.5904 6.06287 13.9836 6.13419 14.362 6.32698C14.9265 6.6146 15.3854 7.07354 15.673 7.63803C15.8658 8.01641 15.9371 8.40963 15.9694 8.80497C16 9.17954 16 9.63428 16 10.1614V17.8385C16 18.3657 16 18.8205 15.9694 19.195C15.9371 19.5904 15.8658 19.9836 15.673 20.362C15.3854 20.9265 14.9265 21.3854 14.362 21.673C13.9836 21.8658 13.5904 21.9371 13.195 21.9694C12.8205 22 12.3658 22 11.8386 22H4.16148C3.63437 22 3.17952 22 2.80497 21.9694C2.40963 21.9371 2.01641 21.8658 1.63803 21.673C1.07354 21.3854 0.614599 20.9265 0.32698 20.362C0.134186 19.9836 0.0628691 19.5904 0.0305681 19.195C-3.52915e-05 18.8205 -1.81249e-05 18.3657 9.48543e-07 17.8385V10.1615C-1.81249e-05 9.63432 -3.52915e-05 9.17955 0.0305681 8.80497C0.0628691 8.40963 0.134186 8.01641 0.32698 7.63803C0.614599 7.07354 1.07354 6.6146 1.63803 6.32698C2.01641 6.13419 2.40963 6.06287 2.80497 6.03057C3.17953 5.99997 3.63432 5.99998 4.16146 6Z"
                fill={color}
            />
            <path
                d="M17.8385 7.97061e-07H10.1615C9.63433 -1.61994e-05 9.17954 -3.08603e-05 8.80498 0.0305721C8.40963 0.0628731 8.01641 0.134189 7.63803 0.326983C7.07355 0.614603 6.61461 1.07354 6.32699 1.63803C6.13419 2.01641 6.06287 2.40963 6.03057 2.80497C6.00314 3.14076 6.00031 3.54098 6.00003 4.00003L11.8809 4.00001C12.3695 3.9999 12.8993 3.99977 13.3579 4.03724C13.8769 4.07964 14.5626 4.1846 15.27 4.54499C16.2108 5.02436 16.9757 5.78926 17.455 6.73007C17.8154 7.43739 17.9204 8.12311 17.9628 8.64213C18.0003 9.10072 18.0001 9.63053 18 10.1192L18 16C18.459 15.9997 18.8593 15.9969 19.195 15.9694C19.5904 15.9371 19.9836 15.8658 20.362 15.673C20.9265 15.3854 21.3854 14.9265 21.673 14.362C21.8658 13.9836 21.9371 13.5904 21.9694 13.195C22 12.8205 22 12.3658 22 11.8386V4.16148C22 3.63437 22 3.17952 21.9694 2.80497C21.9371 2.40963 21.8658 2.01641 21.673 1.63803C21.3854 1.07354 20.9265 0.614603 20.362 0.326983C19.9836 0.134189 19.5904 0.0628731 19.195 0.0305721C18.8205 -3.08603e-05 18.3657 -1.61994e-05 17.8385 7.97061e-07Z"
                fill={color}
            />
        </svg>
    );
}
