import React from "react";
import PropTypes from "prop-types";
import TextFieldMUI from "@mui/material/TextField";
import { ThemeProvider } from "@mui/material/styles";

// Theme
import { theme } from "./theme";

// Utils
import { generateInputProps } from "./utils/utils";

export const TextField = ({
    autoFocus,
    children,
    className,
    color,
    cypressTestId,
    defaultValue,
    disabled,
    endAdornment,
    error,
    helperText,
    id,
    label,
    name,
    onChange,
    required,
    size,
    startAdornment,
    type,
    value,
}) => {
    const hasChildren = React.Children.count(children) > 0;

    return (
        <ThemeProvider theme={theme}>
            <TextFieldMUI
                autoFocus={autoFocus}
                className={className}
                color={color}
                defaultValue={defaultValue}
                disabled={disabled}
                error={error}
                helperText={helperText}
                id={id}
                inputProps={{ "data-cy": cypressTestId }}
                InputProps={generateInputProps({
                    endAdornment,
                    startAdornment,
                })}
                label={label}
                name={name}
                onChange={onChange}
                required={required}
                size={size}
                type={type}
                variant="outlined"
                value={value}
            >
                {hasChildren ? children : null}
            </TextFieldMUI>
        </ThemeProvider>
    );
};

TextField.defaultProps = {
    autoFocus: false,
    className: "",
    disabled: false,
    error: false,
    select: false,
    size: "normal",
    type: "text",
};

TextField.propTypes = {
    autoFocus: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    color: PropTypes.string,
    cypressTestId: PropTypes.string,
    defaultValue: PropTypes.string,
    disabled: PropTypes.bool,
    endAdornment: PropTypes.node,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string.isRequired,
    name: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    size: PropTypes.string,
    startAdornment: PropTypes.node,
    type: PropTypes.string,
    value: PropTypes.string,
};
