import React from "react";
import PropTypes from "prop-types";

// Components
import CloseIcon from "@mui/icons-material/Close";
import { Text } from "components/Text/Text";

// CSS
import styles from "./cart-header.module.scss";

// Utils
import { handleKeyDown } from "utils/eventUtils";

export const CartHeader = ({ headerText, hideCart, resetCartNavigation }) => {
    return (
        <div className={styles.cartHeader}>
            <Text type="h2">{headerText}</Text>
            <CloseIcon
                className={styles.icon}
                onClick={() => {
                    hideCart();
                    resetCartNavigation();
                }}
                onKeyDown={(e) =>
                    handleKeyDown(e, () => {
                        hideCart();
                        resetCartNavigation();
                    })
                }
                tabIndex="0"
            />
        </div>
    );
};

CartHeader.propTypes = {
    headerText: PropTypes.string.isRequired,
    hideCart: PropTypes.func.isRequired,
    resetCartNavigation: PropTypes.func.isRequired,
};
