import React from "react";

// Components
import { ButtonPrimary } from "components/Buttons/Buttons";
import { Text } from "components/Text/Text";

// CSS
import styles from "../phone-verification-modal.module.scss";

// Localization
import { useTranslation } from "localization/localization";

export const PhoneVerificationModalCompleteLetters = ({
    togglePhoneVerificationModal,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * End Hooks
     */

    return (
        <div className={styles["complete-letters"]}>
            <h3 className={styles.header}>
                {t("phone_verification_modal.complete_title", {
                    ns: "components",
                })}
            </h3>
            <p className={styles.description}>
                {t("phone_verification_modal.complete_subtitle", {
                    ns: "components",
                })}
            </p>
            <ButtonPrimary onClick={() => togglePhoneVerificationModal(false)}>
                <Text>{t("select_a_recipient", { ns: "common" })}</Text>
            </ButtonPrimary>
        </div>
    );
};
