import { useHistory } from "react-router";

// Utils
import { isExternalURL, stripOriginFromURL } from "utils/urlUtils";

export const usePreviewCard = ({ item, onCardClick }) => {
    /**
     * Router Hooks
     */

    const history = useHistory();

    /**
     * End Hooks
     */

    function goToTargetUrl(url) {
        if (isExternalURL(url)) {
            window.open(url, "_blank");
        } else {
            history.push(stripOriginFromURL(url));
        }
    }

    function handleCardClick() {
        goToTargetUrl(item?.urls?.card);
        onCardClick();
    }

    return { goToTargetUrl, handleCardClick };
};
