import React from "react";
import { Modal } from "components/Modals/Modal";
import PropTypes from "prop-types";

// Components
import { WalletManageAddCardModalManualInput } from "./components/WalletManageAddCardModalManualInput/WalletManageAddCardModalManualInput";

// CSS
import styles from "./wallet-manage-add-card-modal.module.scss";

// Localization
import { useTranslation } from "localization/localization";

export const WalletManageAddCardModal = (props) => {
    const { setShouldShowAddCardModal, shouldShowAddCardModal } = props;

    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * End Hooks
     */

    function handleCloseAddCardModal() {
        setShouldShowAddCardModal(false);
    }

    return (
        <Modal
            className={styles["wallet-manage-add-card-modal"]}
            cyId="wallet-add-card-modal"
            headerText={t("add_your_gift_card", { ns: "wallet" })}
            onHide={handleCloseAddCardModal}
            show={shouldShowAddCardModal}
        >
            <WalletManageAddCardModalManualInput {...props} />
        </Modal>
    );
};

WalletManageAddCardModal.propTypes = {
    addGiftCardToWalletWithBarcode: PropTypes.func.isRequired,
    setShouldShowAddCardModal: PropTypes.func.isRequired,
    shouldShowAddCardModal: PropTypes.bool.isRequired,
};
