import React, { createContext, useState } from "react";

// Utils
import { SandboxxRestAPI } from "../utils/sandboxx";

// Context
export const InterstitialContext = createContext({});

export const InterstitialProvider = (props) => {
    const { children } = props;

    /**
     * useState
     **/

    const [interstitialContent, setInterstitialContent] = useState({
        callToActionText: "",
        messages: [],
        name: "",
        screen: "",
        subtitle: "",
        title: "",
    });

    const [showInterstitial, setShowInterstitial] = useState(false);

    /**
     * End Hooks
     **/

    function fetchInterstitialContentByName(name) {
        SandboxxRestAPI.getInterstitialByName(
            { name },
            onFetchInterstitialByNameSuccess
        );
    }

    function fetchInterstitialContentByScreen(screen) {
        SandboxxRestAPI.getInterstitialByScreen(
            { screen },
            onFetchInterstitialByScreenSuccess
        );
    }

    function onFetchInterstitialByNameSuccess(res) {
        if (res.interstitial) {
            toggleInterstitial(true);
            setInterstitialContent(res.interstitial);
        }
    }

    function onFetchInterstitialByScreenSuccess(res) {
        if (res.interstitial) {
            toggleInterstitial(true);
            setInterstitialContent(res.interstitial);
        }
    }

    function toggleInterstitial(bool) {
        setShowInterstitial((prev) => bool || !prev);
    }

    const interstitialContext = {
        fetchInterstitialContentByName,
        fetchInterstitialContentByScreen,
        interstitialContent,
        showInterstitial,
        toggleInterstitial,
    };

    return (
        <InterstitialContext.Provider value={interstitialContext}>
            {children}
        </InterstitialContext.Provider>
    );
};
