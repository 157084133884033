export function BookIcon({
    classes = "",
    color = "#9B9B9B",
    height = "49px",
    width = "31px",
}) {
    return (
        <svg
            className={classes}
            fill="none"
            height={height}
            viewBox="0 0 49 31"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M48.0401 3.22152H46.3305V1.46212C46.3305 0.999881 45.9567 0.625 45.4944 0.625H27.0949C26.0581 0.625 25.1292 1.08945 24.5 1.81765C23.8713 1.08945 22.943 0.625 21.9051 0.625H3.50612C3.04498 0.625 2.66899 0.999881 2.66899 1.46212V3.22152H0.962123C0.500987 3.22152 0.125 3.5964 0.125 4.05864V29.3039C0.125 29.7656 0.500987 30.1411 0.962123 30.1411H24.5H48.0401C48.5012 30.1411 48.875 29.7656 48.875 29.3039V4.05864C48.875 3.5964 48.5012 3.22152 48.0401 3.22152ZM27.0949 2.29814H44.659V25.872H27.0949C26.4524 25.872 25.8519 26.0533 25.3366 26.3635V4.05864C25.3371 3.08771 26.1261 2.29814 27.0949 2.29814ZM4.34324 2.29814H21.9051C22.875 2.29814 23.6656 3.08771 23.6656 4.05864V26.3635C23.1503 26.0533 22.5487 25.872 21.9051 25.872H4.34324V2.29814ZM1.79869 4.893H2.66899V26.7074C2.66899 27.1697 3.04387 27.5445 3.50612 27.5445H21.9035C22.5709 27.5445 23.1542 27.9189 23.4511 28.4668H1.79869V4.893ZM47.2024 28.4668H25.5483C25.8453 27.9189 26.4286 27.5445 27.0949 27.5445H45.4944C45.9567 27.5445 46.3305 27.1697 46.3305 26.7074V4.893H47.2019V28.4668H47.2024Z"
                fill={color}
            />
        </svg>
    );
}
