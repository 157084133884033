// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Utils
import { formatNewsItemForPreviewCard } from "../utils/utils";

export const useDashboardNewsPreviewCard = ({ newsItem }) => {
    const item = newsItem ? formatNewsItemForPreviewCard({ newsItem }) : null;

    function handleCardClick() {
        AnalyticsLogger.logNewsArticleViewed({ article: newsItem });
    }

    return { handleCardClick, item };
};
