import React from "react";
import { Link, withRouter } from "react-router-dom";
import cx from "classnames";

// Components
import { AuthModalLogoutWithRouter as AuthModalLogout } from "components/Modals/AuthModalLogout";
import { NavBarItem } from "./components/NavBarItem/NavBarItem";

// CSS
import styles from "./navbar.module.scss";
import navBarItemStyles from "./components/NavBarItem/nav-bar-item.module.scss";

// Enums
import { NavBarItemKeys } from "./enums/NavBarItemKeys";

// Hooks
import { useNavBar } from "./hooks/useNavBar";

// Images
import { IconLogout } from "components/Images/SVGIcons";

// Localization
import { useTranslation } from "localization/localization";

// Media
import sandboxxLogoSrc from "media/shared/logo-text-orange.svg";
import sandboxxLogoWhiteSrc from "media/shared/logo-image-white.png";

// Utils
import { CurrentUser } from "utils/sandboxx";
import { handleKeyDown } from "utils/eventUtils";
import {
    generateLettersDynamicParams,
    generateMusterDynamicParams,
    generateNavBarItemDataList,
} from "./utils/utils";

const NavBar = () => {
    /**
     * Custom Hooks
     **/

    const { t } = useTranslation();

    const {
        hasUnreadInboxMessages,
        history,
        setShouldShowLogOutModal,
        shouldShowLogOutModal,
        shouldShowNavBar,
    } = useNavBar();

    /**
     * End Hooks
     **/

    function handleLoginClick() {
        history.push("/signin");
    }

    function renderLogoutOption() {
        return (
            CurrentUser.isLoggedIn() && (
                <div
                    className={navBarItemStyles.navBarItem}
                    onClick={() => setShouldShowLogOutModal(true)}
                    tabIndex="0"
                    onKeyDown={(e) =>
                        handleKeyDown(e, () => setShouldShowLogOutModal(true))
                    }
                >
                    <IconLogout
                        className={cx(
                            navBarItemStyles.icon,
                            navBarItemStyles.iconLogout
                        )}
                    />
                    <span className={navBarItemStyles.text}>
                        {t("logout", { ns: "common" })}
                    </span>
                </div>
            )
        );
    }

    function renderNavItems() {
        const user = CurrentUser.getUser();
        const rootPersona = (user?.personas && user?.personas?.[0]) || {
            id: "UNKNOWN",
        };
        const dynamicParams = {
            [NavBarItemKeys.INBOX]: { unread: hasUnreadInboxMessages },
            [NavBarItemKeys.LETTERS]: generateLettersDynamicParams(rootPersona),
            [NavBarItemKeys.MUSTER]: generateMusterDynamicParams(rootPersona),
        };
        const navData = generateNavBarItemDataList(rootPersona, dynamicParams);

        return navData.map((data, i) => {
            return <NavBarItem data={data} history={history} key={i} />;
        });
    }

    function renderNav() {
        const navToRender = CurrentUser.isLoggedIn()
            ? renderNavItems()
            : renderNavLoggedOut();
        return (
            shouldShowNavBar && (
                <nav className={styles.navbar} data-cy="navbar" role="menu">
                    <div className={styles.header}>
                        <Link className={styles.logo} to="/">
                            <img
                                alt={t("sandboxx_logo", { ns: "common" })}
                                src={sandboxxLogoSrc}
                            />
                        </Link>
                    </div>
                    {navToRender}
                    <div className={styles["logout-spacer"]}></div>
                    {renderLogoutOption()}
                </nav>
            )
        );
    }

    function renderNavLoggedOut() {
        return (
            <div className={styles["signed-out"]}>
                <div className={styles.image}>
                    <img
                        alt={t("sandboxx_logo", { ns: "common" })}
                        className={styles.image}
                        src={sandboxxLogoWhiteSrc}
                    />
                    <p className={styles.testimonial}>
                        <span className={styles.quote}>
                            {t("navbar.quote", { ns: "components" })}
                        </span>
                        <span className={styles.name}>- Daniel B</span>
                    </p>
                    <div className={styles.button}>
                        <button
                            className="button button--transparent button-small"
                            data-cy="navigation-logged-out-sign-in-button"
                            onClick={handleLoginClick}
                        >
                            {t("sign_in", { ns: "common" })}
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            {renderNav()}
            <AuthModalLogout
                setShouldShowLogOutModal={setShouldShowLogOutModal}
                shouldShowLogOutModal={shouldShowLogOutModal}
            />
        </>
    );
};

export const NavBarWithRouter = withRouter(NavBar);
