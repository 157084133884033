import React, { useState } from "react";

// Components
import { LettersComposeRecipientSearch } from "./LettersComposeRecipientSearch";

// Models
import { Contact } from "../../../../../../models/contacts/Contact";

// Validation
import { LettersValidation } from "../../../../../../validation/lettersValidation";

// Enums
import { LettersComposeNavigation } from "../../enums/enums";

export const LettersComposeRecipientSearchContainer = (props) => {
    const {
        changeComposeCurrentSection,
        handleComposeRecipientSelect,
        history,
    } = props;

    /**
     * useState
     */

    const [isSearching, setIsSearching] = useState(false);

    /**
     * End Hooks & Constants
     */

    function handleGoToLetterCompose() {
        changeComposeCurrentSection(LettersComposeNavigation.COMPOSE);
    }

    function handleSelectSearchResult({ suggestion }) {
        const isContactInstance = suggestion instanceof Contact;
        const contact = isContactInstance
            ? suggestion
            : new Contact(suggestion);
        const isValidContact =
            LettersValidation.validateRecipientSenderFull(contact);
        if (contact.isSandboxxUser && !isValidContact) {
            history.push(`/contact/${contact.id}`, { contact });
        } else {
            handleComposeRecipientSelect(
                { suggestion: contact },
                handleGoToLetterCompose
            );
        }
    }

    return (
        <LettersComposeRecipientSearch
            {...props}
            handleGoToLetterCompose={handleGoToLetterCompose}
            handleSelectSearchResult={handleSelectSearchResult}
            isSearching={isSearching}
            setIsSearching={setIsSearching}
        />
    );
};
