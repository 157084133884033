export const useSupportSquadList = ({
    setCurrentSection,
    setShouldShowCreateModal,
    setShouldShowJoinModal,
    setSquadName,
    setSelectedSquad,
    setSupportSquadImage,
}) => {
    function handleCreateSquadClick() {
        setCurrentSection("name");
        setSquadName("");
        setSupportSquadImage({
            imageFile: "",
            imageName: "",
            imagePreviewUrl: "",
        });
        setShouldShowCreateModal(true);
        setSelectedSquad(null);
    }

    function handleJoinSquadClick() {
        setShouldShowJoinModal(true);
    }

    return { handleCreateSquadClick, handleJoinSquadClick };
};
