import React, { useState } from "react";
import PropTypes from "prop-types";

// Components
import IconButton from "@mui/material/IconButton";
import { TextField } from "components/Inputs/TextField/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export const PasswordInput = (props) => {
    const {
        className,
        cypressTestId,
        isValid,
        id,
        label,
        name,
        onChange,
        value,
    } = props;

    /**
     * setState
     */

    const [shouldShowPassword, setShouldShowPassword] = useState(false);

    /**
     * End Hooks
     */

    return (
        <TextField
            className={className}
            color={isValid ? "success" : ""}
            cypressTestId={cypressTestId}
            endAdornment={
                <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShouldShowPassword((prev) => !prev)}
                    edge="end"
                >
                    {shouldShowPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
            }
            id={id}
            label={label}
            name={name}
            onChange={onChange}
            type={shouldShowPassword ? "text" : "password"}
            value={value}
        />
    );
};

PasswordInput.defaultProps = {
    id: "password",
    label: "Password",
    name: "password",
};

PasswordInput.propTypes = {
    className: PropTypes.string,
    cypressTestId: PropTypes.string,
    isValid: PropTypes.bool,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    value: PropTypes.string,
};
