// Enums
import { StorylyCategory } from "enums/StorylyCategory";

export const storylyCategoryTokens = Object.freeze({
    [StorylyCategory.BASICS]:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2NfaWQiOjc4NjQsImFwcF9pZCI6MTI2ODcsImluc19pZCI6MTY5MTZ9.8ancwYgz0HPIb1DrkY6HtTP6lBUTLsHZsE3-APWft0U",
    [StorylyCategory.BENEFITS]:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2NfaWQiOjc4NjQsImFwcF9pZCI6MTI2ODcsImluc19pZCI6MTgzNzN9.6jA3DO63r-OLsEfP1-7gLzMJ-bfQIeidEwTKA6vcnfU",
    [StorylyCategory.DASHBOARD]:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2NfaWQiOjc4NjQsImFwcF9pZCI6MTI2ODcsImluc19pZCI6MTc5MDR9.q87qQBpOXupStXpzjjSbALEOf-mLZMEZUQpPkjdSeMI",
    [StorylyCategory.FEATURED_RECRUIT]:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2NfaWQiOjc4NjQsImFwcF9pZCI6MTI2ODcsImluc19pZCI6MTY5MTV9.1UIhYnks0YFCvE_HMrg1Llvp4fbV77-yhlgXRW-mtBI",
    [StorylyCategory.FEATURED_RECRUITER]:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2NfaWQiOjc4NjQsImFwcF9pZCI6MTI2ODcsImluc19pZCI6MTY5MzF9.VbP0cbvmMiGO0aJYOBTcub8mhZNX5umdlTPjIByCHNw",
    [StorylyCategory.PREPARE]:
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhY2NfaWQiOjc4NjQsImFwcF9pZCI6MTI2ODcsImluc19pZCI6MTM3MzV9.ZYMyUbnGNlw4dfz5dE0KUvdNjCjNtKpxxQy3K0P_X8w",
    [StorylyCategory.SUPPORT_SQUAD]:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2NfaWQiOjc4NjQsImFwcF9pZCI6MTI2ODcsImluc19pZCI6MTc5MDR9.q87qQBpOXupStXpzjjSbALEOf-mLZMEZUQpPkjdSeMI",
});
