import React, { forwardRef } from "react";

// Components
import { ButtonInvisible } from "components/Buttons/Buttons";

export const MusterOverviewHeaderShipDateButton = forwardRef(
    ({ classes, daysUntilShip, onClick }, ref) => {
        return (
            <ButtonInvisible
                classes={classes}
                cypressTestId="edit-ship-date-button"
                onClick={onClick}
                text={daysUntilShip === null ? "Add Ship Date" : "Edit"}
            />
        );
    }
);
