export function sortDocuments(documents) {
    let complete = [];
    let incomplete = [];

    documents.forEach((collection) =>
        collection.documents.some((document) => document.completed === false)
            ? incomplete.push(collection)
            : complete.push(collection)
    );

    return { complete, incomplete };
}

/**
 * After updating musterDocuments on the backend, update locally before refetching data
 * @param {array} musterDocuments A collection of document arrays containing complete and incomplete status
 * @param {obj} res The response object from a successful put call to the document api
 * @returns {array} A copy of the musterDocuments array with the response object replacing outdated content
 */
export function generateUpdatedDocumentList({ musterDocuments, res }) {
    const placeHolderDocumentArray = musterDocuments;
    const objIndex = placeHolderDocumentArray.findIndex(
        (collection) => collection.id === res.categoryId
    );
    const musterCollection = placeHolderDocumentArray[objIndex];
    const documentIndex = musterCollection.documents.findIndex(
        (document) => document.description === res.description
    );
    musterCollection.documents[documentIndex] = res;
    return placeHolderDocumentArray;
}
