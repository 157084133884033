export function WarningCircleUnfilledIcon({ className, color, height, width }) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 48 47"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M24 15V23.5M24 32H24.0213M45.25 23.5C45.25 35.2361 35.7361 44.75 24 44.75C12.2639 44.75 2.75 35.2361 2.75 23.5C2.75 11.7639 12.2639 2.25 24 2.25C35.7361 2.25 45.25 11.7639 45.25 23.5Z"
                stroke={color}
                strokeWidth="4.25"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

WarningCircleUnfilledIcon.defaultProps = {
    color: "#AB2219",
    className: "",
    height: "25px",
    width: "25px",
};
