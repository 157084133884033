import React from "react";
import PropTypes from "prop-types";

// Components
import { ButtonInvisible, ButtonPrimary } from "components/Buttons/Buttons";
import { Modal } from "components/Modals/Modal";
import { Text } from "components/Text/Text";
import { WarningCircleUnfilledIcon } from "components/Icons/WarningCircleUnfilledIcon";

// CSS
import styles from "./muster-checklist-delete-modal.module.scss";

export const MusterChecklistDeleteModal = (props) => {
    const {
        activeDocument,
        deleteMusterDocument,
        onToggleDeleteModal,
        shouldShowDeleteModal,
    } = props;

    function handleModalClose() {
        onToggleDeleteModal(false, "");
    }

    function renderBody() {
        return (
            <div className={styles.body}>
                <WarningCircleUnfilledIcon height="42px" width="42px" />
                <Text classes={styles.headerText} type="h2">
                    Delete Document?
                </Text>
                <Text isCentered={true} type="h4">
                    Are you sure you want to delete this document from your
                    list? This action cannot be undone.
                </Text>
            </div>
        );
    }

    function renderButtons() {
        return (
            <div className={styles.buttons}>
                <ButtonPrimary
                    classes={styles.button}
                    color="warning"
                    cypressTestId="document-checklist-delete-modal-button"
                    onClick={() => deleteMusterDocument(activeDocument.id)}
                    text="Delete Document"
                    type="button"
                />
                <ButtonInvisible text="Cancel" />
            </div>
        );
    }

    return (
        <Modal
            centered
            className={styles["muster-checklist-delte-modal"]}
            dialogClassName={styles["muster-checklist-delete-modal-dialog"]}
            onHide={handleModalClose}
            shouldHideHeader={true}
            show={shouldShowDeleteModal}
            size="md"
        >
            <div className={styles.content}>
                {renderBody()}
                {renderButtons()}
            </div>
        </Modal>
    );
};

MusterChecklistDeleteModal.propTypes = {
    activeDocument: PropTypes.object,
    deleteMusterDocument: PropTypes.func.isRequired,
    onToggleDeleteModal: PropTypes.func.isRequired,
    shouldShowDeleteModal: PropTypes.bool.isRequired,
};
