import { useEffect, useState } from "react";

// Enums
import { MusterChecklistModalTypes } from "enums/MusterChecklistModalTypes";

export const useMusterChecklistDocumentModal = (
    activeDocument,
    addMusterDocument,
    inputRef,
    onToggleAddEditModal,
    type,
    updateMusterDocument
) => {
    /**
     * Constants
     */

    const buttonText =
        type === MusterChecklistModalTypes.ADD
            ? "Add Document"
            : "Save Document";
    const headerText =
        type === MusterChecklistModalTypes.ADD
            ? "New Document"
            : "Update Document";
    const headerSubText =
        type === MusterChecklistModalTypes.ADD
            ? "Add your document details below"
            : "Update your document details below";

    /**
     * useState
     */

    const [documentDescription, setDocumentDescription] = useState(
        activeDocument?.description || ""
    );

    /**
     * useEffect
     */

    useEffect(() => {
        setDocumentDescription(activeDocument?.description || "");
        inputRef?.current?.focus();
        // eslint-disable-next-line
    }, [activeDocument, type]);

    /**
     * End Hooks
     */

    function handleChange(e) {
        setDocumentDescription(e.target.value);
    }

    function handleModalClose() {
        onToggleAddEditModal(false, type);
        setDocumentDescription("");
    }

    function handleSubmit() {
        if (type === MusterChecklistModalTypes.ADD) {
            addMusterDocument(
                activeDocument.categoryId,
                documentDescription,
                () => setDocumentDescription("")
            );
        } else if (type === MusterChecklistModalTypes.EDIT) {
            updateMusterDocument(
                activeDocument.categoryId,
                activeDocument.id,
                documentDescription,
                activeDocument.completed
            );
        }
    }

    return {
        buttonText,
        documentDescription,
        handleChange,
        handleModalClose,
        handleSubmit,
        headerSubText,
        headerText,
        setDocumentDescription,
    };
};
