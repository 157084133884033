import React from "react";
import cx from "classnames";

// Components
import { ButtonRounded } from "components/Buttons/ButtonRounded/ButtonRounded";

// CSS
import styles from "../profile-persona-selection.module.scss";

export const ProfilePersonaModalSelectChild = ({
    handleSelectProfileChildPersona,
    isLoadingChildPersonas,
    lastChildPersonasArray,
    onComplete,
}) => {
    function renderChildPersonas() {
        if (isLoadingChildPersonas) {
            return (
                <>
                    <ButtonRounded
                        classes={cx(styles.persona, styles.childPersona)}
                        isLoading
                    />
                    <ButtonRounded
                        classes={cx(styles.persona, styles.childPersona)}
                        isLoading
                    />
                    <ButtonRounded
                        classes={cx(styles.persona, styles.childPersona)}
                        isLoading
                    />
                    <ButtonRounded
                        classes={cx(styles.persona, styles.childPersona)}
                        isLoading
                    />
                    <ButtonRounded
                        classes={cx(styles.persona, styles.childPersona)}
                        isLoading
                    />
                    <ButtonRounded
                        classes={cx(styles.persona, styles.childPersona)}
                        isLoading
                    />
                </>
            );
        } else {
            return lastChildPersonasArray?.map((persona, index) => {
                return (
                    <ButtonRounded
                        classes={cx(styles.persona, styles.childPersona)}
                        cypressTestId={`profile-persona-modal-select-child-${persona.id}`}
                        key={index}
                        onClick={() =>
                            handleSelectProfileChildPersona(persona, {
                                onComplete,
                            })
                        }
                        text={persona.displayName}
                    />
                );
            });
        }
    }

    return <>{renderChildPersonas()}</>;
};
