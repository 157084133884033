import React from "react";
import cx from "classnames";
import { Skeleton } from "@material-ui/lab";

// CSS
import styles from "./button-block.module.scss";

export const ButtonBlock = (props) => {
    const {
        bodyText,
        classes,
        cypressTestId,
        headerText,
        isDisabled,
        isFilled,
        isLoading,
        onClick,
    } = props;

    if (isLoading) {
        return (
            <button
                className={cx(styles.buttonBlock, classes)}
                disabled={isDisabled}
            >
                <Skeleton variant="rect" width="50%" height="20px" />
                <Skeleton variant="rect" width="80%" height="16px" />
            </button>
        );
    } else {
        return (
            <button
                className={cx(styles.buttonBlock, classes, {
                    [styles["buttonBlock--filled"]]: isFilled,
                })}
                data-cy={cypressTestId}
                disabled={isDisabled}
                onClick={onClick}
            >
                <span className={styles.headerText}>{headerText}</span>
                <span className={styles.bodyText}>{bodyText}</span>
            </button>
        );
    }
};
