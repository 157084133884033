import React from "react";

// Components
import { CheckmarkIcon } from "components/Icons/CheckmarkIcon";

// CSS
import "./chips.scss";

// Utils
import { handleKeyDown } from "../../utils/eventUtils";

export const Chips = ({ collection, onClick }) => {
    return (
        <div className="chips">
            {collection.map((item, i) => {
                return <Chip index={i} item={item} key={i} onClick={onClick} />;
            })}
        </div>
    );
};

export const Chip = ({ index, item, onClick }) => {
    const { isSelected, text } = item;
    const isSelectedClass = isSelected ? "chip--selected" : "";

    function handleClick() {
        onClick(item, index);
    }

    return (
        <div
            className={`chip ${isSelectedClass}`}
            onClick={handleClick}
            tabIndex="0"
            onKeyDown={
                /* istanbul ignore next */ (e) => handleKeyDown(e, handleClick)
            }
        >
            {isSelected && (
                <CheckmarkIcon className="chip__icon" color="#2a526a" />
            )}
            <span>{text}</span>
        </div>
    );
};
