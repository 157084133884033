import React, { useContext, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";

// Components
import { Cart } from "components/Cart/Cart";
import { WrappedContactModal as ContactModal } from "./scenes/Contacts/ContactModal/ContactModal";
import { Footer } from "./scenes/App/Footer";
import { NavBarWithRouter as NavBar } from "./components/NavBar/NavBar";
import { Interstitial } from "components/Interstitial/Interstitial";
import { PhoneVerificationModalWithRouter as PhoneVerificationModal } from "components/PhoneVerificationModal/PhoneVerificationModal";
import { Providers } from "./components/Providers/Providers";
import { PurchaseModal } from "./components/Purchase/PurchaseModal";
import { RedirectMobile } from "./components/Redirect/RedirectMobile";
import { Routes } from "./routes/Routes";

// Context
import { NavBarContext } from "context/navbar";

// Utils
import {
    isMobile,
    isUsingIPad,
    isVisitingNoAuthURL,
} from "utils/mediaQueryUtils";
import { CurrentUser, SandboxxRestAPI } from "./utils/sandboxx";

const shouldRedirect =
    (isMobile() && !isVisitingNoAuthURL()) ||
    (isUsingIPad() && !isVisitingNoAuthURL());

export const App = () => {
    /**
     * useContext
     */
    const { runShouldShowNavBarCheck, shouldShowNavBar } =
        useContext(NavBarContext);

    /**
     * useEffect
     */

    useEffect(() => {
        handleFetchUserAccount();
        runShouldShowNavBarCheck();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * End Hooks and Constants
     */

    function handleFetchUserAccount() {
        SandboxxRestAPI.getAccount((res) => {
            const { user, onboarding, verification } = res;
            CurrentUser.storeUser(user, verification, onboarding);
        });
    }

    function renderRouter() {
        const shouldShowMobileVersion = isMobile() && !isUsingIPad();
        const navBarHiddenClass = shouldShowNavBar ? "" : "hide-nav-bar";
        const appClassNames = shouldShowMobileVersion
            ? "app-mobile"
            : `app ${navBarHiddenClass}`;
        return (
            <Router forceRefresh={!supportsHistory()}>
                <div className={appClassNames}>
                    <Providers>
                        <div>
                            <ContactModal />
                            <PhoneVerificationModal />
                            <PurchaseModal />
                            <Interstitial />
                            <NavBar />
                            <Cart />
                        </div>
                        <div className="app-content">
                            {Routes()}
                            <Footer />
                        </div>
                    </Providers>
                </div>
            </Router>
        );
    }

    return shouldRedirect ? <RedirectMobile /> : renderRouter();
};

function supportsHistory() {
    return "pushState" in window.history; // For browsers that don't support HTML5 History API
}
