import { useMemo, useState } from "react";

export const useAddOnsCarousel = (addOns) => {
    /**
     * useState
     */

    const [position, setPosition] = useState(0);

    /**
     * useMemo
     */

    const hasAddOns = useMemo(() => !!addOns?.length, [addOns]);
    const canGoLeft = useMemo(
        () => hasAddOns && !!addOns[position - 1],
        [addOns, hasAddOns, position]
    );
    const canGoRight = useMemo(
        () => hasAddOns && !!addOns[position + 2],
        [addOns, hasAddOns, position]
    );

    /**
     * End Hooks
     */

    function handleShiftCarousel(direction) {
        if (direction === "left" && canGoLeft) {
            setPosition((prev) => prev - 2);
        } else if (direction === "right" && canGoRight) {
            setPosition((prev) => prev + 2);
        }
    }

    return { canGoLeft, canGoRight, handleShiftCarousel, hasAddOns, position };
};
