// Utils
import { imagePublicBase } from "utils/urlUtils";

export const slideshowData = [
    {
        alt: "Soldiers on truck",
        id: 1,
        imageSrc: `${imagePublicBase}/web/media/signup/signin-slideshow-1.jpg`,
    },
    {
        alt: "Jets in formation",
        id: 2,
        imageSrc: `${imagePublicBase}/web/media/signup/signin-slideshow-2.jpg`,
    },
    {
        alt: "Helicopter flying over soldiers",
        id: 3,
        imageSrc: `${imagePublicBase}/web/media/signup/signin-slideshow-3.jpg`,
    },
    {
        alt: "Aircraft carrier",
        id: 4,
        imageSrc: `${imagePublicBase}/web/media/signup/signin-slideshow-4.jpg`,
    },
    {
        alt: "Soldier walking down path",
        id: 5,
        imageSrc: `${imagePublicBase}/web/media/signup/signin-slideshow-5.jpg`,
    },
];
