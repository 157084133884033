import { useMemo } from "react";

// Components
// Components
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import LanguageIcon from "@mui/icons-material/Language";
import PhotoSizeSelectActualOutlinedIcon from "@mui/icons-material/PhotoSizeSelectActualOutlined";
import ReplyOutlinedIcon from "@mui/icons-material/ReplyOutlined";

// Localization
import { useTranslation } from "localization/localization";

export const usePurchaseModalBundleOptions = () => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useMemo
     */

    const listIconItems = useMemo(
        () => [
            {
                icon: <FlightTakeoffIcon />,
                subtitle: t("purchase_modal.bundle_options.list.item_1", {
                    ns: "components",
                }),
            },
            {
                icon: <LanguageIcon />,
                subtitle: t("purchase_modal.bundle_options.list.item_2", {
                    ns: "components",
                }),
            },
            {
                icon: <ReplyOutlinedIcon />,
                subtitle: t("purchase_modal.bundle_options.list.item_3", {
                    ns: "components",
                }),
            },
            {
                icon: <PhotoSizeSelectActualOutlinedIcon />,
                subtitle: t("purchase_modal.bundle_options.list.item_4", {
                    ns: "components",
                }),
            },
            {
                icon: <EventOutlinedIcon />,
                subtitle: t("purchase_modal.bundle_options.list.item_5", {
                    ns: "components",
                }),
            },
        ],
        [t]
    );

    const sliderText = useMemo(
        () => [
            {
                title: t("purchase_modal.bundle_options.slider.item_1_title", {
                    ns: "components",
                }),
                subtitle: t(
                    "purchase_modal.bundle_options.slider.item_1_subtitle",
                    {
                        ns: "components",
                    }
                ),
            },
            {
                title: t("purchase_modal.bundle_options.slider.item_2_title", {
                    ns: "components",
                }),
                subtitle: t(
                    "purchase_modal.bundle_options.slider.item_2_subtitle",
                    {
                        ns: "components",
                    }
                ),
            },
            {
                title: t("purchase_modal.bundle_options.slider.item_3_title", {
                    ns: "components",
                }),
                subtitle: t(
                    "purchase_modal.bundle_options.slider.item_3_subtitle",
                    {
                        ns: "components",
                    }
                ),
            },
            {
                title: t("purchase_modal.bundle_options.slider.item_4_title", {
                    ns: "components",
                }),
                subtitle: t(
                    "purchase_modal.bundle_options.slider.item_4_subtitle",
                    {
                        ns: "components",
                    }
                ),
            },
            {
                title: t("purchase_modal.bundle_options.slider.item_5_title", {
                    ns: "components",
                }),
                subtitle: t(
                    "purchase_modal.bundle_options.slider.item_5_subtitle",
                    {
                        ns: "components",
                    }
                ),
            },
        ],
        [t]
    );

    /**
     * End Hooks
     */

    return { listIconItems, sliderText };
};
