// Components
import { MaterialIcon } from "components/MaterialIcon/MaterialIcon";

// CSS
import styles from "../letters-compose-confirmation.module.scss";

// Enums
import { MaterialIconMode } from "components/MaterialIcon/enums/MaterialIconMode";

// Localization
import { t } from "localization/localization";

export function generateListIconItems() {
    return [
        {
            icon: (
                <MaterialIcon
                    className={styles.icon}
                    mode={MaterialIconMode.MATERIAL_SYMBOLS_OUTLINED}
                    name="drafts"
                />
            ),
            subtitle: t("confirmation_printing_subtitle", { ns: "letters" }),
            title: t("confirmation_printing_title", { ns: "letters" }),
        },
        {
            icon: (
                <MaterialIcon
                    className={styles.icon}
                    mode={MaterialIconMode.MATERIAL_SYMBOLS_OUTLINED}
                    name="local_shipping"
                />
            ),
            subtitle: t("confirmation_shipping_subtitle", { ns: "letters" }),
            title: t("confirmation_shipping_title", { ns: "letters" }),
        },
    ];
}
