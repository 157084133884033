import InputAdornment from "@mui/material/InputAdornment";

export function generateInputProps({ endAdornment, startAdornment }) {
    return {
        ...(endAdornment && {
            endAdornment: (
                <InputAdornment position="end">{endAdornment}</InputAdornment>
            ),
        }),
        ...(startAdornment && {
            startAdornment: (
                <InputAdornment position="start">
                    {startAdornment}
                </InputAdornment>
            ),
        }),
    };
}
