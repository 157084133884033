import React from "react";
import cx from "classnames";

// Components
import { Accordion } from "components/Accordion/Accordion";
import { DropdownState } from "components/Dropdowns/DropdownState/DropdownState";
import { WalletGiftCardsPurchaseRecipientNewRecipientInput } from "./components/WalletGiftCardsPurchaseRecipientNewRecipientInput/WalletGiftCardsPurchaseRecipientNewRecipientInput";

// CSS
import styles from "./wallet-gift-cards-purchase-recipient-new-recipient.module.scss";

// Enums
import { RecipientSourceEnum } from "../../../../enums/RecipientSourceEnum";

export const WalletGiftCardsPurchaseRecipientNewRecipient = (props) => {
    const {
        clearRecipient,
        handleSubmit,
        hasContactsOrSuggestedContacts,
        newRecipient,
        recipientSource,
        setNewRecipient,
        setRecipientSource,
    } = props;

    function handleChange({ target }) {
        const { name, value } = target;
        setNewRecipient((prev) => ({ ...prev, [name]: value }));
    }

    function handleSubmitForm(e) {
        e?.preventDefault();
        handleSubmit(newRecipient);
    }

    function onSetAccordionToActive() {
        clearRecipient();
        setRecipientSource(RecipientSourceEnum.NEW_RECIPIENT);
    }

    return (
        <div
            className={
                styles["wallet-gift-cards-purchase-recipient-new-recipient"]
            }
        >
            <Accordion
                onSetToActive={onSetAccordionToActive}
                shouldForceActive={!hasContactsOrSuggestedContacts}
                shouldForceInactive={
                    recipientSource === RecipientSourceEnum.CONTACTS ||
                    recipientSource === RecipientSourceEnum.PREVIOUS_RECIPIENTS
                }
                title="Add New Recipient"
            >
                <form
                    className={cx(styles.form, "form")}
                    onSubmit={handleSubmitForm}
                >
                    <WalletGiftCardsPurchaseRecipientNewRecipientInput
                        cypressTestId="wallet-gift-cards-purchase-recipient-form-first-name"
                        labelText="First Name"
                        name="firstName"
                        onChange={handleChange}
                        value={newRecipient.firstName}
                    />
                    <WalletGiftCardsPurchaseRecipientNewRecipientInput
                        cypressTestId="wallet-gift-cards-purchase-recipient-form-last-name"
                        labelText="Last Name"
                        name="lastName"
                        onChange={handleChange}
                        value={newRecipient.lastName}
                    />
                    <WalletGiftCardsPurchaseRecipientNewRecipientInput
                        cypressTestId="wallet-gift-cards-purchase-recipient-form-line1"
                        labelText="Recipient Address 1"
                        name="line1"
                        onChange={handleChange}
                        value={newRecipient.line1}
                    />
                    <WalletGiftCardsPurchaseRecipientNewRecipientInput
                        cypressTestId="wallet-gift-cards-purchase-recipient-form-line2"
                        labelText="Recipient Address 2"
                        name="line2"
                        onChange={handleChange}
                        value={newRecipient.line2}
                    />
                    <WalletGiftCardsPurchaseRecipientNewRecipientInput
                        cypressTestId="wallet-gift-cards-purchase-recipient-form-city"
                        labelText="City"
                        name="city"
                        onChange={handleChange}
                        value={newRecipient.city}
                    />
                    <label
                        className={cx(styles.label, "label")}
                        htmlFor="state"
                    >
                        <span>State</span>
                        <DropdownState
                            className={styles.select}
                            cypressTestId="wallet-gift-cards-purchase-recipient-form-state"
                            onChange={handleChange}
                            name="state"
                            shouldUseStatesOnly={true}
                            value={newRecipient.state}
                        />
                    </label>
                    <WalletGiftCardsPurchaseRecipientNewRecipientInput
                        cypressTestId="wallet-gift-cards-purchase-recipient-form-zipcode"
                        labelText="Zip Code"
                        name="zipcode"
                        onChange={handleChange}
                        value={newRecipient.zipcode}
                    />
                    <input style={{ display: "none" }} type="submit" />
                </form>
            </Accordion>
        </div>
    );
};
