import { useContext, useEffect, useState } from "react";

// Context
import { CartContext } from "context/Cart/cart";
import { NotificationBarContext } from "context/notificationBar";
import { PurchaseContext } from "context/purchase";

// Utils
import { getCartPaymentMethodFromSavedCards } from "../utils/utils";

export const useCartPaymentMethod = () => {
    /**
     * useContext
     */

    const { cart, submitPaymentDetails } = useContext(CartContext);
    const { showNotification } = useContext(NotificationBarContext);
    const { fetchSavedCards, savedCards } = useContext(PurchaseContext);

    /**
     * useState
     */

    const [hasSetAfterInitialCartFetch, setHasSetAfterInitialCartFetch] =
        useState(false);
    const [isSubmittingPaymentMethod, setIsSubmittingPaymentMethod] =
        useState(false);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");

    /**
     * useEffect
     */

    useEffect(() => {
        fetchSavedCards();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        // Set selectedPaymentMethod if cart has a payment_session after initial cart fetch
        if (
            cart?.payment_session &&
            !hasSetAfterInitialCartFetch &&
            savedCards
        ) {
            const card = getCartPaymentMethodFromSavedCards({
                cart,
                savedCards,
            });
            setSelectedPaymentMethod(card);
            setHasSetAfterInitialCartFetch(true);
        }
    }, [
        cart,
        cart?.payment_session,
        cart?.updated_at,
        hasSetAfterInitialCartFetch,
        savedCards,
    ]);

    /**
     * End Hooks
     */

    function handleSelectPaymentMethod(e) {
        const selectedValue = e.target.value;
        if (selectedValue) {
            setIsSubmittingPaymentMethod(true);
            submitPaymentDetails({
                onFailure: onSubmitPaymentDetailsFailure,
                onSuccess: (res) =>
                    onSubmitPaymentDetailsSuccess(res, selectedValue),
                paymentMethod: selectedValue,
            });
        }
    }

    function handleSelectPaymentMethodNew(newCard, token) {
        const newCardFormatted = {
            brand: newCard.details.cardType,
            expMonth: newCard.details.expirationMonth,
            expYear: newCard.details.expirationYear,
            last4: newCard.details.lastFour,
            token,
        };
        if (newCard) {
            setIsSubmittingPaymentMethod(true);
            submitPaymentDetails({
                onFailure: onSubmitPaymentDetailsFailure,
                onSuccess: (res) =>
                    onSubmitPaymentDetailsSuccess(res, newCardFormatted),
                paymentMethod: newCardFormatted,
            });
        }
    }

    function onSubmitPaymentDetailsFailure(err) {
        showNotification({ text: err.message, type: "warning" });
        setIsSubmittingPaymentMethod(false);
        setSelectedPaymentMethod("");
    }

    function onSubmitPaymentDetailsSuccess(res, paymentMethod) {
        fetchSavedCards();
        setIsSubmittingPaymentMethod(false);
        setSelectedPaymentMethod(paymentMethod);
    }

    function resetCartPaymentMethod() {
        setHasSetAfterInitialCartFetch(false);
        setIsSubmittingPaymentMethod(false);
        setSelectedPaymentMethod("");
    }

    return {
        isSubmittingPaymentMethod,
        handleSelectPaymentMethod,
        handleSelectPaymentMethodNew,
        resetCartPaymentMethod,
        selectedPaymentMethod,
    };
};
