import React, { useState, useRef } from "react";
import cx from "classnames";

// CSS
import styles from "./confirm-verification-code-input.module.scss";

// Validation
import { Validation } from "validation/validation";

export const ConfirmVerificationCodeInput = ({
    characterType,
    className,
    length,
    label,
    loading,
    onChange,
    onComplete,
    size,
    shouldShowErrorState,
}) => {
    const [code, setCode] = useState([...Array(length)].map(() => ""));
    const inputs = useRef([]);

    const processInput = (e, slot) => {
        const num = e.target.value;
        if (
            Validation.isNumber(num) &&
            (!characterType || characterType === "number")
        ) {
            return;
        }
        const newCode = [...code];
        newCode[slot] = num.toUpperCase();
        setCode(newCode);
        onChange(newCode);
        if (slot !== length - 1) {
            inputs.current[slot + 1].focus();
        }
        if (onComplete && newCode.every((num) => num !== "")) {
            onComplete(newCode.join(""));
        }
    };

    const onKeyUp = (e, slot) => {
        if (e.keyCode === 8 && !code[slot] && slot !== 0) {
            const newCode = [...code];
            newCode[slot - 1] = "";
            setCode(newCode);
            inputs.current[slot - 1].focus();
        }
    };

    function onPaste(event) {
        event.preventDefault();
        const pasted = event.clipboardData.getData("text/plain");
        const newCode = pasted.toUpperCase().split("").splice(0, length);
        setCode(newCode);
        onChange(newCode);
        inputs.current[length - 1].focus();
    }

    return (
        <div
            className={cx(styles["confirm-verification-code-input"], className)}
        >
            <label className={styles.label}>{label}</label>
            <div
                className={cx(styles.inputs, {
                    [styles.small]: size === "small",
                })}
            >
                {code.map((num, idx) => {
                    return (
                        <input
                            className={cx({
                                [styles.error]: shouldShowErrorState,
                                [styles.small]: size === "small",
                            })}
                            data-cy={`confirm-verification-code-input-digit-${idx}`}
                            key={idx}
                            type="text"
                            inputMode="numeric"
                            maxLength={1}
                            value={num}
                            autoFocus={!code[0].length && idx === 0}
                            readOnly={loading}
                            onChange={(e) => processInput(e, idx)}
                            onKeyUp={(e) => onKeyUp(e, idx)}
                            onPaste={onPaste}
                            ref={(ref) => inputs.current.push(ref)}
                        />
                    );
                })}
            </div>
        </div>
    );
};
