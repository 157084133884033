import DOMPurify from "dompurify";

/**
 *
 * @param {String} str string containing HTML content
 *
 * @return {Object} DOM node containing converted and sanitized HTML
 *
 * * The return value is wrapped in a <div>
 */
export function convertAndSanitizeStringToHTML(str) {
    const html = convertStringToHTML(str);
    return DOMPurify.sanitize(html);
}

export function convertStringToHTML(str) {
    const htmlStaging = document.createElement("div");
    htmlStaging.innerHTML = str;
    return htmlStaging;
}

export function generateStylelessHTML(plainHtml) {
    let stylelessHTML = plainHtml.replace(
        `<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap" />`,
        ""
    );
    stylelessHTML = stylelessHTML.replace(
        `<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap" />`,
        ""
    );
    stylelessHTML = stylelessHTML.replace(
        `<link rel="stylesheet" href="https://d3i0p21okocj5n.cloudfront.net/weekly_updates/weekly-updates-ios-light.css" />`,
        ""
    );
    return stylelessHTML;
}

export function removeHTMLTags(htmlString) {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlString;
    return tempDiv.textContent || tempDiv.innerText || "";
}
