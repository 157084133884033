export class Validation {
    static canSubmitPurchase(cart) {
        return Validation.hasShippingAddress(cart);
    }

    static hasShippingAddress(cart) {
        const shippingAddress = cart?.shipping_address;
        return !!(
            shippingAddress?.address_1 &&
            shippingAddress?.city &&
            shippingAddress?.first_name &&
            shippingAddress?.last_name &&
            shippingAddress?.phone &&
            shippingAddress?.province &&
            shippingAddress?.postal_code
        );
    }
}
