import React, { useContext, useEffect } from "react";
import cx from "classnames";
import lottie from "lottie-web";
import { useHistory } from "react-router-dom";

// Components
import { ButtonPrimary } from "components/Buttons/Buttons";
import { HeaderBar } from "components/HeaderBar/HeaderBar";
import { ListIcon } from "components/ListIcon/ListIcon";
import { Panel } from "components/Panel/Panel";
import { Text } from "components/Text/Text";

// Context
import { HeaderBarContext } from "context/headerBar";

// CSS
import styles from "./letters-compose-confirmation.module.scss";

// Data
import animation from "lottie-animations/letter-send-confirmation.json";

// Localization
import { useTranslation } from "localization/localization";

// Logging
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Utils
import { generateListIconItems } from "./utils/utils";

export const LettersComposeConfirmation = (props) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * Router Hooks
     */

    const history = useHistory();

    /**
     * useRef
     */

    let animationRef = null;

    /**
     * useContext
     */

    const { setHeaderBarContent } = useContext(HeaderBarContext);

    /**
     * useEffect
     */

    useEffect(() => {
        AnalyticsLogger.logLetterComposeConfirmation();
        setHeaderBarContent({ text: t("confirmation", { ns: "common" }) });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        lottie.loadAnimation({
            animationData: animation,
            autoplay: true,
            container: animationRef,
            loop: false,
            renderer: "svg",
        });
    }, [animationRef]);

    /**
     * End Hooks
     */

    function handleGoToDashboard() {
        history.push("/");
    }

    return (
        <div className="letters-compose-container">
            <HeaderBar text={t("confirmation", { ns: "common" })} />
            <div
                className={cx(styles.lettersComposeConfirmation, "page__body")}
            >
                <Panel
                    classes={styles.container}
                    cypressTestId="letters-compose-confirmation-container"
                >
                    <div
                        className={styles.checkmark}
                        ref={(ref) => (animationRef = ref)}
                    />
                    <div className={styles.header}>
                        <Text classes={styles.title} type="h2">
                            {t("thank_you_for_your_order", { ns: "common" })}
                        </Text>
                        <Text classes={styles.subtitle}>
                            {t("heres_what_happens_next", { ns: "common" })}
                        </Text>
                    </div>
                    <ListIcon
                        classNames={{
                            container: styles.listIcon,
                            title: styles.title,
                        }}
                        items={generateListIconItems()}
                    />
                    <ButtonPrimary onClick={handleGoToDashboard}>
                        <Text>{t("continue", { ns: "common" })}</Text>
                    </ButtonPrimary>
                </Panel>
            </div>
        </div>
    );
};
