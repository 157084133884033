import React, { createContext, useState } from "react";

// Platforms
import { SandboxxRestAPI } from "../utils/sandboxx";

export const TitlesContext = createContext({});

export const TitlesProvider = (props) => {
    const { children } = props;

    /**
     * useState
     **/

    const [titles, setTitles] = useState([]);

    /**
     * End Hooks
     **/

    function fetchTitles(contact) {
        return SandboxxRestAPI.getTitles(contact, onFetchTitlesSuccess);
    }

    function onFetchTitlesSuccess(titles) {
        setTitles(titles);
    }

    const titlesContext = { fetchTitles, titles };

    return (
        <TitlesContext.Provider value={titlesContext}>
            {children}
        </TitlesContext.Provider>
    );
};
