// Utils
import { emailPattern } from "utils/regexUtils";

export class Validation {
    static isAtLeastOfLength(input, length) {
        return input.length >= length;
    }

    static isEmailAddress(email) {
        return emailPattern.test(email);
    }

    static isNumber(number) {
        return /[^0-9]/.test(number);
    }

    static isPhoneNumber(input) {
        return input.length >= 11;
    }

    static isPresent(input) {
        return !!input;
    }

    static isLessThanOrEqualToMaxLength(input, maxLength) {
        return input.length <= maxLength;
    }

    static isLessThanMaxLength(input, maxLength) {
        return input.length < maxLength;
    }
}
