import React from "react";
import cx from "classnames";

// Components
import { ErrorCircleIcon } from "components/Icons/ErrorCircleIcon";

// CSS
import styles from "./input-validatable.module.scss";

export const InputValidatable = (props) => {
    const {
        ariaLabel,
        ariaLabelledBy,
        autoComplete,
        autoFocus,
        containerClasses,
        cypressTestId,
        inputClasses,
        isDisabled,
        isError,
        isValid,
        name,
        onBlur,
        onChange,
        onFocus,
        placeholder,
        setInputRef,
        type,
        value,
    } = props;

    function renderIcon() {
        if (isError) {
            return (
                <div className={styles.errorIcon}>
                    <ErrorCircleIcon />
                </div>
            );
        }
        if (isValid) {
            return (
                <div className={styles.successIcon}>
                    <span>L</span>
                </div>
            );
        }
    }

    return (
        <div
            className={cx(
                styles["input-validatable-container"],
                containerClasses
            )}
        >
            <input
                autoComplete={autoComplete}
                aria-label={ariaLabel}
                aria-labelledby={ariaLabelledBy}
                autoFocus={autoFocus || false}
                className={cx(
                    styles["input-validatable"],
                    inputClasses,
                    "input",
                    {
                        [styles["input-validatable--error"]]: isError,
                    }
                )}
                data-cy={cypressTestId}
                disabled={isDisabled}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                onFocus={onFocus}
                placeholder={placeholder}
                ref={(ref) => setInputRef && setInputRef(ref)}
                type={type || "text"}
                value={value}
            />
            {renderIcon()}
        </div>
    );
};

InputValidatable.defaultProps = {
    containerClasses: "",
    inputClasses: "",
    isDisabled: false,
};
