export function calculateCompletedDocuments(documents) {
    let completed = 0;
    documents.forEach((document) =>
        document.completed ? (completed += 1) : null
    );
    return completed;
}

export function isOnMusterLookupShipDatePage() {
    return (
        window.location.pathname === "/muster/lookup/ship-date" ||
        window.location.pathname === "/waypoints/lookup/ship-date"
    );
}
