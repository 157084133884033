// Enums
import { PersonaEnum } from "enums/PersonaEnum";

// Models
import { Contact } from "../models/contacts/Contact";

// Utils
import { titleCase } from "./textUtils";
import { imageUrl } from "./urlUtils";

// Validation
import { LettersValidation } from "../validation/lettersValidation";

/* istanbul ignore next */
export function generateLetterImageUrl(photo_url) {
    const imagePath = photo_url || "pic/no-photo-added.png";
    return `${imageUrl()}${imagePath}`;
}

/* istanbul ignore next */
export function generateBranchPrettyName(branchId) {
    switch (branchId) {
        case "AIRFORCE":
        case "AIR_FORCE":
            return "Air Force";
        case "ARMY":
            return "Army";
        case "COASTGUARD":
            return "Coast Guard";
        case "MARINES":
            return "Marines";
        case "NAVY":
            return "Navy";
        case "SPACEFORCE":
            return "Space Force";
        case "UNKNOWN":
            return "No Branch";
        default:
            return titleCase(branchId).replace(/_/g, " ");
    }
}

/**
 * @returns {Contact} containing the user's info
 */
export function generateContactFromStorage() {
    const recipientFromStorage = localStorage.getItem(
        "sandboxxMessageRecipient"
    );
    // Before we pass the recipient to JSON.parse() we need to ensure this value exists
    const parsedRecipient =
        LettersValidation.validateRecipientExistence(recipientFromStorage) &&
        JSON.parse(recipientFromStorage);

    return parsedRecipient && new Contact(parsedRecipient);
}

/**
 * @param {Boolean} shouldReverseFullName determines whether full name order should be reversed
 * @param {Object} contactStaging contains contact data that is being modified in form
 * @returns {String} containing the formatted fullName
 */
export function generateFullName(
    { firstName, lastName, title },
    shouldReverseFullName
) {
    const titleText = title ? `${title} ` : "";
    if (shouldReverseFullName) {
        return `${titleText}${lastName}, ${firstName}`;
    } else {
        return `${titleText}${firstName} ${lastName}`;
    }
}

/* istanbul ignore next */
export function generateIntentionPrettyName(intention) {
    switch (intention) {
        case PersonaEnum.CAREER:
            return "Build my military career";
        case PersonaEnum.LETTER_WRITER:
            return "Send Letters";
        case PersonaEnum.RECRUIT:
            return "Prepare for Basic";
        case PersonaEnum.RECRUITER:
            return "Manage Recruits";
        default:
            return "Not Specified";
    }
}

/* istanbul ignore next */
export function generateMilitaryStatusPrettyName(status) {
    switch (status) {
        case "ACTIVE_DUTY":
            return "Active Duty";
        case "RESERVE":
            return "Reserve";
        case "NATIONAL_GUARD":
            return "National Guard";
        default:
            return titleCase(status).replace(/_/g, " ");
    }
}

/* istanbul ignore next */
export function generateRelationshipPrettyName(relationship) {
    switch (relationship) {
        case "MOM":
            return "Mom";
        case "DAD":
            return "Dad";
        case "SPOUSE":
            return "Spouse";
        case "FRIEND":
            return "Friend";
        case "RECRUITER":
            return "Recruiter";
        case "OTHER":
            return "Other";
        default:
            return titleCase(relationship).replace(/_/g, " ");
    }
}

export function generateShipDateObject(basicTrainingShipDate) {
    if (!basicTrainingShipDate) {
        return {};
    } else {
        const shipDateArray = basicTrainingShipDate?.split("-");
        return {
            day: shipDateArray[2],
            month: shipDateArray[1],
            year: shipDateArray[0],
        };
    }
}

/**
 * @param {Object} contactStaging contains contact data that is being modified in form
 * @returns {Boolean} which determines the value of `shouldReverseFullName`
 */
export function generateShouldReverseFullName({ fullName }) {
    if (fullName?.includes(",")) return true;
    return false;
}

/* istanbul ignore next */
export function isAddressProperty(prop) {
    return (
        prop === "city" ||
        prop === "country" ||
        prop === "line1" ||
        prop === "line2" ||
        prop === "state" ||
        prop === "zipcode"
    );
}
