import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Components
import { LetterPreviewStatusBarNode } from "./components/LetterPreviewStatusBarNode/LetterPreviewStatusBarNode";
import { Skeleton } from "@mui/material";

// CSS
import styles from "./letter-preview-status-bar.module.scss";

// Enums
import { NodeVisualStatus } from "./components/LetterPreviewStatusBarNode/enums/LetterPreviewStatusBarNodeState";
import { SentLetterVisualStatus } from "enums/letters/SentLetterVisualStatus";
import { ShippingCarrier } from "enums/letters/ShippingCarrier";

// Localization
import { useTranslation } from "localization/localization";

// Models
import { ReplyLetter } from "models/letters/ReplyLetter";
import { SentLetter } from "models/letters/SentLetter";

export const LetterPreviewStatusBar = ({ className, isLoading, letter }) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * End Hooks
     */

    // We can only provide tracking and delivery confirmation for Fedex
    const isFedex = letter?.shippingCarrier === ShippingCarrier.FEDEX;

    if (isLoading) {
        return (
            <div
                className={cx(
                    styles.letterPreviewStatusBar,
                    styles["letterPreviewStatusBar--loading"],
                    className
                )}
            >
                <Skeleton
                    className={cx(
                        styles.skeleton,
                        styles["skeleton--node"],
                        styles["skeleton--node--left"],
                        "skeleton"
                    )}
                />
                <Skeleton
                    className={cx(styles.skeleton, "skeleton")}
                    height="6px"
                    width="100%"
                />
                <Skeleton
                    className={cx(
                        styles.skeleton,
                        styles["skeleton--node"],
                        styles["skeleton--node--right"],
                        "skeleton"
                    )}
                />
            </div>
        );
    }
    if (letter?.visualStatus === SentLetterVisualStatus.DELIVERED) {
        return (
            <div className={cx(styles.letterPreviewStatusBar, className)}>
                <div className={styles.bar}>
                    <div
                        className={styles.progress}
                        style={{
                            width: "100%",
                        }}
                    />
                </div>
                <LetterPreviewStatusBarNode
                    text={t("letter_preview.received", { ns: "components" })}
                    visualStatus={NodeVisualStatus.SUCCESS}
                />
                <LetterPreviewStatusBarNode
                    text={t("letter_preview.shipped", { ns: "components" })}
                    visualStatus={NodeVisualStatus.SUCCESS}
                />
                <LetterPreviewStatusBarNode
                    text={t("letter_preview.delivered", { ns: "components" })}
                    visualStatus={NodeVisualStatus.SUCCESS}
                />
            </div>
        );
    }
    if (letter?.visualStatus === SentLetterVisualStatus.RECEIVED) {
        return (
            <div className={cx(styles.letterPreviewStatusBar, className)}>
                <div className={styles.bar}>
                    <div
                        className={styles.progress}
                        style={{
                            width: 0,
                        }}
                    />
                </div>
                <LetterPreviewStatusBarNode
                    text={t("letter_preview.received", { ns: "components" })}
                    visualStatus={NodeVisualStatus.SUCCESS}
                />
                <LetterPreviewStatusBarNode
                    text={t("letter_preview.shipped", { ns: "components" })}
                    visualStatus={NodeVisualStatus.EMPTY}
                />
                {isFedex && (
                    <LetterPreviewStatusBarNode
                        shouldHide={!isFedex}
                        text={t("letter_preview.delivered", {
                            ns: "components",
                        })}
                        visualStatus={NodeVisualStatus.EMPTY}
                    />
                )}
            </div>
        );
    }
    if (letter?.visualStatus === SentLetterVisualStatus.SHIPPED) {
        return (
            <div className={cx(styles.letterPreviewStatusBar, className)}>
                <div className={styles.bar}>
                    <div
                        className={styles.progress}
                        style={{
                            width: isFedex ? "50%" : "100%",
                        }}
                    />
                </div>
                <LetterPreviewStatusBarNode
                    text={t("letter_preview.received", { ns: "components" })}
                    visualStatus={NodeVisualStatus.SUCCESS}
                />
                <LetterPreviewStatusBarNode
                    text={t("letter_preview.shipped", { ns: "components" })}
                    visualStatus={NodeVisualStatus.SUCCESS}
                />
                {isFedex && (
                    <LetterPreviewStatusBarNode
                        shouldHide={!isFedex}
                        text={t("letter_preview.delivered", {
                            ns: "components",
                        })}
                        visualStatus={NodeVisualStatus.EMPTY}
                    />
                )}
            </div>
        );
    }
    if (letter?.visualStatus === SentLetterVisualStatus.IN_REVIEW) {
        return (
            <div className={cx(styles.letterPreviewStatusBar, className)}>
                <div className={styles.bar}>
                    <div
                        className={styles.progress}
                        style={{
                            width: isFedex ? "50%" : "100%",
                        }}
                    />
                </div>
                <LetterPreviewStatusBarNode
                    text={t("letter_preview.received", { ns: "components" })}
                    visualStatus={NodeVisualStatus.SUCCESS}
                />
                <LetterPreviewStatusBarNode
                    text={t("letter_preview.in_review", { ns: "components" })}
                    visualStatus={NodeVisualStatus.INFO}
                />
                {isFedex && (
                    <LetterPreviewStatusBarNode
                        shouldHide={!isFedex}
                        text={t("letter_preview.delivered", {
                            ns: "components",
                        })}
                        visualStatus={NodeVisualStatus.EMPTY}
                    />
                )}
            </div>
        );
    }
    if (letter?.visualStatus === SentLetterVisualStatus.ON_HOLD) {
        return (
            <div className={cx(styles.letterPreviewStatusBar, className)}>
                <div className={styles.bar}>
                    <div
                        className={styles.progress}
                        style={{
                            width: isFedex ? "50%" : "100%",
                        }}
                    />
                </div>
                <LetterPreviewStatusBarNode
                    text={t("letter_preview.received", { ns: "components" })}
                    visualStatus={NodeVisualStatus.SUCCESS}
                />
                <LetterPreviewStatusBarNode
                    text={t("letter_preview.on_hold", { ns: "components" })}
                    visualStatus={NodeVisualStatus.ERROR}
                />
                {isFedex && (
                    <LetterPreviewStatusBarNode
                        shouldHide={!isFedex}
                        text={t("letter_preview.delivered", {
                            ns: "components",
                        })}
                        visualStatus={NodeVisualStatus.EMPTY}
                    />
                )}
            </div>
        );
    }
    return null;
};

LetterPreviewStatusBar.propTypes = {
    className: PropTypes.string,
    isLoading: PropTypes.bool,
    letter: PropTypes.oneOfType([
        PropTypes.instanceOf(ReplyLetter),
        PropTypes.instanceOf(SentLetter),
    ]),
};
