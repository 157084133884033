import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// Components
import { Skeleton } from "@mui/material";
import { Text } from "components/Text/Text";

// CSS
import styles from "./cart-items-item.module.scss";

// Localization
import { useTranslation } from "localization/localization";

// Utils
import { convertWholeNumberToDollars } from "utils/cartUtils";

export const CartItemsItem = ({ isLoading, item, isUpdatingCart }) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * End Hooks
     */

    if (isLoading || isUpdatingCart) {
        return (
            <div className={styles.cartItemsItem}>
                <Skeleton className={styles.image} height="100%" />
                <div className={styles.content}>
                    <div className={styles.description}>
                        <Skeleton variant="text" width={150} />
                        <Skeleton variant="text" width={75} />
                    </div>
                    <div className={styles.price}>
                        <Skeleton variant="text" width={50} />
                    </div>
                </div>
            </div>
        );
    } else {
        const hasDiscount = item.discount_total > 0;
        const subtotalWithDiscount = item.subtotal - item.discount_total;
        return (
            <div className={styles.cartItemsItem}>
                <div
                    className={styles.image}
                    style={{
                        backgroundImage: `url(${item.thumbnail})`,
                    }}
                />
                <div className={styles.content}>
                    <div className={styles.label}>
                        <Text classes={styles.title} isBold>
                            {item.title}
                        </Text>
                        <Text classes={styles.quantity}>
                            {t("qty", { ns: "common" })}: {item.quantity} @{" "}
                            {convertWholeNumberToDollars(item.unit_price)}
                        </Text>
                    </div>
                    <div className={styles.value}>
                        <Text
                            classes={cx(styles.subtotal, {
                                [styles["subtotal--hasDiscount"]]: hasDiscount,
                            })}
                            isBold
                        >
                            {convertWholeNumberToDollars(item.subtotal)}
                        </Text>
                        {hasDiscount && (
                            <Text classes={styles.total} isBold>
                                {convertWholeNumberToDollars(
                                    subtotalWithDiscount
                                )}
                            </Text>
                        )}
                    </div>
                </div>
            </div>
        );
    }
};

CartItemsItem.propTypes = {
    isLoading: PropTypes.bool,
    item: PropTypes.object,
    isUpdatingCart: PropTypes.bool,
};
