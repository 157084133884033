import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// Components
import { Banner } from "components/Banner/Banner";
import { ButtonInvisible, ButtonPrimary } from "components/Buttons/Buttons";
import { PhoneNumberInput } from "components/Inputs/PhoneNumberInput/PhoneNumberInput";
import { DropdownState } from "components/Dropdowns/DropdownState/DropdownState";
import { Text } from "components/Text/Text";
import { TextFieldValidated } from "components/Inputs/TextFieldValidated/TextFieldValidated";

// CSS
import styles from "./cart-shipping-address-form.module.scss";

// Hooks
import { useCartShippingAddressForm } from "./hooks/useCartShippingAddressForm";

// Localization
import { useTranslation } from "localization/localization";

export const CartShippingAddressForm = ({
    setCartCurrentSection,
    setHeaderText,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    const {
        handleChange,
        handleChangePhoneNumber,
        handleClearPhoneNumber,
        handleGoBack,
        handleSubmit,
        hasValidProfileAddress,
        hasValidShippingAddress,
        isSubmitting,
        isUpdatingCart,
        populateShippingAddress,
        shippingAddress,
    } = useCartShippingAddressForm({
        setCartCurrentSection,
        setHeaderText,
    });

    /**
     * End Hooks
     */

    return (
        <div className={styles.cartShippingAddressForm} onSubmit={handleSubmit}>
            {hasValidProfileAddress && (
                <Banner
                    classes={styles.populateAddressOption}
                    config={{
                        isActive: true,
                        shouldShowChevron: true,
                        shouldShowIcon: true,
                    }}
                    onClick={populateShippingAddress}
                >
                    <Text>
                        {t("cart.use_profile_address", { ns: "components" })}
                    </Text>
                </Banner>
            )}
            <TextFieldValidated
                className={cx(styles.input, styles["input--firstName"])}
                cypressTestId="cart-shipping-address-form-first-name"
                isValid={shippingAddress.firstName}
                label={t("first_name", { ns: "common" })}
                name="firstName"
                onChange={handleChange}
                required
                size="small"
                type="text"
                value={shippingAddress.firstName}
            />
            <TextFieldValidated
                className={cx(styles.input, styles["input--lastName"])}
                cypressTestId="cart-shipping-address-form-last-name"
                isValid={shippingAddress.lastName}
                label={t("last_name", { ns: "common" })}
                name="lastName"
                onChange={handleChange}
                required
                size="small"
                type="text"
                value={shippingAddress.lastName}
            />
            <TextFieldValidated
                className={cx(styles.input, styles["input--line1"])}
                cypressTestId="cart-shipping-address-form-line-1"
                isValid={shippingAddress.line1}
                label={t("address_line_1", { ns: "common" })}
                name="line1"
                onChange={handleChange}
                required
                size="small"
                type="text"
                value={shippingAddress.line1}
            />
            <TextFieldValidated
                className={cx(styles.input, styles["input--line2"])}
                cypressTestId="cart-shipping-address-form-line-2"
                isValid={shippingAddress.line2}
                label={t("address_line_2", { ns: "common" })}
                name="line2"
                onChange={handleChange}
                size="small"
                type="text"
                value={shippingAddress.line2}
            />
            <TextFieldValidated
                className={cx(styles.input, styles["input--city"])}
                cypressTestId="cart-shipping-address-form-city"
                isValid={shippingAddress.city}
                label={t("city", { ns: "common" })}
                name="city"
                onChange={handleChange}
                required
                size="small"
                type="text"
                value={shippingAddress.city}
            />
            <DropdownState
                className={cx(styles.input, styles["input--province"])}
                cypressTestId="cart-shipping-address-form-province"
                label={`${t("state", { ns: "common" })} *`}
                name="province"
                onChange={handleChange}
                size="small"
                value={shippingAddress.province}
            />
            <TextFieldValidated
                className={cx(styles.input, styles["input--postalCode"])}
                cypressTestId="cart-shipping-address-form-postal-code"
                isValid={shippingAddress.postalCode}
                label={t("zip_code", { ns: "common" })}
                name="postalCode"
                onChange={handleChange}
                required
                size="small"
                type="text"
                value={shippingAddress.postalCode}
            />
            <PhoneNumberInput
                containerClasses={cx(styles.input, styles["input--phone"])}
                onChange={handleChangePhoneNumber}
                onClear={handleClearPhoneNumber}
                phoneNumber={shippingAddress.phone}
                placeholder={`${t("phone_number", { ns: "common" })} *`}
                shouldHideLabel={true}
            />

            <div className={styles.buttons}>
                <ButtonPrimary
                    classes={styles.button}
                    config={{ shouldMaintainDimensions: true }}
                    isDisabled={
                        isUpdatingCart ||
                        isSubmitting ||
                        !hasValidShippingAddress
                    }
                    isLoading={isSubmitting}
                    onClick={handleSubmit}
                    type="submit"
                >
                    <Text>{t("cart.save_address", { ns: "components" })}</Text>
                </ButtonPrimary>
                <ButtonInvisible onClick={handleGoBack}>
                    <Text>{t("back", { ns: "common" })}</Text>
                </ButtonInvisible>
            </div>
        </div>
    );
};

CartShippingAddressForm.propTypes = {
    setCartCurrentSection: PropTypes.func.isRequired,
    setHeaderText: PropTypes.func.isRequired,
};
