import React, { createContext, useState } from "react";

// Context
export const PhoneVerificationContext = createContext({});

export const PhoneVerificationProvider = (props) => {
    const { children } = props;

    /**
     * useState
     **/

    const [phoneVerificationModalSetting, setPhoneVerificationModalSetting] =
        useState("letters");
    const [showPhoneVerificationModal, setShowPhoneVerificationModal] =
        useState(false);

    /**
     * End Hooks
     **/

    function togglePhoneVerificationModal(bool, setting) {
        setPhoneVerificationModalSetting((prev) => setting || prev);
        setShowPhoneVerificationModal((prev) => bool || !prev);
    }

    const phoneVerificationContext = {
        phoneVerificationModalSetting,
        showPhoneVerificationModal,
        togglePhoneVerificationModal,
    };

    return (
        <PhoneVerificationContext.Provider value={phoneVerificationContext}>
            {children}
        </PhoneVerificationContext.Provider>
    );
};
