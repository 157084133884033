import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// Components
import { CheckmarkSuccessIcon } from "components/Icons/CheckmarkSuccessIcon";

// CSS
import styles from "./progress-bar-circle.module.scss";

export const ProgressBarCircle = ({ completed, total }) => {
    const progressBarWidth = (completed * 100) / total;
    return (
        <div
            className={cx(styles.progressBarCircle, {
                [styles["progressBarCircle--complete"]]: completed === total,
                [styles["progressBarCircle--empty"]]: completed === 0,
            })}
            style={{
                background: `radial-gradient(closest-side, #f3f2ef 79%, transparent 80% 100%), conic-gradient(#2B536A ${progressBarWidth}%, #AABAC3 0)`,
            }}
        >
            {completed === total && (
                <div className={styles.successIcon}>
                    <CheckmarkSuccessIcon
                        color="#a9bda9"
                        height="38px"
                        width="38px"
                    />
                </div>
            )}
        </div>
    );
};

ProgressBarCircle.propTypes = {
    completed: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
};
