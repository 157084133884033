/**
 * Detects the user's language based on the browser's language settings.
 * If this function throws an error, default to english ("en").
 *
 * @returns {String} the user's language
 */
export function detectUserLanguage() {
    try {
        return navigator.languages && navigator.languages.length
            ? navigator.languages[0]
            : navigator.language;
    } catch (error) {
        return "en";
    }
}
