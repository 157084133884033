import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// Components
import { ButtonInvisible, ButtonPrimary } from "components/Buttons/Buttons";
import { PhoneNumberInput } from "components/Inputs/PhoneNumberInput/PhoneNumberInput";
import { Text } from "components/Text/Text";

// CSS
import signInStyles from "../../../sign-in.module.scss";
import signUpPhoneStyles from "./sign-up-phone.module.scss";

// Hooks
import { useSignUpPhone } from "./hooks/useSignUpPhone";

// Localization
import { useTranslation } from "localization/localization";

export const SignUpPhone = ({
    handleChangePhoneNumber,
    handleClearPhoneNumber,
    handleLoadingToggle,
    loading,
    setCurrentSection,
    setUserInfo,
    userInfo,
}) => {
    const { phoneNumber } = userInfo;

    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    const { handleSkip, handleSubmit } = useSignUpPhone({
        handleLoadingToggle,
        setCurrentSection,
        setUserInfo,
        userInfo,
    });

    /**
     * End Hooks
     */

    return (
        <div
            className={cx(
                signInStyles.content,
                signUpPhoneStyles["sign-up-phone"]
            )}
        >
            <div className={signInStyles.top}>
                <div
                    className={cx(
                        signInStyles.header,
                        signUpPhoneStyles.header
                    )}
                >
                    <span
                        className={signInStyles.text}
                        data-cy="sign-up-phone-header-text"
                    >
                        {t("sign_up_phone.title", { ns: "onboarding" })}
                    </span>
                    <div className={signInStyles["text-secondary"]}>
                        {t("sign_up_phone.subtitle", { ns: "onboarding" })}
                    </div>
                </div>
                <form
                    className={cx(
                        signInStyles.form,
                        signUpPhoneStyles.form,
                        "form"
                    )}
                    onSubmit={handleSubmit}
                >
                    <PhoneNumberInput
                        autoFocus={true}
                        containerClasses={signUpPhoneStyles.input}
                        inputClasses={cx(signInStyles.input)}
                        onChange={handleChangePhoneNumber}
                        onClear={handleClearPhoneNumber}
                        phoneNumber={phoneNumber}
                        placeholder={t("enter_phone_number", { ns: "common" })}
                        shouldHideLabel={true}
                    />
                    <ButtonPrimary
                        classes={cx(
                            signInStyles.button,
                            signUpPhoneStyles.button
                        )}
                        isLoading={loading.phone}
                        onClick={handleSubmit}
                        type="submit"
                    >
                        <Text>
                            {t("send_confirmation_code", { ns: "common" })}
                        </Text>
                    </ButtonPrimary>
                    <ButtonInvisible
                        classes={cx(
                            signInStyles.button,
                            signUpPhoneStyles.button
                        )}
                        cypressTestId="sign-up-phone-skip-button"
                        onClick={handleSkip}
                    >
                        <Text>{t("skip", { ns: "common" })}</Text>
                    </ButtonInvisible>
                    <span className={signUpPhoneStyles.disclaimer}>
                        {t("sign_up_phone.disclaimer", { ns: "onboarding" })}
                    </span>
                </form>
            </div>
        </div>
    );
};

SignUpPhone.propTypes = {
    handleChangePhoneNumber: PropTypes.func.isRequired,
    handleLoadingToggle: PropTypes.func.isRequired,
    loading: PropTypes.object.isRequired,
    setCurrentSection: PropTypes.func.isRequired,
    setUserInfo: PropTypes.func.isRequired,
    userInfo: PropTypes.object.isRequired,
};
