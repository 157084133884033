import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// Components
import { Text } from "components/Text/Text";

// CSS
import styles from "./character-counter.module.scss";

// Validation
import { Validation } from "validation/validation";

export const CharacterCounter = (props) => {
    const { className, characterLimit, value } = props;

    /**
     * Constants
     */

    const characterCount = value?.length;
    const isUnderCharacterLimit = Validation.isLessThanMaxLength(
        value,
        characterLimit
    );

    /**
     * End Constants
     */

    return (
        <div
            className={cx(styles["character-counter"], className, {
                [styles["character-counter--warning"]]: !isUnderCharacterLimit,
            })}
        >
            <Text>
                {characterCount}/{characterLimit}
            </Text>
        </div>
    );
};

CharacterCounter.defaultProps = {
    className: "",
    value: "",
};

CharacterCounter.propTypes = {
    className: PropTypes.string,
    characterLimit: PropTypes.number.isRequired,
    value: PropTypes.string,
};
