import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// Components
import { Text } from "components/Text/Text";

// CSS
import styles from "./graduation-progress-bar.module.scss";

export const GraduationProgressBar = (props) => {
    const { className, weeksToGraduation, totalWeeks } = props;

    function renderGraphic() {
        const markers = [];
        for (let i = 0; i < totalWeeks; i++) {
            const filled = i < totalWeeks - weeksToGraduation;

            markers.push(
                <div
                    className={cx(styles.gradMarker, {
                        [styles["gradMarker--filled"]]: filled,
                    })}
                    key={i}
                ></div>
            );
        }
        return markers;
    }

    return (
        <div className={cx(styles.graduationCountdown, className)}>
            <Text classes={styles.headerText}>
                {weeksToGraduation} Weeks Until Graduation
            </Text>
            <div className={styles.markerContainer}>{renderGraphic()}</div>
        </div>
    );
};

GraduationProgressBar.defaultProps = {
    totalWeeks: 12,
    weeksToGraduation: 12,
};

GraduationProgressBar.propTypes = {
    totalWeeks: PropTypes.number.isRequired,
    weeksToGraduation: PropTypes.number.isRequired,
};
