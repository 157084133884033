import React, { useEffect } from "react";
import PropTypes from "prop-types";

// Components
import { ButtonInvisible, ButtonPrimary } from "components/Buttons/Buttons";

// CSS
import styles from "./muster-recruit-invite-success.module.scss";

// Enums
import { MusterRecruitInviteModalNavigation } from "../../enums/MusterRecruitInviteModalNavigation";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Media
import chatsImageSrc from "media/muster/chats.png";

export const MusterRecruitInviteModalSuccess = (props) => {
    const {
        handleModalClose,
        inviteEmails,
        setCurrentSection,
        setHeaderSubText,
        setHeaderText,
        setInviteEmails,
    } = props;

    /**
     * useEffect
     */

    useEffect(() => {
        setHeaderText("");
        setHeaderSubText("");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * End Hooks
     */

    const numberOfSuccessfullyInvitedEmails =
        inviteEmails.filter(Boolean).length;

    function renderBody() {
        const userText =
            numberOfSuccessfullyInvitedEmails === 1 ? "recruit" : "recruits";
        return (
            <div
                className={styles.body}
                data-cy="muster-recruit-invite-success-body"
            >
                <img
                    alt="speech bubbles"
                    className={styles.image}
                    src={chatsImageSrc}
                />
                <span className={styles.successText}>
                    You have successfully invited{" "}
                    {numberOfSuccessfullyInvitedEmails} {userText} to Muster!
                </span>
            </div>
        );
    }

    function renderButtons() {
        return (
            <div className={styles.buttons}>
                <ButtonPrimary
                    classes={styles.button}
                    cypressTestId="muster-recruit-invite-success-continue-button"
                    onClick={() => {
                        AnalyticsLogger.logMagicLinkContinueClicked();
                        handleModalClose();
                    }}
                    text="Continue"
                    type="button"
                />
                <ButtonInvisible
                    classes={styles.button}
                    cypressTestId="muster-recruit-invite-success-invite-more-button"
                    onClick={() => {
                        setCurrentSection(
                            MusterRecruitInviteModalNavigation.SUBMIT
                        );
                        setInviteEmails(["", "", ""]);
                        AnalyticsLogger.logMagicLinkInviteMore();
                    }}
                    text="Invite More"
                    type="button"
                />
            </div>
        );
    }

    return (
        <div className={styles["muster-recruit-invite-modal-success"]}>
            {renderBody()}
            {renderButtons()}
        </div>
    );
};

MusterRecruitInviteModalSuccess.propTypes = {
    handleModalClose: PropTypes.func.isRequired,
    inviteEmails: PropTypes.array.isRequired,
    setCurrentSection: PropTypes.func.isRequired,
    setInviteEmails: PropTypes.func.isRequired,
    setHeaderSubText: PropTypes.func.isRequired,
    setHeaderText: PropTypes.func.isRequired,
};
