export function CheckmarkSuccessIcon({ className, color, height, width }) {
    return (
        <svg
            className={className}
            fill="none"
            height={width}
            viewBox="0 0 24 24"
            width={height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM17.7058 8.2927C17.3155 7.90243 16.682 7.90243 16.2917 8.2927L9.99925 14.5851L7.7068 12.2927C7.31654 11.9024 6.68296 11.9024 6.2927 12.2927C5.90243 12.683 5.90243 13.3165 6.2927 13.7068L9.2927 16.7068C9.48425 16.8964 9.73745 16.999 9.99925 16.9992C10.2621 16.999 10.5159 16.8967 10.7058 16.7068L17.7058 9.7068C18.0961 9.31654 18.0961 8.68296 17.7058 8.2927Z"
                fill={color}
            />
        </svg>
    );
}

CheckmarkSuccessIcon.defaultProps = {
    color: "#285929",
    height: "24px",
    width: "24px",
};
