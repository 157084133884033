import { convertStringToCamelCase } from "./textUtils";

export function convertObjectKeysToCamelCase(obj) {
    if (isObject(obj)) {
        return Object.keys(obj).reduce((newObj, key) => {
            return {
                ...newObj,
                [convertStringToCamelCase(key)]: convertObjectKeysToCamelCase(
                    obj[key]
                ),
            };
        }, {});
    } else if (Array.isArray(obj)) {
        return obj.map((i) => {
            return convertObjectKeysToCamelCase(i);
        });
    }
    return obj;
}

/* istanbul ignore next */
export function getLastItemInArray(arr) {
    return arr.slice(-1)[0];
}

/* istanbul ignore next */
export function isObject(obj) {
    return (
        obj === Object(obj) && !Array.isArray(obj) && typeof obj !== "function"
    );
}

/* istanbul ignore next */
export function trimArrayAtIndex(arr, i) {
    return arr.slice(0, i + 1);
}
