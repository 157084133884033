import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// Components
import { ButtonPrimary, ButtonSecondary } from "components/Buttons/Buttons";
import { HandHoldingLettersIllustration } from "components/Illustrations/HandHoldingLettersIllustration/HandHoldingLettersIllustration";
import { Panel } from "../../../../components/Panel/Panel";
import { Text } from "components/Text/Text";

// CSS
import styles from "./referrals-share-link.module.scss";

// Hooks
import { useReferralsShareLink } from "./hooks/useReferralsShareLink";

// Localization
import { useTranslation } from "localization/localization";

export const ReferralsShareLink = ({
    earnedLetters,
    setShouldShowRedeemCodeModal,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    const { handleCopyReferralLinkToClipboard, referralLink } =
        useReferralsShareLink();

    /**
     * End Hooks
     */

    function renderEarnedLetterCount() {
        return (
            earnedLetters !== null && (
                <div className={styles.count}>
                    <Text isBold classes={styles.description}>
                        {t("earned", { ns: "common" })}
                    </Text>
                    <Text classes={styles.amount}>
                        {t("letter_ip", {
                            count: earnedLetters,
                            ns: "common",
                        })}
                    </Text>
                </div>
            )
        );
    }

    function renderInput() {
        return (
            <div className={styles.link}>
                <div className={styles.wrapper}>
                    <input
                        className={cx(styles.input, "input")}
                        data-cy="referrals-share-link-input"
                        readOnly
                        type="text"
                        value={referralLink}
                    />
                    <ButtonPrimary
                        classes={styles.button}
                        cypressTestId="referrals-share-link-button"
                        onClick={handleCopyReferralLinkToClipboard}
                    >
                        <Text>{t("copy", { ns: "common" })}</Text>
                    </ButtonPrimary>
                </div>
            </div>
        );
    }

    function renderText() {
        return (
            <div className={styles.text}>
                <div className={styles.header}>
                    <Text>{t("share_link.title", { ns: "referrals" })}</Text>
                </div>
                <div className={styles.body}>
                    <Text type="body">
                        {t("share_link.subtitle", { ns: "referrals" })}
                    </Text>
                </div>
            </div>
        );
    }

    return (
        <Panel classes={styles.referralsShareLink}>
            {renderEarnedLetterCount()}
            <div className={styles.content}>
                {renderText()}
                {renderInput()}
                <ButtonSecondary
                    onClick={() => setShouldShowRedeemCodeModal(true)}
                >
                    <Text>{t("redeem_code", { ns: "common" })}</Text>
                </ButtonSecondary>
            </div>
            <div className={styles.image}>
                <HandHoldingLettersIllustration
                    className={styles.illustration}
                />
            </div>
        </Panel>
    );
};

ReferralsShareLink.propTypes = {
    earnedLetters: PropTypes.number,
    setShouldShowRedeemCodeModal: PropTypes.func.isRequired,
};
