import { useEffect, useMemo, useState } from "react";
import Fuse from "fuse.js";

// Hooks
import { useDebounceValue } from "hooks/debounce/useDebounceValue";

// Enums
import { ShipDateGroupsEnum } from "../../../../enums/ShipDateGroupsEnum";

export const useMusterRecruitsListModal = (props) => {
    const { contactList } = props;

    /**
     * Constants
     */
    const group1To7 = useMemo(
        () =>
            contactList.filter(
                (contact) => contact?.label === ShipDateGroupsEnum.GROUP_1_TO_7
            ),
        [contactList]
    );
    const group8To30 = useMemo(
        () =>
            contactList.filter(
                (contact) => contact?.label === ShipDateGroupsEnum.GROUP_8_TO_30
            ),
        [contactList]
    );
    const group61Plus = useMemo(
        () =>
            contactList.filter(
                (contact) => contact?.label === ShipDateGroupsEnum.GROUP_61_PLUS
            ),
        [contactList]
    );
    const groupNoShipDate = useMemo(
        () =>
            contactList.filter(
                (contact) => contact?.label === ShipDateGroupsEnum.NO_SHIP_DATE
            ),
        [contactList]
    );

    const groupShipped = useMemo(
        () =>
            contactList.filter(
                (contact) => contact?.label === ShipDateGroupsEnum.SHIPPED
            ),
        [contactList]
    );

    /**
     * useState
     */

    const [fuse, setFuse] = useState(
        new Fuse(contactList, { keys: ["firstName", "fullName", "lastName"] })
    );
    const [isSearching, setIsSearching] = useState(false);
    const [results, setResults] = useState([]);
    const [term, setTerm] = useState("");

    /**
     * useDebounce
     **/

    const debouncedSearchTerm = useDebounceValue(term, 250);

    /**
     * useEffect
     **/

    useEffect(() => {
        if (contactList?.length) {
            setFuse(
                new Fuse(contactList, {
                    keys: ["firstName", "fullName", "lastName"],
                    threshold: 0.3,
                })
            );
            setResults(contactList);
        }
    }, [contactList]);

    useEffect(() => {
        if (!debouncedSearchTerm) {
            setResults(contactList);
        } else {
            setResults(
                fuse.search(debouncedSearchTerm).map(({ item }) => item)
            );
        }

        if (debouncedSearchTerm.length !== 0) {
            setIsSearching(true);
        } else {
            setIsSearching(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchTerm]);

    /**
     * End Hooks & Constants
     */

    function clearSearch() {
        setResults(contactList);
        setTerm("");
    }

    function handleSearchInputChange(e) {
        const { value } = e.target;
        setTerm(value);
    }

    return {
        clearSearch,
        debouncedSearchTerm,
        group1To7,
        group8To30,
        group61Plus,
        groupNoShipDate,
        groupShipped,
        handleSearchInputChange,
        isSearching,
        results,
    };
};
