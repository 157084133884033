export const stateOptionsData = [
    {
        display: "AA",
        name: "Armed Forces Americas",
        isState: false,
        value: "AA",
    },
    { display: "AE", name: "Armed Forces Europe", isState: false, value: "AE" },
    {
        display: "AP",
        name: "Armed Forces Pacific",
        isState: false,
        value: "AP",
    },
    { display: "AL", name: "Alabama", isState: false, value: "AL" },
    { display: "AK", name: "Alaska", isState: true, value: "AK" },
    { display: "AS", name: "American Samoa", isState: false, value: "AS" },
    { display: "AZ", name: "Arizona", isState: true, value: "AZ" },
    { display: "AR", name: "Arkansas", isState: true, value: "AR" },
    { display: "CA", name: "California", isState: true, value: "CA" },
    { display: "CO", name: "Colorado", isState: true, value: "CO" },
    { display: "CT", name: "Connecticut", isState: true, value: "CT" },
    { display: "DE", name: "Delaware", isState: true, value: "DE" },
    { display: "DC", name: "District of Columbia", isState: true, value: "DC" },
    { display: "FL", name: "Florida", isState: true, value: "FL" },
    { display: "GA", name: "Georgia", isState: true, value: "GA" },
    { display: "GU", name: "Guam", isState: true, value: "GU" },
    { display: "HI", name: "Hawaii", isState: true, value: "HI" },
    { display: "ID", name: "Idaho", isState: true, value: "ID" },
    { display: "IL", name: "Illinois", isState: true, value: "IL" },
    { display: "IN", name: "Indiana", isState: true, value: "IN" },
    { display: "IA", name: "Iowa", isState: true, value: "IA" },
    { display: "KS", name: "Kansas", isState: true, value: "KS" },
    { display: "KY", name: "Kentucky", isState: true, value: "KY" },
    { display: "LA", name: "Louisiana", isState: true, value: "LA" },
    { display: "ME", name: "Maine", isState: true, value: "ME" },
    { display: "MP", name: "Mariana Islands", isState: false, value: "MP" },
    { display: "MD", name: "Maryland", isState: false, value: "MD" },
    { display: "MA", name: "Massachusetts", isState: true, value: "MA" },
    { display: "MI", name: "Michigan", isState: true, value: "MI" },
    { display: "MN", name: "Minnesota", isState: true, value: "MN" },
    { display: "MS", name: "Mississippi", isState: true, value: "MS" },
    { display: "MO", name: "Missouri", isState: true, value: "MO" },
    { display: "MT", name: "Montana", isState: true, value: "MT" },
    { display: "NE", name: "Nebraska", isState: true, value: "NE" },
    { display: "NV", name: "Nevada", isState: true, value: "NV" },
    { display: "NH", name: "New Hampshire", isState: true, value: "NH" },
    { display: "NJ", name: "New Jersey", isState: true, value: "NJ" },
    { display: "NM", name: "New Mexico", isState: true, value: "NM" },
    { display: "NY", name: "New York", isState: true, value: "NY" },
    { display: "NC", name: "North Carolina", isState: true, value: "NC" },
    { display: "ND", name: "North Dakota", isState: true, value: "ND" },
    { display: "OH", name: "Ohio", isState: true, value: "OH" },
    { display: "OK", name: "Oklahoma", isState: true, value: "OK" },
    { display: "OR", name: "Oregon", isState: true, value: "OR" },
    { display: "PA", name: "Pennsylvania", isState: true, value: "PA" },
    { display: "PR", name: "Puerto Rico", isState: false, value: "PR" },
    { display: "RI", name: "Rhode Island", isState: true, value: "RI" },
    { display: "SC", name: "South Carolina", isState: true, value: "SC" },
    { display: "SD", name: "South Dakota", isState: true, value: "SD" },
    { display: "TN", name: "Tennessee", isState: true, value: "TN" },
    { display: "TX", name: "Texas", isState: true, value: "TX" },
    { display: "VI", name: "US Virgin Islands", isState: false, value: "VI" },
    { display: "UT", name: "Utah", isState: true, value: "UT" },
    { display: "VT", name: "Vermont", isState: true, value: "VT" },
    { display: "VA", name: "Virginia", isState: true, value: "VA" },
    { display: "WA", name: "Washington", isState: true, value: "WA" },
    { display: "WV", name: "West Virginia", isState: true, value: "WV" },
    { display: "WI", name: "Wisconsin", isState: true, value: "WI" },
    { display: "WY", name: "Wyoming", isState: true, value: "WY" },
];
