import React from "react";
import cx from "classnames";

// CSS
import styles from "./sign-in-welcome.module.scss";

// Hooks
import { useSignInWelcomeBackgroundFade } from "./hooks/useSignInWelcomeBackgroundFade";
import { useSignInWelcomeTypingEffect } from "./hooks/useSignInWelcomeTypingEffect";

// Localization
import { useTranslation } from "localization/localization";

// Media
import sandboxxLogo from "media/signin/sandboxx-text-logo.svg";

// Constants
import {
    delay,
    gradientBackgroundStyle,
    images,
    typingSpeed,
} from "./constants/constants";

export const SignInWelcome = () => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation("common");

    const { currentImageIndex, fade, nextImageIndex, handleSwitchBackground } =
        useSignInWelcomeBackgroundFade({
            images,
        });
    const { currentText } = useSignInWelcomeTypingEffect({
        delay,
        handleSwitchBackground,
        typingSpeed,
    });

    /**
     * End Hooks
     */

    return (
        <div className={styles.signInWelcome}>
            <div
                className={cx(styles.background, {
                    [styles["background--fadeIn"]]: !fade,
                    [styles["background--fadeOut"]]: fade,
                })}
                style={{
                    backgroundImage: `${gradientBackgroundStyle}, url(${images[currentImageIndex]})`,
                }}
            />
            <div
                className={cx(styles.background, {
                    [styles["background--fadeIn"]]: fade,
                    [styles["background--fadeOut"]]: !fade,
                })}
                style={{
                    backgroundImage: `${gradientBackgroundStyle}, url(${images[nextImageIndex]})`,
                }}
            />
            <img
                className={styles.logo}
                src={sandboxxLogo}
                alt={t("sandboxx_logo", { ns: "common" })}
            />
            <div className={styles.textContainer}>
                <span className={styles.text}>{currentText}</span>
                <span className={styles.cursor} />
            </div>
        </div>
    );
};
