import { splitFullName } from "utils/textUtils";
import { imageUrl } from "utils/urlUtils";

export function generateAvatarUrl(user = {}) {
    const avatarUrl = user.avatarS || user.avatar;
    const avatarSource = `${imageUrl()}${avatarUrl}`; // Has avatar
    return avatarUrl && avatarSource;
}

/**
 * Generate Initials based on firstName and lastName or fullName
 * Default to an empty string if the user doesn't have any kind of name
 * @param {string} firstName
 * @param {string} fullName
 * @param {string} lastName
 * @returns {string}
 */
export function generateInitials(firstName, fullName, lastName) {
    const fullNameSplit = fullName && splitFullName(fullName);
    return {
        first:
            firstName?.[0]?.toUpperCase() ||
            fullNameSplit?.[0]?.[0]?.toUpperCase() ||
            "",
        last:
            lastName?.[0]?.toUpperCase() ||
            fullNameSplit?.[1]?.[0]?.toUpperCase() ||
            "",
    };
}
