import React from "react";
import cx from "classnames";

// Components
import { SignInMainButtons } from "./components/SignInMainButtons/SignInMainButtons";
import { SignInMainLoginForm } from "./components/SignInMainLoginForm/SignInMainLoginForm";
import { SignInMainModalPrompt } from "./components/SignInMainModalPrompt/SignInMainModalPrompt";
import { SignInMainSignUpForm } from "./components/SignInMainSignUpForm/SignInMainSignUpForm";
import { Text } from "components/Text/Text";

// CSS
import signInStyles from "../../../sign-in.module.scss";
import signInMainStyles from "./sign-in-main.module.scss";

// Enums
import { SignInModeEnum } from "./constants/constants";

// Hooks
import { useSignInMain } from "./hooks/useSignInMain";

// Localization
import { useTranslation } from "localization/localization";

// Utils
import { handleKeyDown } from "utils/eventUtils";

// Validation
import { Validation } from "validation/validation";

export const SignInMain = (props) => {
    const {
        handleChangeUserInfo,
        handleChangeEmail,
        handleChangePassword,
        handleGoToForgotPassword,
        handleGoToNameEntry,
        handleGoToOnboarding,
        handleGoToPasswordReset,
        handleGoToPhoneEntry,
        handleLogin,
        handleSelectSignInPlatform,
        handleSignUp,
        history,
        isUsingEmail,
        isUsingEmailVerify,
        loading,
        setHasCompletedSignUpName,
        setSignInPlatform,
        signInLoading,
        signInMode,
        showNotification,
        signInPlatform,
        toggleSignInMode,
        userInfo,
    } = props;
    const { email, password } = userInfo;

    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    const {
        handleMissingEmailOAuth,
        onLoginSuccess,
        setShouldShowSignInModalPrompt,
        shouldShowSignInModalPrompt,
    } = useSignInMain({
        handleGoToNameEntry,
        handleGoToOnboarding,
        handleGoToPasswordReset,
        handleGoToPhoneEntry,
        history,
        setHasCompletedSignUpName,
    });

    /**
     * Constants
     */

    // Booleans
    const isInSignUpMode = signInMode === SignInModeEnum.SIGN_UP;

    // Text
    const headerText = isInSignUpMode
        ? t("sign_in_main.title_sign_up", { ns: "onboarding" })
        : t("sign_in_main.title_login", { ns: "onboarding" });
    const headerTextSecondary = isInSignUpMode
        ? t("sign_in_main.subtitle_sign_up", { ns: "onboarding" })
        : t("sign_in_main.subtitle_login", { ns: "onboarding" });

    // Validation
    const hasValidEmail = Validation.isEmailAddress(email);
    const hasValidPassword = isInSignUpMode
        ? Validation.isAtLeastOfLength(password, 8)
        : Validation.isAtLeastOfLength(password, 6);

    /**
     * End Hooks
     */

    function renderForm() {
        return isInSignUpMode ? (
            <SignInMainSignUpForm
                {...props}
                handleChangeUserInfo={handleChangeUserInfo}
                handleSignUp={handleSignUp}
                hasValidEmail={hasValidEmail}
                loading={loading}
                setSignInPlatform={setSignInPlatform}
                signInPlatform={signInPlatform}
                userInfo={userInfo}
            />
        ) : (
            <SignInMainLoginForm
                handleChangeEmail={handleChangeEmail}
                handleChangePassword={handleChangePassword}
                handleGoToForgotPassword={handleGoToForgotPassword}
                handleLogin={handleLogin}
                hasValidEmail={hasValidEmail}
                hasValidPassword={hasValidPassword}
                setSignInPlatform={setSignInPlatform}
                signInLoading={signInLoading}
                signInPlatform={signInPlatform}
                userInfo={userInfo}
            />
        );
    }

    function renderModePrompt() {
        const linkText = isInSignUpMode ? "Log In" : "Sign Up";
        const promptText = isInSignUpMode
            ? t("sign_in_main.switch_mode_to_login", { ns: "onboarding" })
            : t("sign_in_main.switch_mode_to_sign_up", { ns: "onboarding" });
        return (
            <div className={signInMainStyles["mode-prompt"]}>
                <Text>
                    {promptText}{" "}
                    <Text
                        classes={cx(signInMainStyles.link, "link")}
                        cyId="sign-in-mode-toggle"
                        isBold
                        onClick={toggleSignInMode}
                        onKeyDown={(e) => {
                            handleKeyDown(e, toggleSignInMode);
                        }}
                        tabIndex="0"
                    >
                        {linkText}
                    </Text>
                </Text>
            </div>
        );
    }

    function renderPolicies() {
        return (
            <div className={signInMainStyles.policies}>
                <p className={signInMainStyles.policy}>
                    {t("sign_in_main.google_recaptcha_policy", {
                        ns: "onboarding",
                    })}{" "}
                    <a
                        className={signInMainStyles.link}
                        href="https://policies.google.com/privacy"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        {t("privacy_policy", { ns: "common" })}
                    </a>{" "}
                    and{" "}
                    <a
                        className={signInMainStyles.link}
                        href="https://policies.google.com/terms"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        {t("terms_of_service", { ns: "common" })}
                    </a>{" "}
                    apply.
                </p>
                <p className={signInMainStyles.policy}>
                    By continuing you agree to Sandboxx’s{" "}
                    <a
                        className={signInMainStyles.link}
                        href="https://www.sandboxx.us/terms/"
                        rel="noreferrer"
                        target="_blank"
                    >
                        {t("terms_and_conditions", { ns: "common" })}
                    </a>{" "}
                    and{" "}
                    <a
                        className={signInMainStyles.link}
                        href="https://www.sandboxx.us/privacy/"
                        rel="noreferrer"
                        target="_blank"
                    >
                        {t("privacy_policy", { ns: "common" })}
                    </a>
                </p>
            </div>
        );
    }

    return (
        <div
            className={cx(
                signInStyles.content,
                signInMainStyles["sign-in-main"]
            )}
        >
            <SignInMainModalPrompt
                onHide={() => setShouldShowSignInModalPrompt(false)}
                show={shouldShowSignInModalPrompt}
            />
            <div className={signInStyles.top}>
                <div
                    className={cx(signInStyles.header, signInMainStyles.header)}
                >
                    <span className={signInStyles.text}>{headerText}</span>
                    <span
                        className={cx(
                            signInStyles["text-secondary"],
                            signInMainStyles["text-secondary"]
                        )}
                    >
                        {headerTextSecondary}
                    </span>
                </div>
                {renderForm()}
                {renderModePrompt()}
                <div className={signInMainStyles.divider}>
                    <span>{t("or", { ns: "common" }).toUpperCase()}</span>
                </div>
                <SignInMainButtons
                    handleMissingEmailOAuth={handleMissingEmailOAuth}
                    handleSelectSignInPlatform={handleSelectSignInPlatform}
                    isUsingEmail={isUsingEmail}
                    isUsingEmailVerify={isUsingEmailVerify}
                    isInSignUpMode={isInSignUpMode}
                    onLoginSuccess={onLoginSuccess}
                    showNotification={showNotification}
                />
                {renderPolicies()}
            </div>
        </div>
    );
};
