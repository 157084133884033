import React, { useState } from "react";

// Components
import { WalletGiftCardsLanding } from "./components/WalletGiftCardsLanding/WalletGiftCardsLanding";
import { WalletGiftCardsPurchase } from "./components/WalletGiftCardsPurchase/WalletGiftCardsPurchase";

// Enums
import { NavigationEnum } from "./constants/constants";

export const WalletGiftCards = (props) => {
    /**
     * useState
     */

    const [currentSection, setCurrentSection] = useState(
        NavigationEnum.LANDING
    );

    /**
     * End Hooks
     */

    function renderCurrentSection() {
        return {
            [NavigationEnum.LANDING]: (
                <WalletGiftCardsLanding
                    {...props}
                    setCurrentSection={setCurrentSection}
                />
            ),
            [NavigationEnum.PURCHASE]: <WalletGiftCardsPurchase {...props} />,
        }[currentSection];
    }

    return <div className="page">{renderCurrentSection()}</div>;
};
