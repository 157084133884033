// Platforms
import { Branch } from "./branch";
import { GTM } from "./gtm";
import { firebase } from "./firebase";
import { Iterable } from "./iterable/iterable";

export class Analytics {
    static init(callback) {
        Branch.init(callback);
        Iterable.init();
    }

    static login() {
        Branch.identify();
        Iterable.identify();
    }

    static logout() {
        Branch.logout();
        Iterable.logout();
    }

    static signUp({ onIterableIdentifySuccess }) {
        Branch.identify();
        Iterable.identify({ onIdentifySuccess: onIterableIdentifySuccess });
    }

    static trackEvent(platform, params) {
        if (platform === "gtm") {
            GTM.trackEvent(params);
        } else if (platform === "firebase") {
            if (params.data) {
                firebase.trackEvent(params.event, params.data);
            } else {
                firebase.trackEvent(params.event);
            }
        } else if (platform === "iterable") {
            const { data, event } = params;
            Iterable.trackEvent(event, data);
        }
    }

    static trackEventEcommerce(platform, event, params) {
        if (platform === "firebase") {
            firebase.trackEventEcommerce(event, params);
        }
    }

    static trackPurchase(platform, params) {
        if (platform === "iterable") {
            Iterable.trackPurchaseEvent(params);
        }
    }
}
