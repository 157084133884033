import React from "react";
import cx from "classnames";

// Components
import { ButtonPrimary, ButtonSecondary } from "components/Buttons/Buttons";
import { CharacterCounter } from "components/CharacterCounter/CharacterCounter";
import { InputValidatable } from "components/Inputs/InputValidatable/InputValidatable";
import { Text } from "components/Text/Text";

// CSS
import styles from "./wallet-gift-cards-purchase-sender.module.scss";

// Hooks
import { useWalletGiftCardsPurchaseSender } from "./hooks/useWalletGiftCardsPurchaseSender";

// Validation
import { Validation } from "./validation/validation";

export const WalletGiftCardsPurchaseSender = (props) => {
    const { sender } = props;
    const { email, message, name } = sender;

    const { handleChange, handleGoBack, handleSubmit } =
        useWalletGiftCardsPurchaseSender(props);

    return (
        <div className={styles["wallet-gift-cards-purchase-sender"]}>
            <div className={styles.header}>
                <Text type="h2">Who’s it from?</Text>
            </div>
            <div className={styles["sender-info"]}>
                <form className={styles.form} onSubmit={handleSubmit}>
                    <div className={styles.item}>
                        <div className={styles.header}>
                            <label
                                className={cx(styles.label, "label")}
                                htmlFor="name"
                            >
                                From
                            </label>
                            <CharacterCounter
                                characterLimit={18}
                                className={styles["character-counter"]}
                                value={name}
                            />
                        </div>
                        <InputValidatable
                            cypressTestId="wallet-gift-cards-purchase-sender-input-name"
                            isValid={Validation.isValidName(name)}
                            name="name"
                            onChange={handleChange}
                            type="text"
                            value={name}
                        />
                    </div>
                    <div className={styles.item}>
                        <div className={styles.header}>
                            <label
                                className={cx(styles.label, "label")}
                                htmlFor="email"
                            >
                                Email Address
                            </label>
                        </div>
                        <InputValidatable
                            cypressTestId="wallet-gift-cards-purchase-sender-input-email"
                            isValid={Validation.isValidEmail(email)}
                            name="email"
                            onChange={handleChange}
                            type="text"
                            value={email}
                        />
                    </div>
                    <div className={styles.item}>
                        <div className={styles.header}>
                            <label
                                className={cx(styles.label, "label")}
                                htmlFor="message"
                            >
                                Gift Message
                            </label>
                            <CharacterCounter
                                characterLimit={100}
                                className={styles["character-counter"]}
                                value={message}
                            />
                        </div>
                        <textarea
                            className={cx(styles.textarea, "textarea")}
                            data-cy="wallet-gift-cards-purchase-sender-input-message"
                            name="message"
                            onChange={handleChange}
                            rows="10"
                            value={message}
                        ></textarea>
                    </div>
                    <div className={styles.buttons}>
                        <ButtonSecondary
                            onClick={handleGoBack}
                            text="Back"
                            type="button"
                        />
                        <ButtonPrimary
                            cypressTestId="wallet-gift-cards-purchase-sender-submit-button"
                            isDisabled={!Validation.canSubmit(sender)}
                            onClick={handleSubmit}
                            text="Continue"
                            type="submit"
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};
