import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Components
import { ButtonPrimary } from "components/Buttons/Buttons";
import { InputValidatable } from "components/Inputs/InputValidatable/InputValidatable";
import { Text } from "components/Text/Text";

// CSS
import styles from "./muster-recruit-invite-modal-submit.module.scss";

// Utils
import { copyTextToClipboard } from "utils/textUtils";
import { handleKeyDown } from "utils/eventUtils";
import { Validation } from "validation/validation";

export const MusterRecruitInviteModalSubmit = (props) => {
    const {
        emailErrors,
        handleAddEmail,
        handleBlur,
        handleEmailChange,
        handleFocus,
        handleSubmitEmailInvites,
        inviteEmails,
        isEmailInviteButtonDisabled,
        isSendingEmails,
        magicLink,
        numberOfEmails,
        setHeaderSubText,
        setHeaderText,
    } = props;

    /**
     * useRef
     */

    const inputRefs = useRef([]);

    /**
     * useEffect
     */

    useEffect(() => {
        inputRefs.current[0].focus();
        setHeaderText("Invite your recruits to Waypoints!");
        setHeaderSubText(
            "Share your invite link or enter their email address to invite."
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * useState
     */

    const [copyButtonText, setCopyButtonText] = useState("Copy");

    /**
     * End Hooks
     */

    function handleCopyMagicLinkToClipboard() {
        copyTextToClipboard(magicLink);
        setCopyButtonText("Copied");
        AnalyticsLogger.logMagicLinkCopied();
    }

    function setInputRef(ref) {
        inputRefs.current.push(ref);
    }

    function renderBody() {
        return (
            <div className={styles.body} data-cy="invite-recruit-body">
                <div className={styles.inviteViaLink}>
                    <div className={styles.header}>
                        <Text type="h4" isBold={true}>
                            Invite via Link
                        </Text>
                    </div>
                    <div className={styles.magicLink}>
                        <input
                            className="input"
                            data-cy="magic-link-input"
                            name="magicLink"
                            readOnly
                            type="text"
                            value={magicLink}
                        />
                        <ButtonPrimary
                            classes={styles.button}
                            cypressTestId="muster-recruit-invite-modal-copy-magic-link-button"
                            onClick={handleCopyMagicLinkToClipboard}
                            text={copyButtonText}
                            type="button"
                        />
                    </div>
                    <div className={styles.divider}>
                        <span>OR</span>
                    </div>
                </div>
                <div className={styles.inviteViaEmail}>
                    <div className={styles.header}>
                        <Text type="h4" isBold={true}>
                            Invite via Email
                        </Text>
                    </div>
                    <div className={styles.inputs}>{renderEmailFields()}</div>
                    <div
                        className={styles.material}
                        data-cy="muster-recruit-invite-modal-invite-recruit-add-line-button"
                        onKeyDown={
                            /* istanbul ignore next */ (e) =>
                                handleKeyDown(e, handleAddEmail)
                        }
                        onClick={handleAddEmail}
                        tabIndex="0"
                    >
                        <span className="material-icons">add</span>
                        <span>Add another email</span>
                    </div>
                </div>
            </div>
        );
    }

    function renderButtons() {
        return (
            <div className={styles.buttons}>
                <ButtonPrimary
                    classes={styles.button}
                    cypressTestId="muster-recruit-invite-modal-submit-button"
                    isDisabled={isEmailInviteButtonDisabled || isSendingEmails}
                    text={!isSendingEmails ? "Send Invites" : "Sending..."}
                    type="submit"
                />
            </div>
        );
    }

    function renderEmailFields() {
        return [...Array(numberOfEmails).keys()].map((i) => {
            return (
                <div key={i} className={styles.inputContainer}>
                    <InputValidatable
                        ariaLabel={`Email Input ${i}`}
                        autoComplete="nofill"
                        autoFocus={true}
                        className={styles.input}
                        containerClasses={styles["input-validatable"]}
                        cypressTestId={`muster-recruit-invite-modal-email-input-${i}`}
                        id={`recruitEmail-${i}`}
                        inputClasses={styles.input}
                        isError={emailErrors[i]}
                        isValid={Validation.isEmailAddress(inviteEmails[i])}
                        name={`recruitEmail-${i}`}
                        onChange={(e) => handleEmailChange(i, e)}
                        onBlur={() => handleBlur(i)}
                        onFocus={() => handleFocus(i)}
                        placeholder="Enter email here..."
                        setInputRef={setInputRef}
                        type="email"
                        value={inviteEmails[i]}
                    />
                </div>
            );
        });
    }

    return (
        <div className={styles["muster-recruit-invite-modal-submit"]}>
            <form onSubmit={handleSubmitEmailInvites}>
                {renderBody()}
                {renderButtons()}
            </form>
        </div>
    );
};

MusterRecruitInviteModalSubmit.propTypes = {
    emailErrors: PropTypes.array.isRequired,
    handleAddEmail: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleEmailChange: PropTypes.func.isRequired,
    handleFocus: PropTypes.func.isRequired,
    handleSubmitEmailInvites: PropTypes.func.isRequired,
    inviteEmails: PropTypes.array.isRequired,
    isEmailInviteButtonDisabled: PropTypes.bool.isRequired,
    numberOfEmails: PropTypes.number.isRequired,
    isSendingEmails: PropTypes.bool.isRequired,
    setHeaderSubText: PropTypes.func.isRequired,
    setHeaderText: PropTypes.func.isRequired,
};
