import { useTransition } from "react-spring";

export function useCrossFade(item, styles = {}) {
    const { from, enter, leave } = styles;
    return useTransition(item, {
        from: { opacity: 0, ...from },
        enter: { opacity: 1, ...enter },
        leave: { opacity: 0, ...leave },
    });
}
