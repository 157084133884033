import React from "react";
import PropTypes from "prop-types";

// Components
import { SupportSquadDetailActivity } from "./components/SupportSquadDetailActivity/SupportSquadDetailActivity";
import { SupportSquadDetailBanner } from "./components/SupportSquadDetailBanner/SupportSquadDetailBanner";
import { SupportSquadDetailEmpty } from "./components/SupportSquadDetailEmpty/SupportSquadDetailEmpty";
import { SupportSquadDetailHeader } from "./components/SupportSquadDetailHeader/SupportSquadDetailHeader";

// CSS
import styles from "./support-squad-detail.module.scss";

// Hooks
import { useSupportSquadDetail } from "./hooks/useSupportSquadDetail";

export const SupportSquadDetail = (props) => {
    const {
        handleClickSendLetter,
        isFetchingSquad,
        selectedSquad,
        setCurrentSection,
        setShouldShowCreateModal,
        setShouldShowDonateTokensModal,
        setShouldShowLeaveModal,
        setSquadName,
    } = props;

    /**
     * Custom Hooks
     */

    const {
        handleOpenAdminSettings,
        handleOpenMembersList,
        handleOpenDonateTokenModal,
    } = useSupportSquadDetail({
        setCurrentSection,
        setShouldShowCreateModal,
        setShouldShowDonateTokensModal,
        setSquadName,
        selectedSquad,
    });

    /**
     * End Hooks
     */

    function renderSquad() {
        return (
            <div
                className={styles.supportSquadDetail}
                data-cy="support-squad-detail"
            >
                <SupportSquadDetailHeader
                    handleClickSendLetter={handleClickSendLetter}
                    handleOpenAdminSettings={handleOpenAdminSettings}
                    handleOpenMembersList={handleOpenMembersList}
                    handleOpenDonateTokenModal={handleOpenDonateTokenModal}
                    isFetchingSquad={isFetchingSquad}
                    setCurrentSection={setCurrentSection}
                    setShouldShowCreateModal={setShouldShowCreateModal}
                    setShouldShowLeaveModal={setShouldShowLeaveModal}
                    selectedSquad={selectedSquad}
                />
                <SupportSquadDetailBanner
                    isFetchingSquad={isFetchingSquad}
                    setCurrentSection={setCurrentSection}
                    setShouldShowCreateModal={setShouldShowCreateModal}
                    selectedSquad={selectedSquad}
                />
                <SupportSquadDetailActivity
                    isFetchingSquad={isFetchingSquad}
                    selectedSquad={selectedSquad}
                />
            </div>
        );
    }

    if (!isFetchingSquad && !selectedSquad) {
        return <SupportSquadDetailEmpty />;
    } else {
        return renderSquad();
    }
};

SupportSquadDetail.propTypes = {
    handleClickSendLetter: PropTypes.func.isRequired,
    isFetchingSquad: PropTypes.bool.isRequired,
    setCurrentSection: PropTypes.func.isRequired,
    setShouldShowCreateModal: PropTypes.func.isRequired,
    setShouldShowDonateTokensModal: PropTypes.func.isRequired,
    setShouldShowLeaveModal: PropTypes.func.isRequired,
    setSquadName: PropTypes.func.isRequired,
    selectedSquad: PropTypes.object,
};
