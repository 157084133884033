import { useContext, useEffect, useState } from "react";

// Context
import { CartContext } from "context/Cart/cart";

export const useCartShippingOptionsSelect = () => {
    /**
     * useContext
     */

    const { cart, isSubmittingCart, shippingOptions, submitShippingOption } =
        useContext(CartContext);

    /**
     * useState
     */

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedValue, setSelectedValue] = useState("");

    /**
     * useEffect
     */

    useEffect(() => {
        if (cart?.shipping_methods && cart?.shipping_methods[0]) {
            setSelectedValue(cart.shipping_methods[0].shipping_option);
        }
    }, [cart]);

    /**
     * End Hooks
     */

    function handleChange(e) {
        const selectedShippingOption = e.target.value;
        if (selectedShippingOption) {
            setIsSubmitting(true);
            submitShippingOption({
                onFailure: onSubmitShippingOptionFailure,
                onSuccess: onSubmitShippingOptionSuccess,
                shippingOption: selectedShippingOption,
            });
        }
    }

    function onSubmitShippingOptionFailure() {
        setIsSubmitting(false);
        setSelectedValue("");
    }

    function onSubmitShippingOptionSuccess(res, shippingOption) {
        setIsSubmitting(false);
        setSelectedValue(shippingOption);
    }

    return {
        cart,
        handleChange,
        isSubmitting,
        isSubmittingCart,
        selectedValue,
        shippingOptions,
    };
};
