import React, { useState, useEffect } from "react";

// CSS
import styles from "./CountDownTimer.module.scss";

// Utils
import { findTimeDifference } from "utils/dateTimeUtils";

export const CountDownTimer = ({ endTime }) => {
    const [timerMinutes, setTimerMinutes] = useState("60");
    const [timerSeconds, setTimerSeconds] = useState("00");

    useEffect(() => {
        const interval = setInterval(() => {
            const { minutes, seconds } = findTimeDifference(endTime);
            if (Math.floor(minutes) === 0 && Math.floor(seconds) === 0) {
                minutes < 10
                    ? setTimerMinutes(
                          "0".concat(Math.floor(minutes).toString())
                      )
                    : setTimerMinutes(Math.floor(minutes).toString());
                seconds < 10
                    ? setTimerSeconds(
                          "0".concat(Math.floor(seconds).toString())
                      )
                    : setTimerSeconds(Math.floor(seconds).toString());
                clearInterval(interval);
            } else {
                minutes < 10
                    ? setTimerMinutes(
                          "0".concat(Math.floor(minutes).toString())
                      )
                    : setTimerMinutes(Math.floor(minutes).toString());
                seconds < 10
                    ? setTimerSeconds(
                          "0".concat(Math.floor(seconds).toString())
                      )
                    : setTimerSeconds(Math.floor(seconds).toString());
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [endTime]);

    return (
        <span className={styles.countDownTimer}>
            {timerMinutes}:{timerSeconds}
        </span>
    );
};
