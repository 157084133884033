import React from "react";
import PropTypes from "prop-types";

// Components
import { Text } from "components/Text/Text";

// CSS
import styles from "./progress-bar.module.scss";

export const ProgressBar = ({ completed, text, total }) => {
    const progressBarWidth = (completed * 100) / total;

    return (
        <div className={styles.progressBar}>
            <Text type="caption" classes={styles.text}>
                {text}
            </Text>
            <div className={styles.background}>
                <div
                    className={styles.active}
                    style={{ width: `${progressBarWidth}%` }}
                />
            </div>
        </div>
    );
};

ProgressBar.propTypes = {
    completed: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
};
