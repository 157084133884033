import { useContext, useState } from "react";

// API
import { SandboxxRestAPI } from "utils/sandboxx";

// Context
import { NotificationContext } from "context/notification/notification";

// Enums
import { NotificationSeverity } from "enums/notifications/NotificationSeverity";

// Models
import { Notification } from "models/notification/Notification";

export const useReferralsRedeemCodeModal = ({
    setShouldShowRedeemCodeModal,
}) => {
    /**
     * useContext
     */

    const { addNotification } = useContext(NotificationContext);

    /**
     * useState
     */

    const [code, setCode] = useState("");
    const [isSubmittingCode, setIsSubmittingCode] = useState(false);

    /**
     * End Hooks
     */

    function handleChange(value) {
        const formattedValue = value.join("");
        setCode(formattedValue);
    }

    function redeemCode(e) {
        e?.preventDefault();
        setIsSubmittingCode(true);
        return SandboxxRestAPI.claimReferralCode(
            code,
            onRedeemCodeSuccess,
            onRedeemCodeFailure,
            onRedeemCodeFailure
        );
    }

    function onRedeemCodeFailure(err) {
        setIsSubmittingCode(false);
        addNotification(
            new Notification({
                id: "REFERRALS_REDEEM_CODE_FAILURE",
                message: err.message,
                options: { severity: NotificationSeverity.ERROR },
            })
        );
    }

    function onRedeemCodeSuccess(res) {
        setIsSubmittingCode(false);
        setShouldShowRedeemCodeModal(false);
        addNotification(
            new Notification({
                id: "REFERRALS_REDEEM_CODE_SUCCESS",
                message: res.message,
                options: { severity: NotificationSeverity.SUCCESS },
            })
        );
    }

    return { code, handleChange, isSubmittingCode, redeemCode };
};
