import { t } from "localization/localization";

export function generateSuccessText(purchase) {
    if (purchase.bundle.includesSandboxxPlus) {
        return {
            primaryText: t("hooray", { ns: "common" }),
            subText: t("purchase_modal.confirmation.unlocked_sandboxx_plus", {
                ns: "components",
            }),
        };
    } else if (purchase.bundle.credits === 0) {
        return {
            primaryText: t("hooray", { ns: "common" }),
            subText: t("purchase_modal.confirmation.unlocked_multi_photo", {
                ns: "components",
            }),
        };
    } else {
        const bundleText =
            parseInt(purchase.bundle.credits, 10) > 1
                ? t("purchase_modal.confirmation.letters_have_been_added", {
                      count: purchase.bundle.credits,
                      ns: "components",
                  })
                : t("purchase_modal.confirmation.letter_has_been_added", {
                      count: purchase.bundle.credits,
                      ns: "components",
                  });

        return {
            primaryText: t("thank_you_for_your_purchase", { ns: "common" }),
            subText: bundleText,
        };
    }
}
