export function hasValidAddress({ address }) {
    return (
        address &&
        address.line1 &&
        address.city &&
        address.state &&
        address.zipcode
    );
}

export function hasValidName({ firstName, lastName }) {
    return firstName && lastName;
}

export function fullName({ firstName, lastName }) {
    return firstName + lastName;
}
