import React from "react";

// Components
import { ButtonInvisible, ButtonPrimary } from "components/Buttons/Buttons";
import { ConfirmVerificationCodeInput } from "../../Inputs/ConfirmVerificationCodeInput/ConfirmVerificationCodeInput";
import { Text } from "components/Text/Text";

// CSS
import styles from "../phone-verification-modal.module.scss";

// Localization
import { useTranslation } from "localization/localization";

export const PhoneVerificationModalVerificationCode = ({
    handleChangeVerificationCode,
    handleSubmitVerificationCode,
    loading,
    resendVerificationCode,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * End Hooks
     */

    return (
        <div className={styles["verification-code"]}>
            <Text classes={styles.header} type="h2">
                {t("phone_verification_modal.verification_code_title", {
                    ns: "components",
                })}
            </Text>
            <form
                className={styles.form}
                onSubmit={handleSubmitVerificationCode}
            >
                <ConfirmVerificationCodeInput
                    label=""
                    loading={loading.verify}
                    length={6}
                    onChange={handleChangeVerificationCode}
                />
                <ButtonPrimary
                    classes={styles.submit}
                    cypressTestId="phone-verification-modal-verification-code-submit-button"
                    isLoading={loading.verificationCode}
                    onClick={handleSubmitVerificationCode}
                    type="submit"
                >
                    <Text>{t("verify", { ns: "common" })}</Text>
                </ButtonPrimary>
            </form>
            <ButtonInvisible onClick={resendVerificationCode}>
                <Text>{t("resend_code", { ns: "common" })}</Text>
            </ButtonInvisible>
        </div>
    );
};
