import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

// Context
import { NavBarContext } from "context/navbar";

// Interfaces
import IterableInboxManager from "interfaces/inbox/IterableInboxManager";

export const useNavBar = () => {
    /**
     * Router
     **/

    const history = useHistory();

    /**
     * useContext
     **/

    const { shouldShowNavBar } = useContext(NavBarContext);

    /**
     * useState
     **/

    const [inboxManager] = useState(IterableInboxManager);
    const [shouldShowLogOutModal, setShouldShowLogOutModal] = useState(false);

    /**
     * Constants
     **/

    const hasUnreadInboxMessages = !!inboxManager.unreadMessageCount;

    /**
     * End Hooks
     **/

    return {
        hasUnreadInboxMessages,
        history,
        setShouldShowLogOutModal,
        shouldShowLogOutModal,
        shouldShowNavBar,
    };
};
