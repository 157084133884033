import React from "react";
import PropTypes from "prop-types";

// Components
import { ButtonInvisible, ButtonPrimary } from "components/Buttons/Buttons";
import { Text } from "components/Text/Text";

// CSS
import styles from "./cart-payment-method-form.module.scss";

// Hooks
import { useCartPaymentMethodForm } from "./hooks/useCartPaymentMethodForm";

export const CartPaymentMethodForm = ({
    handleSelectPaymentMethodNew,
    setCartCurrentSection,
    setHeaderText,
}) => {
    /**
     * Custom Hooks
     */

    const {
        dropinContainerRef,
        dropinSubmitButtonRef,
        handleGoBack,
        isSubmitting,
    } = useCartPaymentMethodForm({
        handleSelectPaymentMethodNew,
        setCartCurrentSection,
        setHeaderText,
    });

    /**
     * End Hooks
     */

    function renderButtons() {
        return (
            <div className={styles.buttons}>
                <ButtonPrimary
                    config={{ shouldMaintainDimensions: true }}
                    isDisabled={isSubmitting}
                    isLoading={isSubmitting}
                    ref={dropinSubmitButtonRef}
                >
                    <Text>Add Payment Option</Text>
                </ButtonPrimary>
                <ButtonInvisible onClick={handleGoBack}>
                    <Text>Back</Text>
                </ButtonInvisible>
            </div>
        );
    }

    function renderForm() {
        return (
            <div className={styles.form} data-cy="add-payment-container">
                <div id="dropin-container" ref={dropinContainerRef} />
            </div>
        );
    }

    return (
        <div className={styles.cartPaymentMethodForm}>
            {renderForm()}
            {renderButtons()}
        </div>
    );
};

CartPaymentMethodForm.propTypes = {
    handleSelectPaymentMethodNew: PropTypes.func.isRequired,
    setCartCurrentSection: PropTypes.func.isRequired,
    setHeaderText: PropTypes.func.isRequired,
};
