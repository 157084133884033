import { useState } from "react";

export const useReferralsNavigation = () => {
    /**
     * useState
     */

    const [shouldShowRedeemCodeModal, setShouldShowRedeemCodeModal] =
        useState(false);

    /**
     * End Hooks
     */

    return { setShouldShowRedeemCodeModal, shouldShowRedeemCodeModal };
};
