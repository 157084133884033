import React from "react";
import cx from "classnames";

// Components
import { Skeleton } from "@mui/material";

// CSS
import styles from "./memory-book-image.module.scss";

// Hooks
import { useMemoryBookImage } from "./hooks/useMemoryBookImage";

export const MemoryBookImage = () => {
    /**
     * Custom Hooks
     */

    const { cart, currentImageIndex, fade, images, nextImageIndex } =
        useMemoryBookImage();

    /**
     * End Hooks
     */

    function renderContent() {
        return (
            <div className={styles.memoryBookImage}>
                <div
                    className={cx(styles.background, {
                        [styles["background--fadeIn"]]: !fade,
                        [styles["background--fadeOut"]]: fade,
                    })}
                    style={{
                        backgroundImage: `url(${images[currentImageIndex]})`,
                    }}
                />
                <div
                    className={cx(styles.background, {
                        [styles["background--fadeIn"]]: fade,
                        [styles["background--fadeOut"]]: !fade,
                    })}
                    style={{
                        backgroundImage: `url(${images[nextImageIndex]})`,
                    }}
                />
            </div>
        );
    }

    function renderLoading() {
        return (
            <div className={styles.memoryBookImage}>
                <Skeleton className={styles.skeleton} />
            </div>
        );
    }

    return cart ? renderContent() : renderLoading();
};
