import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";

export function generateColorProp({ isInvalid, isValid }) {
    if (isValid) {
        return "success";
    }
    if (isInvalid) {
        return "error";
    }
}

export function generateEndAdornment({ isInvalid, isValid }) {
    if (isValid) {
        return <CheckIcon style={{ color: "var(--success-100)" }} />;
    } else if (isInvalid) {
        return <CancelIcon style={{ color: "var(--error-100)" }} />;
    }
    return null;
}
