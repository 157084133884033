import React, { createContext, useState } from "react";

// Models
import { Contact } from "../models/contacts/Contact";

// Platforms
import { SandboxxRestAPI } from "../utils/sandboxx";

// Utils
import { emptyFunction } from "utils/miscUtils";
import { generateFullName } from "utils/userUtils";

export const ContactsContext = createContext({});

export const ContactsProvider = (props) => {
    const { children } = props;

    /**
     * useState
     **/

    const [contactModal, setContactModal] = useState({
        contactToEdit: {
            firstName: "",
            fullName: "",
            lastName: "",
            title: "",
            address: {
                line1: "",
                line2: "",
                city: "",
                state: "",
                zipcode: "",
            },
        },
        isSupportSquad: false,
        isWritingLetter: false,
        shouldShow: false,
        shouldShowBackButton: false,
        targetScreen: null,
        type: "edit",
    });
    const [contacts, setContacts] = useState([]);
    const [loading, setLoading] = useState({
        contacts: false,
        recentContacts: false,
    });
    const [
        onSubmitContactSuccessFinalCallback,
        setOnSubmitContactSuccessFinalCallback,
    ] = useState(() => emptyFunction);
    const [onBackButtonClickCallback, setOnBackButtonClickCallback] = useState(
        () => emptyFunction
    );
    const [recentContacts, setRecentContacts] = useState([]);

    /**
     * End Hooks
     **/

    function resetContactsContext() {
        setContactModal({
            contactToEdit: {
                firstName: "",
                fullName: "",
                lastName: "",
                title: "",
                address: {
                    line1: "",
                    line2: "",
                    city: "",
                    state: "",
                    zipcode: "",
                },
            },
            isSupportSquad: false,
            isWritingLetter: false,
            shouldShow: false,
            shouldShowBackButton: false,
            type: "edit",
        });
        setContacts([]);
        setLoading({
            contacts: false,
            recentContacts: false,
        });
        setRecentContacts([]);
    }

    async function createContact(
        contact,
        callback,
        onFailure,
        { shouldReverseFullName }
    ) {
        const contactPayload = {
            ...contact,
            fullName: generateFullName(contact, shouldReverseFullName),
        };
        SandboxxRestAPI.createAddressBookContact(
            contactPayload,
            callback,
            onFailure,
            onFailure
        );
    }

    function deleteContact(contact, callback, onFailure) {
        SandboxxRestAPI.deleteAddressBookContact(
            contact,
            callback,
            onFailure,
            onFailure
        );
    }

    async function editContact(contact, callback, onFailure) {
        SandboxxRestAPI.editAddressBookContact(
            contact,
            callback,
            onFailure,
            onFailure
        );
    }

    function fetchContact(id, callback, onFailure) {
        SandboxxRestAPI.getAddressBookContact(
            id,
            callback,
            onFailure,
            onFailure
        );
    }

    function fetchContacts() {
        setLoading((prevLoading) => ({ ...prevLoading, contacts: true }));
        SandboxxRestAPI.getAddressBookContacts(
            onFetchContactsSuccess,
            onFetchContactsError,
            onFetchContactsError
        );
    }

    function fetchRecentContacts() {
        setLoading((prevLoading) => ({ ...prevLoading, recentContacts: true }));
        SandboxxRestAPI.getAddressBookRecentContacts(
            onFetchRecentContactsSuccess,
            onFetchRecentContactsError
        );
    }

    function onFetchContactsError(err) {
        console.error("onFetchContactsError", err);
    }

    function onFetchContactsSuccess(res) {
        const contacts = res.map((contact) => new Contact(contact));
        setContacts(contacts);
        setLoading((prevLoading) => ({ ...prevLoading, contacts: false }));
    }

    function onFetchRecentContactsError(err) {
        console.error("onFetchRecentContactsError", err);
    }

    function onFetchRecentContactsSuccess(res) {
        const recentContacts = res.map((contact) => new Contact(contact));
        setRecentContacts(recentContacts);
        setLoading((prevLoading) => ({
            ...prevLoading,
            recentContacts: false,
        }));
    }

    function toggleContactModal({
        contactToEdit,
        isSupportSquad,
        isWritingLetter,
        shouldShow,
        shouldShowBackButton,
        targetScreen,
        type,
    }) {
        setContactModal((prev) => ({
            ...prev,
            contactToEdit: contactToEdit || prev.contactToEdit,
            isSupportSquad,
            isWritingLetter,
            shouldShow,
            shouldShowBackButton,
            targetScreen: targetScreen || null,
            type: type || prev.type,
        }));
    }

    const contactsContext = {
        contactModal,
        contacts,
        createContact,
        deleteContact,
        editContact,
        fetchContact,
        fetchContacts,
        fetchRecentContacts,
        loading,
        onBackButtonClickCallback,
        onSubmitContactSuccessFinalCallback,
        recentContacts,
        resetContactsContext,
        setOnBackButtonClickCallback,
        setOnSubmitContactSuccessFinalCallback,
        toggleContactModal,
    };

    return (
        <ContactsContext.Provider value={contactsContext}>
            {children}
        </ContactsContext.Provider>
    );
};
