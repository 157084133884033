import React from "react";
import PropTypes from "prop-types";

// Components
import { ButtonInvisible, ButtonPrimary } from "components/Buttons/Buttons";
import { Text } from "components/Text/Text";

// CSS
import styles from "./memory-book-ineligible.module.scss";

// Localization
import { useTranslation } from "localization/localization";

// Platforms
import { Intercom } from "platforms/intercom";

export const MemoryBookIneligible = ({
    handleGoHome,
    ineligibleErrorMessage,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * End Hooks
     */

    return (
        <div className={styles.memoryBookIneligible}>
            <div className={styles.content}>
                <Text isBold type="h3">
                    {t("ineligible.title", { ns: "memory_book" })}
                </Text>
                <Text>{ineligibleErrorMessage}</Text>
            </div>
            <div className={styles.buttons}>
                <ButtonPrimary onClick={handleGoHome}>
                    <Text>{t("home", { ns: "common" })}</Text>
                </ButtonPrimary>
                <ButtonInvisible
                    onClick={() =>
                        Intercom.showNewMessage(
                            t("ineligible.customer_happiness_message", {
                                ns: "memory_book",
                            })
                        )
                    }
                >
                    <Text>{t("contact_us", { ns: "common" })}</Text>
                </ButtonInvisible>
            </div>
        </div>
    );
};

MemoryBookIneligible.propTypes = {
    handleGoHome: PropTypes.func.isRequired,
    ineligibleErrorMessage: PropTypes.string,
};
