import { useState, useEffect } from "react";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Utils
import { CurrentUser } from "utils/sandboxx";
import { generateHasShipDates } from "../utils";

// Enums
import { PersonaEnum } from "enums/PersonaEnum";

export const useMusterOverview = (props) => {
    const { setHeaderBarContent, shipDates } = props;

    /**
     * useState
     */

    const [selectedRecruit, setSelectedRecruit] = useState(null);
    const [shouldShowRecruitDetailModal, setShouldShowRecruitDetailModal] =
        useState(false);
    const [shouldShowShipDateModal, setShouldShowShipDateModal] =
        useState(false);
    const [shouldShowDepModal, setShouldShowDepModal] = useState(false);
    const [shouldShowRecruitsModal, setShouldShowRecruitsModal] =
        useState(false);

    /**
     * Constants
     */

    const user = CurrentUser.getUser();
    const branchPersona = (user.personas && user.personas[2]) || "UNKNOWN";
    const hasShipDates = generateHasShipDates(shipDates);
    const rootPersona = (user.personas && user.personas[0]) || "UNKNOWN";
    const textSecondary =
        rootPersona.id === PersonaEnum.RECRUITER
            ? "Stay up to date with your recruits' training"
            : "Stay up to date with your training";

    /**
     * useState
     */

    useEffect(() => {
        // Analytics calls
        AnalyticsLogger.logMusterDashboardViewed();

        // State calls
        setHeaderBarContent({
            backPathname: "",
            onBackClick: null,
            textSecondary: textSecondary,
            text: "Waypoints",
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * End Hooks & Constants
     */

    return {
        branchPersona,
        hasShipDates,
        rootPersona,
        selectedRecruit,
        setSelectedRecruit,
        setShouldShowRecruitDetailModal,
        setShouldShowShipDateModal,
        setShouldShowDepModal,
        setShouldShowRecruitsModal,
        shouldShowShipDateModal,
        shouldShowRecruitsModal,
        shouldShowDepModal,
        shouldShowRecruitDetailModal,
        user,
    };
};
