export function EditIcon({ height, width }) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 11 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.29946 0.583992C8.86954 0.582658 8.45691 0.753967 8.15338 1.05982L7.73047 1.48465L10.0353 3.77026L10.4582 3.34543C10.9117 2.8769 11.0422 2.18127 10.7895 1.57908C10.5368 0.976895 9.95 0.585007 9.29946 0.583992Z"
                fill="#212121"
            />
            <path
                d="M0.519197 11.4177H2.25999C2.37566 11.4183 2.48685 11.3729 2.56898 11.2914L9.00555 4.85051L6.65113 2.49609L0.210206 8.93267C0.128743 9.0148 0.08333 9.12598 0.0839915 9.24166V10.9825C0.0839915 11.2228 0.278844 11.4177 0.519197 11.4177Z"
                fill="#212121"
            />
        </svg>
    );
}

EditIcon.defaultProps = {
    height: "16px",
    width: "16px",
};
