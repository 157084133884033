import React, { createContext, useState } from "react";

// Components
import { NotificationHandler } from "components/NotificationHandler/NotificationHandler";

export const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([]);

    const addNotification = ({ id, message, options = {} } = {}) => {
        setNotifications((prev) => {
            if (prev.some((notif) => notif.id === id)) {
                return prev;
            }
            return [...prev, { id, message, options }];
        });
    };

    const handleClose = (id) => (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        removeNotification({ id });
    };

    const removeNotification = ({ id }) => {
        setNotifications((prev) => prev.filter((notif) => notif.id !== id));
    };

    const notificationContext = { addNotification, removeNotification };

    return (
        <NotificationContext.Provider value={notificationContext}>
            {children}
            <NotificationHandler
                handleClose={handleClose}
                notifications={notifications}
            />
        </NotificationContext.Provider>
    );
};
