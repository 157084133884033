import React, { useRef } from "react";
import PropTypes from "prop-types";

// Components
import { ButtonPrimary } from "components/Buttons/Buttons";
import { InputValidatable } from "components/Inputs/InputValidatable/InputValidatable";
import { Modal } from "components/Modals/Modal";

// CSS
import styles from "./muster-checklist-document-modal.module.scss";

// Hooks
import { useMusterChecklistDocumentModal } from "./hooks/useMusterChecklistDocumentModal";

export const MusterChecklistDocumentModal = (props) => {
    const {
        activeDocument,
        addMusterDocument,
        onToggleAddEditModal,
        shouldShowAddEditDocumentModal,
        type,
        updateMusterDocument,
    } = props;

    /**
     * useRef
     */

    const inputRef = useRef(null);

    /**
     * Custom Hooks
     */

    const {
        buttonText,
        documentDescription,
        handleChange,
        handleModalClose,
        handleSubmit,
        headerSubText,
        headerText,
    } = useMusterChecklistDocumentModal(
        activeDocument,
        addMusterDocument,
        inputRef,
        onToggleAddEditModal,
        type,
        updateMusterDocument
    );

    /**
     * End Hooks
     */

    function renderBody() {
        return (
            <div className={styles.body}>
                <InputValidatable
                    ariaLabel="Document Description"
                    id="document-modal-description-input"
                    isValid={documentDescription}
                    name="name"
                    onChange={handleChange}
                    placeholder="Enter document description..."
                    setInputRef={(ref) => (inputRef.current = ref)}
                    type="text"
                    value={documentDescription}
                />
            </div>
        );
    }

    function renderButtons() {
        return (
            <div className={styles.buttons}>
                <ButtonPrimary
                    classes={styles.button}
                    cypressTestId="document-checklist-modal-submit-document-button"
                    isDisabled={!documentDescription}
                    onClick={handleSubmit}
                    text={buttonText}
                    type="submit"
                />
            </div>
        );
    }

    return (
        <Modal
            centered
            className={styles["muster-checklist-document-modal"]}
            dialogClassName={styles["muster-checklist-document-modal-dialog"]}
            onHide={handleModalClose}
            headerSubText={headerSubText}
            headerText={headerText}
            show={shouldShowAddEditDocumentModal}
            size="md"
        >
            <div className={styles.content}>
                {renderBody()}
                {renderButtons()}
            </div>
        </Modal>
    );
};

MusterChecklistDocumentModal.propTypes = {
    activeDocument: PropTypes.object,
    addMusterDocument: PropTypes.func.isRequired,
    onToggleAddEditModal: PropTypes.func.isRequired,
    shouldShowAddEditDocumentModal: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired,
    updateMusterDocument: PropTypes.func.isRequired,
};
