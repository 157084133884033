import React from "react";

// Components
import { Banner } from "components/Banner/Banner";
import { Text } from "components/Text/Text";

// CSS
import styles from "./referrals-rewards.module.scss";

// Localization
import { useTranslation } from "localization/localization";

export const ReferralsRewards = (props) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * End Hooks
     */

    return (
        <Banner classes={styles.referralsRewards} theme="secondary">
            <Text classes={styles.text}>
                {t("rewards.line_1", { ns: "referrals" })}
            </Text>
            <Text classes={styles.text}>
                {t("rewards.line_2", { ns: "referrals" })}
            </Text>
        </Banner>
    );
};
