import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// Components
import { Text } from "components/Text/Text";

// CSS
import styles from "./wallet-gift-cards-purchase-recipient-card.module.scss";

// Utils
import { emptyFunction } from "utils/miscUtils";
import { handleKeyDown } from "utils/eventUtils";

export const WalletGiftCardsPurchaseRecipientCard = (props) => {
    const { isActive, classes, cypressTestId, onClick, recipient } = props;
    const { address, fullName } = recipient;
    const { city, line1, line2, state, zipcode } = address;

    return (
        <div
            className={cx(
                classes,
                styles["wallet-gift-cards-purchase-recipient-card"],
                {
                    [styles[
                        "wallet-gift-cards-purchase-recipient-card--active"
                    ]]: isActive,
                }
            )}
            data-cy={cypressTestId}
            onKeyDown={(e) => handleKeyDown(e, onClick)}
            onClick={onClick}
            tabIndex="0"
        >
            <Text isBold type="p">
                {fullName}
            </Text>
            {line1 && <Text type="p">{line1}</Text>}
            {line2 && <Text type="p">{line2}</Text>}
            {city && state && zipcode && (
                <Text type="p">
                    {city}, {state} {zipcode}
                </Text>
            )}
        </div>
    );
};

WalletGiftCardsPurchaseRecipientCard.defaultProps = {
    classes: "",
    onClick: emptyFunction,
};

WalletGiftCardsPurchaseRecipientCard.propTypes = {
    classes: PropTypes.string,
    cypressTestId: PropTypes.string,
    isActive: PropTypes.bool,
    onClick: PropTypes.func,
    recipient: PropTypes.object,
};
