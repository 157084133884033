// Localization
import { t } from "localization/localization";

export function generateButtonText(addOn, hasGiftCard, isGiftCard) {
    if (isGiftCard) {
        if (hasGiftCard) {
            return t("remove", { ns: "common" });
        } else {
            return t("add", { ns: "common" });
        }
    } else {
        if (addOn.isSelected) {
            return t("remove", { ns: "common" });
        } else {
            return t("add", { ns: "common" });
        }
    }
}
