import { useContext } from "react";
import { useHistory } from "react-router";

// Context
import { CartContext } from "context/Cart/cart";
import { NotificationBarContext } from "context/notificationBar";

// Enums
import { CartNavigation } from "../enums/CartNavigation";

export const useCart = ({ resetCartPaymentMethod, setCurrentSection }) => {
    /**
     * Router Hooks
     */

    const history = useHistory();

    /**
     * useContext
     */

    const { completeCartSession, hideCart, shouldDisplayCart } =
        useContext(CartContext);

    const { showNotification } = useContext(NotificationBarContext);

    /**
     * End Hooks
     */

    function handleCompleteCheckout() {
        completeCartSession({
            onFailure: onCompleteCheckoutFailure,
            onSuccess: onCompleteCheckoutSuccess,
        });
    }

    function onCompleteCheckoutFailure(err) {
        showNotification({ text: err.message, type: "warning" });
    }

    function onCompleteCheckoutSuccess(res) {
        setCurrentSection(CartNavigation.CONFIRMATION);
        resetCartPaymentMethod();
        history.push("/letters");
    }

    return {
        handleCompleteCheckout,
        hideCart,
        shouldDisplayCart,
    };
};
