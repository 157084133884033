import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// Components
import { Text } from "components/Text/Text";

// CSS
import styles from "./storyly-group.module.scss";
import "css/storyly/storyly.scss";

// Hooks
import { useStorylyGroup } from "./hooks/useStorylyGroup";

export const StorylyGroup = ({
    category,
    classNames,
    headerText,
    segments,
}) => {
    /**
     * Custom Hooks
     */

    const { storylyRef } = useStorylyGroup({
        category,
        classNames,
        segments,
    });

    /**
     * End Hooks
     **/

    function renderHeader() {
        if (headerText) {
            return <Text classes={styles.header}>{headerText}</Text>;
        }
    }

    return (
        <div className={cx(styles.storylyGroup, classNames.container)}>
            {renderHeader()}
            <storyly-web ref={storylyRef} />
        </div>
    );
};

StorylyGroup.defaultProps = {
    classNames: { container: "", storylyContainer: "" },
    segments: [],
};

StorylyGroup.propTypes = {
    category: PropTypes.string.isRequired,
    classNames: PropTypes.object,
    headerText: PropTypes.string,
    segments: PropTypes.array,
};
