import React, { useContext } from "react";

// Components
import { ButtonPrimary, ButtonSecondary } from "components/Buttons/Buttons";
import { Text } from "components/Text/Text";

// Context
import { MusterContext } from "context/muster";

// CSS
import styles from "./muster-header-bar-content.module.scss";

// Enums
import { PersonaEnum } from "enums/PersonaEnum";

// Utils
import { CurrentUser } from "utils/sandboxx";
import { handleKeyDown } from "utils/eventUtils";

export const MusterHeaderBarContent = (props) => {
    /**
     * Constants
     */

    const user = CurrentUser.getUser();
    const rootPersona = (user.personas && user.personas[0]) || "UNKNOWN";

    /**
     * useContext
     */

    const {
        contactList,
        currentDepLocation,
        hasContactListLoaded,
        setShouldShowJoinModal,
        setShouldShowRecruitModal,
    } = useContext(MusterContext);

    /**
     * End Hooks & Constants
     */

    function renderHeaderBarContent() {
        if (!hasContactListLoaded) {
            return "";
        } else {
            if (rootPersona.id === PersonaEnum.RECRUITER) {
                return renderRecruiterContent();
            }
            if (rootPersona.id === PersonaEnum.RECRUIT) {
                return renderRecruitContent();
            }
        }
    }

    function renderRecruitContent() {
        if (contactList.length !== 0) {
            return (
                <div
                    className={styles.headerBarRightContent}
                    onClick={() => setShouldShowJoinModal(true)}
                    onKeyDown={
                        /* istanbul ignore next */ (e) =>
                            handleKeyDown(e, () => setShouldShowJoinModal(true))
                    }
                    tabIndex="0"
                >
                    <Text type="p">My Recruiter</Text>
                    <Text classes={styles.header} type="h4">
                        {contactList[0]?.fullName}
                    </Text>
                    {currentDepLocation?.depShortCode && (
                        <Text classes={styles.header} type="h4">
                            Station Unit: {currentDepLocation.depShortCode}
                        </Text>
                    )}
                </div>
            );
        } else {
            return (
                <ButtonSecondary
                    classes={styles.button}
                    cypressTestId="muster-connect-with-recruiter-button"
                    onClick={() => setShouldShowJoinModal(true)}
                    text="Connect With Recruiter"
                    type="button"
                />
            );
        }
    }

    function renderRecruiterContent() {
        return (
            <ButtonPrimary
                classes={styles.button}
                cypressTestId="muster-invite-recruits-button"
                onClick={() => setShouldShowRecruitModal(true)}
                text="Invite Recruits"
                type="button"
            />
        );
    }

    return <div>{renderHeaderBarContent()}</div>;
};
