import { useMemo } from "react";

// Utils
import { generateFormattedReferralsListData } from "../utils/utils";

export const useReferralsList = ({ list }) => {
    const formattedList = useMemo(
        () => generateFormattedReferralsListData(list),
        [list]
    );

    const isEmptyList = list?.length === 0;

    return { formattedList, isEmptyList };
};
