import styles from "../wallet-gift-card-purchase-billing-details.module.scss";

export const braintreeFormClassNames = {
    backButton: "button--secondary",
    buttons: styles.buttons,
    container: styles["braintree-container"],
    form: styles.form,
};

export const braintreeFormText = {
    backButton: "BACK",
    submitButton: "CONTINUE",
};
