import React, { useContext } from "react";

// Components
import { CartItemsItem } from "./components/CartItemsItem/CartItemsItem";
import { Text } from "components/Text/Text";

// Context
import { CartContext } from "context/Cart/cart";

// CSS
import styles from "./cart-items.module.scss";

// Localization
import { useTranslation } from "localization/localization";

export const CartItems = () => {
    /**
     * useContext
     */

    const { t } = useTranslation();

    const { cart, isUpdatingCart } = useContext(CartContext);

    /**
     * End Hooks
     */

    if (!cart) {
        // Loading state before cart has been fetched
        return (
            <div className={styles.cartItems}>
                <div className={styles.header}>
                    <Text>{t("cart.your_cart", { ns: "components" })}</Text>
                </div>
                <CartItemsItem isLoading />
                <CartItemsItem isLoading />
            </div>
        );
    } else {
        // Render cart items after cart has been fetched
        return (
            <div className={styles.cartItems}>
                <div className={styles.header}>
                    <Text>{t("cart.your_cart", { ns: "components" })}</Text>
                </div>
                {cart?.items
                    .sort((a, b) => {
                        return (
                            a?.variant?.product?.metadata?.cart_item_index -
                            b?.variant?.product?.metadata?.cart_item_index
                        );
                    })
                    .map((item) => {
                        return (
                            <CartItemsItem
                                item={item}
                                isUpdatingCart={isUpdatingCart}
                                key={item.id}
                            />
                        );
                    })}
            </div>
        );
    }
};
