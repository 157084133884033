export function WalletGiftCardsLandingPhysicalCardIcon({
    height = "131px",
    width = "130px",
}) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 131 130"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M84.3882 14.3312L84.5702 105.097C84.5702 106.345 83.5605 107.359 82.3125 107.363L24.4323 107.48C22.9589 107.48 21.7629 106.293 21.7586 104.82L21.5723 13.9195C21.5679 11.7529 23.3186 9.99353 25.4896 9.9892L79.9119 9.88087C82.3775 9.87653 84.3795 11.8699 84.3839 14.3355L84.3882 14.3312Z"
                fill="#EE5928"
            />
            <path
                d="M65.7933 23.513L58.7863 23.5434C58.6 23.5434 58.4527 23.1274 58.4483 22.6074C58.444 22.0874 58.5913 21.6714 58.7777 21.6671L65.7847 21.6367C65.971 21.6367 66.1183 22.0527 66.1227 22.5727C66.127 23.0927 65.9797 23.5087 65.7933 23.513Z"
                fill="#F1F1ED"
            />
            <path
                d="M79.4831 23.431L68.3465 23.483C68.0518 23.483 67.8135 23.067 67.8135 22.547C67.8135 22.027 68.0475 21.6067 68.3378 21.6067L79.4745 21.5547C79.7691 21.5547 80.0075 21.9707 80.0075 22.4907C80.0075 23.0107 79.7735 23.431 79.4831 23.431Z"
                fill="#F1F1ED"
            />
            <path
                d="M79.1227 31.331L59.4364 31.4176C58.9208 31.4176 58.4961 31.0016 58.4961 30.486C58.4961 29.9703 58.9121 29.5456 59.4278 29.5456L79.1141 29.459C79.6341 29.459 80.0544 29.875 80.0544 30.3906C80.0544 30.9063 79.6384 31.331 79.1227 31.331Z"
                fill="#F1F1ED"
            />
            <path
                d="M73.334 39.2396L59.216 39.3046C58.8434 39.3046 58.54 38.8886 58.54 38.3686C58.54 37.8529 58.8347 37.4283 59.2074 37.4283L73.3254 37.3633C73.698 37.3633 74.0014 37.7793 74.0014 38.2993C74.0014 38.8193 73.7067 39.2396 73.334 39.2396Z"
                fill="#F1F1ED"
            />
            <path
                d="M79.9027 39.2006L75.6691 39.2179C75.5564 39.2179 75.4654 38.7976 75.4611 38.2819C75.4568 37.7662 75.5477 37.3459 75.6604 37.3416L79.8941 37.3242C80.0067 37.3242 80.0977 37.7446 80.1021 38.2602C80.1064 38.7759 80.0154 39.1962 79.9027 39.2006Z"
                fill="#F1F1ED"
            />
            <path
                d="M79.2145 47.0907L59.5282 47.1774C59.0126 47.1774 58.5879 46.7614 58.5879 46.2458C58.5879 45.7301 59.0039 45.3054 59.5196 45.3054L79.2059 45.2188C79.7259 45.2188 80.1462 45.6348 80.1462 46.1504C80.1462 46.6704 79.7302 47.0907 79.2145 47.0907Z"
                fill="#F1F1ED"
            />
            <path
                d="M35.0038 9.9621L35.208 107.453L48.6456 107.425L48.4414 9.93395L35.0038 9.9621Z"
                fill="#F1F1ED"
            />
            <path
                d="M53.6125 87.6734L53.6777 102.047L80.4661 101.925L80.4009 87.5518L53.6125 87.6734Z"
                fill="#F1F1ED"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M84.5169 60.6791C84.5169 60.6791 104.147 70.2731 106.322 71.4431C108.498 72.6131 112.012 78.3027 112.346 78.8054C112.679 79.3081 115.024 102.396 116.696 106.747C118.369 111.097 119.708 116.449 119.708 116.449H87.9186C87.9186 116.449 82.0296 107.873 78.3506 107.371L82.1206 107.345C83.5202 107.336 84.6339 106.179 84.5949 104.779L84.4172 98.5221C84.4172 98.5221 81.6526 93.4694 81.2713 88.5597C80.8899 83.6501 67.1749 68.9254 66.6723 65.7491C66.1696 62.5727 69.1813 58.2221 72.6956 59.3921C76.2099 60.5621 84.3522 70.7151 84.3522 70.7151L84.5213 60.6748L84.5169 60.6791Z"
                fill="#F1F1ED"
            />
            <path
                d="M119.374 116.944C118.703 116.944 118.061 116.549 117.78 115.895C117.641 115.57 114.356 107.831 114.356 100.988C114.356 93.5914 112.857 79.6554 107.241 74.3341C105.391 72.5834 94.9473 68.1115 88.8026 65.7065C87.9099 65.3555 87.4723 64.3501 87.8189 63.4618C88.1656 62.5691 89.1709 62.1315 90.0636 62.4781C91.7536 63.1411 106.678 69.0258 109.624 71.8164C117.593 79.3651 117.823 98.7958 117.823 100.988C117.823 107.107 120.934 114.456 120.965 114.53C121.342 115.41 120.934 116.428 120.055 116.805C119.834 116.9 119.6 116.944 119.374 116.944Z"
                fill="#C8C5B5"
            />
            <path
                d="M97.4951 109.287C97.3434 109.287 97.1874 109.265 97.0314 109.222C86.1981 106.232 80.3134 99.3243 79.5421 88.6903C79.5118 88.283 79.3774 87.906 79.1435 87.5636C78.6538 86.8573 77.6224 85.4576 76.4265 83.837C71.8331 77.6057 67.6038 71.799 66.3818 69.1643C65.5801 67.4397 63.4525 62.8507 68.0631 59.7783C72.6825 56.6973 76.0408 59.9473 79.0048 62.8117C81.9644 65.6717 83.1821 66.885 84.5904 68.289C85.8038 69.498 87.1774 70.8673 89.9681 73.5887C91.0948 74.6893 92.3731 75.3133 93.8551 76.0327C96.0261 77.09 98.4874 78.2903 101.083 81.224C101.716 81.939 101.651 83.0353 100.931 83.6723C100.212 84.305 99.1201 84.24 98.4831 83.5207C96.3468 81.107 94.3101 80.1103 92.3341 79.1483C90.7264 78.364 89.0668 77.558 87.5414 76.0673C84.7421 73.333 83.3598 71.9593 82.1421 70.7417C80.7424 69.3463 79.5334 68.146 76.5954 65.299C72.9641 61.789 71.9155 61.373 69.9871 62.66C68.5398 63.6263 68.1021 64.6273 69.5278 67.6997C70.7021 70.2347 76.0321 77.4583 79.2171 81.7743C80.4781 83.486 81.4791 84.838 81.9948 85.592C82.5798 86.437 82.9264 87.4206 83.0001 88.439C83.2428 91.8016 83.9838 102.028 97.9544 105.881C98.8774 106.136 99.4191 107.09 99.1634 108.013C98.9511 108.78 98.2534 109.287 97.4951 109.287Z"
                fill="#C8C5B5"
            />
            <path
                d="M88.4863 120.015C87.7453 120.015 87.0606 119.538 86.831 118.793C86.8223 118.763 85.57 114.923 82.1813 113.376C81.3103 112.978 80.9246 111.951 81.3233 111.08C81.722 110.209 82.749 109.823 83.62 110.222C88.4516 112.423 90.0766 117.554 90.146 117.77C90.4276 118.685 89.9163 119.655 89.002 119.937C88.833 119.989 88.6596 120.015 88.4906 120.015H88.4863Z"
                fill="#C8C5B5"
            />
        </svg>
    );
}
