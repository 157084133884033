import React, { useEffect, useState } from "react";
import { Modal } from "components/Modals/Modal";
import Fuse from "fuse.js";

// Components
import { LoadingOverlay } from "../Loading/Loading";
import { SearchField } from "../Inputs/SearchField/SearchField";

// CSS
import "./dep-modal.scss";

// Localization
import { useTranslation } from "localization/localization";

// Platforms
import { CurrentUser, SandboxxRestAPI } from "../../utils/sandboxx";

// Utils
import { generateDepLocationsTitle } from "utils/depUtils";
import { handleKeyDown } from "utils/eventUtils";

export const DepModal = (props) => {
    const {
        depsLocations,
        fetchDepsLocations,
        onSetDepLocation,
        handleShowDepModal,
        headerTextOverride,
        shouldShow,
        user,
    } = props;

    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useState
     */

    const [fuses, setFuses] = useState([]);
    const [isSetting, setIsSetting] = useState(false);
    const [results, setResults] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");

    /**
     * useEffect
     */

    useEffect(() => {
        const branchPersona = user?.personas && user?.personas[2];
        if (branchPersona) {
            fetchDepsLocations({
                branchId: branchPersona.id,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (depsLocations) {
            setFuses(
                depsLocations.map(
                    ({ locales }) =>
                        new Fuse(locales, {
                            keys: ["locale", "offices.office"],
                            threshold: 0.2,
                        })
                )
            );
            if (!searchTerm) {
                setResults(depsLocations);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [depsLocations]);

    useEffect(() => {
        if (!searchTerm) {
            setResults(depsLocations);
        } else {
            setResults(
                depsLocations.map((location, i) => ({
                    region: location.region,
                    locales: fuses[i]
                        .search(searchTerm)
                        .map(({ item }) => item),
                }))
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm]);

    /**
     * Constants
     */

    const { branchId } = CurrentUser.getUser() || {};
    const depLocationsTitle = generateDepLocationsTitle(branchId);

    /**
     * End Hooks
     */

    function handleSetDepLocation(dep) {
        setIsSetting(true);
        SandboxxRestAPI.setDepLocation(
            { depsShortCode: dep.depShortCode },
            (res) => onSetDepLocationSuccess(res, dep),
            onSetDepLocationFailure,
            onSetDepLocationFailure
        );
    }

    function handleSetDepLocationKeyPress(e, info, locale) {
        handleKeyDown(e, () =>
            handleSetDepLocation({
                ...info,
                locale,
            })
        );
    }

    function onHideModal() {
        handleShowDepModal(false);
    }

    function onSearchInputChange(e) {
        const { value } = e.target;
        setSearchTerm(value);
    }

    function onSetDepLocationFailure(err) {
        setIsSetting(false);
        console.error("onSetDepsLocationFailure", err);
    }

    function onSetDepLocationSuccess(res, dep) {
        setIsSetting(false);
        onSetDepLocation(res, dep);
    }

    function renderLocations() {
        return (
            results && (
                <div className="dep-modal__locations">
                    <div className="dep-modal__locations__scrollbar">
                        {results
                            ?.filter(({ locales }) => locales?.length)
                            ?.map(({ locales, region }, i) => (
                                <div key={i}>
                                    <span className="dep-modal__locations__region">
                                        {region}
                                    </span>
                                    <div>
                                        {locales.map(({ locale, offices }) =>
                                            offices.map((info, i) => {
                                                const { office } = info;
                                                return (
                                                    <div
                                                        className="dep-modal__locations__location"
                                                        data-cy={`dep-modal-locations-${locale}-${office}`}
                                                        key={i}
                                                        onClick={() =>
                                                            handleSetDepLocation(
                                                                {
                                                                    ...info,
                                                                    locale,
                                                                }
                                                            )
                                                        }
                                                        onKeyDown={(e) =>
                                                            handleSetDepLocationKeyPress(
                                                                e,
                                                                info,
                                                                locale
                                                            )
                                                        }
                                                        tabIndex="0"
                                                    >
                                                        <span>
                                                            {locale} • {office}
                                                        </span>
                                                    </div>
                                                );
                                            })
                                        )}
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            )
        );
    }

    return (
        <Modal
            centered="true"
            className="dep-modal"
            cypressTestId="dep-modal"
            headerSubText={
                headerTextOverride
                    ? null
                    : t("dep_modal.subtitle", {
                          ns: "components",
                      })
            }
            headerText={
                headerTextOverride
                    ? t("recruiting_station_singular", {
                          ns: "common",
                      })
                    : depLocationsTitle
            }
            onHide={onHideModal}
            onClose={onHideModal}
            shouldHideHeaderBorder={headerTextOverride}
            show={shouldShow}
            size="lg"
        >
            {isSetting && <LoadingOverlay />}
            <div className="dep-modal__search">
                <SearchField
                    handleSearchInputChange={onSearchInputChange}
                    placeholder={t("search_for_a_location", {
                        ns: "common",
                    })}
                    term={searchTerm}
                />
            </div>
            {renderLocations()}
        </Modal>
    );
};
