import React from "react";
import PropTypes from "prop-types";

// Components
import { Select } from "components/Inputs/Select/Select";

// Hooks
import { useDropdownCountry } from "./useDropdownCountry";

// Utils
import { emptyFunction } from "utils/miscUtils";

export const DropdownCountry = ({
    className,
    cypressTestId,
    label,
    name,
    onChange,
    required,
    size,
    value,
}) => {
    /**
     * Custom Hooks
     */

    const { i18n, sortedCountries } = useDropdownCountry();

    /**
     * End Custom Hooks
     */

    function renderOptions() {
        return sortedCountries.map((country) => {
            return (
                <option key={country.cca2} value={country.cca2}>
                    {country.translations[i18n.resolvedLanguage].common}
                </option>
            );
        });
    }

    return (
        <Select
            className={className}
            cypressTestId={cypressTestId}
            label={label}
            name={name}
            onChange={onChange}
            required={required}
            size={size}
            value={value}
        >
            <option value={null} />
            {renderOptions()}
        </Select>
    );
};

DropdownCountry.defaultProps = {
    className: "",
    label: "Country",
    onChange: emptyFunction,
    size: "normal",
};

DropdownCountry.propTypes = {
    className: PropTypes.string,
    cypressTestId: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    required: PropTypes.bool,
    size: PropTypes.string,
    value: PropTypes.string,
};
