// Platforms
export class InboxManager {
    constructor(props) {
        this.hasMadeInitialMessagesFetch = false;
        this.hasMessages = false;
        this.totalMessageCount = 0;
        this.unreadMessageCount = 0;
    }

    deleteMessage() {}

    loadMessages() {}

    markMessageAsRead() {}
}
