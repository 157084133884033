export function LocationIcon({ height, width, color }) {
    return (
        <svg
            width={height}
            height={width}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.9161 2.00014C8.86733 1.98014 6.05937 4.04593 5.23069 7.17951C5.04358 7.88705 4.99692 8.58295 5.00015 9.24978C5.01162 11.6163 6.25987 13.6608 7.66873 15.6456C9.07759 17.6303 10.6648 19.5837 11.5109 21.7212C11.5807 21.8901 11.7431 22 11.9231 22C12.103 22 12.2655 21.8901 12.3352 21.7212C13.1813 19.5837 14.7686 17.6303 16.1774 15.6456C17.5863 13.6608 18.8345 11.6163 18.846 9.24978C18.8492 8.58295 18.8026 7.88705 18.6155 7.17951C17.7868 4.04593 14.9788 1.98014 11.9301 2.00014C11.9254 2.00014 11.9208 2.00014 11.9161 2.00014ZM11.9161 2.91092C11.9207 2.911 11.9254 2.911 11.9301 2.91092C14.5832 2.8878 17.0254 4.67734 17.7492 7.41429C17.908 8.01479 17.9548 8.63203 17.9518 9.24978C17.9419 11.2882 16.8344 13.1628 15.4509 15.112C14.2438 16.8125 12.872 18.5618 11.9231 20.5189C10.9742 18.5618 9.60233 16.8125 8.39525 15.112C7.01171 13.1628 5.90421 11.2882 5.89434 9.24978C5.89134 8.63203 5.93812 8.01479 6.09692 7.41429C6.82071 4.67734 9.26297 2.8878 11.9161 2.91092ZM11.9091 6.06968C10.3301 6.06763 9.03594 7.38047 9.03794 8.98655C9.03996 10.5926 10.3371 11.9014 11.9161 11.9034C13.4951 11.9057 14.7823 10.5997 14.7803 8.99367C14.7783 7.38759 13.4881 6.07173 11.9091 6.06968ZM11.9091 6.98031C13.0046 6.98168 13.8847 7.87947 13.8861 8.99367C13.8875 10.1079 13.0046 10.9942 11.9091 10.9928C10.8136 10.9914 9.93351 10.1007 9.93212 8.98655C9.93074 7.87236 10.8136 6.97889 11.9091 6.98031Z"
                fill={color}
            />
        </svg>
    );
}

LocationIcon.defaultProps = {
    height: "25px",
    width: "25px",
    color: "#212121",
};
