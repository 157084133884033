import { useEffect, useState } from "react";

// Hooks
import { useDebounceAction } from "hooks/debounce/useDebounceAction";

// Localization
import { useTranslation } from "localization/localization";

// Models
import { DraftManager } from "../../../models/letters/DraftManager";

// Utils
import { calculateRemainingMessageCharacters } from "utils/lettersUtils";
import { SandboxxRestAPI } from "utils/sandboxx";

export const useDraft = (props) => {
    const {
        hasEditedMessage,
        isUploadingImage,
        message,
        recipient,
        setMessage,
        showNotification,
    } = props;

    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useState
     */

    const [draftManager] = useState(new DraftManager());
    const [isSavingDraft, setIsSavingDraft] = useState(false);

    /**
     * useEffect
     */

    useEffect(() => {
        const { remainingCharacters } = calculateRemainingMessageCharacters(
            message.content
        );
        if (
            (message.content.length !== 0 ||
                message.customPhotoArray.length !== 0) &&
            !isUploadingImage &&
            !isSavingDraft &&
            hasEditedMessage &&
            remainingCharacters >= 0
        ) {
            handleInitializeSaveDraft(false);
            setIsSavingDraft(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasEditedMessage, message.content]);

    /**
     * End Hooks & Constants
     */

    function fetchDraft(hasMultiPhoto) {
        return draftManager.fetchDraft(() => handleLetterLoad(hasMultiPhoto));
    }

    function handleInitializeSaveDraft(isManualSave) {
        isManualSave ? saveDraft(isManualSave) : saveDraftDebounce();
    }

    function handleLetterLoad(hasMultiPhoto) {
        const { customPhotoArray, message } = draftManager.draft;
        const messageDraft = message;
        const messageImages = hasMultiPhoto
            ? customPhotoArray
            : [customPhotoArray[0]];

        messageImages.forEach(function (imageFragment, index) {
            SandboxxRestAPI.getImageCloudfront(imageFragment, (res) => {
                setMessage((prev) => ({
                    ...prev,
                    customPhotoArray: [
                        ...prev.customPhotoArray,
                        { binary: res, url: messageImages[index] },
                    ],
                }));
            });
        });

        setMessage((prev) => ({
            ...prev,
            content: messageDraft || "",
        }));
    }

    function onSaveDraftFailure(res, isManualSave = false) {
        isManualSave &&
            showNotification({
                text: res.message,
                type: "warning",
            });
        setIsSavingDraft(false);
    }

    function onSaveDraftSuccess(res, isManualSave) {
        isManualSave &&
            showNotification({
                text: t("draft_save_success", { ns: "letters" }),
                type: "success",
            });
        setIsSavingDraft(false);
    }

    function saveDraft(isManualSave) {
        const customMessage = message.content;
        const customPhotoArray = message.customPhotoArray.map(
            (photo) => photo.url
        );
        draftManager.saveDraft(
            {
                customMessage,
                customPhotoArray,
                recipient,
            },
            (e) => onSaveDraftSuccess(e, isManualSave),
            (e) => onSaveDraftFailure(e, isManualSave),
            (e) => onSaveDraftFailure(e, isManualSave)
        );
    }

    const saveDraftDebounce = useDebounceAction(() => {
        saveDraft();
    }, 3000);

    return {
        fetchDraft,
        handleInitializeSaveDraft,
        isSavingDraft,
        setIsSavingDraft,
    };
};
