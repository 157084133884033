import React from "react";
import PropTypes from "prop-types";

// Components
import { Modal } from "components/Modals/Modal";
import { SupportSquadDonateTokensModalConfirm } from "./components/SupportSquadDonateTokensModalConfirm/SupportSquadDonateTokensModalConfirm";
import { SupportSquadDonateTokensModalEnterAmount } from "./components/SupportSquadDonateTokensModalEnterAmount/SupportSquadDonateTokensModalEnterAmount";
import { SupportSquadDonateTokensModalSuccess } from "./components/SupportSquadDonateTokensModalSuccess/SupportSquadDonateTokensModalSuccess";

// Hooks
import { useSupportSquadDonateTokensModal } from "./hooks/useSupportSquadDonateTokensModal";

// Constants
import { NavigationEnum } from "./constants/constants";
const { CONFIRM, ENTER_AMOUNT, SUCCESS } = NavigationEnum;

export const SupportSquadDonateTokensModal = (props) => {
    const {
        credits,
        setShouldShowDonateTokensModal,
        shouldShowDonateTokensModal,
    } = props;

    /**
     * Custom Hooks
     */

    const {
        currentSection,
        handleChangeTokensToDonate,
        handleDonateTokens,
        hasAgreedToDonationTerms,
        headerText,
        headerSubText,
        loading,
        resetModal,
        setCurrentSection,
        setHasAgreedToDonationTerms,
        setHeaderText,
        setHeaderSubText,
        tokensToDonate,
    } = useSupportSquadDonateTokensModal(props);

    /**
     * Constants
     */

    const isInvalidTokensToDonateAmount = tokensToDonate > credits;

    /**
     * End Hooks
     */

    function handleCloseModal() {
        setShouldShowDonateTokensModal(false);
        setTimeout(() => {
            resetModal();
        }, 500);
    }

    function renderCurrentSection() {
        return {
            [CONFIRM]: (
                <SupportSquadDonateTokensModalConfirm
                    {...props}
                    handleDonateTokens={handleDonateTokens}
                    hasAgreedToDonationTerms={hasAgreedToDonationTerms}
                    loading={loading}
                    setCurrentSection={setCurrentSection}
                    setHasAgreedToDonationTerms={setHasAgreedToDonationTerms}
                    setHeaderText={setHeaderText}
                    setHeaderSubText={setHeaderSubText}
                />
            ),
            [ENTER_AMOUNT]: (
                <SupportSquadDonateTokensModalEnterAmount
                    {...props}
                    credits={credits}
                    handleChangeTokensToDonate={handleChangeTokensToDonate}
                    isInvalidTokensToDonateAmount={
                        isInvalidTokensToDonateAmount
                    }
                    setCurrentSection={setCurrentSection}
                    setHeaderText={setHeaderText}
                    setHeaderSubText={setHeaderSubText}
                    tokensToDonate={tokensToDonate}
                />
            ),
            [SUCCESS]: (
                <SupportSquadDonateTokensModalSuccess
                    {...props}
                    handleCloseModal={handleCloseModal}
                    setHeaderText={setHeaderText}
                    setHeaderSubText={setHeaderSubText}
                    tokensToDonate={tokensToDonate}
                />
            ),
        }[currentSection];
    }

    return (
        <Modal
            cypressTestId="support-squad-donate-tokens-modal"
            cypressCloseButtonTestId="support-squad-donate-tokens-modal-close-button"
            headerText={headerText}
            headerSubText={headerSubText}
            onHide={handleCloseModal}
            show={shouldShowDonateTokensModal}
        >
            {renderCurrentSection()}
        </Modal>
    );
};

SupportSquadDonateTokensModal.propTypes = {
    credits: PropTypes.number,
    setShouldShowDonateTokensModal: PropTypes.func.isRequired,
    shouldShowDonateTokensModal: PropTypes.bool.isRequired,
};
