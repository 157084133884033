import React from "react";

// Components
import { ButtonPrimary } from "components/Buttons/Buttons";
import { CheckmarkSuccessIcon } from "components/Icons/CheckmarkSuccessIcon";
import { Text } from "components/Text/Text";

// CSS
import styles from "./purchase-modal-confirmation.module.scss";

// Localization
import { useTranslation } from "localization/localization";

// Utils
import { generateSuccessText } from "./utils";

export const PurchaseModalConfirmation = ({ handleCloseModal, purchase }) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * Constants
     */

    const successText = generateSuccessText(purchase);

    /**
     * End Constants
     */

    return (
        <div
            className={styles.purchaseModalConfirmation}
            data-cy="purchase-modal-success-container"
        >
            <CheckmarkSuccessIcon
                className={styles.checkmark}
                height="64"
                width="64"
            />
            <Text classes={styles.successText} type="h2">
                {successText.primaryText}
            </Text>
            <Text classes={styles.creditsText} type="h4">
                {successText.subText}
            </Text>
            <ButtonPrimary
                classes={styles.button}
                cypressTestId="purchase-modal-success-continue-button"
                onClick={handleCloseModal}
            >
                <Text>{t("continue", { ns: "common" })}</Text>
            </ButtonPrimary>
        </div>
    );
};
