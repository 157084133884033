import React from "react";
import PropTypes from "prop-types";
import InputAdornment from "@mui/material/InputAdornment";
import TextFieldMUI from "@mui/material/TextField";
import { ThemeProvider } from "@mui/material/styles";

// Theme
import { theme } from "./theme";

// Utils
import { generateColorProp, generateEndAdornment } from "./utils/utils";

export const TextFieldValidated = ({
    autoComplete,
    autoFocus,
    children,
    className,
    cypressTestId,
    defaultValue,
    disabled,
    error,
    helperText,
    id,
    isValid,
    isInvalid,
    label,
    name,
    onChange,
    required,
    size,
    type,
    value,
}) => {
    const hasChildren = React.Children.count(children) > 0;

    return (
        <ThemeProvider theme={theme}>
            <TextFieldMUI
                autoComplete={autoComplete}
                autoFocus={autoFocus}
                className={className}
                color={generateColorProp({ isInvalid, isValid })}
                defaultValue={defaultValue}
                disabled={disabled}
                error={error}
                helperText={helperText}
                id={id}
                inputProps={{ "data-cy": cypressTestId }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {generateEndAdornment({ isInvalid, isValid })}
                        </InputAdornment>
                    ),
                }}
                label={label}
                name={name}
                onChange={onChange}
                required={required}
                size={size}
                type={type}
                variant="outlined"
                value={value}
            >
                {hasChildren ? children : null}
            </TextFieldMUI>
        </ThemeProvider>
    );
};

TextFieldValidated.defaultProps = {
    autoFocus: false,
    className: "",
    disabled: false,
    error: false,
    select: false,
    size: "normal",
    type: "text",
};

TextFieldValidated.propTypes = {
    autoComplete: PropTypes.string,
    autoFocus: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    cypressTestId: PropTypes.string,
    defaultValue: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    id: PropTypes.string,
    isValid: PropTypes.bool,
    isInvalid: PropTypes.bool,
    label: PropTypes.string.isRequired,
    name: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    size: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
};
