import React from "react";
import PropTypes from "prop-types";

// Components
import { Text } from "components/Text/Text";

// CSS
import styles from "./muster-overview-active-recruits-panel.module.scss";

// Utils
import { handleKeyDown } from "utils/eventUtils";

export const MusterOverviewActiveRecruitsPanel = (props) => {
    const { handleToggleRecruitsModal, shipDates } = props;
    return (
        <div
            className={styles.musterOverviewActiveRecruitsPanel}
            data-cy="muster-overview-active-recruits-panel"
        >
            <Text classes={styles.header} isBold={true} type="h2">
                Active Recruits
            </Text>
            <div className={styles.cards}>
                <div
                    className={styles.card}
                    data-cy="muster-overview-active-recruits-panel-1-7-days"
                    onClick={handleToggleRecruitsModal}
                    onKeyDown={(e) =>
                        handleKeyDown(e, handleToggleRecruitsModal)
                    }
                    tabIndex="0"
                >
                    <div className={styles.text}>
                        <Text type="h1">{shipDates[0]?.value || 0}</Text>
                        <Text classes={styles.label} isBold={true} type="p">
                            SHIPPING
                            <br /> IN {shipDates[0]?.label}
                        </Text>
                    </div>
                </div>
                <div
                    className={styles.card}
                    data-cy="muster-overview-active-recruits-panel-8-30-days"
                    onClick={handleToggleRecruitsModal}
                    onKeyDown={(e) =>
                        handleKeyDown(e, handleToggleRecruitsModal)
                    }
                    tabIndex="0"
                >
                    <div className={styles.text}>
                        <Text type="h1">{shipDates[1]?.value || 0}</Text>
                        <Text classes={styles.label} isBold={true} type="p">
                            SHIPPING
                            <br /> IN {shipDates[1]?.label}
                        </Text>
                    </div>
                </div>
                <div
                    className={styles.card}
                    data-cy="muster-overview-active-recruits-panel-61+-days"
                    onClick={handleToggleRecruitsModal}
                    onKeyDown={(e) =>
                        handleKeyDown(e, handleToggleRecruitsModal)
                    }
                    tabIndex="0"
                >
                    <div className={styles.text}>
                        <Text type="h1">{shipDates[2]?.value || 0}</Text>
                        <Text classes={styles.label} isBold={true} type="p">
                            SHIPPING
                            <br /> IN {shipDates[2]?.label}
                        </Text>
                    </div>
                </div>
            </div>
        </div>
    );
};

MusterOverviewActiveRecruitsPanel.propTypes = {
    shipDates: PropTypes.array,
};
