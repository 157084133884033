import React from "react";
import PropTypes from "prop-types";

// Components
import { ButtonInvisible, ButtonPrimary } from "components/Buttons/Buttons";
import { SavedPaymentOptionsOption } from "components/PaymentOption/SavedPaymentOptions";
import { Text } from "components/Text/Text";

// Localization
import { useTranslation } from "localization/localization";

// Constants
import { NavigationEnum } from "../../constants/constants";
const { SELECT_PAYMENT } = NavigationEnum;

export const WalletManagePurchaseCardModalReview = ({
    giftCard,
    loading,
    handlePurchase,
    selectedCard,
    setCurrentSection,
    setLoading,
    setSelectedCard,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * End Hooks
     */

    const { amount } = giftCard;

    function handleGoBack() {
        setSelectedCard(null);
        setCurrentSection(SELECT_PAYMENT);
    }

    function handleSubmit() {
        setLoading({ ...loading, purchase: true });
        handlePurchase();
    }

    function renderFee() {
        return (
            <div className="letters-compose-purchase-review-list-item">
                <span className="letters-compose-purchase-review-list-item-name">
                    {t("fee", { ns: "common" })}
                </span>
                <span className="letters-compose-purchase-review-list-item-name">
                    ${amount.fee}
                </span>
            </div>
        );
    }

    function renderGiftCard() {
        return (
            <div className="letters-compose-purchase-review-list-item">
                <span className="letters-compose-purchase-review-list-item-name">
                    {t("gift_card_ip_singular", { ns: "common" })}
                </span>
                <span className="letters-compose-purchase-review-list-item-name">
                    ${amount.base}
                </span>
            </div>
        );
    }

    function renderPaymentMethod() {
        return (
            selectedCard?.card && (
                <div className="letters-compose-purchase-review__payment-method">
                    <div className="letters-compose-purchase-review__payment-method__header">
                        <span>{t("payment_method", { ns: "common" })}</span>
                    </div>
                    <SavedPaymentOptionsOption
                        card={selectedCard}
                        changeCurrentSection={setCurrentSection}
                        context="wallet"
                        endItem={{ type: "edit" }}
                        index={1}
                        isSelectedCard={true}
                        setSelectedCard={setSelectedCard}
                    />
                </div>
            )
        );
    }

    function renderTotal() {
        return (
            <div className="letters-compose-purchase-review-list-item letters-compose-purchase-review-list-total">
                <span className="letters-compose-purchase-review-list-item-name">
                    {t("total", { ns: "common" })}
                </span>
                <span className="letters-compose-purchase-review-list-item-name">
                    ${amount.total}
                </span>
            </div>
        );
    }

    return (
        <>
            {renderPaymentMethod()}
            <div className="letters-compose-purchase-review-list-container">
                {renderGiftCard()}
                {renderFee()}
                {renderTotal()}
            </div>
            <div className="button__container force-right">
                <ButtonInvisible onClick={handleGoBack} text="Back">
                    <Text>{t("back", { ns: "common" })}</Text>
                </ButtonInvisible>
                <ButtonPrimary
                    cypressTestId="wallet-purchase-modal-review-submit"
                    isLoading={loading.purchase}
                    onClick={handleSubmit}
                >
                    <Text>{t("purchase", { ns: "common" })}</Text>
                </ButtonPrimary>
            </div>
        </>
    );
};

WalletManagePurchaseCardModalReview.propTypes = {
    giftCard: PropTypes.object.isRequired,
    handleClosePurchaseCardModal: PropTypes.func.isRequired,
    handlePurchase: PropTypes.func.isRequired,
    loading: PropTypes.object.isRequired,
    selectedCard: PropTypes.object,
    setCurrentSection: PropTypes.func.isRequired,
    setLoading: PropTypes.func.isRequired,
    setSelectedCard: PropTypes.func.isRequired,
};
