import React from "react";
import { animated, useTransition } from "react-spring";

// CSS
import "./notification-corner.scss";

// Utils
import { handleKeyDown } from "utils/eventUtils";

export const NotificationCorner = ({
    children,
    classes,
    cyId,
    cypressCloseButtonTestId,
    onClose,
    shouldShow,
}) => {
    /**
     * Constants
     */

    const transition = useTransition(shouldShow, {
        from: { transform: "translate(-100%, 0%)" },
        enter: { transform: "translate(-100%, -100%)" },
        leave: { transform: "translate(-100%, 0%)" },
    });

    /**
     * End Constants
     */

    return transition((style, item) => {
        return (
            item && (
                <animated.div
                    className={`notification-corner ${classes}`}
                    data-cy={cyId}
                    style={style}
                >
                    <div
                        className="notification-corner__close material-icons"
                        data-cy={cypressCloseButtonTestId}
                        onClick={onClose}
                        onKeyDown={(e) => handleKeyDown(e, onClose)}
                        tabIndex="0"
                    >
                        close
                    </div>
                    {children}
                </animated.div>
            )
        );
    });
};
