import { useState } from "react";

// Enums
import { AddOnsNavigation } from "./enums/enums";
import { LettersComposeNavigation } from "./enums/enums";

// Hooks
import { useDocumentTitle } from "@uidotdev/usehooks";

// Localization
import { useTranslation } from "localization/localization";

export const useLettersCompose = () => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    useDocumentTitle(t("document_title", { ns: "letters" }));

    /**
     * useState
     */

    const [display, setDisplay] = useState({
        showAddOnsModal: false,
        showImageCropModal: false,
        showNewRecipientOption: false,
        showNoCreditsModal: false,
    });
    const [hasMultiPhoto, setHasMultiPhoto] = useState(false);
    const [hasEditedMessage, setHasEditedMessage] = useState(false);
    const [isUploadingImage, setIsUploadingImage] = useState(false);
    const [loading, setLoading] = useState({
        addOns: true,
        giftCardOptions: false,
        sendingLetter: false,
        isSubmittingForReview: false,
        updateUserAddress: false,
    });
    const [message, setMessage] = useState({
        content: "",
        customPhotoArray: [],
        isNameReversed: true,
    });
    const [navigation, setNavigation] = useState({
        addOnsModal: AddOnsNavigation.SELECT_DESIGN,
        currentSection: LettersComposeNavigation.COMPOSE,
    });
    const [recipient, setRecipient] = useState({});
    const [sentLetter, setSentLetter] = useState(null);
    const [sender, setSender] = useState({});
    const [supportSquad, setSupportSquad] = useState({});
    const [triggerGoToReview, setTriggerGoToReview] = useState(false);

    /**
     * End Hooks
     */

    return {
        display,
        hasEditedMessage,
        hasMultiPhoto,
        isUploadingImage,
        loading,
        message,
        navigation,
        recipient,
        sender,
        setDisplay,
        setHasMultiPhoto,
        setHasEditedMessage,
        setIsUploadingImage,
        setLoading,
        setMessage,
        setNavigation,
        setRecipient,
        sentLetter,
        setSentLetter,
        setSender,
        setSupportSquad,
        setTriggerGoToReview,
        supportSquad,
        triggerGoToReview,
    };
};
