import { useEffect, useMemo, useState } from "react";

// Localization
import { useTranslation } from "localization/localization";

export const useSignInWelcomeTypingEffect = ({
    delay,
    handleSwitchBackground,
    typingSpeed,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation("common");

    /**
     * useState
     */

    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentText, setCurrentText] = useState("");
    const [isDeleting, setIsDeleting] = useState(false);

    /**
     * useMemo
     */

    const captions = useMemo(
        () => [
            t("sign_in_welcome.connects_friends_and_family", {
                ns: "onboarding",
            }),
            t("sign_in_welcome.sends_letters", { ns: "onboarding" }),
            t("sign_in_welcome.builds_support_networks", { ns: "onboarding" }),
            t("sign_in_welcome.cares", { ns: "onboarding" }),
        ],
        [t]
    );

    /**
     * useEffect
     */

    useEffect(() => {
        const handleTyping = () => {
            const currentCaption = captions[currentIndex];
            let nextText;
            let nextIndex = currentIndex;
            let nextIsDeleting = isDeleting;

            if (isDeleting) {
                nextText = currentText.slice(0, -1);
                if (nextText === "") {
                    nextIsDeleting = false;
                    nextIndex = (currentIndex + 1) % captions.length;
                }
            } else {
                nextText = currentCaption.slice(0, currentText.length + 1);
                if (nextText === currentCaption) {
                    setCurrentText(nextText);
                    setTimeout(() => setIsDeleting(true), delay);
                    return;
                }
            }

            setCurrentText(nextText);
            setIsDeleting(nextIsDeleting);

            if (nextIndex !== currentIndex) {
                setCurrentIndex(nextIndex);
                handleSwitchBackground();
            }
        };

        const typingTimeout = setTimeout(
            handleTyping,
            isDeleting ? typingSpeed / 2 : typingSpeed
        );

        return () => clearTimeout(typingTimeout);
    }, [
        currentIndex,
        currentText,
        delay,
        isDeleting,
        captions,
        typingSpeed,
        handleSwitchBackground,
    ]);

    /**
     * End Hooks
     */

    return { currentText };
};
