// Platforms
import { Iterable } from "platforms/iterable/iterable";

// Utils
import { formatIterableEmbeddedMessageForPreviewCard } from "platforms/iterable/utils/utils";

export const useDashboardMessagesPreviewCard = ({ message }) => {
    /**
     * Constants
     */

    const item = message
        ? formatIterableEmbeddedMessageForPreviewCard({ message })
        : null;

    /**
     * End Constants
     */

    function handleTrackIterableClickEvent() {
        Iterable.trackEmbeddedMessageClickEvent({ message });
    }

    return { handleTrackIterableClickEvent, item };
};
