import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Components
import { SmartLink } from "components/SmartLink/SmartLink";
import { Text } from "components/Text/Text";

// CSS
import styles from "./carousel.module.scss";

// Hooks
import { useCarousel } from "./hooks/useCarousel";

export const CarouselV2 = ({ children, classNames, gap, links, title }) => {
    /**
     * Custom Hooks
     */

    const { activeIndex, childrenCount, carouselWrapperRef, scrollToItem } =
        useCarousel({ children, gap });

    /**
     * End Hooks
     */

    function renderHeader() {
        return (
            <div className={styles.header}>
                <div className={styles.title}>
                    {title && (
                        <Text classes={styles.title} isBold>
                            {title}
                        </Text>
                    )}
                </div>
                <div className={styles.actions}>
                    <div className={styles.links}>
                        {links.map((link, i) => {
                            return (
                                <SmartLink
                                    className={cx(styles.link, link.className)}
                                    config={{ shouldOpenNewWindow: true }}
                                    key={i}
                                    to={link.url}
                                >
                                    {link.text}
                                </SmartLink>
                            );
                        })}
                    </div>
                    <div className={styles.controller}>
                        <div
                            className={cx(styles.arrowContainer, {
                                [styles["arrowContainer--disabled"]]: !(
                                    activeIndex > 0
                                ),
                            })}
                            onClick={() => scrollToItem(activeIndex - 1)}
                        >
                            <span
                                className={cx(styles.arrow, "material-icons")}
                            >
                                arrow_back
                            </span>
                        </div>
                        <div
                            className={cx(styles.arrowContainer, {
                                [styles["arrowContainer--disabled"]]: !(
                                    activeIndex <
                                    childrenCount - 1
                                ),
                            })}
                            onClick={() => scrollToItem(activeIndex + 1)}
                        >
                            <span
                                className={cx(styles.arrow, "material-icons")}
                            >
                                arrow_forward
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={cx(styles.carousel, classNames.container)}>
            {renderHeader()}
            <div
                className={cx(styles.wrapper, classNames.wrapper)}
                ref={carouselWrapperRef}
            >
                {React.Children.map(children, (child, i) => {
                    return (
                        <div
                            className={cx(styles.item, classNames.item)}
                            key={i}
                            style={{ marginRight: `${gap}px` }}
                        >
                            {child}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

CarouselV2.defaultProps = {
    classNames: {
        container: "",
        item: "",
        wrapper: "",
    },
    gap: 16,
    links: [],
};

CarouselV2.propTypes = {
    classNames: PropTypes.shape({
        container: PropTypes.string,
        item: PropTypes.string,
        wrapper: PropTypes.string,
    }),
    children: PropTypes.node,
    links: PropTypes.arrayOf(
        PropTypes.shape({
            className: PropTypes.string,
            text: PropTypes.string,
            url: PropTypes.string,
        })
    ),
    gap: PropTypes.number,
    title: PropTypes.string,
};
